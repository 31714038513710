import styled from 'styled-components';
export const Container = styled.div`
    display : flex;
    flex-direction : column;
    background : white;
    background-image: url(https://static.admirian.com/11d72d78-559f-42ae-abe9-f11902b534ed_background.svg);
    background-color : #F6F6FF;
    background-repeat : no-repeat;
    background-size : contain;
    // overflow-x : hidden;
`
export const HeadingContainer = styled.div`
    padding-top : ${(props) => {
      if (!props.isMobile) {
        return '112px';
      }else {
        return '72px';
      }
    }};
    margin : auto;
    margin-top : 0px;
    display : flex; 
    justify-content : center;
    flex-direction : column ;
`
export const  FooterContainer = styled.div`
    height : 60px;
    display: flex;
    background:#FFFFFF;
    justify-content : space-between;
`

export const Heading1 = styled.div`
    display : flex ;
    align-items : center;
    flex-direction : column;
    color : #010043;
    font-weight : ${(props) => {
      if (!props.isMobile) {
        return '700';
      }else {
        return '600';
      }
    }};;
    font-size : ${(props) => {
      if (!props.isMobile) {
        return '44px';
      }else {
        return '32px';
      }
    }};
    line-height :${(props) => {
      if (!props.isMobile) {
        return '53px';
      }else {
        return '42px';
      }
    }};
    width : 100%;
    background : url(https://static.admirian.com/353fcb36-11b8-40df-a3e6-7730995afe39_headingtop.svg);
    background-repeat : no-repeat;
    background-position-x : center;
    height : ${(props) => {
      if (!props.isMobile) {
        return '255px';
      }else {
        return '';
      }
    }};
    background-size : contain ;
`
export const Heading2 = styled.div`
    display : flex ;
    align-items : center;
    flex-direction : column;
    width : 100%;
`
export const Heading3 = styled.div`
    display : flex ;
    align-items : center;
    flex-direction : column;
    border-radius : ${(props) => {
      if (!props.isMobile) {
        return '30px';
      }else {
        return '';
      }
    }};
    background: linear-gradient(180deg, #241046 0%, #130D58 100%);
    font-weight : 700;
    font-size : 32px;
    line-height : 39px;
    margin :${(props) => {
      if (!props.isMobile) {
        return '0 80px';
      }else {
        return '';
      }
    }};
    padding : ${(props) => {
      if (!props.isMobile) {
        return '';
      }else {
        return '40px';
      }
    }};
`
export const Heading4 = styled.div`
    display : flex ;
    align-items : center;
    flex-direction : column;
    font-size : 16px;
    margin : ${(props) => {
      if (!props.isMobile) {
        return '80px';
      }else {
        return '20px 12px';
      }
    }};
`
export const Heading5 = styled.div`
    display : flex ;
    align-items : center;
    flex-direction : column;
    border-radius : 30px;
    background: linear-gradient(96.74deg, #4E4BEE -1.75%, #F86624 112.67%);
    font-weight : 700;
    font-size : 16px;
    line-height : 19px;
    margin : ${(props) => {
      if (!props.isMobile) {
        return '0px 80px';
      }else {
        return '0px 20px';
      }
    }};
    padding: ${(props) => {
      if (!props.isMobile) {
        return '0px 0px 30px 0px';
      }else {
        return '32px';
      }
    }};
    margin-bottom : ${(props) => {
      if (!props.isMobile) {
        return '80px';
      }else {
        return '48px';
      }
    }};
`
export const Heading6 = styled.div`
    display : flex ;
    align-items : center;
    flex-direction : column;
    background-image: url(https://static.admirian.com/bf8a5a12-22bf-46fa-a0b4-74eca567ac31_profile.png) , linear-gradient(180deg, #241046 0%, #130D58 100%);
    background-position-y: center;
    background-size : contain ;
    background-position: top;
    background-repeat : no-repeat; 
    font-weight : 700;
    font-size : 16px;
    line-height : 19px;
    padding-bottom : 30px ;
`
export const Heading8 = styled.div`
    display : flex ;
    align-items : center;
    flex-direction : column;
    background:linear-gradient(180deg, #241046 0%, #130D58 100%);
    font-size : 18px;
    line-height : 22px;
    padding : 15px ;
    color: #FFFFFF;
    text-align : center;
`

export const Heading7 = styled.div`
    display : flex ;
    align-items : center;
    flex-direction : column;
    background: #4E4BEE;
`

export const Info = styled.div`
    padding-top : ${(props) => {
      if (!props.isMobile) {
        return '20px';
      }else {
        return '18px';
      }
    }};
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: ${(props) => {
      if (!props.isMobile) {
        return '18px';
      }else {
        return '14px';
      }
    }};
    line-height: ${(props) => {
      if (!props.isMobile) {
        return '20px';
      }else {
        return '22px';
      }
    }};
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;

    /* Brand Gradient 1 */

    background: linear-gradient(96.74deg, #4E4BEE -1.75%, #F86624 112.67%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;


    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
`
export const CardHeading = styled.div`
    display : flex ;
    justify-content : center ;
    text-align : center;
    width : 100%;
    color : #4E4BEE;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    padding-top : 20px;
`
export const CardDetail = styled.div`
    display : flex ;
    justify-content : center ;
    text-align : center;
    width : 90%;
    color : #010043;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    padding : 15px;
`
export const RadioContainer = styled.div`
    padding : 8px;
    border-radius: 110px;
    border: 1px solid #050360;
    margin-top : 15px;
    margin-bottom : 50px;
  .ant-radio-button-wrapper {
    background: #F6F6FF;
    border-color: #F6F6FF;
    color: #263f97;
    border-radius: 20px;
    &:first-child {
      padding:0px 30px;
      margin-right : 15px;
    }
    &:last-child {
      padding:0px 30px;
    }
    text-align: center;
  }
  .ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: #050360;
    border-color: #050360;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    display: none;
  }
  .ant-radio-button-wrapper:not(:first-child)::before {
    display: none;
  }
`;

export const DetailsHeading =styled.div`
    display : flex;
    width : 100%;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    padding : 15px 0px;
    /* Brand Gradient 1 */

    background: linear-gradient(96.74deg, #4E4BEE -1.75%, #F86624 112.67%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
`

export const DetailsInfo = styled.div`
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #010043;
`

export const CollapseHeader = styled.div`
  color : #010043;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
`
export const CollapseDetail = styled.div`
  color : #010043;
  font-size: 18px;
  line-height: 22px;
`