import styled from "styled-components";
import { Button, Col, Row } from "antd";


export const CreatorAnalyticsContainer = styled.div`
    display : flex;
    flex-direction : column;
    .ant-carousel{
        width : 100%;
    }
    .slick-dots{
        width : 0px;
    }
`

export const AnalyticsDetailCard = styled.div`
    padding: 28px 16px;
    background: var(--surface-light, #F6FAFF);
    border-radius: 10px;
    width : 100%;
    text-align: center;
`

export const CreatorMediaContainer = styled.div`
    display : flex;
    flex-direction : column;
    .ant-carousel{
        width : 100%;
    }
    .slick-dots{
        width : 0px;
    }
    // .slick-track{
    //     width : 100% !important;
    // }
`

export const FeaturedMediaCard = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    gap: 16px;
    background: #FFFFFF;
    border-radius: 10px;
` 
export const ToggleButton = styled(Button)`
    display: flex;
    border: 0px;
    border-radius: 50%;
    background: rgb(255, 255, 255);
    height: 35px;
    width: 35px;
    justify-content: center;
    align-items: center;
`

export const CompanyLogoContainer = styled.div`
  width: 32px;
  height: 32px;
  background: #FFFFFF;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  margin-right: 10px;
  align-items : flex-start;
  border: 0px; 
  margin-right : 4px;
  background: inherit;
`
export const CardHeadingAnalytics = styled.div`
    color: var(--typo-high, #0F0E1B);

    /* H3 */
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
`

export const ChannelMetricsContainer = styled.div`
    justify-content : space-between; 
    margin-bottom : 24px;
    background : #fff; 
    padding : 32px; 
    border-radius : 10px;
`;

export const CustomRow = styled(Row)`
    display : flex; 
    justify-content : space-evenly; 
    margin-bottom : 24px;
`;

export const CustomColumn = styled(Col)`
    display: flex;
    justify-content: center; 
    align-items: stretch;
    text-align: center;
`;

export const AnalyticsCountText = styled.div`
    font-size: 32px;
    font-weight: 700;
    color: #010043;
`;

export const AnalyticsInfoText = styled.div`
    font-size: 18px;
    color: #01004380;
`;

export const PercentageInformationText = styled.div`
    font-size : 18px; 
    font-weight : 500; 
    line-height : 22px;
    text-align: center;
`;

export const DaysInfoText = styled.div`
    font-size : 12px; 
    color : #010043;
`;

export const AnalyticsTextHeading = styled.div`
    display : flex; 
    width : 100%;
    justify-content : space-between;
    font-size : 18px; 
    font-weight : 700; 
    color : #010043; 
    margin-bottom : 24px;
`;

export const PlayInformationText = styled.div`
    font-size : 16px;
    font-weight : 600;
    color : #010043;
`;

export const PlayText = styled.div`
    font-size : 12px;
    color : #01004380;
`;

export const MediaInfoText = styled.div`
    font-size : 12px; 
    color : #494785;
`;

export const MostViewedText = styled.div`
    width : 100%; 
    color : #010043; 
    font-size : 18px; 
    font-weight : 600;
`;

export const FeaturedMediaText = styled.div`
    font-size : 24px;
    font-weight : 600;
    color : #010043;
`;

export const ViewedText = styled.div`
    font-size : 16px;
    color : #01004380;
`;

export const DemographicText = styled.div`
    font-size : 12px; 
    color : #010043;
`;

export const NoDataAvailableContainer = styled.div`
    display : flex; 
    justify-content : center;
    color: red; 
    font-size : 20px;
    fontWeight : 600;
`;

export const AudienceCountText = styled.div`
    font-size : 40px;
    font-weight : 700;
    color : #010043;
`;

export const ShareInformationText = styled.div`
    font-size : 18px;
    color : #01004380;
`;

export const UserNameText = styled.div`
    color : #0F0E1B;
    font-size : 14px;
    font-weight : 400;
`;

export const PublishedInformation = styled.span`
    color : #8988BF; 
    font-size : 12px;
    margin-left : 8px;
`;

export const DisplayText = styled.div`
    color : #0F0E1B;
    font-size : 14px;
    line-height : 20px;
`;

export const DensityContainer = styled.div`
    padding : 24px;
    background : #FFFFFF;
    border-radius : 10px;
    margin : 24px 0px;
`;