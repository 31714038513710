import styled from 'styled-components';
import media from '../../utils/UIElements/media';

export const Container = styled.div`
    display :flex;
    width : 100%;
    background-image: url(https://static.admirian.com/3e0a7f83-638a-4137-910b-f4402ba5abef_SignInBackground.png);
    background-repeat : no-repeat; 
    background-position-y : -44px;
    flex-direction : column;
    min-height : 80vh;
`

export const MotoContainer = styled.div`
    display :flex;
    align-items : center;
    justify-content : flex-start;
    width : 100%;
    height : 100%;
    font-weight: 600; 
    color: #fff; 
    font-size: 120px; 
    line-height: 144px;
`

export const SignInFormContainer = styled.div`
    display: flex; 
    padding : 24px 24px;
    align-items: center;
    text-align: center; 
    width: 400px; 
    flex-direction: column; 
    min-height: 400px; 
    background: #FFFFFF; 
    margin: 80px 0px 20px 0px; 
    border-radius: 10px; 
    boxShadow: 0px 0px 33px 16px rgba(67, 184, 206, 0.2);
    ${media.mobile`
        margin: 40px 0px 20px 0px; 
    `}
`;

export const SignUpFormText = styled.div`
    display: flex; 
    font-weight: 600; 
    color: #010043;
    font-size: 32px; 
    line-height: 40px;
    margin-bottom: 12px;
    ${media.mobile`
        font-size : 24px;
        text-align: center;
        justify-content: center;
    `}
`;