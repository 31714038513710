import { Button, Col, Dropdown, Menu, Row, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { AdmirianButton, AdmirianPlainButton, CompanyDetails, CompanyLogoContainer, HeaderContainer } from "./style";
import { Link, useNavigate } from "react-router-dom";
import {MehFilled} from '@ant-design/icons';
import Icon, {LogoutOutlined } from '@ant-design/icons';

const DashboardHeader = ({active,setActive,brandDetails}) => {
    const navigate = useNavigate();
    const menu = (
        <Menu style={{ borderRadius: '8px'}}>
            <Menu.Item key='logout'>
            <div
                onClick={() => {
                    localStorage.removeItem(`access_token`)
                    localStorage.removeItem(`refresh_token`)
                    navigate(`/`)
                }}
                style={{ cursor: 'pointer' }}
            >
                <LogoutOutlined/>
                <span style={{ marginLeft: '8px' }}>Logout</span>
            </div>
            </Menu.Item>
        </Menu>
    )

    
    return(
        <HeaderContainer>
            <Row gutter={[20,30]} style={{width : '100%'}}>
                <Col span={18} style={{display : 'flex',alignItems : 'center'}}>
                    <div style={{width : '250px'}}>
                    <Link to="/discover">
                    <img style={{height : '42px'}} src="https://static.admirian.com/9a312601-984e-4237-bfad-1454204ce38f_BrandLogo.svg"/>
                    </Link>
                    </div>
                    <Link to="/discover">
                    <AdmirianButton
                    icon = {<img src="https://static.admirian.com/d50bb654-731f-4962-a118-5d4f36152203_search.svg"/>}
                    isActive = {active === "Discover" ? true : false}
                    onClick={() => {
                        setActive("Discover")
                    }}
                    >
                        
                        Discover
                        
                    </AdmirianButton>
                    </Link>
                    {/* <Link to="/dashboard">
                    <AdmirianButton
                    icon = {<img src="https://static.admirian.com/6aad7296-d8dd-4f7b-9328-c25285d61c7c_settingLogo.svg"/>}
                    isActive = {active === "Dashboard" ? true : false}
                    onClick={() => {
                        setActive("Dashboard")
                        
                    }}
                    >
                       
                        Dashboard
                        
                    </AdmirianButton>
                    </Link> */}
                    <Link to="/manage-campaigns">
                    <AdmirianButton
    
                    icon = {<img src={active === "Manage Campaigns" ? "https://static.admirian.com/e04716d8-0cd7-4376-9863-e1d2fb76f00d_manage-campaigns-icon.svg" : "https://static.admirian.com/fc2cf1b1-4f54-4840-afc3-bc3c5b0ac200_manage-campaigns-icon-black.svg"}/>}
                    isActive = {active === "Manage Campaigns" ? true : false}
                    onClick={() => {
                        setActive("Manage Campaigns")
                        
                    }}
                    >                       
                        Manage Campaigns                      
                    </AdmirianButton>
                    </Link>
                    <Link to="/profile">
                    <AdmirianButton
                    icon = {<img src="https://static.admirian.com/6aad7296-d8dd-4f7b-9328-c25285d61c7c_settingLogo.svg"/>}
                    isActive = {active === "Profile" ? true : false}
                    onClick={() => {
                        setActive("Profile")
                        
                    }}
                    >
                       
                       Profile
                      
                    </AdmirianButton>
                    </Link>
                </Col>
                <Col span={6} style={{display : 'flex',alignItems : 'center',justifyContent : "flex-end"}}>
                    <CompanyDetails>
                    {brandDetails && brandDetails?.brand_name}
                    </CompanyDetails>
                    <Dropdown overlay={menu} placement="bottomRight"> 
                    <a
                        className="ant-dropdown-link"
                        onClick={(e) => e.preventDefault()}
                    >
                    <CompanyLogoContainer>
                    {brandDetails?.logo ? 
                        <img
                        src={brandDetails?.logo}
                        alt="logo"
                        style={{
                        height: '28px',
                        width: '28px',
                        borderRadius: '50%',
                        }}
                        />
                        : 
                        <MehFilled style={{borderRadius : '50%', fontSize : '28px', color : 'gray'}} />
                    }
                    </CompanyLogoContainer>
                    </a>
                    </Dropdown>
                    
                </Col>
            </Row>
        </HeaderContainer>
    )

}

export default DashboardHeader