import { Button, Col, Row,Radio, Collapse, Image, Divider} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import Header from "./Header";
import { CardDetail, CardHeading, CollapseDetail, CollapseHeader, Container, DetailsHeading, DetailsInfo, FooterContainer, Heading1, Heading2, Heading3, Heading4, Heading5, Heading6, Heading7, Heading8, HeadingContainer, Info,RadioContainer } from "./style";
import Brand from "./Header/Brand";
import Influencer from "./Header/Influencer";
import SignUpForm from "./SignUpForm";
import {PlusOutlined, MinusOutlined} from '@ant-design/icons';
import LeadForm from "./LeadForm";
import { Link, useNavigate } from "react-router-dom";
import { isMobile } from "../../utils/constants/inedx";

const { Panel } = Collapse;
const Home = () =>{

    const navigate = useNavigate();

    const [campaignUserType, setCampaignUserType]= useState('brand');
    const [headerText, setHeaderText] = useState('Unleash the power of influencer marketing with our platform!')

    const headerTitle = [
        'Unleash the power of influencer marketing with our platform!', 'Boost engagement, drive conversions and track your ROI','Start your campaign today and see the results for yourself!'
    ]
    const shuffle = useCallback(() => {
        const index = Math.floor(Math.random() * headerTitle.length);
        setHeaderText(headerTitle[index]);
    }, []);

    useEffect(() => {
        const intervalID = setInterval(shuffle, 2000);
        return () => clearInterval(intervalID);
    }, [shuffle])


    const RadioGroup = ({ campaignUserType, setCampaignUserType }) => {
        return (
          <RadioContainer className="mb-16">
            <Radio.Group
              defaultValue="live"
              buttonStyle="solid"
              value={campaignUserType}
              onChange={(e) => setCampaignUserType(e.target.value)}
            >
              <Radio.Button value="brand">Brands</Radio.Button>
              <Radio.Button value="influencer"> Influencer</Radio.Button>
            </Radio.Group>
          </RadioContainer>
        );
      };

    const components = {
    brand: (
        <Brand/>
    ),
    influencer: (
        <Influencer/>
    ),
    };

    return(
        <Container>
            <Header isMobile = {isMobile}>

            </Header>
            <HeadingContainer isMobile = {isMobile}>
                <Heading1 isMobile = {isMobile}>
                <span style={{width : '50%', textAlign : 'center'}}>{headerText}</span>
                {/* <Link to={'/Sign-up'}> */}
                <Link to={'/request-demo'}>
                <Button
                // onClick={()=>document.getElementById("SignUp-form").scrollIntoView({behavior: "smooth",block: "end"})}
                style={{ height : "50px",background : "#F86624", borderRadius : "110px", border : "0px", color : "#FFFFFF", width :!isMobile ? "150px" : '200px',marginTop : "35px", fontSize : "18px"}}>
                    Request Demo
                </Button>
                </Link>
                <Info isMobile = {isMobile}>93% of Marketers Have Used Influencer Marketing</Info>
                </Heading1>
                <Heading2 isMobile = {isMobile}>
                    <Row style={{minWidth : "100%",justifyContent : "space-evenly",marginTop : "30px",marginBottom : "30px",overflowX : 'hidden'}}>
                        <Col span={6} style = {{display : "flex",justifyContent : 'center',height : "inherit",overflow : 'hidden' }}>
                            <img src="https://static.admirian.com/765551d6-1c4f-445a-8b78-107c2f98ac99_targetedAudience.png" style ={{width :!isMobile ?  '-webkit-fill-available' : '100%',height :!isMobile ?  '-webkit-fill-available' : '100%',overflowX : 'hidden'}}></img>
                        </Col>
                        <Col span={11} style = {{display : "flex",justifyContent : 'center',height : "inherit",overflow : 'hidden'}}>
                            <img src="https://static.admirian.com/9151538e-afea-4583-8d2c-7183b3493085_brandAwareness.png" style ={{width :!isMobile ?  '-webkit-fill-available' : '100%',height :!isMobile ?  '-webkit-fill-available' : '100%'}}></img>
                        </Col>
                        <Col span={6} style = {{display : "flex",justifyContent : 'center',height : "inherit",overflow : 'hidden'}}>
                            <img src="https://static.admirian.com/f24624e2-1e79-4e35-b3f3-9aa7addfa865_improvedRoi.png" style ={{width :!isMobile ?  '-webkit-fill-available' : '100%',height :!isMobile ?  '-webkit-fill-available' : '100%',overflowX : 'hidden'}}></img>
                        </Col>
                    </Row>
                </Heading2>
                <Heading3 isMobile = {isMobile}>
                <span style={{
                    background: "linear-gradient(96.74deg, #4E4BEE -1.75%, #F86624 112.67%)",
                    WebkitBackgroundClip : "text",
                    WebkitTextFillColor : "transparent",
                    marginTop : "40px",
                    width : !isMobile ? '50%' : '100%',
                    textAlign : 'center'
                }} >
                    Choose flexible campaign methods to create great impact
                </span>
                <Row style={{minWidth : "100%",justifyContent : "space-evenly",marginTop : "30px",marginBottom : "30px", gap : isMobile ? '20px' : ''}}>
                    <Col span={!isMobile ? 6 : 24} style={{display : 'flex'}}>
                        <div style={{margin : !isMobile ? "10px" : 'auto',borderRadius : "20px", background : "#F6F6FF", minHeight :!isMobile ?  "200px" : '', width : !isMobile ?  '100%' : ''}}>
                            {/* <CardHeading>
                                Only Content 
                            </CardHeading>
                            <CardDetail>
                            Influencers create content that aligns with brand’s vision and identity.
                            </CardDetail> */}
                            <img src="https://static.admirian.com/f46e1a9d-7144-45d9-a9af-ba9ccb71b0da_OnlyContent.png" style ={{width : !isMobile ? '-webkit-fill-available' : '100%' , height : isMobile ? '100%' : '',padding : !isMobile ? '' : '20px 0px'}}/>
                        </div>
                    </Col>
                    <Col span={!isMobile ? 6 : 24} style={{display : 'flex'}}>
                        <div style={{margin : !isMobile ? "10px" : 'auto',borderRadius : "20px", background : "#F6F6FF", minHeight : !isMobile ?  "200px" : '', width : !isMobile ?  '100%' : ''}}>
                            {/* <CardHeading>
                                Organic Post  
                            </CardHeading>
                            <CardDetail>
                            Campaign that looks natural and appeals to your customers.
                            </CardDetail> */}
                            <img src="https://static.admirian.com/77a7c5d8-e869-4e56-b299-47e1b3f02e31_OrganicPost.png" style ={{width : !isMobile ? '-webkit-fill-available' : '100%' , height : isMobile ? '100%' : '',padding : !isMobile ? '' : '20px 0px'}}/>
                        </div>
                    </Col>
                    <Col span={!isMobile ? 6 : 24} style={{display : 'flex'}}>
                        <div style={{margin : !isMobile ? "10px" : 'auto',borderRadius : "20px", background : "#F6F6FF", minHeight : !isMobile ?  "200px" : '' , width : !isMobile ?  '100%' : ''}}>
                            {/* <CardHeading>
                                Creator Ads
                            </CardHeading>
                            <CardDetail>
                            Create tailored content and promote it effectively 
                            </CardDetail> */}
                            <img src="https://static.admirian.com/dd2dd111-22dc-4f85-82d3-5037cb71f4cb_CreaterAds.png" style ={{width : !isMobile ? '-webkit-fill-available' : '100%' , height : isMobile ? '100%' : '',padding : !isMobile ? '' : '20px 0px'}}/>
                        </div>
                    </Col>
                </Row>
                </Heading3>
                {!isMobile ?
                    <Heading4>
                        <span style={{color : "#4E4BEE"}}>Simple 3 step process for great campaign</span>
                        <RadioGroup campaignUserType={campaignUserType} setCampaignUserType={setCampaignUserType} />
                        {components[campaignUserType]}
                    </Heading4>
                    :
                    <Heading4 isMobile = {isMobile}>
                        <span style={{color : "#4E4BEE"}}>Simple 3 step process for great campaign</span>
                        <span style={{width : '100%',marginTop : '32px', marginBottom : '16px', color : 'var(--Typo-High, #0F0E1B)', fontSize : '18px',fontWeight : 500}}>
                            For Brands
                        </span>
                        <Col span={24} style={{display : 'flex', flexDirection : 'row',marginBottom : '30px',width : '100%'}}>
                            <Button 
                            style={{padding : '4px 12px', marginRight : '25px', background : '#FFFFFF' , borderRadius : '400px', border :'1px solid #4E4BEE'}}
                            >
                                1
                            </Button>
                            <div>
                                <span style={{fontSize : '32px', lineHeight : '39px',color : '#010043',fontWeight : 700}}>
                                <span style={{color : "#4E4BEE"}}> Find a best match </span> for your campaign
                                </span>
                                <span style={{fontSize : '16px', lineHeight : '19px',color : '#050360', display : 'flex'}}>
                                Search for influencers based on specific criteria like Category, location, Age range and more.
                                </span>
                            </div>
                        </Col>
                        <Col span={24} style={{display : 'flex', justifyContent : 'center', alignItems : 'center', minHeight : '200px',marginBottom : '32px'}}>
                            <Image style={{height : 'inherit'}} preview = {false} src="https://static.admirian.com/a629b759-9e4c-4cf0-bcc5-910ee6158c77_BrandImage1.png"/>
                        </Col>
                        <Col span={24} style={{display : 'flex', flexDirection : 'row',marginBottom : '30px',width : '100%'}}>
                        <Button 
                        style={{padding : '4px 12px', marginRight : '25px', background : '#FFFFFF' , borderRadius : '400px', border : '1px solid #4E4BEE'}}
                        >
                            2
                        </Button>
                        <div>
                        <span style={{fontSize : '32px', lineHeight : '39px',color : '#010043',fontWeight : 700}}>
                        <span style={{color : "#4E4BEE"}}>Analyse and team up </span>with right influencers 
                        </span>
                        </div>
                        </Col>
                        <Col span={24} style={{display : 'flex', justifyContent : 'center', alignItems : 'center', minHeight : '200px',marginBottom : '32px'}}>
                            <Image style={{height : 'inherit'}} preview = {false} src="https://static.admirian.com/452cac1b-685a-4d05-8227-81e6118c2137_BrandImage2.png"/>
                        </Col>
                        <Col span={24} style={{display : 'flex', flexDirection : 'row',marginBottom : '30px',width : '100%'}}>
                        <Button 
                        style={{padding : '4px 12px', marginRight : '25px', background : '#FFFFFF' , borderRadius : '400px', border : '1px solid #4E4BEE'}}
                        >
                            3
                        </Button>
                        <div>
                        <span style={{fontSize : '32px', lineHeight : '39px',color : '#010043',fontWeight : 700}}>
                        <span style={{color : "#4E4BEE"}}>Track campaign </span>and measure ROI
                        </span>
                        </div>
                        </Col>
                        <Col span={24} style={{display : 'flex', justifyContent : 'center', alignItems : 'center', minHeight : '200px',marginBottom : '32px'}}>
                            <Image style={{height : 'inherit'}} preview = {false} src="https://static.admirian.com/e1753d9b-eaf2-49e0-bb5d-5514ad8fcace_BrandImage3.png"/>
                        </Col>
                        <Divider style={{borderBlockStart : '1px solid var(--Storke-light, #D8D7EA)'}} />

                        <span style={{width : '100%', marginBottom : '16px', color : 'var(--Typo-High, #0F0E1B)', fontSize : '18px',fontWeight : 500}}>
                        For Influencer
                        </span>
                        <Col span={24} style={{display : 'flex', flexDirection : 'row',marginBottom : '30px',width : '100%'}}>
                        <Button 
                        style={{padding : '4px 12px', marginRight : '25px', background : '#FFFFFF' , borderRadius : '400px', border : '1px solid #4E4BEE'}}
                        >
                           1
                        </Button>
                        <div>
                        <span style={{fontSize : '32px', lineHeight : '39px',color : '#010043',fontWeight : 700}}>
                        <span style={{color : "#4E4BEE"}}>Connect</span> your social media account
                        </span>
                        <span style={{fontSize : '16px', lineHeight : '19px',color : '#050360', display : 'flex'}}>
                        With powerful algorithm we match your profile with brands that align with your values 
                        </span>
                        </div>
                        </Col>
                        <Col span={24} style={{display : 'flex', justifyContent : 'center', alignItems : 'center', minHeight : '200px',marginBottom : '32px'}}>
                            <Image style={{height : 'inherit'}} preview = {false} src="https://static.admirian.com/7ac3a535-c952-478b-b596-5cda4b8ed633_InfluencerImage1.png"/>
                        </Col>
                        <Col span={24} style={{display : 'flex', flexDirection : 'row',marginBottom : '30px',width : '100%'}}>
                        <Button 
                        style={{padding : '4px 12px', marginRight : '25px', background : '#FFFFFF' , borderRadius : '400px', border : '1px solid #4E4BEE'}}
                        >
                           2
                        </Button>
                        <div>
                        <span style={{fontSize : '32px', lineHeight : '39px',color : '#010043',fontWeight : 700}}>
                        <span style={{color : "#4E4BEE"}}>Collaborate </span>with brands
                        </span>
                        </div>
                        </Col>
                        <Col span={24} style={{display : 'flex', justifyContent : 'center', alignItems : 'center', minHeight : '200px',marginBottom : '32px'}}>
                            <Image style={{height : 'inherit'}} preview = {false} src="https://static.admirian.com/8c4a23aa-e32a-44b1-aed4-734eb855a0b5_InfluencerImage2.png"/>
                        </Col>
                        <Col span={24} style={{display : 'flex', flexDirection : 'row',marginBottom : '30px',width : '100%'}}>
                        <Button 
                        style={{padding : '4px 12px', marginRight : '25px', background : '#FFFFFF' , borderRadius : '400px', border : '1px solid #4E4BEE'}}
                        >
                           3
                        </Button>
                        <div>
                        <span style={{fontSize : '32px', lineHeight : '39px',color : '#010043',fontWeight : 700}}>
                        <span style={{color : "#4E4BEE"}}>Monetize </span>your content
                        </span>
                        </div>
                        </Col>
                        <Col span={24} style={{display : 'flex', justifyContent : 'center', alignItems : 'center', minHeight : '200px',marginBottom : '32px'}}>
                            <Image style={{height : 'inherit'}} preview = {false} src="https://static.admirian.com/4a77ab93-6a05-41c0-9e9e-9b3801aae52b_InfluencerImage3.png"/>
                        </Col>

                    </Heading4>
                }
                <Heading5 isMobile= {isMobile}>
                    <span style={{width : "100%",color : "#FFFFFF", display : "flex",paddingTop : "30px", marginLeft : !isMobile ? "100px" : ''}}>What’s so special</span>
                    <span style={{width : "100%",color : "#FFFFFF", display : "flex",padding : "15px", fontWeight : !isMobile ? 700 : 600, fontSize : "32px", lineHeight : "39px", marginLeft :  !isMobile ? "100px" : '', marginBottom : !isMobile ? '50px' : '40px'}}>We make collaboration easier than ever.</span>

                    <Row gutter={[20,20]} style={{display : 'flex', justifyContent : 'space-evenly', width : '100%'}}>
                        <Col lg={7} xl={7} md={12} sm={24} xm={24} style={{display : 'flex', width : '100%',padding :isMobile ? '0px' : ''}}>
                            <div style={{display : 'flex',flexDirection : 'column', width :!isMobile ? '90%' : '100%', background : '#FFFFFF', borderRadius : '10px',padding : "20px",margin : 'auto'}}>
                                <DetailsHeading>
                                Direct collaborations
                                </DetailsHeading>
                                <DetailsInfo>
                                Build quick relationship between brands and influencers
                                </DetailsInfo>
                            </div>
                        </Col>
                        <Col lg={7} xl={7} md={12} sm={24} xm={24} style={{display : 'flex', width : '100%',padding :isMobile ? '0px' : ''}}>
                            <div style={{display : 'flex',flexDirection : 'column', width :!isMobile ? '90%' : '100%', background : '#FFFFFF', borderRadius : '10px',padding : "20px",margin : 'auto'}}>
                                <DetailsHeading>
                                Real time Insights
                                </DetailsHeading>
                                <DetailsInfo>
                                Insights to understand audience’s behaviour and trending strategy.
                                </DetailsInfo>
                            </div>
                        </Col>
                        <Col lg={7} xl={7} md={12} sm={24} xm={24} style={{display : 'flex', width : '100%',padding :isMobile ? '0px' : ''}}>
                            <div style={{display : 'flex',flexDirection : 'column', width :!isMobile ? '90%' : '100%', background : '#FFFFFF', borderRadius : '10px',padding : "20px",margin : 'auto'}}>
                                <DetailsHeading>
                                Powerful algorithm
                                </DetailsHeading>
                                <DetailsInfo>
                                Connect brands with influencers who has right target audience.
                                </DetailsInfo>
                            </div>
                        </Col>
                        <Col lg={7} xl={7} md={12} sm={24} xm={24} style={{display : 'flex', width : '100%',padding :isMobile ? '0px' : ''}}>
                            <div style={{display : 'flex',flexDirection : 'column', width :!isMobile ? '90%' : '100%', background : '#FFFFFF', borderRadius : '10px',padding : "20px",margin : 'auto'}}>
                                <DetailsHeading>
                                Performance tracking
                                </DetailsHeading>
                                <DetailsInfo>
                                View analytics and take right decision to reach wider audience.
                                </DetailsInfo>
                            </div>
                        </Col>
                        <Col lg={7} xl={7} md={12} sm={24} xm={24} style={{display : 'flex', width : '100%',padding :isMobile ? '0px' : ''}}>
                            <div style={{display : 'flex',flexDirection : 'column', width :!isMobile ? '90%' : '100%', background : '#FFFFFF', borderRadius : '10px',padding : "20px",margin : 'auto'}}>
                                <DetailsHeading>
                                Measure ROI
                                </DetailsHeading>
                                <DetailsInfo>
                                Track the campaign performance and measure ROI.
                                </DetailsInfo>
                            </div>
                        </Col>
                        <Col lg={7} xl={7} md={12} sm={24} xm={24} style={{display : 'flex', width : '100%',padding :isMobile ? '0px' : ''}}>
                            <div style={{display : 'flex',flexDirection : 'column', width :!isMobile ? '90%' : '100%', background : '#FFFFFF', borderRadius : '10px',padding : "20px",margin : 'auto'}}>
                                <DetailsHeading>
                                Advanced Filters
                                </DetailsHeading>
                                <DetailsInfo>
                                Custom filters helps you to find what you need.
                                </DetailsInfo>
                            </div>
                        </Col>
                    </Row>

                </Heading5>
                <Heading6 id = 'SignUp-form'>
                    <div
                    style={{display : 'flex', padding : '10px',alignItems : 'center', textAlign : 'center', minWidth :!isMobile ?  '400px' : '300px',flexDirection : 'column', minHeight : '400px', background : '#FFFFFF', margin : '80px 0px 20px 0px', borderRadius : '10px', boxShadow : '0px 0px 33px 16px rgba(67, 184, 206, 0.2)'}}
                    >
                        <div style={{display : 'flex', width : '80%', fontWeight : 700, color :'#010043', fontSize : '32px',lineHeight :'39px',justifyContent: 'center',marginBottom : '15px'}}> Request a demo</div>
                        <LeadForm/>
                    </div>
                   

                </Heading6>
                <Heading7>
                    <div style={{color : '#FFFFFF', fontWeight : 700, fontSize : '34px', lineHeight : '41px',margin : '40px 0px 20px 0px'}}>
                    FAQ
                    </div>
                    <Collapse
                        bordered = {false}
                        style={{display : 'flex', flexDirection : 'column', width : '80%', margin : '30px', color :'#010043' }}
                        // onChange={onChange}
                        expandIconPosition='end'
                        expandIcon={({isActive})=> isActive ? <MinusOutlined style={{color : '#010043', fontSize : '16px'}}/> :<PlusOutlined style={{color : '#010043', fontSize : '16px'}}/>}
                    >
                        <Panel style={{marginBottom: '20px', background : '#F6F6FF',borderRadius : '10px',border : 'none'}} header={<CollapseHeader> How does Admirian work?</CollapseHeader>} key="1" >
                        <CollapseDetail>
                        Admirian is a tech platform that specializes in influencer marketing. It connects brands with influencers, providing a streamlined process for discovering suitable influencers and accessing comprehensive analytics to optimize marketing campaigns.                        
                        </CollapseDetail>
                        </Panel>
                        <Panel style={{marginBottom: '20px', background : '#F6F6FF',borderRadius : '10px',border : 'none'}} header={<CollapseHeader>How can brands benefit from using Admirian?</CollapseHeader>} key="2" >
                        <CollapseDetail>
                        Brands can leverage Admirian to discover influencers who align with their target audience and campaign goals. With access to detailed analytics, brands can make data-driven decisions, measure campaign performance, and maximize their return on investment (ROI).                        
                        </CollapseDetail>
                        </Panel>
                        <Panel style={{marginBottom: '20px', background : '#F6F6FF',borderRadius : '10px',border : 'none'}} header={<CollapseHeader> How can influencers join Admirian?</CollapseHeader>} key="3" >
                        <CollapseDetail>
                        Admirian welcomes influencers from various niches and social media platforms. Influencers can sign up on the platform, log in with their instagram account, showcase their work, connect with brands, and explore collaboration opportunities.
                        </CollapseDetail>
                        </Panel>
                        <Panel style={{marginBottom: '20px', background : '#F6F6FF',borderRadius : '10px',border : 'none'}} header={<CollapseHeader>How does Admirian help brands discover influencers?</CollapseHeader>} key="4" >
                        <CollapseDetail>
                        Admirian utilizes advanced algorithms to match brands with influencers based on specific criteria, including audience demographics, engagement rates, content style, reach, content category, etc. This streamlined process simplifies the influencer discovery phase for brands.
                        </CollapseDetail>
                        </Panel>
                        <Panel style={{marginBottom: '20px', background : '#F6F6FF',borderRadius : '10px',border : 'none'}} header={<CollapseHeader>What types of analytics are available for brands on Admirian?</CollapseHeader>} key="5" >
                        <CollapseDetail>
                        Admirian offers a range of analytics to help brands evaluate influencer performance. Brands can access data such as audience demographics, engagement rates, reach, impressions, and previous campaign results to assess an influencer's suitability for their marketing campaigns.
                        </CollapseDetail>
                        </Panel>
                        <Panel style={{marginBottom: '20px', background : '#F6F6FF',borderRadius : '10px',border : 'none'}} header={<CollapseHeader> How secure is the data on Admirian?</CollapseHeader>} key="6" >
                        <CollapseDetail>
                        Data security is a top priority for Admirian. We employ industry-standard encryption protocols and follow stringent security practices to protect user data. Rest assured that your information is kept safe and confidential.
                        </CollapseDetail>
                        </Panel>
                        <Panel style={{marginBottom: '20px', background : '#F6F6FF',borderRadius : '10px',border : 'none'}} header={<CollapseHeader>How does Admirian handle contract management between brands and influencers?</CollapseHeader>} key="7" >
                        <CollapseDetail>
                        Admirian offers a comprehensive contract management system. Brands can draft and send contracts to influencers through Admirian. Influencers can review the terms, provide electronic signatures, and securely store and manage their contracts.
                        </CollapseDetail>
                        </Panel>
                        <Panel style={{marginBottom: '20px', background : '#F6F6FF',borderRadius : '10px',border : 'none'}} header={<CollapseHeader>How can brands track the success of their influencer campaigns on Admirian?</CollapseHeader>} key="8" >
                        <CollapseDetail>
                        Admirian offers comprehensive campaign tracking and performance analytics. Brands can monitor key metrics such as impressions, engagement rates, click-through rates, conversions, and ROI, providing valuable insights to optimize future influencer campaigns.                         
                        </CollapseDetail>
                        </Panel>
                    </Collapse>

                </Heading7>
                <Heading8>
                    <span>Still have a query? write to us at 
                        <a style={{color:'#63A1FF'}}> sales@admirian.com
                        </a>
                        <a> or reach us on +91-7710883542 
                        </a>
                    </span>
                </Heading8>
            </HeadingContainer>
            <FooterContainer style={{padding : !isMobile ? '0px 80px' : '0px 20px'}}>
                <div style={{fontWeight : 700, fontSize : '18px', lineHeight : '22px', display : 'flex', alignItems : 'center'}}>
                <Image style={{height: !isMobile ? '42px' : '32px'}} preview = {false} src = "https://static.admirian.com/9a312601-984e-4237-bfad-1454204ce38f_BrandLogo.svg"/>
                </div>

                <div style={{display : 'flex', alignItems : 'center', color : '#050360', fontSize : '14px', lineHeight : '17px'}}>
                    <span onClick={()=>navigate('/privacy-policy')} style={{ cursor : 'pointer'}}>
                    Privacy policy
                    </span>
                    {/* <span>
                    Terms and Conditions
                    </span> */}
                </div>
                
            </FooterContainer>
            
        </Container>
    )

}

export default Home