import React from 'react';
import { TotalAmountContainer, TotalAmountText, TransactionListContainer, TransactionTextHeading, TransactionTextDescription, TransactionContainer, CustomDrawer, InfluencerHeadingText, InfluencerDetailListContainer, TransactionNameText, TransactionDescriptionNote, PaginationContainer } from './style';
import { FlexContainer, SubHeading } from '../../../utils/common';
import { Avatar, Button, Col, Pagination, Radio, Row } from 'antd';
import { useState } from 'react';
import { useGetTransactionsQuery } from '../../../services/manageCampaignsApi';
import NoDataFound from '../../NoDataFound';
import { transactionStatusValue } from '../../../utils/HelperFunctions/common';
import { useParams } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';

const TransactionDetails = () => {
    const { id: campaignId } = useParams();
    const [transactionStatus, setTransactionStatus] = useState("ALL");
    const [page, setPage] = useState(1);
    const { data: { transactions = [], total_pages } = {} } = useGetTransactionsQuery({ campaignId, transactionStatus, page });
    const [creatorData, setCreatorData] = useState([]);
    const [drawerOpen, setDrawerOpen] = useState();
    let totalTransactionAmount = 0;
    let creatorAmount = 0;


    const showDrawer = (creators) => {
        setDrawerOpen(true);
        setCreatorData(creators)
    };
    const onClose = () => {
        setDrawerOpen(false);
    };

    const handleOnChange = (value) => {
        setTransactionStatus(value);
    };

    const onPageChange = (pageValue) => {
        setPage(pageValue);
    };


    transactions.forEach(element => {
        return (
            totalTransactionAmount = element.amount + totalTransactionAmount
        )
    });

    creatorData.forEach(el => {
        return (
            creatorAmount = el.amount + creatorAmount
        )
    });

    return (
        <TransactionContainer>
            <TotalAmountContainer>
                <TotalAmountText>Total Amount - ₹{transactions.length ? totalTransactionAmount : 0}</TotalAmountText>
                <SubHeading style={{ color: "#fff", margin: 0 }}>Total amount for the selected filter</SubHeading>
            </TotalAmountContainer>

            <div style={{ marginTop: 20 }}>
                <Radio.Group defaultValue="ALL" buttonStyle="solid" onChange={(e) => handleOnChange(e.target.value)}>
                    <Radio.Button value="ALL">All</Radio.Button>
                    <Radio.Button value="PENDING">Pending</Radio.Button>
                    <Radio.Button value="PAID">Cleared</Radio.Button>
                    <Radio.Button value="PROCESSING">Processing</Radio.Button>
                </Radio.Group>
            </div>
            {
                transactions.length ? <>
                    {
                        transactions.map((transactionData, index) => {
                            const { timestamp = "", creator_count, payment_mode, amount, status = "", creators = [] } = transactionData;
                            return (
                                <TransactionListContainer key={index}>
                                    <Row gutter={[16, 24]}>
                                        <Col span={4}>
                                            <TransactionTextHeading>Transaction Timestamp</TransactionTextHeading>
                                            <TransactionTextDescription>{timestamp}</TransactionTextDescription>
                                        </Col>
                                        <Col span={4}>
                                            <TransactionTextHeading>Influencers</TransactionTextHeading>
                                            <TransactionTextDescription>{creator_count}</TransactionTextDescription>
                                        </Col>
                                        <Col span={4}>
                                            <TransactionTextHeading>Payment Mode</TransactionTextHeading>
                                            <TransactionTextDescription>{payment_mode}</TransactionTextDescription>
                                        </Col>
                                        <Col span={4}>
                                            <TransactionTextHeading>Total Amount Paid</TransactionTextHeading>
                                            <TransactionTextDescription>{amount}</TransactionTextDescription>
                                        </Col>
                                        <Col span={4}>
                                            <TransactionTextHeading>Status</TransactionTextHeading>
                                            <TransactionTextDescription style={status === transactionStatusValue.paid ? { color: "#00680A" } : status === transactionStatusValue.pending ? { color: "#F86624" } : { color: "#1551EA" }}>{status}</TransactionTextDescription>
                                        </Col>
                                        <Col span={4} style={{ textAlign: 'right' }}>
                                            <Button style={{ color: '#010043', fontSize: 12, border: '1px solid #010043' }} onClick={() => showDrawer(creators)} shape="round">View</Button>
                                        </Col>
                                    </Row>
                                </TransactionListContainer>
                            )
                        })
                    }
                    <PaginationContainer>
                        <Pagination current={page} onChange={onPageChange} pageSize={10} total={total_pages * 10} showSizeChanger={false} />
                    </PaginationContainer>
                </> :
                    <NoDataFound />
            }

            <CustomDrawer
                title={<>
                    <SubHeading style={{ color: "#fff", margin: 0 }}>Amount Paid</SubHeading>
                    <TotalAmountText>₹{creatorData.length ? creatorAmount : 0}</TotalAmountText>
                </>}
                placement={"right"}
                closable={false}
                onClose={onClose}
                open={drawerOpen}
            >
                <InfluencerHeadingText>
                    All Influencers
                </InfluencerHeadingText>
                {
                    creatorData.length ?
                        creatorData.map((creators, ind) => {
                            const { name, thumbnail = "", amount } = creators;
                            return (
                                <InfluencerDetailListContainer key={ind}>
                                    <FlexContainer>
                                        {
                                            thumbnail.length ?  <Avatar src={thumbnail} /> : <Avatar icon={<UserOutlined />} />
                                        }
                                        <TransactionNameText style={{ paddingLeft: 12, alignSelf: 'center' }}>{name}</TransactionNameText>
                                    </FlexContainer>
                                    <TransactionNameText style={{ color: "#0F0E1B", alignSelf: 'center' }}>₹{amount}</TransactionNameText>
                                </InfluencerDetailListContainer>
                            )
                        })
                        :
                        <div>No data available yet</div>
                }
                <InfluencerHeadingText style={{ paddingTop: 20 }}>
                    Note:
                </InfluencerHeadingText>
                <TransactionDescriptionNote>All payments are secured and will be paid to influencers after content is approved by brand.</TransactionDescriptionNote>
            </CustomDrawer>
        </TransactionContainer>
    )
}
export default TransactionDetails;