import styled from 'styled-components';
import { Button, Checkbox, Form, Upload, Input } from 'antd';

export const Container = styled.div`
    margin: 20px 48px;
    padding: 32px 0px;
    background: #FFFFFF;
    border-radius: 4px;
    width: 96%;
    display: flex;
    justify-content: center;

    .ant-radio-button-wrapper {
        margin-right: 12px;
        border-radius: 16px;
        border: 1px solid #494785;
        color: #494785;
    }
    .ant-radio-button-wrapper:not(:first-child)::before {
        width: unset;
    }
    .ant-radio-button-checked {
        color: #1551EA;
        border: 1px solid #1551EA;
        background : #EBF4FF;
        border-radius: 16px;
    }
`;

export const BasicInfluencerHeading = styled.h3`
    font-style: normal;
    font-size: 16px;
    font-weight: 400; 
    color: #494785;
    margin-bottom: 16px;
    margin-top: 26px;
`;

export const InfluencerCardHeading = styled.h3`
    font-size: 16px;
    color: #1551EA;
    font-weight: 500;
    line-height: 24px;
`;

export const InfluencerCardDetails = styled.p`
    font-size: 12px;
    color: #494785; 
    fontWeight: 400;
`;


export const SubHeading = styled.p`
    font-size: 12px;
    font-weight: 300;
    color: #2C2B50;
    margin-top: 12px;
`;

export const CardBox = styled.div`
    display:flex;
    gap: 20px;
    width: 744px !important;
    height: 199px;
    .card-1 {
        display: flex;
width: 328px !important;
padding: 16px;
align-items: flex-start;
gap: 16px;
align-self: stretch;
border-radius: 8px;
    border: 3px solid #EBF4FF;
  background-color: #FFF;
  outline: none;
  transition: border-color 0.3s ease;
    }
    .card-1.selected{
        border: 2px solid #1551EA;
    }
    .card-2 {
        display: flex;
width: 328px !important;
padding: 16px;
align-items: flex-start;
gap: 16px;
align-self: stretch;
border-radius: 8px;
border: 3px solid #EBF4FF;
  background-color: #FFF;
  outline: none;
  transition: border-color 0.3s ease;
    }
    .card-2.selected{
        border: 2px solid #1551EA;
    }
`;

export const CardDetails = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: -12%;
    top: -28px;
    width: 130%;
    height: 100%;

    h3{
        margin-top: 20px;
    }
    P{
        margin-top: -1px;
        word-wrap: break-word;
        overflow-wrap: break-word;
    }
`;

export const Checkboxes = styled.input`
    width: 40px;
  height: 40px;
  border-radius: 50%;
  appearance: none;
  border: 2px solid #1551EA; 
  transition: border-color 0.3s ease;

  &:checked {
    background-color: #1551EA;
    position: absolute;
    top: 66%;
    left: 21%;
    transform: translate(207px,-127px);
  }

  &:checked::before {
    content: '✔';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 20px;
  }
`;

export const UploadImg = styled(Upload)`
    display: flex;
    width: 100%;
    height: 200px;
    border-radius: 12px;
    border: 1px dashed  #1551EA;
    background:  #F6FAFF;
    text-align: center;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    gap: 16px;
    padding: 16px;
`;

export const CampaignNameInput = styled(Input)`
    border-radius: 60px !important;
    border: 1px solid #8988BF;
    background: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    color:#8988BF;
`;

export const BriefHolder = styled.div`
    .ant-input{
    font-style: normal;
    font-size: 16px;
    font-weight: 400;
    // color: #8988BF;
    border-radius: 8px;
    }
`;

export const RadioContainer1 = styled.div`
    padding : 16px 24px;
    border-radius: 30px;
    margin-top : 12px;
    margin-bottom : 12px;
    background: #F6F6FF;
    .ant-radio-wrapper {
    border-color: #F6F6FF;
    color: #1551EA;
    font-size: 16px;
    &:first-child {
    }
    &:last-child {
    }
    text-align: center;
  }
`;

export const InfluencerSubDetails = styled.h4`
    font-style: normal;
    font-size: 12px; 
    font-weight: 300;
    color: #494785;
`;

export const NumericField = styled(Input)`
    border-radius: 60px !important;
    border: 1px solid #8988BF;
    background: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    color:#8988BF;
`;

export const CategoryContainer = styled.div`
.ant-radio-button-wrapper {
    margin-right: 12px;
    border-radius: 16px;
    border: 1px solid #494785;
    color: #494785;
}
.ant-radio-button-wrapper:not(:first-child)::before {
    width: unset;
}
.ant-radio-button-checked {
    color: #1551EA;
    border: 1px solid #1551EA;
    background : #EBF4FF;
    border-radius: 16px;
}
`;