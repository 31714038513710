import React, { useState } from 'react';
import { Container } from './style';
import { Heading, SubHeading } from '../../../utils/common';

// import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
// import AddProductDetails from './AddProductDetails';
import DeliverablesList from './DeliverablesList';
import { useGetAllDeliverablesQuery } from '../../../services/manageCampaignsApi';
import DeliverableForm from './DeliverableForm';
import EditDeliverableForm from './EditDeliverableForm';
import { useParams } from 'react-router-dom';

const AddDeliverables = () => {
    const {campaignId, selectedCardId} = useParams();
    const [particularDeliverableData, setParticularDeliverable] = useState({});
    const [screenId, setScreenId] = useState("1")

    const { data = [], isLoading, isError, isSuccess, error, refetch } =
        useGetAllDeliverablesQuery({ campaignId });
  
    return (
        <Container>
            <div style={{ width: 700 }}>
                {
                    screenId === "2" ? <><Heading>Edit your deliverable </Heading>
                        <SubHeading>You can edit your deliverables which you have created.</SubHeading><EditDeliverableForm particularDeliverableData={particularDeliverableData} setParticularDeliverable={setParticularDeliverable} refetch={refetch} setScreenId={setScreenId} /> </> :
                        <>
                            {
                                !!data.length && (
                                    <DeliverablesList data={data} isLoading={isLoading} isError={isError} isSuccess={isSuccess} error={error} refetch={refetch} setParticularDeliverable={setParticularDeliverable} setScreenId={setScreenId} />
                                )
                            }
                            {
                                (data.length === 1 && selectedCardId === "101") ? <></> : <> <Heading>Define Influencer Deliverables</Heading>
                                    <SubHeading>Influencers will take deliverable inspiration from here and will prepare the content on the basis of the information and specifics you provide.</SubHeading>
                                    <DeliverableForm refetch={refetch} /></>
                            }

                        </>
                }
            </div>
        </Container>
    )
}

export default AddDeliverables;