import { Col, Row,Spin } from "antd";
import React from "react";
import { NavigationCard, NavigationTitle, NoDataAvailableContainer } from "./style";
import CreatorYoutubeAnalytics from "../CreatorYoutubeAnalytics";
import { JustifyCenterContainer } from "../../utils/common";

const YoutubeAnalytics = ({analyticsData, setAnalyticsData,isLoading}) => {

    const handleNavigationClick = (id) => {
        const element = document.getElementById(id);
        element.scrollIntoView({ behavior: "smooth", inline: "nearest" });
    }

    return(
        <>
         { !isLoading ?
        Object.keys(analyticsData)?.length > 0 ?
        <Row gutter={[16,16]}>                 
            <Col xl={6} lg={6} md={12} sm={0} xs={0}>
                <NavigationCard>
                    <NavigationTitle onClick={()=> handleNavigationClick('channelMetricsyt')}>
                        Channel Metrics
                    </NavigationTitle>
                    {analyticsData?.featured_media &&
                        <NavigationTitle onClick={()=> handleNavigationClick('featuredMedia')}>
                            Featured Media
                        </NavigationTitle>
                    }
                    {analyticsData?.audience_demographics &&
                        <NavigationTitle onClick={()=> handleNavigationClick('audienceDemographics')}>
                            Audience Demographics
                        </NavigationTitle>
                    }
                    {analyticsData?.audience_engagement &&
                    <NavigationTitle onClick={()=> handleNavigationClick('audienceEngagement')}>
                        Audience Engagement
                    </NavigationTitle>
                    }
                    <NavigationTitle onClick={()=> handleNavigationClick('postDensityyt')}>
                        Post Density
                    </NavigationTitle>
                    <NavigationTitle onClick={()=> handleNavigationClick('activityHeatMapyt')}>
                        Activity Heat Map
                    </NavigationTitle>
                    
                </NavigationCard>

            </Col>
            <Col xl={18} lg={18} md={12} sm={24} xs={24}>
                <CreatorYoutubeAnalytics
                analyticsData={analyticsData}
                setAnalyticsData={setAnalyticsData}
                isLoading={isLoading}
                />
            </Col>
            </Row>       
            :
            <NoDataAvailableContainer>
                No Data Available
            </NoDataAvailableContainer>
        :
        <JustifyCenterContainer className="w-100">
            <Spin/>
        </JustifyCenterContainer>
        }
    </>        
    )
}

export default YoutubeAnalytics