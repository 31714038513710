import './App.css';
import Home from './components/Home';
import SignUp from './pages/SignUp';
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';
import { PrivateRoute } from './routes';
import SignInFlow from './pages/SignInFlow';
import SignIn from './pages/SignIn';
import Dashboard from './pages/Dashboard';
import PrivacyPolicy from './pages/PrivacyPolicy';
import CreateCampaigns from './pages/CreateCampaigns'
import OtpVerify from './pages/OtpVerify';
import RequestADemo from './pages/RequestADemo';
import CreateNewPassword from './pages/CreateNewPassword';
import EmailAddress from './pages/EmailAddress';
import SuccessfulPasswordChange from './pages/SuccessfulPasswordChange';

function App() {
  return (

    <Router>
      <PrivateRoute
          exact
          path="/"
          element={Home}
          header={{
            title: 'Home',
            // iconLink:
            //   'https://d10srchmli830n.cloudfront.net/1633538766532_Setting.svg',
          }}
        />
        <PrivateRoute
          exact
          path="/sign-up"
          element={SignUp}
          header={{
            title: 'Sign Up',
            // iconLink:
            //   'https://d10srchmli830n.cloudfront.net/1633538766532_Setting.svg',
          }}
        />
         <PrivateRoute
          exact
          path="/brands/elite/register"
          element={SignUp}
          header={{
            title: 'Sign Up',
            // iconLink:
            //   'https://d10srchmli830n.cloudfront.net/1633538766532_Setting.svg',
          }}
        />
        <PrivateRoute
          exact
          path="/verify-otp"
          element={OtpVerify}
          header={{
            title: 'Sign Up',
            // iconLink:
            //   'https://d10srchmli830n.cloudfront.net/1633538766532_Setting.svg',
          }}
        />
        <PrivateRoute
         exact
          path="/Sign-in/flow"
          element={SignInFlow}
          header={{
            title: 'Sign In',
            // iconLink:
            //   'https://d10srchmli830n.cloudfront.net/1633538766532_Setting.svg',
          }}
        />
        <PrivateRoute
          exact
          path="/Sign-in"
          element={SignIn}
          header={{
            title: 'Sign In',
            // iconLink:
            //   'https://d10srchmli830n.cloudfront.net/1633538766532_Setting.svg',
          }}
        />
         <PrivateRoute
          exact
          path="/create-new-password"
          element={EmailAddress}
          header={{
            title: 'Create New Password',
            // iconLink:
            //   'https://d10srchmli830n.cloudfront.net/1633538766532_Setting.svg',
          }}
        />
         <PrivateRoute
          exact
          path="/password-changed-successful"
          element={SuccessfulPasswordChange}
          header={{
            title: 'Successful Password Change',
            // iconLink:
            //   'https://d10srchmli830n.cloudfront.net/1633538766532_Setting.svg',
          }}
        />
        <PrivateRoute
          exact
          path="/password/reset"
          element={CreateNewPassword}
          header={{
            title: 'Create New password',
          }}
         />
        <PrivateRoute
          exact
          path="/discover"
          element={Dashboard}
          header={{
            title: 'Discover',
            // iconLink:
            //   'https://d10srchmli830n.cloudfront.net/1633538766532_Setting.svg',
          }}
        />
        <PrivateRoute
          exact
          path="/dashboard"
          element={Dashboard}
          header={{
            title: 'Dashboard',
            // iconLink:
            //   'https://d10srchmli830n.cloudfront.net/1633538766532_Setting.svg',
          }}
      />
      <PrivateRoute
          exact
          path="/campaign"
          element={Dashboard}
          header={{
            title: 'Campaigns',
            // iconLink:
            //   'https://d10srchmli830n.cloudfront.net/1633538766532_Setting.svg',
          }}
      />
      <PrivateRoute
          exact
          path={`/campaign-summary-influencer/:campaignId`}
          element={Dashboard}
          header={{
            title: 'Campaigns',
            // iconLink:
            //   'https://d10srchmli830n.cloudfront.net/1633538766532_Setting.svg',
          }}
      />
      <PrivateRoute
          exact
          path={`/deliverable/:campaignId/:creatorDeliverableId`}
          element={Dashboard}
          header={{
            title: 'Campaigns',
            // iconLink:
            //   'https://d10srchmli830n.cloudfront.net/1633538766532_Setting.svg',
          }}
      />
        <PrivateRoute
          exact
          path="/create-campaign"
          element={CreateCampaigns}
          header={{
            title: 'Create Campaign',
            // iconLink:
            //   'https://d10srchmli830n.cloudfront.net/1633538766532_Setting.svg',
          }}
        />
         <PrivateRoute
          exact
          path="/add-deliverables/:campaignId/:selectedCardId"
          element={CreateCampaigns}
          header={{
            title: 'Create Campaign',
            // iconLink:
            //   'https://d10srchmli830n.cloudfront.net/1633538766532_Setting.svg',
          }}
        />
        <PrivateRoute
          exact
          path="/manage-campaigns"
          element={Dashboard}
          header={{
            title: 'Manage Campaigns',
            // iconLink:
            //   'https://d10srchmli830n.cloudfront.net/1633538766532_Setting.svg',
          }}
        />
        <PrivateRoute
          exact
          path="/influencer-details"
          element={Dashboard}
          header={{
            title: 'Manage Campaigns',
            // iconLink:
            //   'https://d10srchmli830n.cloudfront.net/1633538766532_Setting.svg',
          }}
        />
        <PrivateRoute
          exact
          path="/initial-influencer-details/:campaignId"
          element={Dashboard}
          header={{
            title: 'Manage Campaigns',
            // iconLink:
            //   'https://d10srchmli830n.cloudfront.net/1633538766532_Setting.svg',
          }}
        />
        <PrivateRoute
          exact
          path="/influencer-summary/:campaignId"
          element={Dashboard}
          header={{
            title: 'Manage Campaigns',
            // iconLink:
            //   'https://d10srchmli830n.cloudfront.net/1633538766532_Setting.svg',
          }}
        />
        <PrivateRoute
          exact
          path="/manage-campaign-view/:id"
          element={Dashboard}
          header={{
            title: 'Manage Campaigns',
            // iconLink:
            //   'https://d10srchmli830n.cloudfront.net/1633538766532_Setting.svg',
          }}
        />
        <PrivateRoute
          exact
          path="/campaign-summary/:campaignId/"
          element={Dashboard}
          header={{
            title: 'Manage Campaigns',
            // iconLink:
            //   'https://d10srchmli830n.cloudfront.net/1633538766532_Setting.svg',
          }}
        />
         <PrivateRoute
          exact
          path="/campaign-summary/:campaignId/:campaignCreatorIds"
          element={Dashboard}
          header={{
            title: 'Manage Campaigns',
            // iconLink:
            //   'https://d10srchmli830n.cloudfront.net/1633538766532_Setting.svg',
          }}
        />
        <PrivateRoute
          exact
          path="/payments/:campaignId/:totalAmount/:campaignCreatorIdArray"
          element={Dashboard}
          header={{
            title: 'Manage Campaigns',
            // iconLink:
            //   'https://d10srchmli830n.cloudfront.net/1633538766532_Setting.svg',
          }}
        />
        <PrivateRoute
          exact
          path="/influencer-campaign-information/:id/:creatorId"
          element={Dashboard}
          header={{
            title: 'Manage Campaigns',
            // iconLink:
            //   'https://d10srchmli830n.cloudfront.net/1633538766532_Setting.svg',
          }}
        />
         <PrivateRoute
          exact
          path="/proposed-influencers/:id/:creatorUuidArray"
          element={Dashboard}
          header={{
            title: 'Manage Campaigns',
            // iconLink:
            //   'https://d10srchmli830n.cloudfront.net/1633538766532_Setting.svg',
          }}
        />
        <PrivateRoute
          exact
          path="/analytics"
          element={Dashboard}
          header={{
            title: 'Analytics',
            // iconLink:
            //   'https://d10srchmli830n.cloudfront.net/1633538766532_Setting.svg',
          }}
        />
        <PrivateRoute
          exact
          path="/profile"
          element={Dashboard}
          header={{
            title: 'Profile',
            // iconLink:
            //   'https://d10srchmli830n.cloudfront.net/1633538766532_Setting.svg',
          }}
        />
        <PrivateRoute
          exact
          path="/privacy-policy"
          element={PrivacyPolicy}
          header={{
            title: 'Privacy Policy',
            // iconLink:
            //   'https://d10srchmli830n.cloudfront.net/1633538766532_Setting.svg',
          }}
        />
        <PrivateRoute
          exact
          path="/home"
          element={Dashboard}
          header={{
            title: 'Home',
            // iconLink:
            //   'https://d10srchmli830n.cloudfront.net/1633538766532_Setting.svg',
          }}
        />
        <PrivateRoute
          exact
          path="/connect-accounts"
          element={Dashboard}
          header={{
            title: 'Connect Social Profile',
            // iconLink:
            //   'https://d10srchmli830n.cloudfront.net/1633538766532_Setting.svg',
          }}
        />
        <PrivateRoute
          exact
          path="/deliverable-details/:id/:campaignId"
          element={Dashboard}
          header={{
            title: 'Connect Social Profile',
            // iconLink:
            //   'https://d10srchmli830n.cloudfront.net/1633538766532_Setting.svg',
          }}
        />
        <PrivateRoute
          exact
          path="/request-demo"
          element={RequestADemo}
          header={{
            title: 'Request a Demo',
            // iconLink:
            //   'https://d10srchmli830n.cloudfront.net/1633538766532_Setting.svg',
          }}
        />
    </Router>
    // <Home>
      
    // </Home>
    // <SignUp>
      
    // </SignUp>
    // <SignIn>

    // </SignIn>
  );
}

export default App;
