import React, { useEffect, useState } from "react";
import DashboardHeader from "../../components/Dashboard/Header";
import Sidebar from "../../components/Dashboard/Sidebar";
import { MasterComponentStyle, MasterLayoutStyle } from "../../components/Home/Header/style";
import FindProfile from "../../components/Dashboard/FindProfile";
import LikedProfile from "../../components/Dashboard/LikedProfile";
import { parseJwtToken } from "../../utils";
import Profile from "../Profile";
import DashboardBrand from "../../components/Dashboard";
import { useLocation, useNavigate } from "react-router-dom";
import { notification } from "antd";
import axios from "axios";
import DashboardHeaderCreator from "../../components/Dashboard/HeaderCreator";
import ProfileCreator from "../ProfileCreator";
import CreatorLandingPage from "../CreatorLandingPage";
import ManageCampaigns from "../ManageCampaigns";
import InfluencerDetails from "../InfluencerDetails";
import InfluencerSummary from "../InfluencerSummary";
import CampaignSummary from "../CampaignSummary";
import PaymentDetails from "../PaymentDetails";
import CampaignCreator from "../CampaignCreator";
import CampaignSummaryInfluencer from "../CampaignSummaryInfluencer";
import Deliverable from "../Deliverable";
import ManageCampaignView from "../../components/ManageCampaigns/ManageCampaignView";
import InfluencerCampaignInformation from "../../components/ManageCampaigns/InfluencerCampaignInformation";
import ProposedInfluencers from "../../components/ManageCampaigns/ProposedInfluencers";
import DemoProfile from "../../components/DemoProfile";
import ConnectInstagram from "../../components/ConnectInstagram";
import DeliverableInformation from "../DeliverableInformation";
import { BASE_URL } from "../../utils/HelperFunctions/constants";

const Dashboard = () => {
    const location = useLocation();
    const navigate = useNavigate()
    const [defaultSelectedKey, setDefaultSelectedKey] = useState('findProfile')
    const [defaultActive, setDefaultActive] = useState('')
    const [brandDetails,setBrandDetails]= useState({})
    const [creatorDetails,setCreatorDetails]= useState({})
    const refresh_token = parseJwtToken(localStorage.getItem('refresh_token'))
    const [campaignid,setCampaignId] = useState('')
    const [creatorDeliverableId,setCreatorDeliverableId] = useState('')

    const [active,setActive] = useState("");

    const getBrandDetail = async() => {
        try{
            const response = await axios.get(`${BASE_URL}/profiles/brands/${refresh_token?.user_uuid}/`)

            if(!response?.data?.error){
                setBrandDetails(response?.data)
            }
        }catch(error){
            notification.error({message : error?.response?.data?.message})
        }
    }

    useEffect(()=>{
        if(refresh_token?.user_type === "Brand"){
            getBrandDetail()
        }else if(refresh_token?.user_type === "Creator"){
            getCreatorDetails()
        }
        
    },[])
    const getCreatorDetails = async() => {
        try{
            const response = await axios.get(`${BASE_URL}/profiles/creators/${refresh_token?.user_uuid}/`)

            if(!response?.data?.error){
                setCreatorDetails(response?.data)
            }
        }catch(error){
            notification.error({message : error?.response?.data?.message})
        }
    }

    useEffect(()=>{
        if(refresh_token?.user_type === "Brand"){
            if(location.pathname.includes("/manage-campaign-view")){
                setDefaultActive("/manage-campaign-view");
                setActive("Manage Campaigns");  
            }else if(location.pathname.includes("/initial-influencer-details")){
                setDefaultActive("/initial-influencer-details");
                setActive("Manage Campaigns"); 
            }else if(location.pathname.includes("/influencer-summary")){
                setDefaultActive("/influencer-summary");
                setActive("Manage Campaigns"); 
            }else if(location.pathname.includes("/campaign-summary")){
                setDefaultActive("/campaign-summary");
                setActive("Manage Campaigns"); 
            }else if(location.pathname.includes("/influencer-campaign-information")){
                setDefaultActive('/influencer-campaign-information');
                setActive("Manage Campaigns")
            }else if(location.pathname.includes("/proposed-influencers")){
                setDefaultActive('/proposed-influencers');
                setActive("Manage Campaigns")
            }else if(location.pathname.includes("/payments")){
                setDefaultActive('/payments');
                setActive("Manage Campaigns")
            }else{
                setDefaultActive(location.pathname)
                switch (location.pathname.toUpperCase()) {
                    case '/DISCOVER':
                        setDefaultActive('/discover')
                        setActive("Discover")
                        break
                    case '/DASHBOARD':
                        setActive("Dashboard")
                        break
                    case '/MANAGE-CAMPAIGNS':
                        setDefaultActive('/manage-campaigns')
                        setActive("Manage Campaigns")
                        break;
                    case '/PROPOSED-INFLUENCERS':
                        setActive("Manage Campaigns")
                        break;
                    case '/PROFILE': 
                        setDefaultActive('/profile')
                        setActive("Profile")
                        break
                    default : 
                        navigate('/discover')
                }
            }
        }
        else{
            if(location.pathname.toUpperCase() === '/ANALYTICS'){
                setDefaultActive('/analytics')
                setActive("Analytics")
            }
            if(location.pathname.toUpperCase() === '/PROFILE'){
                setDefaultActive('/profile')
                setActive("Profile")
            }else if(location.pathname.toUpperCase() === '/CAMPAIGN'){
                setDefaultActive('/campaign')
                setActive("Campaign")
            }else if(location.pathname.toUpperCase().includes('/CAMPAIGN-SUMMARY-INFLUENCER/')){
                setDefaultActive('/campaign-summary-influencer')
                setActive("Campaign")
            }else if(location.pathname.toUpperCase().includes('/DELIVERABLE/')){
                setDefaultActive('/deliverable')
                setActive("Campaign")
            }else if(location.pathname.toUpperCase() === '/HOME'){
                setDefaultActive('/home')
                setActive("Analytics")
            }else if(location.pathname.toUpperCase().includes('/CONNECT-ACCOUNTS')){
                setDefaultActive('/connect-accounts')
                setActive("Analytics")
            }else if(location.pathname.toUpperCase().includes('/DELIVERABLE-DETAILS')){
                setDefaultActive('/deliverable-details')
                setActive("Campaign")
            }else{
                navigate('/analytics')
            }

            // get campaign id
            let pathnameArray = location.pathname.split('/')
            if(location.pathname.toUpperCase().includes('/CAMPAIGN-SUMMARY-INFLUENCER/')){
                setCampaignId(pathnameArray[pathnameArray.length-1])
            }else if(location.pathname.toUpperCase().includes('/DELIVERABLE/')){
                setCampaignId(pathnameArray[pathnameArray.length-2])
                setCreatorDeliverableId(pathnameArray[pathnameArray.length-1])
            }else if(location.pathname.toUpperCase().includes('/DELIVERABLE-DETAILS')){
                setCampaignId(pathnameArray[pathnameArray.length-1])
                setCreatorDeliverableId(pathnameArray[pathnameArray.length-2])
            }else{
                setCampaignId('')
                setCreatorDeliverableId('')
            }

            
        }
    },[location.pathname])
    
    const access_token = localStorage.getItem('refresh_token');
    const getComponent = {
        '/discover': <DashboardBrand />,
        '/dashboard': <DashboardBrand />,
        '/manage-campaigns': <ManageCampaigns />,
        '/influencer-details': <InfluencerDetails />,
        '/initial-influencer-details': <InfluencerDetails />,
        '/influencer-summary': <InfluencerSummary />,
        '/campaign-summary': <CampaignSummary />,
        '/payments': <PaymentDetails />,
        '/manage-campaign-view': <ManageCampaignView />,
        '/influencer-campaign-information': <InfluencerCampaignInformation />,
        '/proposed-influencers': <ProposedInfluencers />,
        '/profile' :  <Profile brandDetails = {brandDetails} getBrandDetail = {getBrandDetail}/>  
    }

    const getCreatorComponents = {
        '/analytics' : <CreatorLandingPage getCreatorDetails={getCreatorDetails} creatorDetails = {creatorDetails} setCreatorDetails = {setCreatorDetails} userID = {refresh_token?.user_uuid} drawerView = {false}/>,
        '/profile' :  <ProfileCreator creatorDetails = {creatorDetails} getCreatorDetails = {getCreatorDetails}/>  ,
        '/campaign' :  <CampaignCreator creatorDetails = {creatorDetails} getCreatorDetails = {getCreatorDetails}/>,
        '/campaign-summary-influencer' :  <CampaignSummaryInfluencer campaignid = {campaignid}/>,
        '/deliverable'  : <Deliverable campaignid = {campaignid} creatorDeliverableId = {creatorDeliverableId} />,
        '/home' : <DemoProfile creatorDetails = {creatorDetails} getCreatorDetails = {getCreatorDetails} />,
        '/connect-accounts' :   <ConnectInstagram creatorDetails = {creatorDetails} getCreatorDetails={getCreatorDetails}/>,
        '/deliverable-details' : <DeliverableInformation  campaignid = {campaignid} creatorDeliverableId = {creatorDeliverableId}/>
    }

    console.log("getCreatorComponents[defaultActive]", getCreatorComponents[defaultActive]);

    return(
        <div style={{background : 'var(--surface-medium, #EBF4FF)', minHeight : '100vh'}}>
            {refresh_token?.user_type === "Brand" ?
            <>
                <DashboardHeader
            active = {active}
            setActive = {setActive}
            brandDetails = {brandDetails}
            />
            
            <MasterLayoutStyle>
                {
                    getComponent[defaultActive]
                }         
            </MasterLayoutStyle>
            </>
            : 
            <div style={{background : 'var(--surface-medium, #EBF4FF)'}}>
                <DashboardHeaderCreator
                active = {active}
                setActive = {setActive}
                creatorDetails = {creatorDetails}
                />
            <MasterLayoutStyle style={location.pathname === "/profile" ? {flexDirection: 'column', alignItems: 'center'} : {}}>
                {
                getCreatorComponents[defaultActive]
                }
            </MasterLayoutStyle>

            </div>
            }
        </div>
        
    )
}

export default Dashboard