import { Col, Input, Tag, notification } from 'antd';
import React, { useState } from 'react';
import { Container, CustomRow, NoteHeading, SubDescription, SubmitButton, TabContentBox, TabContentDescription, TabContentHeading, TaxContainerBox, TaxLabel } from './style';
import { Heading, JustifyCenterContainer, SpaceBetweenContainer } from '../../utils/common';
import PaymentModeDetails from '../../components/ManageCampaigns/PaymentModeDetails';
import NEFTPayment from '../../components/ManageCampaigns/PaymentModeDetails/NEFTPayment';
import Successful from '../../components/ManageCampaigns/Successful';
import { usePaymentInitiatedMutation } from '../../services/manageCampaignsApi';
import { useParams } from 'react-router-dom';

const PaymentDetails = () => {
    const { campaignId, totalAmount, campaignCreatorIdArray  } = useParams();
    const [paymentMode, setPaymentMode] = useState('UPI');
    const [screenId, setScreenId] = useState(1);
    const [referenceNumber, setReferenceNumber] = useState('');
    const [amountSend, setAmountSend] = useState('');
    const [paymentButtonLoading, setPaymentButtonLoading] = useState(false);
    const [paymentInitiatedMutation] = usePaymentInitiatedMutation();

    const handleReference = (e) => {
        setReferenceNumber(e.target.value);
    }

    const handleAmountSend = (e) => {
        setAmountSend(e.target.value);
    }

    const handlePaymentMode = (mode) => {
        setPaymentMode(mode)
    }

    const handleSubmit = async () => {
        const paymentDataToSend = {
            "campaign_creator_ids": campaignCreatorIdArray.split(","),
            "payment_mode": paymentMode,
            "reference": referenceNumber
        }
        if(Number(amountSend) !== 'NaN'){
            paymentDataToSend.amount = Number(amountSend) 
        }
        const response = await paymentInitiatedMutation({ campaignId, paymentDataToSend });
        setPaymentButtonLoading(true);
        try {
            if (response && !response.error) {
                notification.success({
                    message: response?.data?.message
                })
                setScreenId(2);
                setPaymentButtonLoading(false);
            } else {
                notification.error({
                    message: response?.error?.data?.message
                })
                setPaymentButtonLoading(false);
            }
        } catch (error) {
            notification.error({
                message: error?.response?.error?.data?.message
            })
            setPaymentButtonLoading(false);
        }
    }

    return (
        <>
            {
                screenId === 1 ? <CustomRow gutter={[16, 16]} className='w-100' style={{ marginTop: 8 }}>
                    <Col span={16}>
                        <Container style={{ height: 417 }}>
                            <SpaceBetweenContainer>
                                <div style={{ width: '50%' }}>
                                    <Heading>Amount to be paid - ₹{totalAmount}</Heading>
                                    <SubDescription>After successful payment verification, you will be able to connect and collaborate with influencers</SubDescription>
                                    <TabContentBox onClick={() => handlePaymentMode('UPI')} className={`${paymentMode === "UPI" ? 'selected-payment-mode' : ''}`}>
                                        <SpaceBetweenContainer>
                                            <TabContentHeading>UPI Payments</TabContentHeading>
                                            {/* <Tag color="#17A600">Instant</Tag> */}
                                        </SpaceBetweenContainer>
                                        <TabContentDescription>Scan QR and pay instantly</TabContentDescription>
                                    </TabContentBox>
                                    <TabContentBox onClick={() => handlePaymentMode('NEFT')} className={`${paymentMode === "NEFT" ? 'selected-payment-mode' : ''}`}>
                                        <SpaceBetweenContainer>
                                            <TabContentHeading>NEFT Payment</TabContentHeading>
                                        </SpaceBetweenContainer>
                                        <TabContentDescription>Pay the amount through internet banking</TabContentDescription>
                                    </TabContentBox>
                                </div>
                                {
                                    paymentMode === "UPI" ?
                                        <JustifyCenterContainer>
                                            <PaymentModeDetails />
                                        </JustifyCenterContainer> :
                                        <NEFTPayment />
                                }
                            </SpaceBetweenContainer>
                        </Container>
                    </Col>
                    <Col span={8}>
                        <Container style={{ height: 417 }}>
                            <TaxContainerBox>
                                <TaxLabel>Enter Amount Paid</TaxLabel>
                                <Input size='large' value={amountSend} onChange={(e) => handleAmountSend(e)} placeholder="Eg. ₹5000" />
                                <TaxLabel style={{ marginTop: 16 }}>Enter Transaction Reference Number</TaxLabel>
                                <Input size='large' value={referenceNumber} onChange={(e) => handleReference(e)} placeholder="Eg. 23232xxxx232323" />
                                <SubmitButton shape='round' type="primary" onClick={handleSubmit} disabled={!referenceNumber || !amountSend} loading={paymentButtonLoading} block>
                                    Submit
                                </SubmitButton>
                            </TaxContainerBox>
                        </Container>
                    </Col>
                    <Col span={24}>
                        <Container>
                            <NoteHeading>Note:</NoteHeading>
                            <TabContentDescription>After making payment and providing payment details, please allow us some time to verify the payment. After successful payment verification you will be able to connect and collaborate with influencers</TabContentDescription>
                            <TabContentDescription>Track all your campaign from the Manage Campaign page. Payment verification update will become available in the campaign Activity Log.</TabContentDescription>
                        </Container>
                    </Col>
                </CustomRow> :
                    <Container style={{ margin: "20px 32px" }} className='w-100'>
                        <Successful />
                    </Container>
            }
        </>
    )
}

export default PaymentDetails