import { Col, Dropdown, Menu, Row } from "antd";
import React from "react";
import { AdmirianButton, CompanyDetails, CompanyLogoContainer, HamburgerAlignment, HeaderContainer } from "./style";
import { Link, useNavigate } from "react-router-dom";
import { FieldTimeOutlined, FileSearchOutlined, MehFilled, MenuOutlined, UserOutlined, LogoutOutlined } from '@ant-design/icons';
import { isMobile } from "../../../utils/constants/inedx";

const DashboardHeaderCreator = ({ active, setActive, creatorDetails }) => {
    const navigate = useNavigate();

    const menu = (
        <Menu style={{ borderRadius: '8px' }}>
            <Menu.Item key='logout'>
                <div
                    onClick={() => {
                        localStorage.removeItem(`access_token`)
                        localStorage.removeItem(`refresh_token`)
                        navigate(`/`)
                    }}
                    className="cursor-pointer"
                >
                    <LogoutOutlined />
                    <span style={{ marginLeft: '8px' }}>Logout</span>
                </div>
            </Menu.Item>
        </Menu>
    )

    const menuAnalytics = (
        <Menu style={{ borderRadius: '8px' }}>
            <Menu.Item key='analytics'>
                <Link to="/analytics">
                    <div
                        className="cursor-pointer"
                    >
                        <FileSearchOutlined />
                        <span style={{ marginLeft: '8px' }}>Analytics</span>
                    </div>
                </Link>
            </Menu.Item>
            <Menu.Item key='campaigns'>
                <Link to="/campaign">
                    <div
                        className="cursor-pointer"
                    >
                        <FieldTimeOutlined />
                        <span style={{ marginLeft: '8px' }}>Campaigns</span>
                    </div>
                </Link>
            </Menu.Item>
            <Menu.Item key='profile'>
                <Link to="/profile">
                    <div
                        className="cursor-pointer"
                    >
                        <UserOutlined />
                        <span style={{ marginLeft: '8px' }}>Profile</span>
                    </div>
                </Link>
            </Menu.Item>
        </Menu>
    )

    return (
        <HeaderContainer>
            <Row gutter={[20, 30]} className="w-100">
                <Col xl={4} lg={4} md={4} sm={4} xs={4} style={{ display: 'flex', alignItems: 'center' }}>
                    <Link to="/analytics">
                        {
                            isMobile ? <img src="https://static.admirian.com/Admirian_Logo.png" width={32} height={32} alt="logo" /> : <img src="https://static.admirian.com/9a312601-984e-4237-bfad-1454204ce38f_BrandLogo.svg" alt="logo" height={42} style={{ marginTop: 12 }} />
                        }
                    </Link>
                </Col>
                <Col xl={14} lg={14} md={14} sm={14} xs={14} style={{ alignItems: 'center', display: 'flex' }}>
                    {
                        !isMobile && <>
                            <Link to="/analytics">
                                <AdmirianButton
                                    icon={<img src="https://static.admirian.com/d50bb654-731f-4962-a118-5d4f36152203_search.svg" />}
                                    isActive={active === "Analytics" ? true : false}
                                    onClick={() => {
                                        setActive("Analytics")
                                    }}
                                >
                                    Analytics
                                </AdmirianButton>
                            </Link>
                            <Link to="/campaign">
                                <AdmirianButton
                                    icon={<img src="https://static.admirian.com/d50bb654-731f-4962-a118-5d4f36152203_search.svg" />}
                                    isActive={active === "Campaign" ? true : false}
                                    onClick={() => {
                                        setActive("Campaign")
                                    }}
                                >
                                    Campaigns
                                </AdmirianButton>
                            </Link>
                            <Link to="/profile">
                                <AdmirianButton
                                    icon={<img src="https://static.admirian.com/6aad7296-d8dd-4f7b-9328-c25285d61c7c_settingLogo.svg" />}
                                    isActive={active === "Profile" ? true : false}
                                    onClick={() => {
                                        setActive("Profile")

                                    }}
                                >

                                    Profile

                                </AdmirianButton>
                            </Link>
                        </>
                    }
                </Col>
                <Col xl={6} lg={6} md={6} sm={6} xs={6} style={{ display: "flex", justifyContent: 'flex-end', alignItems: 'center' }}>
                    {
                        isMobile ? <Dropdown overlay={menuAnalytics} placement="bottomRight">
                            <HamburgerAlignment><MenuOutlined /></HamburgerAlignment>
                        </Dropdown> : (
                            <CompanyDetails>
                                {creatorDetails && creatorDetails?.name}
                            </CompanyDetails>
                        )
                    }
                    <Dropdown overlay={menu} placement="bottomRight">
                        <a
                            className="ant-dropdown-link"
                            onClick={(e) => e.preventDefault()}
                        >
                            <CompanyLogoContainer>
                                {creatorDetails?.image ?
                                    <img
                                        src={creatorDetails?.image}
                                        alt="logo"
                                        width={28}
                                        height={28}
                                        className="border-circle"
                                    />
                                    :
                                    <MehFilled style={{ borderRadius: '50%', fontSize: '28px', color: 'gray' }} />
                                }
                            </CompanyLogoContainer>
                        </a>
                    </Dropdown>
                </Col>
            </Row>
        </HeaderContainer>
    )

}

export default DashboardHeaderCreator