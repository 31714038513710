import { Button, Row } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
    background : #FFFFFF;
    border-radius: 4px;
    padding: 24px;

    .selected-payment-mode {
        border: 1px solid #1551EA;
    }
`;

export const CustomRow = styled(Row)`
    padding: 20px
`;

export const SubDescription = styled.p`
    margin-bottom: 0px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: #2C2B50; 
    margin-top: 4px;
`;

export const TabContentBox = styled.div`
    padding: 12px;
    margin-top: 20px;
    border-radius: 8px;
    cursor: pointer;
    background: #F6FAFF;
    .ant-tag {
        padding-inline: 20px;
        margin-inline-end: 0px;
    }
`;

export const TabContentHeading = styled.h3`
    font-size: 14px;
    font-weight: 500;
    color: #0F0E1B;
    margin-top: 0px;
    margin-bottom: 0px;
`;

export const TabContentDescription = styled.p`
    font-size: 12px;
    color: #494785;
    margin-top: 4px;
    margin-bottom: 0px;
`;

export const NoteHeading = styled.h4`
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
    color: #010043;
    margin-top: 0px;
    margin-bttom: 16px;
`;

export const TaxContainerBox = styled.div`
    margin-top: 20px;
    .ant-input-lg {
        border-radius: 24px;
    }
    .ant-btn-primary {
        color: #fff;
        background-color: #C83E00;
        background-image: linear-gradient(97deg, #F86624 4.34%, #C83E00 101.42%);
        box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
        margin-top: 12px;
    }
`;

export const TaxLabel = styled.div`
    padding-bottom: 8px;
    font-size: 12px;
    font-weight: 400;
    color: #0F0E1B;
`;

export const SubmitButton = styled(Button)`
&:disabled {
    background-color: #BDBDDB !important;
    color: #fff;
    background: #BDBDDB !important;
}
`;

