import { Col, Image, Row } from "antd";
import React from "react";
import SignInForm from "../../components/Home/SignInForm";
import { Container, MotoContainer, SignInFormContainer, SignInText } from "./style";
import { JustifyCenterContainer } from "../../utils/common";
import Footer from "../../components/Footer";

const SignIn = ()=> {
    return (
        <div className="overflow-hidden">
                <JustifyCenterContainer className="w-100" style={{ minHeight : '50px', alignItems : 'center', padding: '6px 0px'}}>
                    <Image height={50} style={{margin : "4px 0px"}} preview = {false} src = "https://static.admirian.com/9a312601-984e-4237-bfad-1454204ce38f_BrandLogo.svg"/>
                </JustifyCenterContainer>
            <Container>
                <Row className="w-100" style={{display :'flex',justifyContent :'center'}}>
                    <Col xl={11} lg={11} md={12} sm={24} xs={24} style={{display : 'flex', justifyContent : 'center'}}>
                    <SignInFormContainer>
                        <SignInText> Try now to create impact</SignInText>
                        <SignInForm/>
                    </SignInFormContainer>
                    </Col>
                    <Col xl={13} lg={13} md={12} sm={0} xs={0} style={{padding :'20px'}}>
                        <MotoContainer>
                            Collaborate.
                            Measure.
                            Grow.
                        </MotoContainer>

                    </Col>
                </Row>
            </Container>
            <Footer />
        </div>
    )
}

export default SignIn