import React, { useEffect, useState } from 'react';
import { Container, CopyText, DeliverableContainer, DeliverableFullDetailsBox, DeliverableText, DeliverableType, FileDataContainer, GoLiveContainer, GoLiveSubHeading, ListItemDescription, ListItemHeading, LiveContainer, LiveLink, NoDataText } from './style';
import { Avatar, Button, Col, Divider, Input, Popconfirm, Row, Tag, Typography, message, notification } from 'antd';
import { LeftOutlined, UserOutlined } from '@ant-design/icons';
import { FlexContainer, Heading, MainButton, SubHeading } from '../../../utils/common';
import { useNavigate, useParams } from 'react-router-dom';
import { useCampaignApprovedContentMutation, useGetCampaignCreatorDeliverablesQuery, useReadyToLiveContentMutation } from '../../../services/manageCampaignsApi';
import Deliverable from '../../../pages/Deliverable';

const InfluencerCampaignInformation = () => {
    const navigate = useNavigate();
    const { id, creatorId } = useParams();
    const { data: { creator_thumbnail, creator_name, approved_rate, campaign_creator_status, creator_rate, deliverables = [] } = {}, refetch } = useGetCampaignCreatorDeliverablesQuery({ campaignId: id, campaignCreatorId: creatorId });
    const [campaignCreatorDeliverableId, setCampaignCreatorDeliverableId] = useState(deliverables[0]?.campaign_creator_deliverable_id);
    const [campaignCreatorDeliverable, setCampaignCreatorDeliverable] = useState(deliverables[0]);
    const [markAsCompletedLoading, setMarkAsCompletedLoading] = useState(false);
    const [goLiveLoading, setGoLiveLoading] = useState(false);
    const [campaignApprovedContentInitiatedMutation] = useCampaignApprovedContentMutation();
    const [readyToLiveContentInitiatedMutation] = useReadyToLiveContentMutation();
    useEffect(() => {
        if (deliverables) {
            setCampaignCreatorDeliverableId(deliverables[0]?.campaign_creator_deliverable_id)
            setCampaignCreatorDeliverable(deliverables[0])
        }
    }, [deliverables])

    // const { data: { deliverable_basic_info = {} } = {} } = useGetCampaignCreatorDeliverablesSubmissionQuery({ campaignId: id, campaignCreatorDeliverableId: campaignCreatorDeliverableId });

    const handleCopy = () => {
        navigator.clipboard.writeText("3434323423432");
        message.success("copied!!")
    }

    const handleMarkAsCompleted = async () => {
        const response = await campaignApprovedContentInitiatedMutation({ campaignId: id, campaignCreatorDeliverableId });
        setMarkAsCompletedLoading(true);
        try {
            if (response && !response.error) {
                notification.success({
                    message: response?.data?.message
                })
                setMarkAsCompletedLoading(false);
                refetch();
            } else {
                notification.error({
                    message: response?.error?.data?.error
                })
                setMarkAsCompletedLoading(false);
            }
        } catch (error) {
            notification.error({
                message: error?.response?.error?.data?.message
            })
            setMarkAsCompletedLoading(false);
        }
    }

    const handleConfirm = async () => {
        const response = await readyToLiveContentInitiatedMutation({ campaignId: id, creatorId });
        setGoLiveLoading(true);
        try {
            if (response && !response.error) {
                notification.success({
                    message: response?.data?.message
                })
                setGoLiveLoading(false);
                refetch();
            } else {
                notification.error({
                    message: response?.error?.data?.error
                })
                setGoLiveLoading(false);
            }
        } catch (error) {
            notification.error({
                message: error?.response?.error?.data?.message
            })
            setGoLiveLoading(false);
        }
    }

    return (
        <Container>
            <Typography className='cursor-pointer' onClick={() => navigate(-1)} style={{ color: '#494785', fontSize: 14 }}>
                <LeftOutlined style={{ marginRight: 4 }} /> Back
            </Typography>
            <Divider />
            {
                !!(deliverables.length && deliverables.every((el) => el?.submission_status?.toLowerCase() === "completed" && campaign_creator_status !== 'Live')) && (
                    <GoLiveContainer>
                        <div className='f-bold'>🥳 Yay! {campaignCreatorDeliverable?.deliverable_name} Content is ready to Go Live</div>
                        <GoLiveSubHeading>What's next? Click go live button to give permission to influencer to post this content</GoLiveSubHeading>
                        {/* <Input placeholder="Add Note" /> */}
                        {
                            campaign_creator_status === "Live Requested" ? <Button shape="round" disabled style={{ marginTop: 12, background: '#f9f9f9', border:'1px solid #bdbdbd',  color: '#bdbdbd' }}>
                                Live Requested
                            </Button>
                                : <Popconfirm
                                    placement="bottomLeft"
                                    onConfirm={handleConfirm}
                                    title={`Are you sure want to request ${creator_name} to go live?`}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <MainButton shape="round" loading={goLiveLoading} disabled={campaign_creator_status === "Live Requested"} style={{ marginTop: 12 }}>
                                        Request Go-Live
                                    </MainButton>
                                </Popconfirm>
                        }

                    </GoLiveContainer>
                )
            }
            {/* {
                (campaignCreatorDeliverableLength === 1 && deliverables?.[0]?.submission_status.toLowerCase() === "completed") && (
                    <LiveContainer>
                        <div className='f-bold'>🥳 Woow! Product Intro Content is Live</div>
                        <GoLiveSubHeading>Check the below link of your post</GoLiveSubHeading>
                        <LiveLink><div>instagram.com/Jasonhill&23#23232#post32323</div><CopyText onClick={handleCopy}>Copy</CopyText></LiveLink>
                    </LiveContainer>
                )
            } */}

            <Row gutter={[16, 24]} className='influencer-block'>
                <Col span={4}>
                    <FlexContainer>
                        {
                            creator_thumbnail?.length ? <Avatar size={'large'} src={creator_thumbnail} style={{ marginRight: 12 }} /> : <Avatar icon={<UserOutlined />} style={{ marginRight: 12 }} />
                        }

                        <div>
                            <ListItemHeading>Influencer Name</ListItemHeading>
                            <ListItemDescription style={{ color: '#1551EA' }}>{creator_name}</ListItemDescription>
                        </div>
                    </FlexContainer>
                </Col>
                <Col span={4}>
                    <ListItemHeading>Influencer Rate</ListItemHeading>
                    <ListItemDescription>{creator_rate !== approved_rate && (<span style={{ color: "#8988BF", marginRight: 4, textDecoration: 'line-through' }}>₹{creator_rate}</span>)} ₹{approved_rate}</ListItemDescription>
                </Col>
                <Col span={16}>
                    <ListItemHeading>Status</ListItemHeading>
                    <ListItemDescription>{campaign_creator_status}</ListItemDescription>
                </Col>
            </Row>
            <Heading style={{ marginTop: 16 }}>Deliverables</Heading>
            <SubHeading>All the deliverables which the influencer is supposed to deliver</SubHeading>
            <Row gutter={[16, 24]}>
                <Col span={7}>
                    {
                        deliverables.map((el) => (
                            <DeliverableContainer onClick={() => { setCampaignCreatorDeliverableId(el?.campaign_creator_deliverable_id); setCampaignCreatorDeliverable(el) }} className={campaignCreatorDeliverableId === el.campaign_creator_deliverable_id ? "deliverable-selected" : ""} key={el.campaign_creator_deliverable_id}>
                                <div style={{ width: "20%" }}>
                                    <img src={el?.platform?.toLowerCase() === "instagram" ? 'https://static.admirian.com/64d8bcd2-a452-4586-85c8-fcd10261d3c2_Instagram_Glyph_Gradient_1.svg' : "https://static.admirian.com/d1df6ebc-69fe-47f1-96d1-b82b212c4482_Youtube.svg"} alt='media' width={40} height={40} />
                                </div>
                                <div style={{ width: "50%" }}>
                                    <DeliverableText>{el.deliverable_name}</DeliverableText>
                                    <DeliverableType>{el.deliverable_type}</DeliverableType>
                                </div>
                                <div style={{ width: "30%", textAlign: "right" }}>
                                    {
                                        el?.submission_status?.toLowerCase() === "preparing content" ? <Tag color="#F86624">✍️ Preparing</Tag>
                                            : el?.submission_status?.toLowerCase() === "in review" ? <Tag color='#4E4BEE'>⏳ Review</Tag>
                                                : el?.submission_status?.toLowerCase() === "completed" ? <Tag color='#17A600'>Completed</Tag>
                                                    : ''
                                    }
                                </div>
                            </DeliverableContainer>
                        ))
                    }
                </Col>
                <Col span={17}>
                    {
                        id && campaignCreatorDeliverableId ? (
                            <Deliverable campaignid={id} creatorDeliverableId={campaignCreatorDeliverableId} handleMarkAsCompleted={handleMarkAsCompleted} markAsCompletedLoading={markAsCompletedLoading} campaignCreatorDeliverable={campaignCreatorDeliverable} />
                        ) :
                            <div>No Data</div>
                    }

                    {/* <DeliverableFullDetailsBox>
                        <Row gutter={[16, 24]}>
                            <Col span={6}>
                                <DeliverableType>Name</DeliverableType>
                                <ListItemDescription className='padding-top-8'>{deliverable_basic_info?.name}</ListItemDescription>
                            </Col>
                            <Col span={6}>
                                <DeliverableType>Type</DeliverableType>
                                <ListItemDescription className='padding-top-8'>{deliverable_basic_info?.media_type}</ListItemDescription>
                            </Col>
                            <Col span={12}>
                                <DeliverableType>Reference</DeliverableType>
                                <ListItemDescription className='padding-top-8'>{deliverable_basic_info?.reference ? deliverable_basic_info?.reference : "-"}</ListItemDescription>
                            </Col>
                            <Col span={24}>
                                <DeliverableType>Inspiration :</DeliverableType>
                                <ListItemDescription className='padding-top-8'>{deliverable_basic_info?.content_inspiration ? deliverable_basic_info?.content_inspiration : '-'}</ListItemDescription>
                            </Col>
                        </Row>
                    </DeliverableFullDetailsBox>
                    <Heading style={{ marginTop: 12 }}>Deliverables</Heading>
                    <SubHeading>Your basic details will help you to get discovered</SubHeading>
                    <FileDataContainer>
                        <div style={{ alignSelf: 'center', textAlign: 'center' }}>
                            <img src='https://static.admirian.com/c224b1b7-bd85-476a-bff2-18a2a510b321_no-file.svg' width={64} height={64} />
                            <NoDataText>No Files</NoDataText>
                        </div>
                    </FileDataContainer> */}
                </Col>
            </Row>
        </Container>
    )
}
export default InfluencerCampaignInformation;