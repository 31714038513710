import styled from "styled-components";
import { Input } from 'antd';

export const Container = styled.div`
    padding: 20px;
    background : #FFFFFF;
    border-radius: 4px;
    margin : 24px 32px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const FormContainer = styled.div`
    margin-top: 20px;
    .ant-btn-default {
        border-radius: 20px;
        border-color: #8988BF;
        color: #8988BF;
    }
`;

export const CollapseHeader = styled.div`
    font-size: 14px;
    font-weight: 600;
`
export const CollapseDetail = styled.div`
  font-size: 12px;
  color: #494785;
  .ant-radio-wrapper {
    font-size: 14px;
    color: #494785;
  }
`
export const CollapseSubDetail = styled.div`
  font-size: 12px;
  color: #494785;
`
export const ProductLabel = styled.p`
    font-size: 14px;
    font-weight: 400;
    color: #494785;
    margin-bottom: 12px;
    margin-top: 20px;
`;

export const InputField =styled(Input)`
    border: 1px solid #8988BF;
    border-radius: 20px;
`;

export const ProductDetailsExtraInformation = styled.div`
  font-size: 12px;
  margin-top: 12px;
  color: #494785;
`;

export const CategoryContainer = styled.div`
.ant-radio-button-wrapper {
    margin-right: 12px;
    border-radius: 16px;
    border: 1px solid #494785;
    color: #494785;
}
.ant-radio-button-wrapper:not(:first-child)::before {
    width: unset;
}
.ant-radio-button-checked {
    color: #1551EA;
    border: 1px solid #1551EA;
    background : #EBF4FF;
    border-radius: 16px;
}
`;

export const DeliverableListContainer = styled.div`
    height: 188px;
    overflow: auto;
    padding: 8px;
    .ant-list-split .ant-list-item {
        border-block-end: unset;
        background: #F6FAFF;
        margin-bottom: 12px;
        padding: 12px;
    }
    .ant-avatar-lg {
        width: 52px;
        height: 52px;
        border-radius: 12px;
    }
    .ant-list .ant-list-item .ant-list-item-meta .ant-list-item-meta-title {
        margin: 0px;
    }
    .ant-list .ant-list-item .ant-list-item-action .ant-list-item-action-split {
        background-color: unset;
    }
    .ant-list .ant-list-item .ant-list-item-action>li {
        font-size: 16px;
        color: #000000;
    }
`;

export const DeliverableListHeading = styled.h4`
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: #494785;
    margin: 0px;
`;

export const DeliverableListDescription = styled.p`
    font-size: 14px;
    font-weight: 400;
    color: #0F0E1B;
    margin-top: 8px;
`;