import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../utils/HelperFunctions/constants";
import { parseJwtToken } from "../utils";
// import { getIdcCookie } from "../utils/HelperFunctions/common";

export const manageCampaignsApi = createApi({
  reducerPath: "manageCampaignsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}`,
  }),
  endpoints: (builder) => ({
    getAllDeliverables: builder.query({
      query(params) {
        const refresh_token = parseJwtToken(
          localStorage.getItem("refresh_token")
        );
        const campaignId = params.campaignId;
        return {
          url: `/campaigns/${refresh_token?.user_uuid}/${campaignId}/deliverable/`,
          headers: {
            //clientid: getClientId(),
            Authorization: "",
          },
        };
      },
    }),
    getAllCampaigns: builder.query({
      query(params) {
        const refresh_token = parseJwtToken(
          localStorage.getItem("refresh_token")
        );
        const status = params.status, paramsData={page : params.page} ;
        if(status === "IN_PROGRESS" || status === "LIVE" || status === "COMPLETED" || status === "DRAFT" ){
          paramsData.campaign_status =  status;
        }else if(status === "ALL"){
          delete params.campaign_status
        }
        
        if(params.campaignType !== null && status !== "DRAFT"){
          paramsData.campaign_type = params.campaignType
        }
        return {
          url: `/campaigns/brand/${refresh_token?.user_uuid}/`,
          headers: {
            //clientid: getClientId(),
            Authorization: "",
            "X-App-Identifier" : "AdmirianFE"
          },
          params: paramsData
        };
      },
    }),
    // getParticularDeliverable: builder.query({
    //   query(params) {
    //     const refresh_token = parseJwtToken(
    //       localStorage.getItem("refresh_token")
    //     );
    //     return {
    //       url: `/campaigns/${
    //         refresh_token?.user_uuid
    //       }/${9}/deliverable/${params}/`,
    //       headers: {
    //         //clientid: getClientId(),
    //         Authorization: "",
    //         // "X-App-Identifier": "AdmirianFE",
    //       },
    //     };
    //   },
    // }),
    getParticularCampaign: builder.query({
      query(params) {
        const refresh_token = parseJwtToken(
          localStorage.getItem("refresh_token")
        );
        return {
          url: `/campaigns/${refresh_token?.user_uuid}/${params.campaignId}/`,
          headers: {
            //clientid: getClientId(),
            Authorization: "",
          },
        };
      },
    }),
    getInfluencerSummary: builder.query({
        query(params) {
          const refresh_token = parseJwtToken(
            localStorage.getItem("refresh_token")
          );
          const campaignId = params.campaignId, paramsData={};
          if(params.status !== "ALL"){
            paramsData.status =  params.status
          }
          if(params?.campaignCreatorIds?.length) {
            paramsData.campaign_creator_ids = params?.campaignCreatorIds; 
          }
          return {
            url: `/campaigns/${refresh_token?.user_uuid}/${campaignId}/campaign-creators/`,
            headers: {
              //clientid: getClientId(),
              Authorization: "",
            },
            params: paramsData
          };
        },
      }),
    getInfluencerRatecard: builder.query({
      query(params) {
        const refresh_token = parseJwtToken(
          localStorage.getItem("refresh_token")
        );
        return {
          url: `/pricing/${params.uuid}/ratecard/?platform=${params.platform}`,
          headers: {
            //clientid: getClientId(),
            Authorization: "",
          },
        };
      },
    }),
    getCampaignCreatorDeliverables: builder.query({
      query(params) {
        const refresh_token = parseJwtToken(
          localStorage.getItem("refresh_token")
        ), campaignId = params.campaignId, campaignCreatorId = params.campaignCreatorId ;
        return {
          url: `/campaigns/${refresh_token?.user_uuid}/${campaignId}/campaign-creator/${campaignCreatorId}/deliverables/`,
          headers: {
            //clientid: getClientId(),
            Authorization: "",
          },
        };
      }
    }),
    getTransactions: builder.query({
      query(params) {
        const refresh_token = parseJwtToken(
          localStorage.getItem("refresh_token")
        ), paramsData = {page: params.page}, campaignId = params.campaignId;
        if(params.transactionStatus !== "ALL") {
          paramsData.status = params.transactionStatus
        }
        return {
          url: `/pricing/${refresh_token?.user_uuid}/${campaignId}/transactions/`,
          headers: {
            //clientid: getClientId(),
            Authorization: "",
          },
          params : paramsData
        };
      }
    }),
    getCampaignCreatorDeliverablesSubmission: builder.query({
      query(params) {
        const refresh_token = parseJwtToken(
          localStorage.getItem("refresh_token")
        ), campaignId = params.campaignId, campaignCreatorDeliverableId = params.campaignCreatorDeliverableId ;
        return {
          url: `/campaigns/${refresh_token?.user_uuid}/${campaignId}/campaign-creator-deliverable/${campaignCreatorDeliverableId}/get-submission/`,
          headers: {
            //clientid: getClientId(),
            Authorization: "",
          },
        };
      }
    }),
    getCampaignActivity: builder.query({
      query(params) {
        const refresh_token = parseJwtToken(localStorage.getItem("refresh_token")), campaignId = params.campaignId ;
        return {
          url: `/campaigns/${refresh_token?.user_uuid}/${campaignId}/brand-activity-log`,
          headers: {
            //clientid: getClientId(),
            Authorization: "",
          },
        };
      }
    }),
    allInfluencersInitiated: builder.mutation({
      query(params) {
        const refresh_token = parseJwtToken(
          localStorage.getItem("refresh_token")
        );
        const data = { ...params };
        return {
          url: `/campaigns/${refresh_token?.user_uuid}/discover-creators/`,
          method: "POST",
          headers: {
            //clientid: getClientId(),
            Authorization: "",
          },
          body: data,
        };
      },
    }),
    campaignInitiated: builder.mutation({
      query(params) {
        const refresh_token = parseJwtToken(
          localStorage.getItem("refresh_token")
        );
        const formData = new FormData();
        formData.append("campaign_type", params?.selectedCard);
        formData.append("banner_file", params?.bannerFile);
        formData.append("name", params?.campaignName);
        formData.append("brief", params?.briefDescription);
        formData.append("objective", params?.campaignObjective);
        formData.append("category", params?.campaignCategory);
        formData.append("delivery_mode", params?.deliveryMode);
        formData.append("min_budget", params?.minValue);
        formData.append("max_budget", params?.maxValue);
        if(params?.selectedCard === 101){
          if(params?.genderSelected === "any"){
            formData.append("creator_gender", "male");
            formData.append("creator_gender", "female")
          }else {
            formData.append("creator_gender", params?.genderSelected);
          }
          formData.append("creator_tier", params?.inFluencerFollowerCount);
        }
        return {
          url: `/campaigns/${refresh_token?.user_uuid}/initiate/`,
          method: "POST",
          headers: {
            //clientid: getClientId(),
            Authorization: "",
          },
          body: formData,
          formData: true,
        };
      },
    }),
    deliverablesInitiated: builder.mutation({
      query(params) {
        const refresh_token = parseJwtToken(
          localStorage.getItem("refresh_token")
        );
        const data = params.deliverablesData;
        return {
          url: `/campaigns/${refresh_token?.user_uuid}/${params.campaignId}/deliverable/`,
          method: "POST",
          headers: {
            //clientid: getClientId(),
            Authorization: "",
          },
          body: data,
        };
      },
    }),
    deliverablesDeleteInitiated: builder.mutation({
      query(params) {
        const refresh_token = parseJwtToken(
          localStorage.getItem("refresh_token")
        );
        const deliverableId = params.id;
        const campaignId = params.campaignId;
        return {
          url: `/campaigns/${
            refresh_token?.user_uuid
          }/${campaignId}/deliverable/${deliverableId}`,
          method: "DELETE",
          headers: {
            //clientid: getClientId(),
            Authorization: "",
          },
        };
      },
    }),
    selectedInfluencerDeleteInitiated: builder.mutation({  query(params) {
      const refresh_token = parseJwtToken(
        localStorage.getItem("refresh_token")
      );
      const campaignCreatorId = params.campaignCreatorId;
      const campaignId = params.campaignId;
      return {
        url: `/campaigns/${
          refresh_token?.user_uuid
        }/${campaignId}/campaign-creators/${campaignCreatorId}`,
        method: "DELETE",
        headers: {
          //clientid: getClientId(),
          Authorization: "",
        },
      };
    },}),
    editDeliverablesInitiated: builder.mutation({
      query(params) {
        const refresh_token = parseJwtToken(
          localStorage.getItem("refresh_token")
        );
        const campaignId = params.campaignId;
        const deliverableId = params.deliverableId;
        const data = params.deliverableUpdatedData;
        return {
          url: `/campaigns/${refresh_token?.user_uuid}/${campaignId}/deliverable/${deliverableId}/`,
          method: "PATCH",
          headers: {
            //clientid: getClientId(),
            Authorization: "",
          },
          body: data,
        };
      },
    }),
    allAddedInfluencerInitiated: builder.mutation({
      query(params) {
        const refresh_token = parseJwtToken(
          localStorage.getItem("refresh_token")
        );
        const campaignId = params.campaignId;
        const data = {"creators": params.creators};
        return {
          url: `/campaigns/${refresh_token?.user_uuid}/${campaignId}/campaign-creators/`,
          method: "POST",
          headers: {
            //clientid: getClientId(),
            Authorization: "",
          },
          body: data,
        };
      },
    }),
    campaignSendProposalInitiated: builder.mutation({
        query(params) {
          const refresh_token = parseJwtToken(
            localStorage.getItem("refresh_token")
          );
          const campaignId = params.campaignId;
          return {
           url: `/campaigns/${refresh_token?.user_uuid}/${campaignId}/publish-campaign/`,
            method: "POST",
            headers: {
              //clientid: getClientId(),
              Authorization: "",
            }
          };
        },
    }), 
    paymentInitiated: builder.mutation({
      query(params) {
        const refresh_token = parseJwtToken(
          localStorage.getItem("refresh_token")
        );
        const campaignId = params.campaignId;
        const data = params.paymentDataToSend;
        return {
         url: `/campaigns/${refresh_token?.user_uuid}/${campaignId}/payment/`,
          method: "POST",
          headers: {
            //clientid: getClientId(),
            Authorization: "",
          },
          body: data,
        };
      },  
    }),
    campaignApprovedContent: builder.mutation({
      query(params) {
        const refresh_token = parseJwtToken(
          localStorage.getItem("refresh_token")
        );
        const campaignId = params.campaignId, campaignCreatorDeliverableId = params.campaignCreatorDeliverableId;
        return {
         url: `/campaigns/${refresh_token?.user_uuid}/${campaignId}/campaign-creator-deliverable/${campaignCreatorDeliverableId}/approve-content/`,
          method: "PATCH",
          headers: {
            //clientid: getClientId(),
            Authorization: "",
          },
        };
      },  
    }),
    readyToLiveContent: builder.mutation({
      query(params) {
        const refresh_token = parseJwtToken(
          localStorage.getItem("refresh_token")
        );
        const campaignId = params.campaignId, campaignCreatorId = params.creatorId;
        return {
         url: `/campaigns/${refresh_token?.user_uuid}/${campaignId}/campaign-creator/${campaignCreatorId}/request-go-live/`,
          method: "PATCH",
          headers: {
            //clientid: getClientId(),
            Authorization: "",
          },
        };
      },  
    }),
  }),
});

export const {
  useGetInfluencerSummaryQuery,
  useGetAllDeliverablesQuery,
  useGetAllCampaignsQuery,
//   useGetParticularDeliverableQuery,
  useGetCampaignActivityQuery,
  useGetParticularCampaignQuery,
  useGetInfluencerRatecardQuery,
  useGetTransactionsQuery,
  useGetCampaignCreatorDeliverablesQuery,
  useGetCampaignCreatorDeliverablesSubmissionQuery,
  useAllInfluencersInitiatedMutation,
  useCampaignInitiatedMutation,
  useDeliverablesInitiatedMutation,
  useDeliverablesDeleteInitiatedMutation,
  useSelectedInfluencerDeleteInitiatedMutation,
  useEditDeliverablesInitiatedMutation,
  useAllAddedInfluencerInitiatedMutation,
  useCampaignSendProposalInitiatedMutation,
  usePaymentInitiatedMutation,
  useCampaignApprovedContentMutation,
  useReadyToLiveContentMutation
} = manageCampaignsApi;
