import styled from 'styled-components';
import { Button } from 'antd';


export const HeaderContainer = styled.div`
    display : flex;
    background : #FFFFFF;
    min-height : 60px;
    padding: 8px 24px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
`

export const AdmirianButton = styled(Button)`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding:10px 24px 10px 18px;
    gap: 12px;
    height: 38px;
    background:${(props) => {
        switch (props.isActive) {
          case true:
            return 'linear-gradient(96.74deg, #4E4BEE -1.75%, #F86624 112.67%)';
          default:
            return '#FFFFFF';
        }
    }};
    border-radius: 10px;
    font-weight:${(props) => {
        switch (props.isActive) {
          case true:
            return '600';
          default:
            return '400';
        }
    }};
    font-size: 14px;
    line-height: 17px;
    color:${(props) => {
        switch (props.isActive) {
          case true:
            return '#FFFFFF !important';
          default:
            return '#010043 !important';
        }
    }};
    border: 0px !important;
    &:focus{
        outline : none !important;
     }
    &:active {
      outline : none !important;
    }
    margin-right : 20px;
    box-shadow : none;
`


export const CompanyDetails = styled.div`
    display : flex;
    align-items : center;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #010043;
    margin-right: 10px;
`

export const CompanyLogoContainer = styled.div`
  width: 32px;
  height: 32px;
  background: #FFFFFF;
  border: 1px solid #4E4BEE;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HamburgerAlignment = styled.div`
    margin-right: 8px;
    font-size: 16px;
    cursor: pointer;
`;