import styled from 'styled-components';

export const Heading6 = styled.div`
    display : flex ;
    align-items : center;
    flex-direction : column;
    background-image: url(https://static.admirian.com/bf8a5a12-22bf-46fa-a0b4-74eca567ac31_profile.png) , linear-gradient(180deg, #241046 0%, #130D58 100%);
    background-position-y: center;
    background-size : contain ;
    background-position: top;
    background-repeat : no-repeat; 
    font-weight : 700;
    font-size : 16px;
    line-height : 19px;
    padding-bottom : 30px ;
`

export const  FooterContainer = styled.div`
    height : 60px;
    display: flex;
    background:#FFFFFF;
    justify-content : space-between;
`

export const Container = styled.div`
    display : flex;
    flex-direction : column;
`

export const NewPasswordFormContainer = styled.div`
    flex-direction: column;
    min-height: 400px; 
    background: #FFFFFF; 
    margin: 80px 0px 20px 0px; 
    border-radius: 10px; 
    box-shadow: 0px 0px 33px 16px rgba(67, 184, 206, 0.2);
    display: flex; 
    padding: 16px; 
    align-items: center; 
    text-align: center;
`;

export const NewFormHeadingText = styled.div`
    display: flex; 
    font-weight: 600; 
    color: #010043; 
    font-size: 24px; 
    line-height: 40px; 
    justify-content: center; 
    margin-bottom: 26px;
`;

export const SignInText = styled.div`
    color: blue;
    margin-bottom: 16px;
`;