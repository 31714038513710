import React, { useState } from 'react';
import { Container, FormContainer } from './style';
import { AdmirianForm, FlexEndContainer, Heading, SubHeading } from '../../../utils/common';
import {
    Button,
    Col,
    Divider,
    Space,
    Form,
    Input,
    Row,
    Select,
    Upload,
    notification
} from 'antd';

import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import AddProductDetails from './AddProductDetails'
import { useDeliverablesInitiatedMutation } from '../../../services/manageCampaignsApi';
import { useParams } from 'react-router-dom';

const { Option } = Select;

const DeliverableForm =({ refetch}) => {
    const [platformType, setPlatformType] = useState('');
    const {campaignId} = useParams();
    const [addDeliverablesButtonLoading, setAddDeliverablesButtonLoading] = useState(false);
    const [associatedProductName, setAssociatedProductName] = useState("");
    const [productGiftingType, setProductGiftingType]= useState('');
    const [deliverablesInitiatedMutation] = useDeliverablesInitiatedMutation();

    const [form] = Form.useForm();

    const onFinish = async (values) => {
        try {
            setAddDeliverablesButtonLoading(true);
            if(Number(values.min_budget) > Number(values.max_budget)){
                setAddDeliverablesButtonLoading(false);
                notification.error({message : "min budget cannot be greater than max budget"})
            }else{
            const deliverablesData = {
                name: values.name,
                platform: values.platform,
                media_type: values.media_type,
                min_budget: values.min_budget,
                max_budget: values.max_budget,
            }
            if(values.content_inspiration.length) {
                deliverablesData.content_inspiration = values.content_inspiration
            }
            if(values.reference !== undefined) {
                deliverablesData.reference = values.reference
            }
            if(associatedProductName.length) {
                deliverablesData.associated_product_name = values.associatedProductName
            }
            if(productGiftingType.length) {
                deliverablesData.product_gifting_type = values.productGiftingType
            }
            const response = await deliverablesInitiatedMutation({deliverablesData, campaignId});
            if (response && !response.error) {
                setAddDeliverablesButtonLoading(false);
                notification.success({
                  message:  'Deliverable details saved. Add more or proceed to select influencers'
                })
                refetch();
                form.resetFields();
                setProductGiftingType("");
                setAssociatedProductName('');

              } else if (response.error) {
                setAddDeliverablesButtonLoading(false);
                notification.error({
                  message: response?.error?.data?.message
                })
              }
            }
        } catch (error) {
            setAddDeliverablesButtonLoading(false);
            notification.error({
                message: error?.response?.data?.message
            })
        }
    };

    const onReset = () => {
        form.resetFields();
        setProductGiftingType("");
        setAssociatedProductName('');
      };

    return(
        <FormContainer>
        <AdmirianForm
            form={form}
            name="deliverables"
            onFinish={onFinish}
            layout='vertical'
        >
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                        name="name"
                        label="Deliverable Name"
                        rules={[
                            {
                                required: true,
                                message: 'Please provide deliverable name',
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input placeholder='e.g. Makeup Tutorial Reel' />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        name="platform"
                        label="Platform"
                        rules={[
                            {
                                required: true,
                                message: 'Please select a platform',
                            },
                        ]}
                    >
                        <Select placeholder="Select Platform" onChange={(value) => setPlatformType(value)}>
                            <Option value="instagram">Instagram</Option>
                            <Option value="youtube">Youtube</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        name="media_type"
                        label="Media Type"
                        rules={[
                            {
                                required: true,
                                message: 'Please select a media type',
                            },
                        ]}

                    >
                        {
                            platformType === 'instagram' ? <Select placeholder="Select Media Type">
                                <Option value="image">Image</Option>
                                <Option value="story">Story</Option>
                                <Option value="reel">Reel</Option>
                                <Option value="carousel_album">Carousel Album</Option>
                            </Select> : <Select placeholder="Select Media Type">
                                <Option value="shorts">Shorts</Option>
                                <Option value="video">Video</Option>
                            </Select>
                        }
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="min_budget"
                        label="Deliverable Min Budget"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter min budget',
                                whitespace: true,
                                // type: 'number'
                            }
                        ]}
                    >
                        <Input placeholder='Enter Amount' />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="max_budget"
                        label="Deliverable Max Budget"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter max budget',
                                whitespace: true,
                                // type: 'number'
                            }
                        ]}
                    >
                        <Input placeholder='Enter Amount' />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        name="content_inspiration"
                        label="Provide a brief about deliverable"
                        rules={[
                            {
                                required: true,
                                message: 'Please provide a brief',
                                whitespace: true,
                                // type: 'number'
                            }
                        ]}
                    >
                        <Input placeholder='e.g. Create a vibrant makeup tutorial reel showcasing beach-friendly products and summer beauty tips.' />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        name="reference"
                        label="Add Reference URL"
                    >
                        <Input placeholder='https://www.abc.com/' />
                    </Form.Item>
                    {/* <Upload name="logo" action="/upload.do">
                        <Button icon={<PlusOutlined />}>Upload File</Button>
                    </Upload> */}
                </Col>
                <Col span={24}>
                    <AddProductDetails associatedProductName={associatedProductName} setAssociatedProductName={setAssociatedProductName} productGiftingType={productGiftingType} setProductGiftingType={setProductGiftingType} />
                    <Divider />
                </Col>
            </Row>
            <FlexEndContainer>
                <Space>
                    <Button style={{ color: '#494785' }} type='text' onClick={onReset} htmlType="reset">Cancel</Button>
                    <Button htmlType="submit" loading={addDeliverablesButtonLoading}>
                        Save Deliverable
                    </Button>
                </Space>
            </FlexEndContainer>
        </AdmirianForm>
    </FormContainer>
    )
}

export default DeliverableForm;