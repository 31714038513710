import { Modal } from "antd";
import styled from "styled-components";

export const CardContainer = styled.div`
    display: flex;
    padding: 16px;
    background-color: #F6FAFF;
    margin-bottom: 12px;
    .ant-typography {
        color: #010043;
        font-size: 12px;
        padding-left: 8px;
        padding-right: 8px;
    }
    .influencer-selected {
        border: 1px solid #00680A;
        border-radius: 8px;
        position: relative;
    }
`;

export const ContentHeading = styled.h4`
    font-size: 12px;
    font-weight: 400;
    color: #494785;
    text-transform: capitalize;
    margin-top: 0px;
    margin-bottom: 8px;
`;

export const ContentDescription = styled.p`
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
`;

export const PricingInformation = styled.p`
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin: 0px;
    color: #010043;
`;

export const ProductInfoContainer = styled.div`
    height: 150px;
    overflow-x: hidden;
    width: 100%;
`;

export const DeliverableInfo = styled.div`
    margin-bottom: 12px;
    padding: 8px;
    background: #F6FAFF;
    border-radius: 4px; 
    width: 100%;
    .ant-checkbox-wrapper {
        align-items: flex-start; 
    }
`;

export const DeliverableInfoText = styled.div`
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #010043;
    padding-left: 12px;
`;

export const AmountInfoText = styled.div`
    font-size: 12px;
    font-weight: 300;
    line-height: normal;
    color: #0F0E1B;
`;

export const AmountText = styled.div`
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    color: #494785;
`;

export const CustomModal = styled(Modal)`
.ant-btn-default {
    border: 1px solid #010043;
    color: #010043
}
`;

export const RateHeading = styled.h4`
    color: #010043;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0px;
    margin-top: 16px;
    line-height: unset;
`;

export const MediaRateInfo = styled.div`
    padding-top: 6px;
    padding-bottom: 6px;
    font-size: 12px;
    color: #010043;
    font-weight: 300;
    text-transform: capitalize
`;

export const MediaRate = styled.div`
    padding-top: 6px;
    padding-bottom: 6px;
    font-size: 13px;
    color: #010043;
    font-weight: 500;
`;