export const discoverstatuses = [
    {
        name :'All Campaigns',
        value : 'All'
    },
    {
        name :'Awaiting Brand Confirmation',
        value : 'requestPending'
    }];

export const creatorStatusList = [
    {
        name :'In Progress',
        value : 'IN_PROGRESS',
        campaignStatus : 'IN_PROGRESS'
    },
    {
        name :'Delivered',
        value : 'DELIVERED',
        campaignStatus : 'IN_PROGRESS'
    },
    {
        name :'Live Requested',
        value : 'LIVE_REQUESTED',
        campaignStatus : 'IN_PROGRESS,LIVE'
    },
    {
        name :'Live',
        value : 'LIVE',
        campaignStatus : 'IN_PROGRESS,LIVE'
    },
    {
        name :'Completed',
        value : 'LIVE',
        campaignStatus : 'COMPLETED'
    },
]

export const newRequestList =  [
    {
        name :'Pending',
        value : 'PENDING',
        campaignStatus : 'PUBLISHED,IN_PROGRESS'
    },
    {
        name :'Accepted',
        value : 'ACCEPTED',
        campaignStatus : 'PUBLISHED,IN_PROGRESS'
    },
]
