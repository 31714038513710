import styled from "styled-components";


export const Container = styled.div`
    padding: 20px;
    background : #FFFFFF;
    border-radius: 4px;
    margin : 24px 32px;
    width: 100%;

    .influencer-selected {
        border: 1px solid #00680A;
        border-radius: 8px;
        position: relative;
    }
`;

export const DetailsContainer = styled.div`
    margin-top: 32px;
    padding-bottom: 16px;
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border-radius: 20px;
        color: #494785;
        font-size: 12px;
        border: 1px solid #494785;
    }
    .ant-select .ant-select-arrow {
        color: #494785;
    }
    .ant-select .ant-select-selection-placeholder {
        color: #494785;
    }
`;

export const FilterRadioContainer = styled.div`
    padding-top: 20px;
    padding-bottom: 20px;
    .ant-radio-button-wrapper {
        margin-right: 12px;
        border-radius: 16px;
        border: 1px solid #D8D7EA;
        color: #010043;
        font-size: 12px;
    }
    .ant-radio-button-wrapper:not(:first-child)::before {
        width: unset;
    }
    .ant-radio-button-checked {
        color: #ffffff;
        border: 1px solid #F86624 !important;
        background : #F86624;
        border-radius: 16px;
        border-color: #F86624;
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
        border-color: #F86624;
    }
`;

export const InfluencerFooter = styled.div`
    background: #fff;
    width: 100%;
    padding: 0px 24px;
    position: fixed;
    bottom: 0px;
    color: #010043;
`;

export const BackButtonText = styled.div`
    font-size: 14px;
    font-weight: 500;
    padding-left: 8px;
`;

export const EstimationText = styled.div`
    color: #010043;
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
`;

export const ViewSummaryText = styled.div`
    font-size: 10px;
    font-weight: 300;
    margin-top: 4px
    color: #1551EA;
`;

export const AmountText = styled.div`
    font-size: 16px;
    font-weight: 500;
    color: #010043;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: 6px;
`;

export const PaginationContainer = styled.div`
    margin-top: 12px;
    text-align: right;
    margin-bottom: 40px;
`;