import styled from 'styled-components';

export const Container = styled.div`
    padding: 20px;
    background : #FFFFFF;
    border-radius: 4px;
    margin : 24px 32px;
    width: 100%;

    .ant-radio-button-wrapper {
        margin-right: 12px;
        border-radius: 16px;
        font-size: 12px;
        border: 1px solid #D8D7EA;
        padding-left: 20px;
        padding-right: 20px;
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
        background-color: unset;
    }
    .ant-radio-button-wrapper:not(:first-child)::before {
        background-color: unset;
    }
    .ant-radio-button-wrapper-checked {
        background: #F86624 !important;
        border-color: #F86624 !important;
        font-weight: 500;
    }
`;

export const CampaignContentContainer = styled.div`
    padding: 16px 20px;
    border-radius: 8px;
    background-color: #F6FAFF;
    margin-top: 12px;
`;

export const CampaignInfoHeading = styled.h2`
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
    color: #0F0E1B;
    margin-top: 0px;
`;


export const BannerNameText = styled.div`
    color: #010043;
    font-size: 14px;
    font-weight: 500;
`;

export const BannerNameHeadingText = styled.div`
    color: #010043;
    font-size: 10px;
    font-weight: 400;
    margin-bottom: 4px;
`;


export const CampaignInfoDescription = styled.div`
    font-size: 14px;
    line-height: 20px;
    color: #0F0E1B;
`;

export const CampaignObjectiveBox = styled.div`
    width: 20%
`;

export const CampaignObjectiveHeading = styled.h4`
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    color: #2C2B50;
    margin-top: 0px;
    margin-bottom: 8px;
`;

export const CampaignObjectiveDescription = styled.p`
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    text-transform: capitalize;
    color: #0F0E1B;
    margin: 0px;
`;

export const ListContainer = styled.div`
    padding: 16px;
    border: 1px solid #D8D7EA;
    border-radius: 12px;
    .ant-btn.ant-btn-round {
        padding-inline-start: 10px;
        padding-inline-end: 10px;
        font-size: 12px;
        border: 1px solid #1677ff;
        color: #1677ff;
    };
    // :last-child{
    //     // :first-child {
    //     //     border-bottom: none !important;
    //     //     padding-bottom: 0px !important;
    //     // }
    //     border-bottom: none !important;
    //     padding-bottom: 0px !important;
    // };
`;

export const ListBox = styled.div`
    display: flex !important;
    flex-wrap: wrap !important;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    // :last-child {
    //     border-bottom: none;
    //     padding-bottom: 0px;
    // };
    :first-child {
        padding-top: 0px;
    };
`;

export const ListContentBox = styled.div`
    width: 20%;
`;

export const ListItemHeading = styled.p`
    margin: 0px;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: #494785; 
    padding-bottom: 8px;
`;
export const ListItemDescription = styled.p`
    margin: 0px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #0F0E1B; 
`;

export const AmountInformationContainer = styled.div`
    max-height: 100vh;
    overflow: auto;
    border-radius: 12px;
    text-align: left;
    padding: 20px;
    box-shadow: 16px 8px 40px 0px rgba(0, 0, 0, 0.08);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .ant-btn-primary {
        color: #fff;
        background-color: #C83E00;
        background-image: linear-gradient(97deg, #F86624 4.34%, #C83E00 101.42%);
        box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
    }
`;


export const PaymentHeading = styled.div`
    font-size: 14px;
    font-weight: 500;
    color: #0F0E1B;
    margin-bottom: 4px;
`;

export const PaymentDescription = styled.p`
    font-size: 12px;
    font-weight: 400;
    color: #2C2B50;
    margin-top: 0px;
`;

export const NoActivityContainer = styled.div`
    text-align: center;
    color: #8988BF;
    height: 120px;
`;

export const NoActivityText = styled.div`
    color: #8988BF;
    font-size: 12px;
    font-weight: 400;
`;

export const AcceptedOfferContainer = styled.div`
    padding: 16px 20px;
    background: #F86624;
    border-radius: 8px;
    margin-bottom: 12px;
    .ant-btn-primary {
        color: #2C2B50;
        background: #EBF4FF
    }
    .ant-btn-primary:not(:disabled):hover {
        color: #2C2B50;
        background: #EBF4FF;
    }
`;

export const AcceptInfleuencerText = styled.div`
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    padding-left: 4px;
`;
export const NoDataText = styled.div`
    color: #010043;
    font-size: 12px;
    font-weight: 400;
    padding-top: 4px;
    opacity: 0.5;
`;

export const ActivityCard = styled.div`
    padding: 12px;
    border-radius: 8px;
    background: #F6FAFF;
    display: flex;
    margin-top: 8px;
`;

export const UserNameText = styled.div`
    color: #0F0E1B;
    font-size: 14px;
`;

export const CreatedDateText = styled.div`
    color: #8988BF;
    font-size: 10px;
`;

export const CommentDescriptionText = styled.div`
    color: #4E4BEE;
    font-size: 12px;
    padding-top: 8px;
`;