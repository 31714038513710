import { Col, Row, Spin } from "antd";
import React from "react";
import CreatorAnalytics from "../CreatorAnalytics";
import { NavigationCard, NavigationTitle, NoDataAvailableText } from "./style";
import { JustifyCenterContainer } from "../../utils/common";

const InstagramAnalytics = ({ analyticsData, setAnalyticsData, isLoading }) => {

    const handleNavigationClick = (id) => {
        const element = document.getElementById(id);
        element.scrollIntoView({ behavior: "smooth", inline: "nearest" });
    }


    return (
        <>
            {!isLoading ?
                analyticsData && Object.keys(analyticsData).length > 0 ?
                    <Row gutter={[16, 16]}>
                        <Col xl={6} lg={6} md={6} sm={0} xs={0}>
                            <NavigationCard>
                                <NavigationTitle onClick={() => handleNavigationClick('channelMetrics')}>
                                    Channel Metrics
                                </NavigationTitle>
                                {analyticsData?.featured_media?.featured_posts.length > 0 &&
                                    <NavigationTitle onClick={() => handleNavigationClick('featurePost')}>
                                        Featured Post
                                    </NavigationTitle>
                                }
                                {analyticsData?.featured_media?.featured_reels.length > 0 &&
                                    <NavigationTitle onClick={() => handleNavigationClick('featureReel')}>
                                        Featured Reels
                                    </NavigationTitle>
                                }
                                <NavigationTitle onClick={() => handleNavigationClick('postDensity')}>
                                    Post Density
                                </NavigationTitle>
                                <NavigationTitle onClick={() => handleNavigationClick('activityHeatMap')}>
                                    Activity Heat Map
                                </NavigationTitle>

                            </NavigationCard>

                        </Col>
                        <Col xl={18} lg={18} md={18} sm={24} xs={24}>
                            <CreatorAnalytics
                                analyticsData={analyticsData}
                                setAnalyticsData={setAnalyticsData}
                                isLoading={isLoading}
                            />
                        </Col>
                    </Row>
                    :
                    <NoDataAvailableText>
                        No Data Available
                    </NoDataAvailableText>
                :
                <JustifyCenterContainer className="w-100">
                    <Spin />
                </JustifyCenterContainer>
            }
        </>
    )
}

export default InstagramAnalytics