import React from "react";
import { Container } from "./style";
import { Heading, SubHeading } from "../../utils/common";

const NoDataFound =({ tabSelection })=>{
    let subHeadingText = "";
    if (tabSelection === 'ALL') {
        subHeadingText = "No campaigns to display yet. Click on 'Create Campaigns' button to launch your first campaign!"
    } else if (tabSelection === 'DRAFT') {
        subHeadingText = "No campaigns to display yet. Click on 'Create Campaigns' button to launch your first campaign!"
    } else if (tabSelection === 'IN_PROGRESS') {
        subHeadingText = "No campaigns in progress at the moment. Start publishing your campaigns and continue with creators"
    } else if (tabSelection === 'LIVE') {
        subHeadingText = `No campaigns are live at the moment. Monitor campaign activity logs to know when your campaigns can go live`
    } else if (tabSelection === 'COMPLETED') {
        subHeadingText = `No completed campaigns available yet`
    } else {
        subHeadingText = `No data available`
    }
    return(
        <Container>
            {/* <img src="https://static.admirian.com/cc25669b-4289-4bfe-ba87-65688e10fca9_NoInformation.svg" alt="no-data" width={152} height={124} /> */}
            <img src='https://static.admirian.com/c224b1b7-bd85-476a-bff2-18a2a510b321_no-file.svg' width={64} height={64} />
            <SubHeading>{subHeadingText}</SubHeading>
        </Container>
    )
}

export default NoDataFound;