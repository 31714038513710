import { Drawer } from 'antd';
import styled from 'styled-components';

export const TransactionContainer = styled.div`
    .ant-drawer-header {
        background: #1551EA; 
    }
`;

export const TotalAmountContainer = styled.div`
    background: #1551EA;
    color: #fff;
    padding: 20px;
    border-radius: 8px;
`;

export const TotalAmountText = styled.h1`
    margin: 0px;
    font-size: 20px;
    color: #fff;
    padding-bottom: 2px;
    font-weight: 600;
`;

export const TransactionListContainer = styled.div`
    border: 1px solid #EBF4FF;
    padding: 12px;
    border-radius: 10px;
    margin-top: 16px;
`;

export const TransactionTextHeading = styled.div`
    color: #2C2B50;
    font-size: 12px;
    padding-bottom: 4px;
`;

export const TransactionTextDescription = styled.div`
    color: #0F0E1B;
    font-size: 14px;
`;
export const CustomDrawer = styled(Drawer)`
    .ant-drawer-header {
        background: #1551EA; 
    };
    .ant-drawer-body {
        background: #ebf4ff;
    }
`
export const InfluencerHeadingText = styled.div`
    color: #2C2B50;
    font-size: 14px;
    margin-bottom: 8px;
`;

export const InfluencerDetailListContainer = styled.div`
    background: #fff;
    padding: 8px;
    border-radius: 4px;
    margin-top: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const TransactionNameText = styled.div`
    color: #1551EA;
    font-size: 14px;
`;

export const TransactionDescriptionNote = styled.div`
    padding-top: 6px;
    color: #0F0E1B;
    font-size: 12px;
    line-height: 16px;
`;

export const PaginationContainer = styled.div`
    margin-top: 12px;
    text-align: right;
`;