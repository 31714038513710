import styled from 'styled-components';
import media from '../../utils/UIElements/media';

export const Container = styled.div`
    display :flex;
    width : 100%;
    background-image: url(https://static.admirian.com/55a34dd1-7371-4791-83af-ed8f067400ef_BGSignInFlow.svg),linear-gradient(180deg, #241046 0%, #130D58 100%);
    background-repeat : no-repeat; 
    min-height : 80vh;
`

export const MotoContainer = styled.div`
    display :flex;
    align-items : center;
    justify-content : flex-start;
    width : 100%;
    height : 100%;
    font-weight: 700; 
    color: #FFFFFF;
    font-size: 120px;
    line-height: 145px;
`

export const OtpHeading = styled.div`
    display : flex;

    color: var(--Typo-High, #0F0E1B);
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    margin-top: 8px;
    margin-bottom:8px;
    line-height: normal;
`

export const OtpSubHeading = styled.div`
    display : flex;
    color: var(--Typo-Mid-High, #2C2B50);

    /* H4 */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
`

export const FormContainer = styled.div`
    display: flex;
    padding: 32px;
    align-items: center; 
    text-align: center;
    width : 350px;
    flex-direction : column;
    min-height : 400px; 
    background : #FFFFFF;
    margin : 80px 0px 40px 0px;
    border-radius : 10px;
    box-shadow : 0px 0px 33px 16px rgba(67, 184, 206, 0.2);
    ${media.mobile`
        margin : 40px 0px 40px 0px;
    `}
`;

export const OtpBox = styled.div`
    margin-top: 48px;
    margin-bottom: 48px;
    margin-left: 20px;
    ${media.mobile`
        margin-top: 24px;
        margin-bottom: 24px;
        margin-left: 0px;
    `}
`;

export const ResendOtp = styled.div`
    display : flex; 
    justify-content : center;
    margin-bottom : 24px;
    font-size : 16px;
    color : #010043;
    cursor : pointer; 
    text-decoration : underline;
`;