import styled from "styled-components";
import { Button, Col, Row } from "antd";
import media from "../../utils/UIElements/media";

export const CreatorAnalyticsContainer = styled.div`
    display : flex;
    flex-direction : column;
    .ant-carousel{
        width : 100%;
    }
    .slick-dots{
        width : 0px;
    }
`

export const AnalyticsDetailCard = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 27px 0px;
    gap: 4px;
    background: var(--surface-light, #F6FAFF);
    border-radius: 10px;
    width : 100%;
`

export const AnalyticsDetailCardRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px 0px;
    gap: 32px;
    background: var(--surface-light, #F6FAFF);
    border-radius: 10px;
`

export const MostActiveMediaContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 24px;
    gap: 10px;
    background: #FFFFFF;
    border-radius: 10px;
    justify-content : flex-start;
`

export const CardHeadingAnalytics = styled.div`
    color: var(--typo-high, #0F0E1B);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    ${media.mobile`
        font-size: 16px;   
        `
    }
`

export const CardSubHeadingAnalytics = styled.div`
    color: var(--typo-medium, #494785);
    leading-trim: both;
    text-edge: cap;

    /* Caption Light */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
`

export const FeaturedMediaCard = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    gap: 16px;
    background: #FFFFFF;
    border-radius: 10px;
` 
export const ToggleButton = styled(Button)`
    display: flex;
    border: 0px;
    border-radius: 50%;
    background: rgb(255, 255, 255);
    height: 35px;
    width: 35px;
    justify-content: center;
    align-items: center;
`
export const CompanyLogoContainer = styled.div`
  width: 32px;
  height: 32px;
  background: #FFFFFF;
  border-radius: 50%;
  display: flex;
  align-items: center;
  margin-right: 10px;
  alignItems : flex-start;
  border: 0px;
  margin-right : 5px;
`
export const ChannelMatricsContainer = styled.div`
  justify-content : space-between;
  margin-bottom : 24px;
  background : #fff;
  padding : 32px;
  border-radius : 10px;
`;

export const CustomRow = styled(Row)`
    display : flex;
    justify-content : space-between;
    margin-bottom : 24px;
`;

export const CustomColumn = styled(Col)`
    display : flex;
    justify-content : center;
    align-items : center;
`;

export const FollowerInformation = styled.div`
    font-size : 32px;
    font-weight : 700;
    color: #010043;
`;

export const FollowerInfoHeading = styled.div`
    font-size : 18px;
    color: #01004380;
`;

export const PastInfoText = styled.div`
   font-size : 10px;
   color: #01004380;
`;

export const PercentageInformation = styled.div`
    display : flex;
    font-size : 18px;
    font-weight : 500;
    line-height : 22px;
    justify-content : center;
    alignItems : center;
`;

export const PeriodText = styled.div`
    font-size : 12px; 
    color : #010043;
`;

export const FeaturedPost = styled.div`
    display : flex;
    width : 100%;
    justify-content : space-between;
    font-size: 18px; 
    font-weight: 700; 
    color : #010043; 
    margin-bottom : 24px;
`;

export const MediaCountText = styled.div`
    font-size : 16px;
    font-weight : 600;
    color : #010043
`;

export const MediaText = styled.div`
    font-size : 12px;
    color : #01004380;
`;

export const MostTextHeading = styled.div`
    color : #010043; 
    font-size : 18px;
    font-weight : 600;
`;

export const MostMetricCountText = styled.div`
    font-size : 24px;
    font-weight: 600;
    color : #010043;
`;

export const MostMetricText = styled.div`
    font-size : 14px;
    font-weight : 400;
    color : #01004380;
`;

export const GraphCardContainer = styled.div`
    padding : 24px; 
    background : #FFFFFF;
    border-radius : 10px;
    margin-bottom : 24px;
`;

export const NoDataAvailableText = styled.div`
    display : flex;
    justify-content : center;
    color: red;
    font-size : 20px; 
    font-weight : 600;
`;