import { Modal } from 'antd';
import React from 'react';
import { MediaRate, MediaRateInfo, RateHeading } from './style';
import { SpaceBetweenContainer } from '../../../utils/common';
import { useGetInfluencerRatecardQuery } from '../../../services/manageCampaignsApi';

const RatecardModal = ({ rateCardModal, setRateCardModal, selectedInfluencerId }) => {

    const { data: { ratecard = [] } = {}, isLoading, isError, isSuccess, error, refetch } =
        useGetInfluencerRatecardQuery({ platform: ["youtube", "instagram"], uuid: selectedInfluencerId });

    const showModal = () => {
        setRateCardModal(true);
    };
    const handleOk = () => {
        setRateCardModal(false);
    };
    const handleCancel = () => {
        setRateCardModal(false);
    };
    const instagramData = ratecard.filter((card) => card.platform === "instagram");
    const youtubeData = ratecard.filter((card) => card.platform === "youtube");
    return (
        <Modal title="All Rates" open={rateCardModal} footer={null} onOk={handleOk} onCancel={handleCancel}>
            {
                instagramData && (
                    <>
                        <RateHeading>Instagram</RateHeading>
                        {
                            instagramData.map((instaData, index) => {
                                return (
                                    <SpaceBetweenContainer key={index}>
                                        <MediaRateInfo>{instaData.media_type}</MediaRateInfo>
                                        <MediaRate>₹{instaData.rate}</MediaRate>
                                    </SpaceBetweenContainer>
                                )
                            })
                        }

                    </>
                )
            }
            {
                youtubeData && (
                    <>
                        <RateHeading>Youtube</RateHeading>
                        {
                            youtubeData.map((youData, index) => {
                                return (
                                    <SpaceBetweenContainer key={index}>
                                        <MediaRateInfo>{youData.media_type}</MediaRateInfo>
                                        <MediaRate>₹{youData.rate}</MediaRate>
                                    </SpaceBetweenContainer>
                                )
                            })
                        }
                    </>
                )
            }
        </Modal>
    )
}

export default RatecardModal;