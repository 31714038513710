
import { Button } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
    margin : 32px 48px;
    border-radius: 10px;
    background: var(--Typo-White, #FFF);
    padding : 0 32px;
    display : flex;
    flex-direction : column;
    .ant-divider-horizontal{
        margin : 0;
    }
`;

export const CardContainer = styled.div`
    display: flex;
    padding: var(--Space-List-Lg, 24px);
    justify-content: center;
    align-items: flex-start;
    gap: 24px;
    border-radius: 10px;
    flex-direction : column;
    border: 1px solid var(--UI-Component-Stroke-light, #D8D7EA);
`

export const CardHeading = styled.div`
    color: var(--Typo-Mid-High, #2C2B50);

    /* Title/Title 4 */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom : 8px;
`

export const CardDetail = styled.div`
    color: var(--Typo-High, #0F0E1B);

    /* Body L */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
`

export const Heading = styled.div`
    color: #010043;

    /* H3 */
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom : 8px;
`

export const SubHeading = styled.div`
    color: var(--Typo-Mid-High, #2C2B50);
    leading-trim: both;
    text-edge: cap;

    /* Caption Light */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
`

export const UploadContainer = styled.div`
    display: flex;
    padding: var(--Space-List-Md, 16px);
    justify-content: center;
    align-items: center;
    gap: var(--Space-List-Md, 16px);
    flex: 1 0 0;
    border-radius: var(--Radius-Modal-Md, 12px);
    background: var(--Surface-Light, #F6FAFF);
    margin-bottom : 16px;
    .ant-upload-wrapper .ant-upload-disabled {
        cursor: unset !important;
    }
`

export const FeedbackButton = styled(Button)`
    display: flex;
    padding: 10px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 60px;
    background: var(--Surface-Medium, #EBF4FF);
    border : 0px !important;
    color: var(--UI-Component-Blue, var(--System-Blue, #1551EA));
    text-align: center;

    /* Button/small */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
`

export const CommentContainer = styled.div`
    display: flex;
    padding: var(--Radius-Modal-Md, 12px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Radius, 4px);
    align-self: stretch;
    border-radius: var(--Space-List-Sm, 8px);
    background: var(--Surface-Light, #F6FAFF);
    margin-bottom : 16px;
`

export const ActivityCard = styled.div`
    display: flex;
    padding: var(--Radius-Modal-Md, 12px);
    justify-content : space-between;
    align-items: flex-start;
    border-radius: var(--Space-List-Sm, 8px);
    background: var(--Surface-Light, #F6FAFF);
    overflow-y : scroll;
`
export const PhotoContainer = styled.div`
    width: 24px;
    height: 24px;
    background: #FFFFFF;
    border: none;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right : 10px;
`
export const ActivityCardDetail = styled.div`
    color: var(--Typo-Medium, #494785);

    /* Body S */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
`

export const CancelButton = styled(Button)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 30px;
    gap: 10px;
    width: 107px;
    height: 40px;
    background: #FFFFFF !important;
    border: 1px solid #010043 !important;
    border-radius: 60px;
    margin-right : 16px;
    color: #010043 !important;
    font-weight : 500;
    font-size : 14px;
`

export const SaveButton = styled(Button)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 40px;
    gap: 10px;
    width: 113px;
    height: 40px;
    background: linear-gradient(0deg, #F86624, #F86624), #F9C80E !important;
    border-radius: 60px;
    border : 0px !important;
    color: #FFFFFF !important;
    font-weight : 500;
    font-size : 14px;
`
export const FileDataContainer = styled.div`
    border-radius: 10px;
    border: 1px solid #D8D7EA;
    height: 240px;
    display: flex;
    justify-content: center;
`;

export const NoDataText = styled.div`
    color: #010043;
    font-size: 12px;
    font-weight: 400;
    padding-top: 4px;
    opacity: 0.5;
`;

export const GoLiveContainer = styled.div`
    background: #1551EA;
    color: #fff;
    font-size: 14px;
    border-radius: 10px;
    padding: 12px;
    margin-bottom: 12px;
`;

export const GoLiveSubHeading = styled.p`
   font-size: 12px;
`;

export const LiveContainer = styled.div`
    background: #17A600;
    color: #fff;
    font-size: 14px;
    border-radius: 10px;
    padding: 12px;
    margin-bottom: 12px;
`;
export const LiveRequestContainer = styled.div`
    background: #1551EA;
    color: #fff;
    font-size: 14px;
    border-radius: 10px;
    padding: 12px;
    margin-bottom: 12px;
`

export const LiveLink = styled.div`
    background: #fff;
    border-radius: 10px;
    padding: 12px;
    display: flex;
    justify-content: space-between;
    color: #2C2B50;
    font-size: 14px;
`;

export const CopyText = styled.div`
    font-size: 12px;
    font-weight: 400;
    color: #1551EA;
    cursor: pointer;
`;
