export const campaignStatus = {
    draft: "DRAFT",
    published: "PUBLISHED",
    in_progress:"IN_PROGRESS",
    live: "LIVE",
    completed: "COMPLETED",
    archived: "ARCHIVED"
}

export const campaignObjective = {
    "Product Launch" : "product launch",
    "Promotion": "promotion",
    "Review": "review",
    "Awareness" : "awareness",
    "Other": "other"
}

export const transactionStatusValue ={
    "pending": "Pending",
    "paid" : "Paid",
    "processing": "Processing"
}