import React from 'react';
import { Link } from "react-router-dom";
import { Container } from './style';
import { FlexContainer, Heading, MainButton, SpaceBetweenContainer, SubHeading } from '../../utils/common';
// import { SearchOutlined } from '@ant-design/icons';
// import { Input } from 'antd';
import AllCampaigns from '../../components/ManageCampaigns/AllCampaigns';

const ManageCampaigns = () => {
    return (
        <Container>
            <SpaceBetweenContainer>
                <div>
                    <Heading>
                        All Campaigns
                    </Heading>
                    <SubHeading>Find and manage all your campaigns at one place</SubHeading>
                </div>
                <div>
                    <FlexContainer>
                        {/* <Input size='large' placeholder="Search" prefix={<SearchOutlined style={{ color: 'grey' }} />} style={{ borderRadius: 16 }} /> */}
                        <Link to="/create-campaign" >
                            <MainButton size='large' shape="round" style={{ marginLeft: 16, }}>
                                Create Campaigns
                            </MainButton>
                        </Link>
                    </FlexContainer>
                </div>
            </SpaceBetweenContainer>
            <AllCampaigns />
        </Container>
    )
}

export default ManageCampaigns;