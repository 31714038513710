import React, { useState } from 'react';
import { FlexContainer, Heading, SubHeading } from '../../../utils/common';
import { DeliverableListContainer, DeliverableListHeading, DeliverableListDescription } from './style';
import { Avatar, Divider, List, Popconfirm, notification } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useDeliverablesDeleteInitiatedMutation } from '../../../services/manageCampaignsApi';
import { useParams } from 'react-router-dom';

const DeliverablesList = ({ data, isLoading, isError, isSuccess, error, refetch, setParticularDeliverable, setScreenId }) => {

    const [deleteDeliverablesInitiatedMutation] = useDeliverablesDeleteInitiatedMutation();
    const {campaignId} = useParams();

    const handleDeleteDeliverables = async (deliverableData) => {
        const { id } = deliverableData;
        const response = await deleteDeliverablesInitiatedMutation({id, campaignId});
        try {
            if (response && !response.error) {
                notification.success({
                    message: 'Deliverable deleted successfully.'
                })
                refetch();
            } else if (response.error) {
                notification.error({
                    message: response?.error?.data?.message
                })
            }
        } catch (error) {
            notification.error({
                message: response?.error?.data?.message
            })
        }
    }

    const handleEditDeliverable = (deliverableData) =>{
        setParticularDeliverable(deliverableData)
        setScreenId("2")
    }

    return (
        <>
            <Heading>You have added {data.length} {data.length === 1 ? "Deliverable": "Deliverables"}</Heading>
            <SubHeading>You may edit or remove your deliverables here</SubHeading>
            <DeliverableListContainer>
                <List
                    itemLayout="horizontal"
                    dataSource={data}
                    renderItem={(item, index) => (
                        <List.Item actions={[<EditOutlined onClick={()=>handleEditDeliverable(item)} />, <Popconfirm
                            placement="right"
                            title={"Are you sure you want to delete this deliverable?"}
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => handleDeleteDeliverables(item)}
                        > <DeleteOutlined className='cursor-pointer' /> </Popconfirm>]}>
                            <List.Item.Meta
                                style={{ alignItems: 'flex-start' }}
                                avatar={<Avatar size={"large"} src={`${item.platform === "youtube"? "https://static.admirian.com/d1df6ebc-69fe-47f1-96d1-b82b212c4482_Youtube.svg": "https://static.admirian.com/64d8bcd2-a452-4586-85c8-fcd10261d3c2_Instagram_Glyph_Gradient_1.svg" }`} />}
                                title={<FlexContainer>
                                    <div style={{ width: "30%" }}>
                                        <DeliverableListHeading>Name</DeliverableListHeading>
                                        <DeliverableListDescription>{item?.name}</DeliverableListDescription>
                                    </div>
                                    <div style={{ width: "70%" }}>
                                        <DeliverableListHeading>Type</DeliverableListHeading>
                                        <DeliverableListDescription>{item?.media_type}</DeliverableListDescription>
                                    </div>
                                </FlexContainer>}
                            // description={{item.title}}
                            />
                        </List.Item>
                    )}
                />
            </DeliverableListContainer>
            <Divider />
        </>
    )
}

export default DeliverablesList;