import { Col, Row, Spin, notification } from "antd";
import React, { useState } from "react";
import { AccountPageButton, BackButton, ChoosingInformation, ComingSoonAlignment, ConnectInstagramContainer, CustomButton, DifferentAccountText, GetStartedHeading, HowToDoText, IconContainer, RequirementInformation, RightArrowAlignment, SaveAndSecureText, SelectedPlatform, SelectedPlatformContent, SelectedPlatformPage, SocialAccountHeading, SocialMediaButton, SocialMediaText, StepsContainer, StepsText } from "./style";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { parseJwtToken } from "../../utils";
import { RightOutlined, ArrowLeftOutlined, CheckOutlined } from '@ant-design/icons';
import FetchingModal from "./FetchingModal";
import ConnectInfoModal from "./ConnectInfoModal";
import { BASE_URL } from "../../utils/HelperFunctions/constants";
import { FlexColContainer, FlexEndContainer, JustifyCenterContainer, FlexContainer } from "../../utils/common";
import CompleteYourProfile from "../../components/CompleteYourProfile";
import { LandingPageContainer } from "../../pages/Analytics/style";

const ConnectInstagram = ({ creatorDetails, getCreatorDetails }) => {

    const navigate = useNavigate();
    const refresh_token = parseJwtToken(localStorage.getItem('refresh_token'))

    const [searchParams, setSearchParams] = useSearchParams();
    const [step, setStep] = useState(0)
    const [fbPageData, setFbPageData] = useState({})
    const [ytPageData, setYtPageData] = useState({})
    const [fbPageDataLoading, setFbPageDataLoading] = useState(false)
    const [ytPageDataLoading, setYtPageDataLoading] = useState(false)
    const [selectedPageData, setSelectedPageData] = useState({})
    const [showFetchingModal, setShowFetchingModal] = useState(false)
    const [modalError, setModalError] = useState(false)
    const [connectInfoModalVisible, setConnectInfoModalVisible] = useState(false)

    useEffect(() => {
        let status = searchParams.get("status")
        let platform = searchParams.get("platform")
        let code = searchParams.get("code")
        if (!!status) {
            if (status === "success" && platform === 'instagram') {
                setStep(3)
            } else if (status === "success" && platform === 'youtube') {
                setStep(5)
            } else if (status === "error" && platform === 'instagram') {
                setStep(2)
            }
            else if (status === "error" && platform === 'youtube') {
                if (code === '500') {
                    setStep(2)
                } else if (code === '400') {
                    openYoutubeFrame()
                }
            }
        } else {
            if (creatorDetails?.is_instagram_connected && !creatorDetails?.related_ig_data?.insights_ready) {
                setStep(3)
            } else if (creatorDetails?.is_youtube_connected && !creatorDetails?.related_yt_data?.insights_ready) {
                setStep(5)
            } else {
                setStep(0)
            }
        }
    }, [creatorDetails, searchParams])

    let openFrame = () => {
        window.open(`${BASE_URL}/fb-login/v1/authorize-user/?user_uuid=${refresh_token?.user_uuid}&response_type=code&scope=email,public_profile,pages_show_list,instagram_basic,instagram_manage_insights,business_management`, `width=400,height=600`)
        return false
    };

    let openYoutubeFrame = () => {
        window.open(`${BASE_URL}/yt-login/v1/authorize-user/?user_uuid=${refresh_token?.user_uuid}`)
        return false
    }

    const getCreatorAnalytics = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/ig/media/analytics/${refresh_token?.user_uuid}/`)
            if (!response?.data?.error) {
                notification.success({
                    message: response?.data?.message,
                    duration: 10
                })
                //    setShowFetchingModal(false)
                navigate('/analytics')
            } else {
                setModalError(true)
            }
        } catch (error) {
            setModalError(true)
        }
    }
    const getCreatorYoutubeAnalytics = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/yt/videos/analytics/${refresh_token?.user_uuid}/`)
            if (!response?.data?.error) {
                setShowFetchingModal(false)
                navigate('/analytics')
            } else {
                setModalError(true)
            }
        } catch (error) {
            setModalError(true)
        }
    }

    const screen = {
        0:
            <>
                <GetStartedHeading>Let's get started</GetStartedHeading>
                <RequirementInformation>
                    Before connecting your Instagram account make sure you meet the following requirements:
                </RequirementInformation>
                <StepsContainer>
                    <FlexContainer style={{ marginBottom: '20px' }}>
                        <img src="https://static.admirian.com/skill_icons_instagram.png" height={32} width={32} style={{ marginRight: '10px' }} />
                        You must have an Instagram Creator or Business account
                    </FlexContainer>
                    <FlexContainer style={{ marginBottom: '20px' }}>
                        <img src="https://static.admirian.com/logos_facebook.png" height={32} width={32} style={{ marginRight: '10px' }} />
                        A Facebook account and a public Facebook page
                    </FlexContainer>
                    <FlexContainer style={{ marginBottom: '20px' }}>
                        <img src="https://static.admirian.com/ph_meta_logo_fill.png" height={32} width={32} style={{ marginRight: '10px' }} />
                        Your Instagram account must be linked with your Facebook page
                    </FlexContainer>
                </StepsContainer>
                <HowToDoText>
                    If you’re still confused, check <a style={{ color: '#1551EA' }} onClick={() => { setConnectInfoModalVisible(true) }}> how to do it</a>
                </HowToDoText>
                <CustomButton
                    disabled={!creatorDetails?.is_profile_completed}
                    onClick={() => {
                        setStep(1)
                        // handleConnectInstagram()
                    }}
                    style={{ padding: '10px 40px' }}
                    title={creatorDetails?.is_profile_completed ? null : 'Please complete your profile to get started'}
                >
                    Get Started
                </CustomButton>
            </>,
        1:
            <>
                <BackButton onClick={() => setStep(0)} className="w-100 cursor-pointer">
                    <ArrowLeftOutlined style={{ marginRight: '4px' }} />
                    Back
                </BackButton>
                <SocialAccountHeading>
                    Choose your social media account
                </SocialAccountHeading>
                <RequirementInformation>
                    Connect your social media account with us to get more insights
                </RequirementInformation>
                <StepsContainer>
                    <SocialMediaButton disabled={creatorDetails?.is_instagram_connected} onClick={() => openFrame()}>
                        <img src="https://static.admirian.com/4f6aa157-d774-47d5-9a62-41fad222419a_insta.svg" />
                        Connect your Instagram <SocialMediaText>via Facebook Login</SocialMediaText>
                        <RightArrowAlignment><RightOutlined /></RightArrowAlignment>
                    </SocialMediaButton>
                    <SocialMediaButton disabled={creatorDetails?.is_youtube_connected} onClick={(e) => openYoutubeFrame()} >
                        <img src="https://static.admirian.com/logos_youtube_icon.png" />
                        Connect your Youtube
                        <RightArrowAlignment><RightOutlined /></RightArrowAlignment>
                    </SocialMediaButton>
                    <SocialMediaButton onClick={(e) => e.preventDefault()} style={{ cursor: 'not-allowed' }} >
                        <img src="https://static.admirian.com/grommet_icons_tiktok.png" />
                        Connect your TikTok
                        <ComingSoonAlignment>coming soon</ComingSoonAlignment>
                    </SocialMediaButton>
                    <JustifyCenterContainer className="w-100" style={{ fontSize: 16, lineHeight: "24px", color: 'var(--typo-medium, #494785)' }}>
                        <img src="https://static.admirian.com/gala_secure.png" width={24} height={24} style={{ marginRight: 4 }} />
                        Safe and Secure
                    </JustifyCenterContainer>
                </StepsContainer>
            </>,
        2:
            <>
                <SocialAccountHeading>
                    We need access permissions to connect
                </SocialAccountHeading>
                <RequirementInformation>
                    Please provide us the following permissions to access valuable analytics
                </RequirementInformation>
                <StepsContainer>
                    <StepsText>
                        <CheckOutlined style={{ marginRight: '10px' }} />
                        <div className="w-100">
                            <b>Access to public profile </b>
                            <span style={{ fontWeight: '300' }}>It'll allow us to provide you a personalized experience on our platform.</span>
                        </div>
                    </StepsText>
                    <StepsText>
                        <CheckOutlined style={{ marginRight: '10px' }} />
                        <div className="w-100">
                            <b>Show list of Pages you manage</b>
                            <span style={{ fontWeight: '300' }}>This helps you to select the Page and Instagram account that you want to connect with us.</span>
                        </div>
                    </StepsText>
                    <StepsText>
                        <CheckOutlined style={{ marginRight: '10px' }} />
                        <div className="w-100">
                            <b>Your public Instagram info </b>
                            <span style={{ fontWeight: '300' }}>This will get you easily discovered by brands for potential collaborations.</span>
                        </div>
                    </StepsText>
                    <StepsText>
                        <CheckOutlined style={{ marginRight: '10px' }} />
                        <div className="w-100">
                            <b>Access to Instagram insights</b>
                            <span style={{ fontWeight: '300' }}>This will empower you to optimize your strategy, create more engaging posts, and potentially secure more profitable partnerships with brands.</span>
                        </div>
                    </StepsText>
                    <StepsText>
                        <CheckOutlined style={{ marginRight: '10px' }} />
                        <div className="w-100">
                            <b>Manage business</b>
                            <span style={{ fontWeight: '300' }}>This will help you understand your audience better and make data-driven decisions to improve your content and engagement.</span>
                        </div>
                    </StepsText>
                </StepsContainer>
                <SaveAndSecureText>
                    <img src="https://static.admirian.com/gala_secure.png" width={24} height={24} style={{ marginRight: '4px' }} />
                    Safe and Secure
                </SaveAndSecureText>
                <CustomButton
                    onClick={() => openFrame()}
                    style={{ padding: '10px 80px' }}
                >
                    Try again
                </CustomButton>
                <DifferentAccountText
                    onClick={() => setStep(1)}
                >
                    Connect with a different account
                </DifferentAccountText>
            </>,
        3:
            <>
                <SocialAccountHeading>
                    Here's the list of pages you manage
                </SocialAccountHeading>
                <RequirementInformation>
                    Please select the page you'd like to work with.
                    By choosing a Facebook Page, you'll be able to unlock valuable insights and analytics.
                </RequirementInformation>
                <ChoosingInformation>
                    {fbPageDataLoading ?
                        <JustifyCenterContainer className="w-100">
                            <Spin />
                        </JustifyCenterContainer>
                        :
                        fbPageData?.fb_pages?.map((pageData, index) => {
                            return (
                                <AccountPageButton key={index} onClick={() => {
                                    setSelectedPageData(pageData)
                                    setStep(4)
                                }}>
                                    <img src={pageData?.page_profile_pic} alt="profile" width={40} height={40} style={{ marginRight: '10px', border: '3px white solid', borderRadius: '50%' }} />
                                    <b>{pageData?.page_name}</b>
                                    <FlexEndContainer className="w-100"><RightOutlined /></FlexEndContainer>
                                </AccountPageButton>
                            )
                        })
                    }
                    <SaveAndSecureText className="w-100">
                        <img src="https://static.admirian.com/gala_secure.png" height={24} width={24} style={{ marginRight: 4 }} />
                        Safe and Secure
                    </SaveAndSecureText>
                </ChoosingInformation>
            </>,
        4:
            <>
                <BackButton onClick={() => setStep(3)} className="w-100 cursor-pointer">
                    <ArrowLeftOutlined style={{ marginRight: 4 }} />
                    Back
                </BackButton>
                <SocialAccountHeading>
                    Confirm to access valuable insights
                </SocialAccountHeading>
                <RequirementInformation>
                    We will be fetching valuable analytics for your selected Instagram account.
                </RequirementInformation>
                <RequirementInformation>
                    Your Facebook page and its connected Instagram account
                </RequirementInformation>
                <SelectedPlatformPage>
                    {selectedPageData?.related_ig_data?.map((relatedIgData, index) => {
                        return (
                            <div key={index}>
                                <SelectedPlatform>
                                    <SelectedPlatformContent>
                                        <img src={selectedPageData?.page_profile_pic} width={40} height={40} style={{ marginRight: '10px', border: '3px white solid', borderRadius: '50%' }} />
                                        <IconContainer>
                                            <img src="https://static.admirian.com/ellipse_fb_icon.svg" width={22} height={22} className="border-circle" style={{
                                                border: '3px white',
                                            }} />
                                        </IconContainer>
                                    </SelectedPlatformContent>
                                    <div className="w-100" style={{ gap: 'var(--space-list-low, 8px)' }}>
                                        <b>{selectedPageData?.page_name}</b>
                                    </div>
                                </SelectedPlatform>
                                <SelectedPlatform style={{ marginBottom: 'unset' }}>
                                    <SelectedPlatformContent>
                                        <img src={relatedIgData?.ig_profile_pic} width={40} height={40} style={{ marginRight: 10, border: '3px white solid', borderRadius: '50%' }} alt="ig-profile" />
                                        <IconContainer>
                                            <img src="https://static.admirian.com/ellipse_instagram_icon.svg" width={22} height={22} style={{
                                                border: '3px white',
                                                borderRadius: "9999px"
                                            }} />
                                        </IconContainer>
                                    </SelectedPlatformContent>
                                    <div className="w-100" style={{ gap: 'var(--space-list-low, 4px)' }}>
                                        <b>{relatedIgData?.ig_account_name}</b>
                                        <span style={{ color: '#010043' }}>@{relatedIgData?.ig_handle}</span>
                                    </div>
                                </SelectedPlatform>
                            </div>
                        )
                    })
                    }
                </SelectedPlatformPage>

                <CustomButton
                    onClick={() => {
                        // setShowFetchingModal(true)
                        getCreatorAnalytics()
                    }}
                    className="w-100"
                    style={{ marginBottom: '10px' }}
                >
                    Get Insights
                </CustomButton>
                <JustifyCenterContainer
                    onClick={() => setStep(1)}
                    className="w-100 cursor-pointer"
                    style={{ margin: '20px 0px', color: '#010043', fontSize: '16px' }}
                >
                    Maybe later
                </JustifyCenterContainer>
                <JustifyCenterContainer className="w-100" style={{ fontSize: '16px', lineHeight: '24px', color: 'var(--typo-medium, #494785)', marginTop: '16px' }}>
                    <img src="https://static.admirian.com/gala_secure.png" height={24} width={24} style={{ marginRight: '4px' }} />
                    Safe and Secure
                </JustifyCenterContainer>
            </>,
        5:
            <>
                <SocialAccountHeading>
                    Confirm to access valuable insights
                </SocialAccountHeading>
                <RequirementInformation>
                    We will be fetching valuable analytics for your selected youtube channel.
                </RequirementInformation>
                <ChoosingInformation className="w-100">
                    {ytPageDataLoading ?
                        <JustifyCenterContainer>
                            <Spin />
                        </JustifyCenterContainer>
                        :
                        ytPageData?.channels?.map((channel, index) => {
                            return (
                                <AccountPageButton
                                    key={index}
                                // onClick={()=>{
                                //     setSelectedPageData(pageData)
                                //     setStep(4)
                                //     }}
                                >
                                    <img src={channel?.thumbnail_high} width={40} height={40} style={{ marginRight: '10px', border: '3px white solid', borderRadius: '50%' }} />
                                    <FlexColContainer style={{ alignItems: 'flex-start' }}>
                                        <span style={{ fontSize: '18px', fontWeight: 600 }}>{channel?.title}</span>
                                        <span style={{ fontSize: '14px', fontWeight: 400 }}>{channel?.handle}</span>
                                    </FlexColContainer>
                                    <FlexEndContainer className="w-100"><RightOutlined /></FlexEndContainer>
                                </AccountPageButton>
                            )
                        })
                    }
                </ChoosingInformation>
                <CustomButton
                    onClick={() => {
                        setShowFetchingModal(true)
                        getCreatorYoutubeAnalytics()
                    }}
                    className="w-100"
                    style={{ marginBottom: '10px' }}
                    disabled={ytPageDataLoading || ytPageData?.channels?.length < 1}
                >
                    Get Insights
                </CustomButton>
                <div
                    onClick={() => setStep(1)}
                    className="w-100 cursor-pointer text-center"
                    style={{ margin: '20px 0px', color: '#010043', fontSize: '16px' }}
                >
                    Maybe later
                </div>
                <JustifyCenterContainer className="w-100" style={{ fontSize: '16px', lineHeight: '24px', color: 'var(--typo-medium, #494785)', marginTop: '16px' }}>
                    <img src="https://static.admirian.com/gala_secure.png" alt="secure" width={24} height={24} style={{ marginRight: 4 }} />
                    Safe and Secure
                </JustifyCenterContainer>
            </>,
    }
    const fetchConnectedPagesDetails = async () => {
        setFbPageDataLoading(true)
        try {
            const response = await axios.get(`${BASE_URL}/fb-login/v1/fb-pages-list/${refresh_token?.user_uuid}/`)

            if (response?.data) {
                setFbPageData(response?.data)
            } else {
                notification.error({
                    message: response?.data?.message
                })
            }
            setFbPageDataLoading(false)
        } catch (error) {
            // notification.error({message : error?.response?.data?.message})
            setFbPageDataLoading(false)
            setStep(2)
        }
    }

    const fetchConnectedYoutubeChannelList = async () => {
        setYtPageDataLoading(true)
        try {
            const response = await axios.get(`${BASE_URL}/yt-login/v1/channels/list/${refresh_token?.user_uuid}/`)

            if (response?.data) {
                setYtPageData(response?.data)
            } else {
                notification.error({
                    message: response?.data?.message
                })
            }
            setYtPageDataLoading(false)
        } catch (error) {
            // notification.error({message : error?.response?.data?.message})
            setYtPageDataLoading(false)
            setStep(2)
        }
    }



    useEffect(() => {
        if (step === 3) {
            fetchConnectedPagesDetails()
        }
        if (step === 5) {
            fetchConnectedYoutubeChannelList()
        }
    }, [step])

    const handleConnectInstagram = async () => {
        try {
            window.open(`${BASE_URL}/fb-login/v1/authorize-user/?user_uuid=${refresh_token?.user_uuid}&response_type=code&scope=email,public_profile,pages_show_list,instagram_basic,instagram_manage_insights,business_management`, '_self')
        } catch (error) {
            notification.error({ message: error?.message })
        }
    }

    return (
        <LandingPageContainer>
            {!creatorDetails?.is_profile_completed && <CompleteYourProfile />}   
            <ConnectInstagramContainer>
                <Row gutter={[16, 20]}>
                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                        <div style={{ padding: '32px 12px 18px 12px' }}>
                            {screen[step]}
                        </div>
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={0} xs={0}>
                        {step === 0 ?
                            <img src="https://static.admirian.com/5b69fdb2-bc46-473c-899e-c0918589664d_connectInstagram.png" alt="Connect Instagram" width={"100%"} height={617} />
                            :
                            <img src="https://static.admirian.com/right_1_col_dark.svg" alt="Connect Instagram" width={"100%"} height={617} />
                        }
                    </Col>
                </Row>
                {/* {showFetchingModal && */}
                <FetchingModal
                    visible={showFetchingModal}
                    setVisible={setShowFetchingModal}
                    modalError={modalError}
                    setModalError={setModalError}
                    getCreatorAnalytics={step === 5 ? getCreatorYoutubeAnalytics : getCreatorAnalytics}
                />
                {/* } */}
                <ConnectInfoModal
                    visible={connectInfoModalVisible}
                    setVisible={setConnectInfoModalVisible}
                />
            </ConnectInstagramContainer>
        </LandingPageContainer>
    )

}

export default ConnectInstagram