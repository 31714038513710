import { Button, Col, Image, Row } from "antd";
import React, { useState } from "react";
import { InfluencerCardContainer } from "./style";

const Influencer = () => {

    const [brandContentActive , setBrandContentActive]= useState('1')

    return(
        <InfluencerCardContainer>
            <Row style={{width : "100%"}}>
                <Col span={12} style={{display : 'flex',flexDirection : 'column'}}>
                    <div style={{display : 'flex', flexDirection : 'row',marginBottom : '45px'}}>
                    <Button 
                    style={{padding : '4px 12px', marginRight : '30px',marginTop:'10px', background : '#FFFFFF' , borderRadius : '400px', border : brandContentActive === '1' ? '1px solid #4E4BEE' : '0px'}}
                    onClick={()=>setBrandContentActive('1')}
                    >
                        1
                    </Button>
                    <div>
                    <span style={{fontSize : '32px', lineHeight : '39px',color : '#010043',fontWeight : 700}}>
                    <span style={{color : "#4E4BEE"}}>Connect</span> your social media account
                    </span>
                    <span style={{fontSize : '16px', lineHeight : '19px',color : '#050360', display : 'flex'}}>
                    With powerful algorithm we match your profile with brands that align with your values 
                    </span>
                    </div>
                    </div>
                    <div style={{display : 'flex', flexDirection : 'row',marginBottom : '45px'}}>
                    <Button 
                    style={{padding : '4px 12px', marginRight : '30px',marginTop:'10px', background : '#FFFFFF' , borderRadius : '400px', border : brandContentActive === '2' ? '1px solid #4E4BEE' : '0px'}}
                    onClick={()=>setBrandContentActive('2')}
                    >
                        2
                    </Button>
                    <div>
                    <span style={{fontSize : '32px', lineHeight : '39px',color : '#010043',fontWeight : 700}}>
                    <span style={{color : "#4E4BEE"}}>Collaborate </span>with brands
                    </span>
                    </div>
                    </div>
                    <div style={{display : 'flex', flexDirection : 'row',marginBottom : '45px'}}>
                    <Button  
                    style={{padding : '4px 12px', marginRight : '30px',marginTop:'10px', background : '#FFFFFF' , borderRadius : '400px', border : brandContentActive === '3' ? '1px solid #4E4BEE' : '0px'}}
                    onClick={()=>setBrandContentActive('3')}
                    >
                        3
                    </Button>
                    <div>
                    <span style={{fontSize : '32px', lineHeight : '39px',color : '#010043',fontWeight : 700}}>
                    <span style={{color : "#4E4BEE"}}>Monetize </span>your content
                    </span>
                    </div>
                    </div>

                </Col>
                <Col span={12} style={{display : 'flex', justifyContent : 'center', alignItems : 'center', height : '350px'}}>
                {brandContentActive === '1' && (<Image style={{height : 'inherit'}} preview = {false} src="https://static.admirian.com/7ac3a535-c952-478b-b596-5cda4b8ed633_InfluencerImage1.png"/>)}
                {brandContentActive === '2' && (<Image style={{height : 'inherit'}} preview = {false} src="https://static.admirian.com/8c4a23aa-e32a-44b1-aed4-734eb855a0b5_InfluencerImage2.png"/>)}
                {brandContentActive === '3' && (<Image style={{height : 'inherit'}} preview = {false} src="https://static.admirian.com/4a77ab93-6a05-41c0-9e9e-9b3801aae52b_InfluencerImage3.png"/>)}                
                </Col>
            </Row>
        </InfluencerCardContainer>
    )
}

export default Influencer