import styled from "styled-components";
import media from "../../utils/UIElements/media";

export const AnalyticsContainer = styled.div`
    display  : flex ;
    margin: 0px 20px;
    .ant-tabs .ant-tabs-tab-btn{
        color : #010043 !important;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        width : 100% ;
    }
    .ant-tabs{
        width : 100% !important;
    }
    .ant-tabs-ink-bar{
        background : #ff756c !important;
    }
    .ant-tabs >.ant-tabs-nav{
        background: #FFFFFF;
        margin: 25px 0px;
        padding: 16px 24px;
        border-top: 3px solid #F6F6FF;
        border-radius: 10px;
    }
    .plus-outlined {
        background: var(--surface-medium, #EBF4FF);
        padding: 4px;
        color: #010043;
        border-radius: 50%;
        margin-right: 16px;
        ${media.mobile`
        margin-right: 0px;
    `}
    }
`
export const LandingPageContainer = styled.div`
    display  : flex ;
    margin: 0px 20px;
    width : 100%;
    flex-direction : column;
`

export const CompanyDetails = styled.div`
    display : flex;
    align-items : center;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #010043;
    margin-right: 5px;
`

export const CompanyLogoContainer = styled.div`
  width: 32px;
  height: 32px;
  background: #FFFFFF;
  border: 1px solid #4E4BEE;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: inherit;
  align-items: flex-start;
  border: 0px;
  margin-right: 4px;
`;