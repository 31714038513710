import React from "react";
import { Container, ContentContainer, MainHeading, Paragraph, SubHeading } from "./style";

const PrivacyPolicy = () => {

    return(
        <Container>
            <MainHeading>
            Privacy Policy
            </MainHeading>
            <ContentContainer>
                <span style={{display : 'flex', fontSize : '20px', marginBottom : '20px'}}>
                Last modified: Feb 8, 2024
                </span>
                <SubHeading>
                Introduction
                </SubHeading>
                <Paragraph>
                Admirian technologies private limited (hereinafter – the «Company» and «we») respects the protection of the information you provide and your privacy, so we shall protect it by complying with this privacy policy and in full accordance with applicable law.
                </Paragraph>
                <Paragraph>
                Please read this privacy policy to understand the terms on which you provide information about yourself. If, after reading this Privacy Policy, you do not agree to the relevant terms, you should not use the official website of the Company at <a style={{color : '#1755e3'}} href="https://www.admirian.com">www.admirian.com</a>(hereinafter referred to as "Service"/"Official Site") /application. By accessing the Company's hosted Service, you agree to this Privacy Policy.
                </Paragraph>
                <Paragraph>
                This Privacy Policy applies to all information that the Company receives from you during the use of the Service/Applications, as well as during the performance by the Company / its affiliates of any agreements / contracts entered into with you in connection with your use of the Service / Applications. Following the Company's obligations to protect the information you provide under this Privacy Policy, we duly inform you, including but not limited to, of:
                </Paragraph>
                <Paragraph>
                - the purposes for which the Company collects/processes /stores provided information when using the Service / Applications;
                </Paragraph>
                <Paragraph>
                - the amount of information the Company collects / processes / stores;- the measures the Company takes to protect the provided information;
                </Paragraph>
                <Paragraph>
                - your rights within the ongoing collection / processing and storage of the provided information.
                </Paragraph>
                <Paragraph>
                This Privacy Policy applies to the collection / processing and storage of information you provide, including:
                </Paragraph>
                <Paragraph>
                - use of the Official Website / applications;
                </Paragraph>
                <Paragraph>
                - exchange of electronic messages between you and the Company;
                </Paragraph>
                <Paragraph>
                - use of mobile and desktop applications that you download from the Company's Official Website or the application through the Apple App Store, which provide interaction without a browser between you and the Company's Official Website and / or the application;
                </Paragraph>
                <Paragraph>
                - Use of the Service;
                </Paragraph>
                <Paragraph>
                - interaction with our advertising and applications on third-party websites, if these ads and applications contain a link to this Privacy Policy.
                </Paragraph>
                <Paragraph>
                - Admirian’s use  and transfer to any other app of information received from Google APIs will adhere to <a style={{color : '#1755e3'}} href="https://developers.google.com/terms/api-services-user-data-policy"> Google API Services User Data Policy</a>, including the Limited Use requirements.
                </Paragraph>
                <Paragraph>
                Here you can find Google API Services User Data Policy
                </Paragraph>
                <Paragraph>
                <a style={{color : '#1755e3'}} href="https://developers.google.com/terms/api-services-user-data-policy">Google API Services User Data Policy</a>
                </Paragraph>
                <SubHeading>
                Information you may provide the company with
                </SubHeading>
                <Paragraph>
                When you navigate the Official Website of the Company or interact with it, we can use automatic data collection technologies to obtain specific information about your equipment, your browser's activities and templates, including:
                </Paragraph>
                <Paragraph>
                - information about your visits to the Company's Official Website, including data on traffic, location, authorization and other transmitted data, as well as information on the resources you access and which you use on the Company's Official Website;
                </Paragraph>
                <Paragraph>
                - Information about your computer and Internet connection, including your IP address, operating system and browser type.We can also use these technologies to collect information about your online activity over a certain period of time on third-party websites or other online services (called "behavior tracking").
                </Paragraph>
                <Paragraph>
                The information we collect in automatic mode is statistical data and does not include information that we can receive or associate this data with other information that we collect in other ways or receive from third parties. This helps the Company to improve the work of the Official Website / Service in order to implement a more qualitative and personal approach to each user, and allows us to:                </Paragraph>
                <Paragraph>
                - estimate the size of our audience and behavior patterns;
                </Paragraph>
                <Paragraph>
                - store information about your preferences, which allows us to adapt our Official Website to your individual needs;
                </Paragraph>
                <Paragraph>
                - increase the speed of your searches;
                </Paragraph>
                <Paragraph>
                - identify you when you return to the Company's Official Website.
                </Paragraph>
                <Paragraph>
                The technologies we use for such automatic data collection may include:
                </Paragraph>
                <Paragraph>
                - cookies (or cookies in the browser). A cookie file is a small file located on the hard drive of your computer. You can refuse to accept cookies in the browser by activating the appropriate option in it. However, if you select this option, you will not be able to access certain elements of the Company's Official Website. Unless you have set cookie blocking in your browser, our system will send you cookies, every time your browser accesses the Official Website of the Company.
                </Paragraph>
                <Paragraph>
                - flash cookies in the browser. Some elements of the Company's Official Website may use locally stored objects (or flash cookies) to collect and store information about your preferences and requests to the Company's website, from and within it. The management of flash cookies is carried out by other browser settings rather than used for cookies in the browser. For information on managing the privacy and security settings for flash cookies, see below the Section "Choosing how we use and disclose your information."
                </Paragraph>
                <Paragraph>
                If any of the Sponsors permit Admirian access to their ad manager within a social media platform, such as Facebook, Snapchat, Google, Pinterest, Linkedin, or any similar platforms, then Admirian shall have the right to analyze aggregated data of the Sponsor, generated by said ad manager, as well as any Sponsor Content, available through the Platform, provided such use is strictly for the improvement of user experience, and only aggregated results shall be used. Admirian warrants that it will not disclose any such data to any third party competitors of the Sponsor.
                </Paragraph>
                <SubHeading>
                Objectives for the collection / Processing and storage of information you provide
                </SubHeading>
                <Paragraph>
                We collect, process and store the information you provide for:
                </Paragraph>
                <Paragraph>
                - submission of the Company's Official Website and its contents;
                </Paragraph>
                <Paragraph>
                - providing you with information, products or services that you request from us;
                </Paragraph>
                <Paragraph>
                - personalization of user interaction and sending you notifications about your account or subscription, including notifications of the expiration and extension of its term;
                </Paragraph>
                <Paragraph>
                - fulfillment of our obligations and observance of our rights arising from any agreements concluded between you and us, including for issuing and paying bills;
                </Paragraph>
                <Paragraph>
                - notifying you about changes on the Official Website, or about any products or services that we offer or provide through it;
                </Paragraph>
                <Paragraph>
                - use of the interactive functions of the Official Website;
                </Paragraph>
                <Paragraph>
                - improving the quality of user service;- for sending you Requests;- for any other purpose with your exclusive consent.
                </Paragraph>
                <SubHeading>
                Disclosure of information you submit
                </SubHeading>
                <Paragraph>
                We can unlimitedly disclose impersonal statistical information about users of the Company:
                </Paragraph>
                <Paragraph>
                - to our subsidiaries and affiliated companies;
                </Paragraph>
                <Paragraph>
                - to counterparties, service providers and other third parties hired to conduct our business and who by virtue of contractual obligations are obliged to respect the privacy of the information provided and use it only for the purposes for which we disclose it to them;
                </Paragraph>
                <Paragraph>
                - to a buyer or other successor in the event of a merger, sale of assets, restructuring, reorganization, liquidation or any other way of selling or transferring all or part of the assets of Admirian whether as an operating company or in a bankruptcy, liquidation or similar proceedings, in which the information you provide is among the transferred assets;
                </Paragraph>
                <Paragraph>
                - to fulfill the purposes for which you provide it. For example, if you provide us with an email address to use the setting "Set Up a New Campaign" on the Official Website, we will transfer the content of your campaign to the e-mail of this recipient;
                </Paragraph>
                <Paragraph>
                - for any other purposes declared by us, at the time the information is provided;
                </Paragraph>
                <Paragraph>
                - in other situations not described by this Privacy Policy solely with your consent.
                </Paragraph>
                <Paragraph>
                The company may disclose the information you provide:
                </Paragraph>
                <Paragraph>
                - for enforcing the enacted court decision, law or case, including to satisfy any state or administrative requirement;
                </Paragraph>
                <Paragraph>
                - in order to ensure or apply our terms of service and other agreements, including billing and payment;
                </Paragraph>
                <Paragraph>
                - if we believe that such disclosure is necessary or appropriate to protect the rights, property or safety of Admirian , our customers or others.
                </Paragraph>
                <SubHeading>
                Choosing how we use and disclose your information
                </SubHeading>
                <Paragraph>
                We aim to provide you with a choice regarding the information you provide us with. We have developed mechanisms to provide you with the following types of control over your information:
                </Paragraph>
                <Paragraph>
                - technology tracking and advertising. You can configure your browser to refuse all or some cookies in your browser, or set up alerts for sending you cookies. To find out how you can manage your cookie settings, visit the Flash player settings page on the Adobe website. If you disable or refuse to accept cookies, please note that some elements of the Official Website may become unavailable or may not function properly.
                </Paragraph>
                <Paragraph>
                - disclosure of your information for third-party advertising. We do not sell or otherwise transfer your information, without prior notice. This does not apply to the hosting partners of the Official Webite and other third parties who assist us with our Official Website operation, or the conduct of our business, or the service, as long as these persons shall maintain confidentiality with respect to your information. The financial information you provide (for example, credit card information and / or bank information) will never be passed on to third parties without your prior written consent, regardless of any provision about the contrary of this Privacy Policy.
                </Paragraph>
                <Paragraph>
                - targeted advertising. If you do not want us to use the information we collect or that you provide us with to send you promotional messages pre-targeted in accordance with the requests of our advertisers, you can unsubscribe by sending us an e-mail request to: admin@admirian.com
                </Paragraph>
                <SubHeading>
                Children under 13
                </SubHeading>
                <Paragraph>
                Official website / Service is not intended for children under 13. No person under the age of 13 is allowed to provide any information to the Official Website. We do not intentionally collect information from children under the age of 13. If you are under the age of 13, do not use or provide any information to this Official Site or through any of its elements; Do not register on the Official Website for making any purchases through the Official Website, do not use the opportunities of interactive or public commenting on the Official Website; Do not provide any information about yourself, including your name, address, telephone number, financial information (if you have accessed the Official Website as a Client), an e-mail address or any nickname or aliases you use. If the Company becomes aware that we have collected or received information about a child under the age of 13 without the consent of the parents, we will delete this information. If you believe that we have any information received from a child under the age of 13 or about such a child, please contact us at admin@admirian.com
                </Paragraph>
                <SubHeading>
                Access to and editing your information
                </SubHeading>
                <Paragraph>
                You can view and change your personal information by visiting the Official Website and further your account.
                </Paragraph>
                <Paragraph>
                You can also send us an e-mail to: admin@admirian.com to request access, rectify or delete any information that you provided us with. We cannot delete your information except by deleting your account. We cannot comply with your request for change of information if we believe that such a change would result in violation of any law or legal requirement, or if the information becomes incorrect due to such changes.
                </Paragraph>
                <Paragraph>
                If you delete your User Publications on the Official Website, their copies may remain accessible for viewing in the cache or on archived pages, or these User Publications may be copied or saved by other users of the Official Website. The procedure for proper access and use of information posted on the Official Website, including user materials, is governed by our Terms of Service.
                </Paragraph>
                <SubHeading>
                Access to your information and its protection
                </SubHeading>
                <Paragraph>
                Access to your information can be provided to the Company's employees solely for the purpose of fulfilling the duties assigned to them. To protect and ensure the privacy of the information you provide, all employees shall comply with the provisions of confidentiality agreements. Employees must also follow all technical and organizational security measures in place to protect the information you provide.
                </Paragraph>
                <Paragraph>
                The Company has implemented sufficient technical and organizational measures to protect the information you provide from unauthorized, accidental or unlawful destruction, loss, alteration, unfair use, disclosure or access, as well as other illegal forms of processing. These security measures were implemented taking into account the current technologies, the cost of their implementation, the risks associated with processing and the nature of the information you provided.
                </Paragraph>
                <Paragraph>
                The safety and security of your information is also up to you. If we have given you (or you decide to choose) a password for accessing certain elements of our Official Website, you are responsible for not disclosing this password. We ask you not to tell your password to anyone.
                </Paragraph>
                <SubHeading>
                Your information storage expiry date
                </SubHeading>
                <Paragraph>
                The Company will store the information you provided for as long as necessary to achieve the purpose for which it was collected, or to comply with the requirements of legislation and regulations.
                </Paragraph>
                <Paragraph>
                If you want the information you provided to be deleted, you can delete the necessary information yourself by deleting your account or through the Official Webite / Service interface or by sending a request to admin@admirian.com
                </Paragraph>
                <SubHeading>
                Your rights
                </SubHeading>
                <Paragraph>
                If this is provided by applicable law, you have the right to access your information processed by the Company in accordance with this Privacy Policy.
                </Paragraph>
                <Paragraph>
                If you believe that any information the Company stores about you, is incorrect or incomplete, you can enter your account and correct your information yourself.
                </Paragraph>
                <Paragraph>
                If this is provided by applicable law, you have the right to:
                </Paragraph>
                <Paragraph>
                - request the removal of your information;
                </Paragraph>
                <Paragraph>
                - request restrictions on the processing of your information;
                </Paragraph>
                <Paragraph>
                - object to the processing of your information, if provided by applicable law.
                </Paragraph>
                <Paragraph>
                ‍
                The Company will comply with these requests in accordance with applicable law.
                </Paragraph>
                <Paragraph>

                In cases provided by applicable law, you may also have other rights not specified above. How you can exercise your rights.
                </Paragraph>
                <Paragraph>
                To exercise the above rights, please log in to your account, and in the absence of a special function in the interface, contact the company at the email address admin@admirian.com.
                </Paragraph>
                <SubHeading>
                Changes in our privacy policy
                </SubHeading>
                <Paragraph>
                Our Policy is to publish changes to our Privacy Policy on this page. If we make significant changes in how we handle the personal information of our users, we will notify you by e-mail to the address indicated in your account and / or by posting a notice on the main page of the site. The date of the last revision of this Policy is indicated at the top of the page. You are responsible for keeping an up-to-date valid email address, and you are responsible for periodically visiting our Website and this Privacy Policy to track any changes.
                </Paragraph>
                <SubHeading style={{fontSize : '24px'}}>
                Contact information
                </SubHeading>
                <Paragraph>
                To ask questions or comments on this Privacy Policy and our privacy practices, please contact us at admin@admirian.com

                </Paragraph>

            </ContentContainer>
        </Container>
    )
}

export default PrivacyPolicy