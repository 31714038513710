import React, { useState } from "react";
import Analytics from "../Analytics";
import { LandingPageContainer } from "../Analytics/style";
import CompleteYourProfile from "../../components/CompleteYourProfile";
import ConnectInstagram from "../../components/ConnectInstagram";
import CreatorBio from "../../components/CreatorBio";
import { Navigate } from "react-router-dom";
import { Spin } from "antd";


const CreatorLandingPage = ({ creatorDetails, setCreatorDetails, userID, setBio, drawerView, getCreatorDetails }) => {
    const [creatorProfileDetails, setCreatorProfileDetails] = useState({})
    const [creatorProfileDetailsYt, setCreatorProfileDetailsYt] = useState({})
    return (
        <LandingPageContainer>
            {Object.keys(creatorDetails).length ?
                <>
                    {!creatorDetails?.is_profile_completed && <CompleteYourProfile />}
                    {(!creatorDetails?.related_ig_data?.insights_ready && !creatorDetails?.related_yt_data?.insights_ready) ?
                        <Navigate to={'/home'} />
                        : <>
                            <CreatorBio
                                creatorDetails={creatorDetails}
                                bio={creatorProfileDetails?.bio}
                                drawerView={false}
                                creatorProfileDetails={creatorProfileDetails}
                                creatorProfileDetailsYt={creatorProfileDetailsYt}
                            />
                            <Analytics
                                creatorDetails={creatorDetails}
                                setCreatorDetails={setCreatorDetails}
                                userID={userID}
                                setBio={setBio}
                                drawerView={drawerView}
                                setCreatorProfileDetails={setCreatorProfileDetails}
                                setCreatorProfileDetailsYt={setCreatorProfileDetailsYt}
                            />
                        </>
                    }
                </>
                :
                <div style={{ display: 'flex', justifyContent: "center", alignItems: 'center', width: '100%', height: '80vh' }}>
                    <Spin />
                </div>
            }
        </LandingPageContainer>
    )
}

export default CreatorLandingPage