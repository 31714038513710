import { Table } from 'antd'
import React from 'react'
import { TableContainer } from './style'

const InfluencerTable = (props) => {
  return (
    <TableContainer>
        <Table
        style={{overflow: 'auto'}}
        dataSource={props.tableData}
        columns={props?.columns}
        showHeader = {false}
        pagination = {{
          total : props?.total,
          pageSize : 25,
          current : props?.pageNumber,
          onChange : (page,pageSize)=>{
            props?.setPageNumber(page)
          }
        }}
        />
    </TableContainer>
  )
}

export default InfluencerTable
