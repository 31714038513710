import { Carousel, Modal } from "antd";
import React from "react";
import { CarouselContainer, ConnectInfoCarouselBox, ConnectInfoModalCation, ConnectInfoModalHeading, CustomColumn, CustomRow, ModalContainer } from "./style";

const ConnectInfoModal = ({ visible, setVisible }) => {

    const carouselRef = React.createRef();

    const settings = {
        infinite: false,
        lazyLoad: true,
        slidesToShow: 1,
        speed: 300,
        ref: carouselRef,
        dots: true,
    }

    return (
        <ModalContainer>
            <Modal
                visible={visible}
                destroyOnClose={true}
                onCancel={() => {
                    setVisible(false)
                }}
                width={900}
                footer={null}
            >
                <CarouselContainer>
                    <Carousel {...settings}>
                        <ConnectInfoCarouselBox>
                            <ConnectInfoModalHeading>
                                Switch your Instagram Personal account to a Professional account
                            </ConnectInfoModalHeading>
                            <img src="https://static.admirian.com/switch_to_professional_AC.svg" alt="insta-account" className="w-100" height={400} />
                            <CustomRow>
                                <CustomColumn xl={8} lg={8} md={8} sm={0} xs={0}>
                                    <ConnectInfoModalCation>
                                        Click <b>Settings</b> under Menu
                                    </ConnectInfoModalCation>
                                </CustomColumn>
                                <CustomColumn xl={8} lg={8} md={8} sm={0} xs={0}>
                                    <ConnectInfoModalCation>
                                        Click <b>Account</b> under Settings
                                    </ConnectInfoModalCation>
                                </CustomColumn>
                                <CustomColumn xl={8} lg={8} md={8} sm={0} xs={0}>
                                    <ConnectInfoModalCation>
                                        Click <b>Switch to professional account</b> at the bottom
                                    </ConnectInfoModalCation>
                                </CustomColumn>
                            </CustomRow>
                        </ConnectInfoCarouselBox>
                        <ConnectInfoCarouselBox>
                            <ConnectInfoModalHeading>
                                Create a Facebook Page from your Instagram
                            </ConnectInfoModalHeading>
                            <img src="https://static.admirian.com/create_your_facebook_page.svg" alt="instagram" height={400} className="w-100" />
                            <CustomRow style={{ width: '792px' }}>
                                <CustomColumn xl={6} lg={6} md={6} sm={0} xs={0}>
                                    <ConnectInfoModalCation>
                                Edit Profile > click <b>Connect or create</b> button
                                    </ConnectInfoModalCation>
                                </CustomColumn>
                                <CustomColumn xl={6} lg={6} md={6} sm={0} xs={0}>
                                    <ConnectInfoModalCation>
                                        Click <b>Continue</b> to proceed
                                    </ConnectInfoModalCation>
                                </CustomColumn>
                                <CustomColumn xl={6} lg={6} md={6} sm={0} xs={0}>
                                    <ConnectInfoModalCation>
                                        Click on <b>Create a new Facebook Page</b>
                                    </ConnectInfoModalCation>
                                </CustomColumn>
                                <CustomColumn xl={6} lg={6} md={6} sm={0} xs={0}>
                                    <ConnectInfoModalCation>
                                        Enter Page name, Page category and click <b>Create</b>
                                    </ConnectInfoModalCation>
                                </CustomColumn>
                            </CustomRow>
                        </ConnectInfoCarouselBox>
                        <ConnectInfoCarouselBox>
                            <ConnectInfoModalHeading>
                                Link the Facebook Page with your Instagram Professional account
                            </ConnectInfoModalHeading>
                            <img src="https://static.admirian.com/link_your_facebook_page.svg" alt="professional account" className="w-100" height={400} />
                            <CustomRow>
                                <CustomColumn xl={8} lg={8} md={8} sm={0} xs={0}>
                                    <ConnectInfoModalCation>
                                Edit Profile > click <b>Connect or create button</b>
                                    </ConnectInfoModalCation>
                                </CustomColumn>
                                <CustomColumn xl={8} lg={8} md={8} sm={0} xs={0}>
                                    <ConnectInfoModalCation>
                                        Click <b>Continue</b> to proceed
                                    </ConnectInfoModalCation>
                                </CustomColumn>
                                <CustomColumn xl={8} lg={8} md={8} sm={0} xs={0}>
                                    <ConnectInfoModalCation>
                                        Select your Facebook Page and click <b>Done</b>
                                    </ConnectInfoModalCation>
                                </CustomColumn>
                            </CustomRow>
                        </ConnectInfoCarouselBox>
                    </Carousel>
                </CarouselContainer>
            </Modal>
        </ModalContainer>
    )
}

export default ConnectInfoModal