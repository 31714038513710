import styled from 'styled-components';
import { Button, Form } from 'antd';

export const SpaceBetweenContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const Heading = styled.h1`
    font-size: 20px;
    font-weight: 600;
    line-height: normal; 
    margin: 0px;
    color: #010043;
`;

export const SubHeading = styled.p`
    font-size: 12px;
    font-weight: 300;
    color: #2C2B50;
    margin-top: 12px;
`;

export const FlexContainer = styled.div`
    display: flex;
`;
export const FlexColContainer = styled.div`
    display: flex;
    flex-direction : column;
`;

export const FlexEndContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export const JustifyCenterContainer = styled.div`
    display: flex;
    justify-content: center;
`;

export const MainButton = styled(Button)`
    color: #fff;
    background-color: #F86624;
    border: 0px !important;
    font-size: 14px;
    font-weight: 500;
    padding-inline-start: 24px;
    padding-inline-end: 24px;
    &:focus{
        outline : none !important;
     }
    &:active {
      outline : none !important;
    }
    &:hover {
        color: #fff !important;
    }
    margin-right : 20px;
    box-shadow : none;
`;

export const AdmirianOutlinedButton = styled(Button)`
    border: 1px solid #010043;
    .ant-btn.ant-btn-round {
        padding-inline-start: 20px;
        padding-inline-end: 20px;
        border: 1px solid #010043 !important;
        border-color: #010043 !important;
    }
    .ant-btn-default {
        border: 1px solid #010043 !important;
        border-color: #010043 !important;
    }
`;

export const AdmirianButton = styled(Button)`
    box-shadow : none;
    border-radius: 60px;
    background: linear-gradient(97deg, #F86624 4.34%, #C83E00 101.42%);
    color: #fff;
    &:disabled {
        background-color: #BDBDDB !important;
        color: #fff;
        background: #BDBDDB !important;
    }
    &:hover{
        color: #fff;
    }
`;

export const AdmirianForm = styled(Form)`
    .ant-form-item .ant-form-item-label >label {
        color: #494785;
        font-size: 12px;
        font-weight: 400;
        line-height: 24px;
    }
    .ant-input {
        border-color: #8988BF;
        border-radius: 24px;
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border-color: #8988BF;
        border-radius: 24px;
    }
    .ant-select .ant-select-arrow {
        color: #8988BF;
    }
`;

// Styles Added for Create Campaign by Manan

export const BrandLogo = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="31" viewBox="0 0 32 31" fill="none">
            <path d="M6.01938 10.503C2.58468 12.3028 2.58469 17.2578 6.01939 19.0575L25.0154 29.0111C28.1974 30.6784 31.9994 28.35 31.9994 24.7339L31.9994 4.82666C31.9994 1.21056 28.1974 -1.11788 25.0154 0.549445L6.01938 10.503Z" fill="url(#paint0_linear_4165_12935)" />
            <path d="M25.9806 11.3624C29.4153 13.1621 29.4153 18.1171 25.9806 19.9169L6.98459 29.8705C3.80256 31.5378 0.00059298 29.2093 0.00059298 25.5932L0.00059298 5.68604C0.00059298 2.06993 3.80256 -0.258508 6.98459 1.40882L25.9806 11.3624Z" fill="url(#paint1_linear_4165_12935)" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.01939 19.0564C2.58469 17.2567 2.58468 12.3017 6.01938 10.502L15.1778 5.70312L25.9794 11.363C29.4141 13.1627 29.4141 18.1177 25.9794 19.9174L16.821 24.7163L6.01939 19.0564Z" fill="url(#paint2_linear_4165_12935)" />
            <defs>
                <linearGradient id="paint0_linear_4165_12935" x1="-1.11271" y1="14.7803" x2="31.9994" y2="14.7803" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FDDCCE" />
                    <stop offset="1" stop-color="#F86624" />
                </linearGradient>
                <linearGradient id="paint1_linear_4165_12935" x1="4.34111" y1="0.859375" x2="7.78869" y2="31.3342" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#F86624" />
                    <stop offset="0.486714" stop-color="#EA3546" />
                    <stop offset="0.729167" stop-color="#5342D6" />
                    <stop offset="1" stop-color="#43BCCD" />
                </linearGradient>
                <linearGradient id="paint2_linear_4165_12935" x1="14.1698" y1="3.0124" x2="19.551" y2="34.6895" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#F86624" />
                    <stop offset="0.338542" stop-color="#EA3546" />
                    <stop offset="0.729167" stop-color="#5342D6" />
                    <stop offset="1" stop-color="#43BCCD" />
                </linearGradient>
            </defs>
        </svg>
    );
}

