import { Col, Divider, Form, Modal, notification, Row, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { CancelButton, ModalContainer, ModalTitle, SaveButton, UploadContainer } from "./style";
import axios from "axios";
import { parseJwtToken } from "../../utils";
import { BASE_URL } from "../../utils/HelperFunctions/constants";

const UpdateLogoModal = ({visible,setVisible,brandDetails,getBrandDetail})=>{
    const [form] = Form.useForm()
    const [profileImage, setProfileImage] = useState([]);
    const [formProfileImage, setFormProfileImage] = useState(null);
    const [submitDisabled , setSubmitDisabled] = useState(true)
    const refresh_token = parseJwtToken(localStorage.getItem('refresh_token'))
    useEffect(()=>{
        setProfileImage([
            {
              url: brandDetails?.logo,
            },
          ])
          form.setFieldsValue({
            "logo" : [
                {
                  url: brandDetails?.logo,
                },
              ]
          })
    },[brandDetails])

    const profileImageProps = {
        onRemove: (file) => {
            form.resetFields();
          setProfileImage([]);
          setFormProfileImage(null)
          setSubmitDisabled(false)
        },
        beforeUpload: async (file) => {
          if (
            file.type === 'image/png' ||
            file.type === 'image/jpeg' ||
            file.type === 'image/jpg'
          ) {
                setProfileImage([file]);
                setFormProfileImage(file)
                setSubmitDisabled(false)
            return false;
          } else {
            notification.error({
              message: 'You can only upload png/jpeg/jpg file!',
            });
            return Upload.LIST_IGNORE;
          }
        },
      };

      const uploadButton = (
        <div style={{display : 'flex',justifyContent : 'center',alignItems : 'center', background : '#F6F6FF', borderRadius : '10px', flexDirection : 'column',width : '100%', height : '100%'}}>
          { <img src="https://static.admirian.com/9bddc673-8134-421e-817e-14da029b651a_UploadImage.svg" alt="Upload" /> }
          <div
            style={{
              color :'#010043',
              fontSize :'16px',
              fontWeight : 500,
            }}
          >
            Upload
          </div>
        </div>
      );

      const handleFormSubmit = async(values) => {
        let formData = new FormData();
        if(profileImage.length >= 1){
            refresh_token?.user_type === "Brand" ? 
            formData.append("logo", formProfileImage)
             :
             formData.append("image", formProfileImage)
        
        try{
            const response =  refresh_token?.user_type === "Brand" ? await axios.patch(`${BASE_URL}/profiles/brands/${refresh_token?.user_uuid}/`,
            formData
            )
            : await axios.patch(`${BASE_URL}/profiles/creators/${refresh_token?.user_uuid}/`,
            formData
            )

            if(!response?.data?.error){
                notification.success({
                    message : "Logo Updated SuccessFully"
                })
                getBrandDetail()
                setVisible(false)
                // carouselRef.current.next()
            }
            else{
                notification.error({
                    message : response?.data?.error?.message
                })
            }
            
        }catch(error){
            notification.error({message : error?.response?.data?.message})
        }
        }else{
            notification.error({message : "Logo is required"})
        }
      }

    return(
        <ModalContainer>
        <Modal
        title = {<ModalTitle>Upload Logo</ModalTitle>}
        visible = {visible}
        destroyOnClose = {true}
        onCancel = {()=>{
            setVisible(false)
        }}
        footer = {false}
        >
            <Form
            form={form}
            onFinish = {handleFormSubmit}
            >
            <Row gutter={[20,20]} style={{marginTop : '30px'}}>
                <UploadContainer>
                    <Form.Item
                    name={"logo"}
                    rules={[{
                        required : true,
                        message : "Logo Is Required"
                    }]}
                    >
                        <Upload
                        name="avatar"
                        listType="picture-card"
                        className="avatar-uploader"
                        {...profileImageProps}
                        maxCount={1}
                        previewFile ={false}
                        fileList={profileImage}
                        >
                            {profileImage.length >= 1 ? null : uploadButton}
                        </Upload>
                </Form.Item>
                </UploadContainer>
            </Row>
            <Divider />
            <Col span={24} style={{display : 'flex',justifyContent : 'flex-end',marginTop : '20px'}}>
                <CancelButton onClick={()=>{
                    setVisible(false)
                }}
                >
                    Cancel
                </CancelButton>
                <SaveButton 
                htmlType="submit"
                disabled = {submitDisabled}
                >
                    Save Changes
                </SaveButton>
            </Col>
            </Form>
        </Modal>
        </ModalContainer>
    )
}

export default UpdateLogoModal