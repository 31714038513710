import React from 'react';
import { Route, Routes, Redirect, useNavigate, useLocation } from 'react-router-dom';
import { parseJwtToken } from './utils';
import { useEffect } from 'react';


export const PrivateRoute = ({ element: Component, path, ...rest}) => {

  const navigate = useNavigate();
  const refresh_token = parseJwtToken(localStorage.getItem('refresh_token'))
  const location = useLocation()

  useEffect(()=>{
    if(location.pathname !== "/" && location.pathname.toLowerCase() !== "/sign-in" && location.pathname.toLowerCase() !== "/sign-up" && location.pathname.toLowerCase() !== "/privacy-policy" && location.pathname.toLowerCase() !== "/verify-otp"  && location.pathname.toLowerCase() !== "/request-demo" && location.pathname.toLowerCase() !== "/brands/elite/register" && location.pathname.toLowerCase() !== "/create-new-password" && location.pathname.toLowerCase() !== "/password-changed-successful" && location.pathname.toLowerCase() !== "/password/reset"){
      if(!refresh_token){
        navigate('/')
      }
    }
    if(location.pathname === "/" || location.pathname.toLowerCase() === "/sign-in" || location.pathname.toLowerCase() === "/sign-up" || location.pathname.toLowerCase() === "/verify-otp" || location.pathname.toLowerCase() === '/brands/elite/register' || location.pathname.toLowerCase() === "/create-new-password" || location.pathname.toLowerCase() === "/password-changed-successful" ||  location.pathname.toLowerCase() === "/password/reset"){
      if(refresh_token){
        if(refresh_token?.user_type === "Brand"){
          navigate('/discover')
        }
        else{
          navigate('/analytics')
        }
      }
    }
  },[location.pathname])

  return (
    <Routes>
      {' '}
      <Route
        {...rest}
        path={path}
        element={
          <>
                <Component/>
          </>
        }
      />
    </Routes>
  );
};
