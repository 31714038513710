
import { Button, Row } from 'antd';
import styled from 'styled-components';
import media from '../../utils/UIElements/media';

export const Container = styled.div`
    margin : 32px 48px;
    border-radius: 10px;
    background: var(--Typo-White, #FFF);
    padding : 0 32px;
    display : flex;
    width : 100%;
    flex-direction : column;
    .ant-divider-horizontal{
        margin : 0;
    }
    ${media.mobile`
        padding: 0px 16px;
        margin: 16px 24px;
    `}
`;

export const CampaignContentContainer = styled.div`
    display: flex;
    padding: var(--Space-List-Md, 16px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Space-List-Md, 16px);
    align-self: stretch;
    border-radius: 10px;
    background: var(--Surface-Light, #F6FAFF);
    margin :24px 0;
`;

export const CampaignInfoHeading = styled.div`
    color: var(--Typo-High, #0F0E1B);
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom : 16px;
    ${media.mobile`
        font-size: 16px;
        margin-bottom: 12px;
    `}
`;

export const CampaignInfoDescription = styled.div`
    color: var(--Typo-High, #0F0E1B);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    ${media.mobile`
        font-size: 12px;
        line-height: 20px;
    `}
`;

export const CampaignObjectiveHeading = styled.div`
color: var(--Typo-Mid-High, #2C2B50);
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-bottom : 16px;
${media.mobile`
    font-size: 12px;
    margin-bottom: 12px;
`}
`;

export const CampaignObjectiveDescription = styled.div`
color: var(--Typo-High, #0F0E1B);
text-align: left;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 24px;
text-transform: capitalize;
${media.mobile`
    font-size: 14px;
`}
`;

export const ListContainer = styled.div`
    display: flex;
    padding:  16px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 10px;
    border: 1px solid #D8D7EA;
    margin-bottom : 24px;
    width: 100%;
    ${media.mobile`
        padding: 12px;
        margin-bottom:12px;
        width: unset;
    `}
`;

export const ListItemHeading = styled.div`
    color: var(--Typo-Medium, #494785);
    leading-trim: both;
    text-edge: cap;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    margin-bottom: 12px;
    ${media.mobile`
        margin-bottom: 4px;
    `}
`;
export const ListItemDescription = styled.div`
    color: var(--Typo-High, #0F0E1B);
    /* Body L */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
`;

export const AmountInformationContainer = styled.div`
    border-radius: 12px;
    padding: var(--Space-List-Lg, 24px);
    box-shadow: 16px 8px 40px 0px rgba(0, 0, 0, 0.08);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap : 32px;
    .ant-btn-primary {
        display: flex;
        padding: 16px 32px;
        width : 100%;
        justify-content: center;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        border-radius: 60px;
        background: linear-gradient(97deg, #F86624 4.34%, #C83E00 101.42%);
        color: var(--Typo-White, #FFF);
        text-align: center;

        /* Button/Medium */
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 125% */
    }
    .ant-btn-secondary {
        display: flex;
        padding: 16px 32px;
        width : 100%;
        justify-content: center;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        border-radius: 60px;
        border: 1px solid var(--UI-Component-accent, #010043);
        background: var(--Surface-base, #FFF);
        color: var(--UI-Component-accent, #010043);
        text-align: center;
        /* Button/Medium */
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 125% */
    }
`;


export const PaymentHeading = styled.div`
    font-size: 14px;
    font-weight: 500;
    color: #0F0E1B;
    margin-bottom: 4px;
`;

export const PaymentDescription = styled.p`
    font-size: 12px;
    font-weight: 400;
    color: #2C2B50;
    margin-top: 0px;
`;

export const PaymentListContent = styled.ul`
    padding-inline-start: 12px;
    margin-block-start: 0px;
    margin-block-end: 0px;
    font-size: 12px;
    color: #2C2B50;
`;

export const CampaignContentSubHeading = styled.div`
    color: var(--Typo-Mid-High, #2C2B50);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom : 8px;
    ${media.mobile`
        font-size: 12px;
        margin-bottom: 6px;
    `}
`
export const Heading = styled.div`
    color: #010043;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    ${media.mobile`
        font-size: 16px;
        text-align: center;
    `}
`;

export const SubHeading = styled.div`
    color: var(--Typo-Med, var(--Typo-Mid-High, #2C2B50));
    leading-trim: both;
    text-edge: cap;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    ${media.mobile`
        text-align: center;
    `}
`;

export const ListImageContaner = styled.div`
    display : flex;
    justify-content : center;
    align-items : center;
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 10px;
    margin-right : 16px;
    ${media.mobile`
        margin-bottom: 6px;
        padding-top: 6px;
        margin-right : 0px;
        margin: auto;
    `}
`

export const StatusDiv = styled.div`
    height : 12px;
    display: flex;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 50px;
    background: ${(props) => {
        if(props.status?.toUpperCase() === "AWAITING BRAND KICKSTART"){
            return `var(--UI-Creative, #4E4BEE)`;
        }else if(props.status?.toUpperCase() === "PREPARING CONTENT"){
            return `var(--UI-Young, linear-gradient(0deg, #F86624 0%, #F86624 100%), #F9C80E)`;
        }else if(props.status?.toUpperCase() === 'IN REVIEW'){
            return `var(--UI-Creative, #4E4BEE)`
        }else if(props.status?.toUpperCase() === 'COMPLETED'){
            return `var(--UI-Creative, #1551EA)`
        }
        else{
            return `var(--UI-Creative, #4E4BEE)`
        }
    }};
    color: var(--Typo-White, #FFF);
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
   ${media.mobile`
        width: 100%;
        height: 20px;
        font-size: 12px;
   `} 
`


export const CampaignDetail = styled.div`
    display: flex;
    align-items: flex-start;
    height : 50px;
    margin-top : 18px;
    margin-bottom : 40px;
`

export const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    align-self: stretch;
`
export const ModalHeading = styled.div`
    color: var(--Typo-High, #0F0E1B);
    text-align: center;

    /* H2 */
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
` 
export const ModalDetail = styled.div`
    color: var(--Typo-Med, var(--Typo-Mid-High, #2C2B50));
    text-align: center;
    leading-trim: both;
    text-edge: cap;

    /* Body S */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
`

export const ConfirmModalButton = styled(Button)`
    display: flex;
    padding: 16px 32px;
    justify-content: center;
    align-items: center;
    border-radius: 60px;
    border: 1px solid var(--UI-Component-accent, #010043) !important;
    background: var(--Surface-base, #FFF) !important;
    color: var(--UI-Component-accent, #010043) !important;
    text-align: center;
    height : max-content !important;

    /* Button/Medium */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 125% */
    box-shadow : none !important;
    outline : none !important;
`

export const CancelModalButton = styled(Button)`
    display: flex;
    padding: 16px 32px;
    justify-content: center;
    align-items: center;
    border : none !important;
    color: var(--UI-Component-accent, #010043) !important;
    text-align: center;
    height : max-content !important;

    /* Button/Medium */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 125% */
    box-shadow : none !important;
    outline : none !important;
`

export const CancelRequestContainer = styled.div`
    display : flex;
    margin-top: 16px;
    justify-content : space-between;
    align-items : center;
    background : #F86624;
    padding: 24px;
    border-radius: 8px;
    gap: 24px;
    color : #FFFFFF;
    ${media.mobile`
        padding: 16px;
        flex-wrap: wrap-reverse;
        text-align: center;
    `}
`

export const CancelButton = styled(Button)`
    height : auto !important;
    font-weight : 500;
    font-size : 14px;
    line-height : 20px;
    border: 1px solid #FFFFFF !important;
    padding: 10px 24px 10px 24px;
    border-radius: 60px;
    gap: 10px;
    color : #FFFFFF !important;
    background : transparent !important;
    ${media.mobile`
        margin: auto;
    `}
`;

export const RequestInformationText = styled.div`
    font-weight : 500;
    font-size : 16px;
    line-height : 24px;
      ${media.mobile`
        font-size : 14px;
    `}
`;

export const RequestSubHeadingText = styled.div`
    font-weight : 400;
    font-size : 14px;
    line-height : 20px;
    ${media.mobile`
        font-size: 12px;
    `}
`;

export const LinkText = styled.span`
    text-decoration : underline; 
    color : #ffffff;
    cursor : pointer;
`;

export const InfluencerProfile = styled.img`
    margin : 0 24px;
    margin-top : -70px;
    border-radius : 10px;
`;

export const CustomRow = styled(Row)`
    padding-top: 24px;
    ${media.mobile`
        display: flex;
        flex-wrap: wrap-reverse;
    `}
`;

export const RejectOfferButton = styled.span`
    color : #E9000B;
    font-size : 16px;
    cursor : pointer;
    font-weight : 500;
    line-height : 20px;
`;

export const CampaignButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    ${media.mobile`
        justify-content: center;
    `}
`;

export const CampaignTextContainer = styled.div`
    display: flex;
    ${media.mobile`
        display: block;
        padding-bottom: 8px;
        text-align: center;
    `}
`;

export const CampaignInfoContainer = styled.div`
    ${media.mobile`
        padding-bottom: 8px;
        text-align: center;
    `}
`;

export const ActivityRowContainer = styled(Row)`
    display : flex;
    overflowY : scroll;
    max-height : 90vh;
    ${media.mobile`
        max-height : 50vh;  
    `}
`;

export const ActivityText = styled.span`
    color : #0F0E1B;
    font-size : 16px;
    ${media.mobile`
        font-size: 14px;
    `}
`;

export const ActivitySubText = styled.span`
    color : #8988BF;
    font-size : 12px;
    font-weight : 300;
    ${media.mobile`
        font-size: 8px;
    `}
`;

export const ActivityCustomRow = styled(Row)`
    display : flex;
    overflowY : scroll;
    max-height : 90vh;
`;