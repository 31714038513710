import { Carousel, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { AnalyticsDetailCard, CreatorAnalyticsContainer, CreatorMediaContainer, FeaturedMediaCard, ToggleButton, CompanyLogoContainer, CardHeadingAnalytics, ChannelMetricsContainer, CustomRow, CustomColumn, AnalyticsCountText, AnalyticsInfoText, PercentageInformationText, DaysInfoText, AnalyticsTextHeading, PlayInformationText, PlayText, MediaInfoText, MostViewedText, FeaturedMediaText, ViewedText, DemographicText, NoDataAvailableContainer, AudienceCountText, ShareInformationText, UserNameText, PublishedInformation, DisplayText, DensityContainer } from "./style";
import { ResponsiveHeatMap } from "@nivo/heatmap";
import { ResponsiveBar } from '@nivo/bar'
import { ResponsivePie } from '@nivo/pie'
import { getTimeVariable } from "./utils";
import { FlexContainer, SpaceBetweenContainer } from "../../utils/common";
import { isMobile } from "../../utils/constants/inedx";

const CreatorYoutubeAnalytics = ({ analyticsData, setAnalyticsData, isLoading }) => {

    const [data, setData] = useState([])
    const [BarData, setBarData] = useState([])
    const [LocationBarData, LocationSetBarData] = useState([])
    const [AgeBarData, AgeSetBarData] = useState([])
    const [GenderBarData, GenderSetBarData] = useState([])

    useEffect(() => {
        if (analyticsData && analyticsData?.activity_heat_map) {
            setData(Object.entries(analyticsData?.activity_heat_map)?.map(([key, valuesArr]) => {
                return (
                    {
                        id: key,
                        data: Object.entries(valuesArr)?.map(([time, value]) => {
                            return (
                                {
                                    x: getTimeVariable[time],
                                    y: value
                                }
                            )
                        })
                    }
                )
            }))
        } else {
            setData([])
        }
        if (analyticsData && analyticsData?.post_density) {
            setBarData(Object.entries(analyticsData?.post_density)?.map(([key, value]) => {
                return (
                    {
                        day: key,
                        "Post Density": value,
                        "Post DensityColor": "#F86624",
                    }
                )
            }))
        } else {
            setBarData([])
        }
        if (analyticsData && analyticsData?.audience_demographics?.geographic_location?.length > 0) {
            LocationSetBarData(analyticsData?.audience_demographics?.geographic_location?.map((geoData) => {
                return (
                    {
                        city: geoData?.city,
                        "Percentage": geoData?.percentage,
                        "PercentageColor": "#F86624",
                    }
                )
            }))
        } else {
            LocationSetBarData([])
        }
        if (analyticsData && analyticsData?.audience_demographics?.age_group?.length > 0) {
            AgeSetBarData(analyticsData?.audience_demographics?.age_group?.map((ageData) => {
                return (
                    {
                        "id": ageData?.age_group,
                        "label": ageData?.age_group,
                        "value": ageData?.viewer_percentage,
                        "color": "#F86624",
                    }
                )
            }))
        } else {
            AgeSetBarData([])
        }
        if (analyticsData && analyticsData?.audience_demographics?.gender?.length > 0) {
            GenderSetBarData(analyticsData?.audience_demographics?.gender?.map((genderData) => {
                return (
                    {
                        "id": genderData?.gender,
                        "label": genderData?.gender,
                        "value": genderData?.viewer_percentage,
                        "color": "#F86624",
                    }
                )
            }))
        } else {
            GenderSetBarData([])
        }
    }, [analyticsData])


    /// There might be an issue with the gender bar and age bar, i wasnt completely able to render it, it might the key or data issue, please check. 
    /// There might be some styling left, or some minor changes in the styling. I have made the necessary changes in most of it. There might be something that i might have missed, please do check.
    /// Apart from that i think it should work, I think i integrated the api correctly, if i made a mistake it is because i cant see the data or else i might have done it correctly.
    /// Never worked on such a big code, it was a bit complex but i hope i managed to do it. I apologize if i miss something. 

    const reelCarouselRef = React.createRef();
    const [reelCarouselActiveIndex, setReelCarouselActiveIndex] = useState(0);

    return (
        <CreatorAnalyticsContainer>
            <ChannelMetricsContainer id="channelMetricsyt">
                <CustomRow gutter={[16, 20]}>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24} style={{ display: "flex", flexDirection: "row", marginBottom: '10px' }}>
                        <CompanyLogoContainer>
                            <img
                                src={analyticsData?.profile_details?.thumbnails?.high}
                                alt="logo"
                                width={28}
                                height={28}
                                style={{
                                    borderRadius: '50%',
                                }}
                            />
                            <img
                                src="https://static.admirian.com/657ef4b3-0905-4fc4-ad7f-3bedec4362bc_youtube-bg-white.svg"
                                alt="logo"
                                height={18}
                                width={18}
                                style={{
                                    borderRadius: '50%',
                                    marginLeft: '-8px'
                                }}
                            />
                        </CompanyLogoContainer>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <CardHeadingAnalytics>
                                Channel Metrics
                            </CardHeadingAnalytics>
                        </Col>
                    </Col>
                    <CustomColumn xl={6} lg={6} md={12} sm={24} xs={24}>
                        <AnalyticsDetailCard>
                            <img src="https://static.admirian.com/25f14eaf-c40d-49d6-9500-c672e2ed499b_followers.svg" className="m-auto" width={48} height={48} style={{ border: 'unset', borderRadius: '50%' }} />
                            <AnalyticsCountText>
                                {analyticsData?.profile_metrics?.subscriber_count}
                            </AnalyticsCountText>
                            <AnalyticsInfoText>
                                Total Subscribers
                            </AnalyticsInfoText>
                            <PercentageInformationText style={{ color: analyticsData?.profile_metrics?.shares_count_trend < 0 ? "#E9000B" : "#17A600" }}>
                                <span style={{ marginRight: 4 }}>{analyticsData?.profile_metrics?.shares_count_trend} %</span>
                                <img src={analyticsData?.profile_metrics?.shares_count_trend < 0 ? "https://static.admirian.com/86efc633-e04b-4206-af73-07333c9ba3e3_decrementLogo.svg" : "https://static.admirian.com/c66aab96-e919-4573-b46f-a534da62dfef_incrementLogo.svg"} width={24} height={24} />
                            </PercentageInformationText>
                            <DaysInfoText>
                                {`${analyticsData?.profile_metrics?.subscriber_count_trend < 0 ? 'Less' : "High"}`} than last 30 days
                            </DaysInfoText>
                        </AnalyticsDetailCard>
                    </CustomColumn>
                    <CustomColumn xl={6} lg={6} md={12} sm={24} xs={24}>
                        <AnalyticsDetailCard>
                            <img src="https://static.admirian.com/f29a4bd9-b951-4b53-bcd4-cc5560d8bef2_profileVisit.svg" className="m-auto" width={48} height={48} style={{ border: 'unset', borderRadius: '50%' }} />
                            <AnalyticsCountText>
                                {analyticsData?.profile_metrics?.views_count}
                            </AnalyticsCountText>
                            <AnalyticsInfoText>
                                Total Views
                            </AnalyticsInfoText>
                            <PercentageInformationText style={{ color: analyticsData?.profile_metrics?.views_count_trend < 0 ? "#E9000B" : "#17A600" }}>
                                <span style={{ marginRight: 4 }}>{analyticsData?.profile_metrics?.views_count_trend} %</span>
                                <img src={analyticsData?.profile_metrics?.views_count_trend < 0 ? "https://static.admirian.com/86efc633-e04b-4206-af73-07333c9ba3e3_decrementLogo.svg" : "https://static.admirian.com/c66aab96-e919-4573-b46f-a534da62dfef_incrementLogo.svg"} height={24} width={24} alt="increment" />
                            </PercentageInformationText>
                            <DaysInfoText>
                                {`${analyticsData?.profile_metrics?.views_count_trend < 0 ? 'Less' : "High"}`} than last 30 days
                            </DaysInfoText>
                        </AnalyticsDetailCard>
                    </CustomColumn>
                    <CustomColumn xl={6} lg={6} md={12} sm={24} xs={24}>
                        <AnalyticsDetailCard>
                            <img src="https://static.admirian.com/51e89da2-ac4b-45e4-b35e-7412084e23d5_likes.svg" width={48} height={48} className="m-auto" style={{ border: 'unset', borderRadius: '50%' }} />
                            <AnalyticsCountText>
                                {analyticsData?.profile_metrics?.likes_count}
                            </AnalyticsCountText>
                            <AnalyticsInfoText>
                                Total Likes
                            </AnalyticsInfoText>
                            <PercentageInformationText style={{ color: analyticsData?.profile_metrics?.likes_count_trend < 0 ? "#E9000B" : "#17A600" }}>
                                <span style={{ marginRight: 4 }}>{analyticsData?.profile_metrics?.likes_count_trend} %</span>
                                <img src={analyticsData?.profile_metrics?.likes_count_trend < 0 ? "https://static.admirian.com/86efc633-e04b-4206-af73-07333c9ba3e3_decrementLogo.svg" : "https://static.admirian.com/c66aab96-e919-4573-b46f-a534da62dfef_incrementLogo.svg"} width={24} height={24} />
                            </PercentageInformationText>
                            <DaysInfoText>
                                {`${analyticsData?.profile_metrics?.likes_count_trend < 0 ? 'Less' : "High"}`} than last 30 days
                            </DaysInfoText>

                        </AnalyticsDetailCard>
                    </CustomColumn>
                    <CustomColumn xl={6} lg={6} md={12} sm={24} xs={24}>
                        <AnalyticsDetailCard>
                            <img src="https://static.admirian.com/71043585-f913-4a1d-a116-913ed67a4092_comments.svg" width={48} height={48} className="m-auto" style={{ border: 'unset', borderRadius: '50%' }} />
                            <AnalyticsCountText>
                                {analyticsData?.profile_metrics?.comments_count}
                            </AnalyticsCountText>
                            <AnalyticsInfoText>
                                Total Comments
                            </AnalyticsInfoText>
                            <PercentageInformationText style={{ color: analyticsData?.profile_metrics?.comments_count_trend < 0 ? "#E9000B" : "#17A600" }}>
                                <span style={{ marginRight: 4 }}>{analyticsData?.profile_metrics?.comments_count_trend} %</span>
                                <img src={analyticsData?.profile_metrics?.comments_count_trend < 0 ? "https://static.admirian.com/86efc633-e04b-4206-af73-07333c9ba3e3_decrementLogo.svg" : "https://static.admirian.com/c66aab96-e919-4573-b46f-a534da62dfef_incrementLogo.svg"} height={24} width={24} />
                            </PercentageInformationText>
                            <DaysInfoText>
                                {`${analyticsData?.profile_metrics?.comments_count_trend < 0 ? 'Less' : "High"}`} than last 30 days
                            </DaysInfoText>
                        </AnalyticsDetailCard>
                    </CustomColumn>
                    <CustomColumn xl={6} lg={6} md={12} sm={24} xs={24}>
                        <AnalyticsDetailCard>
                            <img src="https://static.admirian.com/64780a6d-7227-487e-a078-662efb16ae1f_share.svg" className="m-auto" width={48} height={48} style={{ border: 'unset', borderRadius: '50%' }} />
                            <AnalyticsCountText>
                                {analyticsData?.profile_metrics?.shares_count}
                            </AnalyticsCountText>
                            <AnalyticsInfoText>
                                Share Count
                            </AnalyticsInfoText>
                            <PercentageInformationText style={{ color: analyticsData?.profile_metrics?.shares_count_trend < 0 ? "#E9000B" : "#17A600" }}>
                                <span style={{ marginRight: 4 }}>{analyticsData?.profile_metrics?.shares_count_trend} %</span>
                                <img src={analyticsData?.profile_metrics?.shares_count_trend < 0 ? "https://static.admirian.com/86efc633-e04b-4206-af73-07333c9ba3e3_decrementLogo.svg" : "https://static.admirian.com/c66aab96-e919-4573-b46f-a534da62dfef_incrementLogo.svg"} height={24} width={24} />
                            </PercentageInformationText>
                            <DaysInfoText>
                                {`${analyticsData?.profile_metrics?.shares_count_trend < 0 ? 'Less' : "High"}`} than last 30 days
                            </DaysInfoText>
                        </AnalyticsDetailCard>
                    </CustomColumn>
                    <CustomColumn xl={6} lg={6} md={12} sm={24} xs={24}>
                        <AnalyticsDetailCard>
                            <img src="https://static.admirian.com/399bd3a1-a2ac-4299-bdcc-a46d2cfb3a73_min_watched.svg" width={48} height={48} className="m-auto" style={{ border: 'unset', borderRadius: '50%' }} />
                            <AnalyticsCountText>
                                {analyticsData?.profile_metrics?.estimated_minutes_watched}
                            </AnalyticsCountText>
                            <AnalyticsInfoText>
                                Estimated Mins Watched
                            </AnalyticsInfoText>
                            <DaysInfoText>
                                In {analyticsData?.profile_metrics?.channel_age} years
                            </DaysInfoText>
                        </AnalyticsDetailCard>
                    </CustomColumn>
                    <CustomColumn xl={6} lg={6} md={12} sm={24} xs={24}>
                        <AnalyticsDetailCard>
                            <img src="https://static.admirian.com/b59d6d49-4bb0-48d7-a6e3-e1fd21463c9b_viewDuration.svg" className="m-auto" width={48} height={48} style={{ border: 'unset', borderRadius: '50%' }} />
                            <AnalyticsCountText>
                                {analyticsData?.profile_metrics?.average_view_duration}
                            </AnalyticsCountText>
                            <AnalyticsInfoText>
                                Average View Duration
                            </AnalyticsInfoText>
                        </AnalyticsDetailCard>
                    </CustomColumn>
                    <CustomColumn xl={6} lg={6} md={12} sm={24} xs={24}>
                        <AnalyticsDetailCard>
                            <img src="https://static.admirian.com/3138538a-891b-4b3e-ab6c-2ce06cf46fc1_clickRate.svg" className="m-auto" width={48} height={48} style={{ border: 'unset', borderRadius: '50%' }} />
                            <AnalyticsCountText>
                                {analyticsData?.profile_metrics?.annotation_click_through_rate}
                            </AnalyticsCountText>
                            <AnalyticsInfoText>
                                Click Through Rate
                            </AnalyticsInfoText>
                        </AnalyticsDetailCard>
                    </CustomColumn>
                    <CustomColumn xl={6} lg={6} md={12} sm={24} xs={24}>
                        <AnalyticsDetailCard>
                            <img src="https://static.admirian.com/b59d6d49-4bb0-48d7-a6e3-e1fd21463c9b_viewDuration.svg" className="m-auto" width={48} height={48} style={{ border: 'unset', borderRadius: '50%' }} />
                            <AnalyticsCountText>
                                {analyticsData?.profile_metrics?.average_view_percentage ? `${analyticsData?.profile_metrics?.average_view_percentage}%` : '-'}
                            </AnalyticsCountText>
                            <AnalyticsInfoText>
                                Average View Percentage
                            </AnalyticsInfoText>
                        </AnalyticsDetailCard>
                    </CustomColumn>
                    <CustomColumn xl={6} lg={6} md={12} sm={24} xs={24}>
                        <AnalyticsDetailCard>
                            <img src="https://static.admirian.com/399bd3a1-a2ac-4299-bdcc-a46d2cfb3a73_min_watched.svg" className="m-auto" width={48} height={48} style={{ border: 'unset', borderRadius: '50%' }} />
                            <AnalyticsCountText>
                                {analyticsData?.profile_metrics?.channel_age ? `${analyticsData?.profile_metrics?.channel_age} Years` : '0 Years'}
                            </AnalyticsCountText>
                            <AnalyticsInfoText>
                                Channel Age
                            </AnalyticsInfoText>
                        </AnalyticsDetailCard>
                    </CustomColumn>
                </CustomRow>
            </ChannelMetricsContainer>
            <CreatorMediaContainer id="featuredMedia" style={{ marginTop: '20px' }}>
                {analyticsData?.featured_media?.featured_videos?.length > 0 &&
                    <>
                        <AnalyticsTextHeading>
                            <FlexContainer style={{ alignItems: 'center' }}>
                                <CompanyLogoContainer>
                                    <img
                                        src={analyticsData?.profile_details?.thumbnails?.high}
                                        height={28}
                                        width={28}
                                        alt="logo"
                                        style={{
                                            borderRadius: '50%',
                                        }}
                                    />
                                    <img
                                        src="https://static.admirian.com/657ef4b3-0905-4fc4-ad7f-3bedec4362bc_youtube-bg-white.svg"
                                        alt="logo"
                                        height={18}
                                        width={18}
                                        style={{
                                            borderRadius: '50%',
                                            marginLeft: '-8px'
                                        }}
                                    />
                                </CompanyLogoContainer>
                                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                    <CardHeadingAnalytics>
                                        Featured Media
                                    </CardHeadingAnalytics>
                                </Col>
                            </FlexContainer>
                            <FlexContainer style={{ flexDirection: 'row' }}>
                                <ToggleButton
                                    style={{ marginRight: '15px' }}
                                    onClick={() => {
                                        reelCarouselRef.current.prev()
                                    }}
                                    disabled={reelCarouselActiveIndex === 0}
                                >
                                    {'<'}

                                </ToggleButton>
                                <ToggleButton
                                    onClick={() => {
                                        reelCarouselRef.current.next()
                                    }}
                                    disabled={reelCarouselActiveIndex >= analyticsData?.featured_media?.featured_videos?.length - 4}
                                >
                                    {'>'}

                                </ToggleButton>
                            </FlexContainer>
                        </AnalyticsTextHeading>
                        <Row gutter={[20, 20]} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '24px' }}>
                            <Carousel
                                slidesToShow={isMobile ? 1 : 4}
                                infinite={false}
                                ref={reelCarouselRef}
                                beforeChange={(current, next) => setReelCarouselActiveIndex(next)}
                            >
                                {analyticsData?.featured_media?.featured_videos?.map((featuredVideo, index) => {
                                    return (
                                        <Col xl={24} lg={24} md={24} sm={24} xs={24} key={index}>
                                            <FeaturedMediaCard>
                                                <div
                                                    style={{ position: 'relative' }}
                                                    className="cursor-pointer w-100"
                                                    onClick={() => {
                                                        window.open(`https://www.youtube.com/watch?v=${featuredVideo?.video_id}`)
                                                    }}
                                                >
                                                    <img
                                                        src={featuredVideo?.thumbnails?.high}
                                                        height={130}
                                                        className="w-100"
                                                        style={{ borderRadius: '10px' }}
                                                    />
                                                    <img height={30} width={45} style={{ position: 'absolute', top: '40%', left: '40%' }} class="image2" src="https://static.admirian.com/0245d12b-7524-48f4-849e-ab535f12ff94_play-white.svg" />
                                                </div>
                                                <Row gutter={[0, 20]} style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                                    <Col xl={8} lg={8} md={8} sm={8} xs={8} className="text-center">
                                                        <img src="https://static.admirian.com/09ca32be-dfe0-4706-afec-3d275f6c9994_iconamoon_like-thin.svg" height={24} width={24} className="m-auto" />
                                                        <PlayInformationText>{featuredVideo?.likes_count}</PlayInformationText>
                                                        <PlayText>Likes</PlayText>
                                                    </Col>
                                                    <Col xl={8} lg={8} md={8} sm={8} xs={8} className="text-center">
                                                        <img src="https://static.admirian.com/7e87676a-65e1-4cd7-ad1d-3437b0bdb799_mdi-light_comment.svg" width={24} height={24} className="m-auto" />
                                                        <PlayInformationText>{featuredVideo?.comments_count}</PlayInformationText>
                                                        <PlayText>Comment</PlayText>
                                                    </Col>
                                                    <Col xl={8} lg={8} md={8} sm={8} xs={8} className="text-center">
                                                        <img src="https://static.admirian.com/a29658b7-72d3-430e-8d59-2a98916c9c9d_mdi_eye.svg" width={24} height={24} className="m-auto" />
                                                        <PlayInformationText>{featuredVideo?.views_count}</PlayInformationText>
                                                        <PlayText>Views</PlayText>
                                                    </Col>
                                                </Row>
                                                <SpaceBetweenContainer className="w-100">
                                                    <MediaInfoText>Click through Rate</MediaInfoText>
                                                    <MediaInfoText style={{ color: '#0F0E1B' }}>{featuredVideo?.annotation_click_through_rate}</MediaInfoText>
                                                </SpaceBetweenContainer>
                                                <SpaceBetweenContainer className="w-100">
                                                    <MediaInfoText>Average View Duration</MediaInfoText>
                                                    <MediaInfoText style={{ color: '#0F0E1B' }}>{featuredVideo?.average_view_duration}</MediaInfoText>
                                                </SpaceBetweenContainer>
                                                <SpaceBetweenContainer className="w-100">
                                                    <MediaInfoText>Average View Percentage</MediaInfoText>
                                                    <MediaInfoText style={{ color: '#0F0E1B' }}>{featuredVideo?.average_view_percentage ? `${featuredVideo?.average_view_percentage.toFixed(2)}%` : '-'}</MediaInfoText>
                                                </SpaceBetweenContainer>
                                            </FeaturedMediaCard>
                                        </Col>
                                    )
                                })}

                            </Carousel>
                        </Row>
                    </>
                }


            </CreatorMediaContainer>
            <Row gutter={[16, 20]} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '24px' }}>
                {analyticsData?.featured_media?.most_viewed_video &&
                    <Col xl={8} lg={8} md={12} sm={24} xs={24}>
                        <FeaturedMediaCard style={{ padding: '24px', gap: '20px' }}>
                            <MostViewedText>
                                Most Viewed
                            </MostViewedText>
                            <div
                                className="w-100 cursor-pointer"
                                style={{ position: 'relative' }}
                                onClick={() => {
                                    window.open(`https://www.youtube.com/watch?v=${analyticsData?.featured_media?.most_viewed_video?.video_id}`)
                                }}
                            >
                                <img
                                    src={analyticsData?.featured_media?.most_viewed_video?.thumbnails?.high}
                                    className="w-100 cursor-pointer"
                                    height={160}
                                    style={{ borderRadius: '10px' }}
                                />
                                <img height={40} width={60} style={{ position: 'absolute', top: '40%', left: '40%' }} class="image2" src="https://static.admirian.com/f6a33fb9-23ac-4bfe-b7ff-a87a86093773_youtube.svg" />
                            </div>

                            <div className="text-center w-100">
                                <img src="https://static.admirian.com/f29a4bd9-b951-4b53-bcd4-cc5560d8bef2_profileVisit.svg" className="m-auto" height={48} width={48} style={{ border: 'unset', borderRadius: '50%' }} />
                                <FeaturedMediaText>{analyticsData?.featured_media?.most_viewed_video.views_count}</FeaturedMediaText>
                                <ViewedText>Audience Viewed</ViewedText>
                            </div>
                        </FeaturedMediaCard>
                    </Col>
                }
                {analyticsData?.featured_media?.most_liked_video &&
                    <Col xl={8} lg={8} md={12} sm={24} xs={24}>
                        <FeaturedMediaCard style={{ padding: '24px', gap: '20px' }}>
                            <MostViewedText>
                                Most liked
                            </MostViewedText>

                            <div
                                className="cursor-pointer w-100"
                                style={{ position: 'relative' }}
                                onClick={() => {
                                    window.open(`https://www.youtube.com/watch?v=${analyticsData?.featured_media?.most_liked_video?.video_id}`)
                                }}
                            >
                                <img src={analyticsData?.featured_media?.most_liked_video?.thumbnails?.high}
                                    className="w-100 cursor-pointer"
                                    height={160}
                                    style={{ borderRadius: '10px' }}
                                />
                                <img height={40} width={60} style={{ position: 'absolute', top: '40%', left: '40%' }} class="image2" src="https://static.admirian.com/f6a33fb9-23ac-4bfe-b7ff-a87a86093773_youtube.svg" />
                            </div>
                            <div className="text-center w-100">
                                <img src="https://static.admirian.com/51e89da2-ac4b-45e4-b35e-7412084e23d5_likes.svg" width={48} height={48} style={{ border: 'unset', borderRadius: '50%' }} />
                                <FeaturedMediaText>{analyticsData?.featured_media?.most_liked_video?.likes_count}</FeaturedMediaText>
                                <ViewedText>Audience Liked</ViewedText>
                            </div>
                        </FeaturedMediaCard>
                    </Col>
                }
                {analyticsData?.featured_media?.most_commented_video &&
                    <Col xl={8} lg={8} md={12} sm={24} xs={24}>
                        <FeaturedMediaCard style={{ padding: '24px', gap: '20px' }}>
                            <MostViewedText>
                                Most Commmented
                            </MostViewedText>

                            <div
                                className="cursor-pointer w-100"
                                style={{ position: 'relative' }}
                                onClick={() => {
                                    window.open(`https://www.youtube.com/watch?v=${analyticsData?.featured_media?.most_commented_video?.video_id}`)
                                }}
                            >
                                <img src={analyticsData?.featured_media?.most_commented_video?.thumbnails?.high}
                                    className="w-100 cursor-pointer"
                                    height={160}
                                    style={{ borderRadius: '10px' }}
                                />
                                <img height={40} width={60} style={{ position: 'absolute', top: '40%', left: '40%' }} class="image2" src="https://static.admirian.com/f6a33fb9-23ac-4bfe-b7ff-a87a86093773_youtube.svg" />
                            </div>

                            <div className="text-center w-100">
                                <img src="https://static.admirian.com/71043585-f913-4a1d-a116-913ed67a4092_comments.svg" width={48} height={48} style={{ border: 'unset', borderRadius: '50%' }} />
                                <FeaturedMediaText>{analyticsData?.featured_media?.most_commented_video?.comments_count}</FeaturedMediaText>
                                <ViewedText>Audience Commented</ViewedText>
                            </div>
                        </FeaturedMediaCard>
                    </Col>
                }
            </Row>
            <Row id="audienceDemographics" gutter={[16, 20]} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '24px', marginTop: '24px' }}>
                <Col span={24} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                    <CompanyLogoContainer>
                        <img
                            src={analyticsData?.profile_details?.thumbnails?.high}
                            alt="logo"
                            height={28}
                            width={28}
                            style={{
                                borderRadius: '50%',
                            }}
                        />
                        <img
                            src="https://static.admirian.com/657ef4b3-0905-4fc4-ad7f-3bedec4362bc_youtube-bg-white.svg"
                            alt="logo"
                            height={18}
                            width={18}
                            style={{
                                borderRadius: '50%',
                                marginLeft: '-8px'
                            }}
                        />
                    </CompanyLogoContainer>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <CardHeadingAnalytics>
                            Audience Demographics
                        </CardHeadingAnalytics>
                    </Col>
                </Col>
                <Col xl={8} lg={8} md={12} sm={24} xs={24}>
                    <FeaturedMediaCard style={{ gap: '0' }}>
                        <MostViewedText>
                            Geographic Location
                        </MostViewedText>
                        <DemographicText>Top audience locations</DemographicText>
                        <div className="w-100" style={{ height: data?.length > 0 ? "300px" : 'inherit' }}>
                            {LocationBarData?.length > 0 ?
                                <ResponsiveBar
                                    data={LocationBarData}
                                    keys={[
                                        'Percentage'
                                    ]}
                                    indexBy="city"
                                    layout="horizontal"
                                    margin={{ top: 20, right: 20, bottom: 40, left: 60 }}
                                    padding={0.3}
                                    minValue={0}
                                    maxValue={100}
                                    valueScale={{ type: 'linear' }}
                                    indexScale={{ type: 'band', round: true }}
                                    colors={["#F86624"]}
                                    defs={[
                                        {
                                            id: 'dots',
                                            type: 'patternDots',
                                            background: 'inherit',
                                            color: '#38bcb2',
                                            size: 4,
                                            padding: 1,
                                            stagger: true
                                        },
                                        {
                                            id: 'lines',
                                            type: 'patternLines',
                                            background: 'inherit',
                                            color: '#eed312',
                                            rotation: -45,
                                            lineWidth: 6,
                                            spacing: 10
                                        }
                                    ]}
                                    fill={[
                                        {
                                            match: {
                                                id: 'fries'
                                            },
                                            id: 'dots'
                                        },
                                        {
                                            match: {
                                                id: 'sandwich'
                                            },
                                            id: 'lines'
                                        }
                                    ]}
                                    borderColor={{
                                        from: 'color',
                                        modifiers: [
                                            [
                                                'darker',
                                                1.6
                                            ]
                                        ]
                                    }}
                                    axisTop={null}
                                    axisRight={null}
                                    axisBottom={{
                                        tickSize: 5,
                                        tickPadding: 5,
                                        tickRotation: 0,
                                        legendOffset: 32,
                                        format: e => Math.floor(e) === e && e
                                    }}
                                    axisLeft={{
                                        tickSize: 2,
                                        tickPadding: 5,
                                        tickRotation: -45,
                                        legendRotation: 90,
                                        legendOffset: -40,
                                    }}
                                    enableLabel={false}
                                    enableGridY={false}
                                    labelSkipWidth={12}
                                    labelSkipHeight={12}
                                    labelTextColor={{
                                        from: 'color',
                                        modifiers: [
                                            [
                                                'darker',
                                                1.6
                                            ]
                                        ]
                                    }}
                                    legends={[]}
                                    role="application"
                                    ariaLabel="Nivo bar chart demo"
                                    barAriaLabel={e => e.id + ": " + e.formattedValue + " in country: " + e.indexValue}
                                />
                                :
                                <NoDataAvailableContainer>
                                    No Data Available
                                </NoDataAvailableContainer>
                            }
                        </div>
                    </FeaturedMediaCard>
                </Col>
                <Col xl={8} lg={8} md={12} sm={24} xs={24}>
                    <FeaturedMediaCard style={{ gap: '0' }}>
                        <MostViewedText>
                            Audience Age
                        </MostViewedText>
                        <DemographicText>Age-wise audience distribution</DemographicText>
                        <div className="w-100" style={{ height: data?.length > 0 ? "300px" : 'inherit' }}>
                            {AgeBarData?.length > 0 ?
                                <ResponsivePie
                                    data={AgeBarData}
                                    margin={{ top: 0, right: 80, bottom: 80, left: 90 }}
                                    innerRadius={0.5}
                                    padAngle={0.7}
                                    cornerRadius={3}
                                    activeOuterRadiusOffset={8}
                                    borderWidth={1}
                                    borderColor={{
                                        from: 'color',
                                        modifiers: [
                                            [
                                                'darker',
                                                0.2
                                            ]
                                        ]
                                    }}
                                    arcLinkLabelsSkipAngle={10}
                                    arcLinkLabelsTextColor="#333333"
                                    arcLinkLabelsThickness={2}
                                    arcLinkLabelsColor={{ from: 'color' }}
                                    enableArcLabels={false}
                                    arcLabelsSkipAngle={10}
                                    arcLabelsTextColor={{
                                        from: 'color',
                                        modifiers: [
                                            [
                                                'darker',
                                                2
                                            ]
                                        ]
                                    }}
                                    defs={[
                                        {
                                            id: 'dots',
                                            type: 'patternDots',
                                            background: 'inherit',
                                            color: 'rgba(255, 255, 255, 0.3)',
                                            size: 4,
                                            padding: 1,
                                            stagger: true
                                        },
                                        {
                                            id: 'lines',
                                            type: 'patternLines',
                                            background: 'inherit',
                                            color: 'rgba(255, 255, 255, 0.3)',
                                            rotation: -45,
                                            lineWidth: 6,
                                            spacing: 10
                                        }
                                    ]}
                                    fill={[
                                        {
                                            match: {
                                                id: 'ruby'
                                            },
                                            id: 'dots'
                                        },
                                        {
                                            match: {
                                                id: 'c'
                                            },
                                            id: 'dots'
                                        },
                                        {
                                            match: {
                                                id: 'go'
                                            },
                                            id: 'dots'
                                        },
                                        {
                                            match: {
                                                id: 'python'
                                            },
                                            id: 'dots'
                                        },
                                        {
                                            match: {
                                                id: 'scala'
                                            },
                                            id: 'lines'
                                        },
                                        {
                                            match: {
                                                id: 'lisp'
                                            },
                                            id: 'lines'
                                        },
                                        {
                                            match: {
                                                id: 'elixir'
                                            },
                                            id: 'lines'
                                        },
                                        {
                                            match: {
                                                id: 'javascript'
                                            },
                                            id: 'lines'
                                        }
                                    ]}
                                    legends={[
                                        {
                                            anchor: 'left',
                                            direction: 'column',
                                            justify: false,
                                            translateX: -85,
                                            translateY: 120,
                                            itemWidth: 70,
                                            itemHeight: 30,
                                            itemsSpacing: 0,
                                            symbolSize: 20,
                                            itemDirection: 'left-to-right',
                                        }
                                    ]}
                                    colors={['#1551EA', '#F86624', '#F9C80E', '#17A600']}
                                />
                                :
                                <NoDataAvailableContainer>
                                    No Data Available
                                </NoDataAvailableContainer>
                            }
                        </div>
                    </FeaturedMediaCard>
                </Col>
                <Col xl={8} lg={8} md={12} sm={24} xs={24}>
                    <FeaturedMediaCard style={{ gap: '0' }}>
                        <MostViewedText>Gender</MostViewedText>
                        <DemographicText>Gender-wise audience distribution</DemographicText>
                        <div className="w-100" style={{ height: data?.length > 0 ? "300px" : 'inherit' }}>
                            {GenderBarData?.length > 0 ?
                                <ResponsivePie
                                    data={GenderBarData}
                                    margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                                    innerRadius={0.5}
                                    padAngle={0.7}
                                    cornerRadius={3}
                                    activeOuterRadiusOffset={8}
                                    borderWidth={1}
                                    borderColor={{
                                        from: 'color',
                                        modifiers: [
                                            [
                                                'darker',
                                                0.2
                                            ]
                                        ]
                                    }}
                                    colors={['#1551EA', '#F86624', '#F9C80E', '#17A600']}
                                    arcLinkLabelsSkipAngle={10}
                                    arcLinkLabelsTextColor="#333333"
                                    arcLinkLabelsThickness={2}
                                    arcLinkLabelsColor={{ from: 'color' }}
                                    enableArcLabels={false}
                                    arcLabelsSkipAngle={10}
                                    arcLabelsTextColor={{
                                        from: 'color',
                                        modifiers: [
                                            [
                                                'darker',
                                                2
                                            ]
                                        ]
                                    }}
                                    defs={[
                                        {
                                            id: 'dots',
                                            type: 'patternDots',
                                            background: 'inherit',
                                            color: 'rgba(255, 255, 255, 0.3)',
                                            size: 4,
                                            padding: 1,
                                            stagger: true
                                        },
                                        {
                                            id: 'lines',
                                            type: 'patternLines',
                                            background: 'inherit',
                                            color: 'rgba(255, 255, 255, 0.3)',
                                            rotation: -45,
                                            lineWidth: 6,
                                            spacing: 10
                                        }
                                    ]}
                                    fill={[
                                        {
                                            match: {
                                                id: 'ruby'
                                            },
                                            id: 'dots'
                                        },
                                        {
                                            match: {
                                                id: 'c'
                                            },
                                            id: 'dots'
                                        },
                                        {
                                            match: {
                                                id: 'go'
                                            },
                                            id: 'dots'
                                        },
                                        {
                                            match: {
                                                id: 'python'
                                            },
                                            id: 'dots'
                                        },
                                        {
                                            match: {
                                                id: 'scala'
                                            },
                                            id: 'lines'
                                        },
                                        {
                                            match: {
                                                id: 'lisp'
                                            },
                                            id: 'lines'
                                        },
                                        {
                                            match: {
                                                id: 'elixir'
                                            },
                                            id: 'lines'
                                        },
                                        {
                                            match: {
                                                id: 'javascript'
                                            },
                                            id: 'lines'
                                        }
                                    ]}
                                    legends={[
                                        {
                                            anchor: 'bottom-left',
                                            direction: 'row',
                                            justify: false,
                                            translateX: -80,
                                            translateY: 55,
                                            itemWidth: 80,
                                            itemHeight: 20,
                                            itemsSpacing: 0,
                                            symbolSize: 20,
                                            itemDirection: 'left-to-right'
                                        }
                                    ]}
                                />
                                :
                                <div style={{ display: 'flex', justifyContent: 'center', color: 'red', fontSize: '20px', fontWeight: 600 }}>
                                    No Data Available
                                </div>
                            }
                        </div>
                    </FeaturedMediaCard>
                </Col>
            </Row>

            <Row id="audienceEngagement" gutter={[16, 20]} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '24px', marginTop: '24px' }}>
                <Col xl={24} lg={24} md={24} sm={24} xs={24} style={{ display: 'flex', alignItems: 'center' }}>
                    <CompanyLogoContainer>
                        <img
                            src={analyticsData?.profile_details?.thumbnails?.high}
                            alt="logo"
                            height={28}
                            width={28}
                            style={{
                                borderRadius: '50%',
                            }}
                        />
                        <img
                            src="https://static.admirian.com/657ef4b3-0905-4fc4-ad7f-3bedec4362bc_youtube-bg-white.svg"
                            alt="logo"
                            height={18}
                            width={18}
                            style={{
                                borderRadius: '50%',
                                marginLeft: '-8px'
                            }}
                        />
                    </CompanyLogoContainer>
                    <CardHeadingAnalytics>
                        Audience Engagement
                    </CardHeadingAnalytics>
                </Col>
                <Col xl={8} lg={8} md={12} sm={24} xs={24}>
                    <FeaturedMediaCard>
                        <MostViewedText>Subscriber Interaction</MostViewedText>
                        <div className="w-100 text-center">
                            <img src="https://static.admirian.com/d4264d01-db86-4dd7-a3c7-aca5b73f0560_sharedshared.svg" className="w-100" alt="shared" />
                            <AudienceCountText>{analyticsData?.audience_engagement?.shares_count}</AudienceCountText>
                            <ShareInformationText>Lifetime Video Share Count</ShareInformationText>
                        </div>
                    </FeaturedMediaCard>
                </Col>
                <Col xl={16} lg={16} md={12} sm={24} xs={24}>
                    <FeaturedMediaCard>
                        <MostViewedText>Comments</MostViewedText>
                        <DemographicText>Top engaging comments</DemographicText>
                        <Row gutter={[16, 20]} style={{ maxHeight: '300px', overflowX: 'scroll' }}>
                            {analyticsData?.audience_engagement?.top_comments?.map((commentData, index) => {
                                return (
                                    <Col xl={24} lg={24} md={24} sm={24} xs={24} className="w-100" key={index}>
                                        <Row gutter={[16, 20]}>
                                            <Col xl={2} lg={2} md={2} sm={4} xs={4}>
                                                <img src={commentData?.author_profile_img_url} height={32} width={32} style={{ borderRadius: '50%' }} />
                                            </Col>
                                            <Col xl={22} lg={22} md={22} sm={20} xs={20} style={{ padding: 'unset' }}>
                                                <UserNameText>{commentData?.author_display_name ?? '@anonymous'} <PublishedInformation>{commentData?.published_at}</PublishedInformation></UserNameText>
                                                <DisplayText>
                                                    {commentData?.text_display}
                                                </DisplayText>
                                                <div style={{ fontWeight: 500 }}>
                                                    {commentData?.like_count} <span style={{ fontWeight: 400, opacity: '0.5', paddingRight: 4 }}>Likes </span>
                                                    {commentData?.reply_count} <span style={{ fontWeight: 400, opacity: '0.5' }}>Reply </span>
                                                </div>
                                            </Col>
                                        </Row>

                                    </Col>
                                )
                            })
                            }
                        </Row>
                    </FeaturedMediaCard>
                </Col>
            </Row>

            <DensityContainer id="postDensityyt" style={{ height: data?.length > 0 ? '300px' : '100px' }}>
                <FlexContainer style={{ alignItems: 'center' }}>
                    <CompanyLogoContainer>
                        <img
                            src={analyticsData?.profile_details?.thumbnails?.high}
                            width={28}
                            height={28}
                            alt="logo"
                            style={{
                                borderRadius: '50%',
                            }}
                        />
                        <img
                            src="https://static.admirian.com/657ef4b3-0905-4fc4-ad7f-3bedec4362bc_youtube-bg-white.svg"
                            alt="logo"
                            height={18}
                            width={18}
                            style={{
                                borderRadius: '50%',
                                marginLeft: '-8px'
                            }}
                        />
                    </CompanyLogoContainer>
                    <Col>
                        <MostViewedText>
                            Post Density
                        </MostViewedText>
                        <DemographicText>
                            Most posted day of week
                        </DemographicText>
                    </Col>
                </FlexContainer>

                <div className="w-100" style={{ height: data?.length > 0 ? "300px" : 'inherit' }}>
                    {BarData?.length > 0 ?
                        <ResponsiveBar
                            data={BarData}
                            keys={[
                                'Post Density',
                            ]}
                            indexBy="day"
                            margin={isMobile ? { top: 50, right: 10, bottom: 50, left: 20 } : { top: 50, right: 130, bottom: 50, left: 60 }}
                            padding={0.3}
                            valueScale={{ type: 'linear' }}
                            indexScale={{ type: 'band', round: true }}
                            colors={["#F86624"]}
                            defs={[
                                {
                                    id: 'dots',
                                    type: 'patternDots',
                                    background: 'inherit',
                                    color: '#38bcb2',
                                    size: 4,
                                    padding: 1,
                                    stagger: true
                                },
                                {
                                    id: 'lines',
                                    type: 'patternLines',
                                    background: 'inherit',
                                    color: '#eed312',
                                    rotation: -45,
                                    lineWidth: 6,
                                    spacing: 10
                                }
                            ]}
                            fill={[
                                {
                                    match: {
                                        id: 'fries'
                                    },
                                    id: 'dots'
                                },
                                {
                                    match: {
                                        id: 'sandwich'
                                    },
                                    id: 'lines'
                                }
                            ]}
                            borderColor={{
                                from: 'color',
                                modifiers: [
                                    [
                                        'darker',
                                        1.6
                                    ]
                                ]
                            }}
                            axisTop={null}
                            axisRight={null}
                            axisBottom={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legendPosition: 'middle',
                                legendOffset: 32
                            }}
                            axisLeft={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legendPosition: 'middle',
                                legendOffset: -40,
                                format: e => Math.floor(e) === e && e
                            }}
                            enableLabel={false}
                            enableGridY={false}
                            labelSkipWidth={12}
                            labelSkipHeight={12}
                            labelTextColor={{
                                from: 'color',
                                modifiers: [
                                    [
                                        'darker',
                                        1.6
                                    ]
                                ]
                            }}
                            legends={[]}
                            role="application"
                            ariaLabel="Nivo bar chart demo"
                            barAriaLabel={e => e.id + ": " + e.formattedValue + " in country: " + e.indexValue}
                        />
                        :
                        <NoDataAvailableContainer>
                            No Data Available
                        </NoDataAvailableContainer>
                    }
                </div>
            </DensityContainer>

            <DensityContainer id="activityHeatMapyt" style={{ height: data?.length > 0 ? '400px' : '100px' }}>
                <FlexContainer style={{ alignItems: 'center' }}>
                    <CompanyLogoContainer>
                        <img
                            src={analyticsData?.profile_details?.thumbnails?.high}
                            alt="logo"
                            width={28}
                            height={28}
                            style={{
                                borderRadius: '50%',
                            }}
                        />
                        <img
                            src="https://static.admirian.com/657ef4b3-0905-4fc4-ad7f-3bedec4362bc_youtube-bg-white.svg"
                            alt="logo"
                            width={18}
                            height={18}
                            style={{
                                borderRadius: '50%',
                                marginLeft: '-8px'
                            }}
                        />
                    </CompanyLogoContainer>
                    <Col>
                        <MostViewedText>
                            Activity Heat-map
                        </MostViewedText>
                        <DemographicText>
                            Most Active time of week
                        </DemographicText>
                    </Col>
                </FlexContainer>
                <div className="w-100" style={{ height: data?.length > 0 ? "400px" : 'inherit' }}>
                    {data?.length > 0 ?
                        <ResponsiveHeatMap
                            data={data}
                            margin={isMobile ? { top: 60, right: 20, bottom: 120, left: 20 } : { top: 60, right: 90, bottom: 120, left: 90 }}
                            enableLabels={false}
                            axisTop={null}
                            axisBottom={{
                                tickSize: 5,
                                tickPadding: 5,
                                legend: '',
                                legendPosition: 'middle',
                                legendOffset: 36
                            }}
                            axisLeft={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: '',
                                legendPosition: 'middle',
                                legendOffset: -72
                            }}
                            colors={{
                                type: 'sequential',
                                scheme: 'blues',
                            }}
                            emptyColor="#ffffff"
                            inactiveOpacity={0.6}
                            borderColor="black"
                            hoverTarget="cell"
                            animate={false}
                        />
                        :
                        <NoDataAvailableContainer>
                            No Data Available
                        </NoDataAvailableContainer>
                    }
                </div>
            </DensityContainer>

        </CreatorAnalyticsContainer>
    )

}

export default CreatorYoutubeAnalytics