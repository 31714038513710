import styled from 'styled-components';

export const PaymentModeContainer = styled.div`
    background-color: #EBF4FF;
    padding: 20px 16px;
    width: 350px;
    margin-left: 16px;
    text-align: center;
`;

export const ScanContentHeading = styled.h3`
    color: #010043;
    font-size: 14px;
    font-weight: 500;
    margin: 0px;
`;

export const ScannerContainer = styled.div`
    border-radius: 10px;
    margin-top: 16px;
    background: #fff;
    text-align: center;
    padding: 20px;
`;

export const UpiIdContainer = styled.div`
    padding: 16px 24px;
    margin-top: 24px;
    background: #F6FAFF;
    border-radius: 12px;
`;

export const UpiIdContentDescription = styled.p`
    font-size: 12px;
    color: #494785;
    margin-top: 4px;
    margin-bottom: 0px;
    text-align: left;
`;

export const UpiId = styled.div`
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #0F0E1B;
`;

export const UpiIdCopy = styled.div`
    font-size: 12px;
    font-weight: 400;
    color: #1551EA;
    cursor: pointer;
`;

export const NeftModeContainer = styled.div`
    background-color: #EBF4FF;
    padding: 20px 16px;
    height: 100%;
    width: 50%;
    margin-left: 12px;
`;

export const NeftContentHeading = styled.h3`
    font-size: 14px;
    font-weight: 500;
    color: #0F0E1B;
    margin-top: 0px;
    margin-bottom: 0px;
`;

export const NeftContentDescription = styled.p`
    font-size: 12px;
    color: #494785;
    margin-top: 4px;
    margin-bottom: 8px;
`;

export const BankDetailsDescription = styled.div`
    font-size: 14px;
    font-weight: 400;
    color: #0F0E1B;
`;

export const BankDetailsContent = styled.div`
    margin-top: 40px;
`;
