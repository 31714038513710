import React from 'react';
import { Modal } from 'antd';
import { Heading } from '../../utils/common';
import { PaymentDescription, PaymentHeading, PaymentListContent } from './style';

const listPaymentInformation = [
    "Once payment is made, Payment will be on hold with Admirian.", 
    "Admirian will release the payment after the content is delivered and approved by brand.",
    "Full Payment will be refunded if influencer rejected brand’s proposal.",
    "80% of Payment will be refunded if brand didn’t like the quality of the content provided by the influencer after few iteration.",
    "If in case of any discrepancy brand can raise dispute and Admirian will contact you to solve the issue."
]


const HowPaymentsWorksModal = ({ isHowPaymentsWorksModal, setIsHowPaymentsWorksModal }) => {

    const handleCancel = () => {
        setIsHowPaymentsWorksModal(false);
    };
    return (
        <Modal 
            title={<Heading>How Payment works</Heading>} 
            open={isHowPaymentsWorksModal} 
            footer={false} 
            onCancel={handleCancel}
        >
           <PaymentHeading>UPI Payment</PaymentHeading>
           <PaymentDescription>Scan QR code and complete the payment to send proposal</PaymentDescription>
           <PaymentHeading>NEFT Payment</PaymentHeading>
           <PaymentDescription>After completing payment paste the transaction id and click verify payments once payment is verified, Proposal will be sent to added influencers</PaymentDescription>
           <PaymentHeading>What happens after payment</PaymentHeading>
           <PaymentListContent>
            {
                listPaymentInformation.map((payInfo, index) => {
                    return(
                        <li key={index} style={{marginBottom: 6}}>{payInfo}</li>
                    )
                })
            }
           </PaymentListContent>
           <PaymentDescription style={{marginTop: 12,marginBottom: 0}}>For more payment related queries please contact <span style={{color: '#1551EA'}}>sales@admirian.com</span></PaymentDescription>
        </Modal>
    );
};
export default HowPaymentsWorksModal;