import React, { Fragment, useState } from 'react';
import { TabContainer, Container, PaginationContainer } from './style';
import { Tabs, Select, Space, Radio } from 'antd';
import CampaignDetails from './CampaignDetails';
import { useGetAllCampaignsQuery } from '../../../services/manageCampaignsApi';
import NoDataFound from '../../NoDataFound';
import { Pagination } from 'antd';

const { TabPane } = Tabs

const optionValue = [
    {
        value: 'BROADCAST',
        label: 'Posted Campaigns',
    },
    {
        value: 'INFLUENCER',
        label: 'Select Influencer Campaigns',
    },
]


const AllCampaigns = () => {
    const [campaignStatus, setCampaignStatus] = useState("ALL");
    const [selectedCampaignType, setSelectedCampaignType] = useState(null);
    const [page, setPage] = useState(1);
    const { data: { campaigns = [], total_pages } = {}, isLoading, isError, isSuccess, error, refetch } = useGetAllCampaignsQuery({ status: campaignStatus, campaignType: selectedCampaignType, page });

    const handleCampaignStatusChange = (value) => {
        setCampaignStatus(value)
    }

    const handleCampaignChange = (campaignValue) => {
        setSelectedCampaignType(campaignValue);
    }

    const handleTabChange = (value) => {
        setCampaignStatus(value);
        setSelectedCampaignType(null);
    }

    const onPageChange = (pageValue) => {
        setPage(pageValue);
    };

    return (
        <Container>
            <Tabs
                defaultActiveKey="All"
                onChange={handleTabChange}
            >
                <TabPane
                    key='ALL'
                    tab="All"
                >
                    <TabContainer>
                        <Select
                            placeholder="Select Campaign Type"
                            style={{
                                width: 215,
                            }}
                            allowClear
                            value={selectedCampaignType}
                            onChange={(campaignValue) => handleCampaignChange(campaignValue)}
                            options={optionValue}
                        />
                        <div style={{ marginTop: 20 }}>
                            <Radio.Group defaultValue="ALL" buttonStyle="solid" onChange={(e) => handleCampaignStatusChange(e.target.value)}>
                                <Radio.Button value="ALL">All</Radio.Button>
                                <Radio.Button value="IN_PROGRESS">In Progress</Radio.Button>
                                <Radio.Button value="LIVE">Live</Radio.Button>
                                <Radio.Button value="COMPLETED">Completed</Radio.Button>
                            </Radio.Group>
                        </div>
                        {
                            campaigns.length ?
                                <>
                                    {
                                        campaigns.map((campaignData) => {
                                            return (
                                                <Fragment key={campaignData.id}>
                                                    <CampaignDetails campaignData={campaignData} />
                                                </Fragment>
                                            )
                                        })
                                    }
                                </> :
                                <NoDataFound tabSelection={campaignStatus}/>
                        }
                    </TabContainer>

                </TabPane>
                <TabPane
                    key='DRAFT'
                    tab="Draft"
                >
                    <TabContainer>
                        {
                            campaigns.length ?
                            <>
                                {
                                    campaigns.map((campaignData) => {
                                        return (
                                            <Fragment key={campaignData.id}>
                                                <CampaignDetails campaignData={campaignData} />
                                            </Fragment>
                                        )
                                    })
                                }
                            </> :
                            <NoDataFound tabSelection={campaignStatus}/>
                        }
                    </TabContainer>
                </TabPane>
            </Tabs>
            {
                !!campaigns.length && (
                    <PaginationContainer>
                        <Pagination current={page} onChange={onPageChange} pageSize={10} total={total_pages * 10} showSizeChanger={false} />
                    </PaginationContainer>
                )
            }
        </Container>
    )
}

export default AllCampaigns;