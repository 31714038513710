import {  Form, Input, notification} from "antd";
import React, { useState } from "react";
import { ChangePasswordButton, FormContainer } from "./style";
import { useResetUpdatedPasswordInitiatedMutation } from "../../../services/resetPasswordApi";
import { useNavigate, useSearchParams } from "react-router-dom";

const NewPasswordForm = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const uidb64 = searchParams.get("uidb64") || "";
  const token = searchParams.get("token") || "";
  const [resetUpdatedPasswordInitiated] = useResetUpdatedPasswordInitiatedMutation();
  const [passwordButtonLoading, setPasswordButtonLoading] = useState(false);

  const [form] = Form.useForm();


  const handleSubmit = async (values) => {
    const postdata = {password : values.password, uidb64: uidb64, token: token  };
    const response = await resetUpdatedPasswordInitiated(postdata);
    setPasswordButtonLoading(true); 
    try {
     if (response && !response.error) {
         notification.success({
             message: response?.data?.message
         })
         setPasswordButtonLoading(false);
         navigate('/password-changed-successful')
     } else if (response.error) {
         notification.error({
             message: response?.error?.data?.message
         })
     }
     setPasswordButtonLoading(false);
 } catch (error) {
     notification.error({
         message: error?.response?.error?.data?.message
     })
     setPasswordButtonLoading(false);
 }
  };

  return (
    <FormContainer>
      <Form className="w-100" layout="vertical" form={form} onFinish={handleSubmit}>
        <Form.Item
          name="password"
          label="New Password"
          rules={[
            {
              required: true,
              message: "Please input your password!"
            }
          ]}
          >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name="confirm"
          label="Confirm Password"
          dependencies={["password"]}
          rules={[
            {
              required: true,
              message: "Please confirm your password!"
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("The new password that you entered do not match!"));
              }
            })
          ]}>
          <Input.Password />
        </Form.Item>
        <Form.Item style={{ marginBottom: "24px" }}>
          <ChangePasswordButton
            htmlType="submit" loading={passwordButtonLoading}>
            <span style={{ fontWeight: 700, fontSize: "16px", lineHeight: "24px" }}>Change Password</span>
            {/* <span style={{fontSize : '12px',lineHeight: '14px'}}>You’re one click way</span> */}
          </ChangePasswordButton>
        </Form.Item>
      </Form>
    </FormContainer>
  );
};

export default NewPasswordForm;
