import styled from 'styled-components';

export const PaymentSuccessfulContainer = styled.div`
    width: 600px;
    text-align: center;
    padding: 0px;
`;

export const SuccessfulHeading = styled.h1`
    font-size: 28px;
    font-weight: 600;
    background: var(--Brand-Gradient-1, linear-gradient(97deg, #4E4BEE -1.75%, #F86624 112.67%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`;

export const CampaignContentContainer = styled.div`
    background-color: #EBF4FF;
    padding: 12px;
    text-align: left;
`;

export const CampaignContentDescription = styled.p`
    font-size: 12px;
    color: #494785;
    margin-top: 4px;
    margin-bottom: 8px;
`;

export const CampaignDetailsDescription = styled.div`
    font-size: 14px;
    font-weight: 400;
    color: #0F0E1B;
`;