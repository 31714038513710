import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Container, SubHeading, Stepper, CloseButton, BrandBox, NextButton, PreviousButton, CampaignStepper, StepBox, StepNumber } from './style';
import { HeaderStyle, BrandLogo, FlexContainer, Heading, MainButton, SpaceBetweenContainer } from '../../utils/common';
import { SearchOutlined, LeftOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { Layout, Steps, Button, message, notification, Typography } from 'antd';
import BasicDetails from '../../components/ManageCampaigns/createCampaign/BasicDetails';
import { useCampaignInitiatedMutation, useGetAllDeliverablesQuery } from '../../services/manageCampaignsApi';
import AddDeliverables from '../../components/ManageCampaigns/AddDeliverables';
import { useDispatch, useSelector } from 'react-redux';
import { storeManageCampaign } from '../../components/ManageCampaigns/Reducers/ManageCampaignReducer';

const { Header, Footer, Sider, Content } = Layout;


const CreateCampaigns = () => {
  const location = useLocation();
  const { selectedCardId = ""} = useParams();
  const [current, setCurrent] = useState(0);
  const [addInfluencerLoading, setAddInfluencerLoading] = useState(false);
  const [selectedCard, setSelectedCard] = useState(102);
  const [bannerFile, setBannerFile] = useState([]);
  const [campaignName, setCampaignName] = useState('');
  const [briefDescription, setBriefDescription] = useState('');
  const [campaignObjective, setCampaignObjective] = useState(111);
  const [campaignCategory, setCampaignCategory] = useState(121);
  const [deliveryMode, setDeliveryMode] = useState(141);
  const [minValue, setMinValue] = useState('');
  const [maxValue, setMaxValue] = useState('');
  const [genderSelected, setGenderSelected] = useState("male");
  const [inFluencerFollowerCount, setInFluencerFollowerCount] = useState("50");

  const [campaignInitiatedMutation] = useCampaignInitiatedMutation();
  const {campaignId} = useParams();
  const { data = [] } = useGetAllDeliverablesQuery({ campaignId });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === "/create-campaign") {
      setCurrent(0);
    } else {
      setCurrent(1);
    }
  }, [])

  const steps = [
    {
      title: 'Basic Details',
      content: <BasicDetails selectedCard={selectedCard} setSelectedCard={setSelectedCard} setBannerFile={setBannerFile} setCampaignName={setCampaignName} setBriefDescription={setBriefDescription} setCampaignObjective={setCampaignObjective} setCampaignCategory={setCampaignCategory} inFluencerFollowerCount={inFluencerFollowerCount} setInFluencerFollowerCount={setInFluencerFollowerCount} genderSelected={genderSelected} setGenderSelected={setGenderSelected} setDeliveryMode={setDeliveryMode} setMinValue={setMinValue} setMaxValue={setMaxValue} minValue={minValue} maxValue={maxValue} />,
    },
    {
      title: 'Add Deliverables',
      content: <AddDeliverables selectedCard={selectedCard} />,
    }
  ];

  const handleContinue = () => {
    setCurrent(current + 1);
  };


  const handleCreateCampaign = async () => {
    try {
      setAddInfluencerLoading(true);
      if (Number(minValue) > Number(maxValue)) {
        setAddInfluencerLoading(false);
        notification.error({ message: "min budget cannot be greater than max budget" })
      } else {
        const response = await campaignInitiatedMutation({
          selectedCard,
          bannerFile,
          campaignName,
          briefDescription,
          campaignObjective,
          campaignCategory,
          deliveryMode,
          minValue,
          maxValue,
          genderSelected,
          inFluencerFollowerCount
        });
        if (response && !response.error) {
          setAddInfluencerLoading(false);
          notification.success({
            message: 'Campaign details saved. Next, add campaign deliverables'
          })
          dispatch(storeManageCampaign(response?.data?.campaign_id));
          handleContinue();
          navigate(`/add-deliverables/${response?.data?.campaign_id}/${selectedCard}`);
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        } else if (response.error) {
          setAddInfluencerLoading(false);
          notification.error({
            message: response?.error?.data?.message
          })
        }
      }
    } catch (error) {
      setAddInfluencerLoading(false);
      notification.error({
        message: error?.response?.error?.data?.message
      })
    }
  }

  const HeaderStyle = {
    padding: '0px 70px',
    background: '#Fff',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '70px',
    color: '#000',
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 10
  };

  const prev = () => {
      navigate(-1);
  };

  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  return (
    <Container>
      <Layout>
        <Header style={HeaderStyle}>
          <BrandBox>
            <BrandLogo />
          </BrandBox>
          {/* <Stepper type="navigation" percent={80} current={current} items={items} /> */}
          <CampaignStepper>
            {
              items.map((item, index) => {
                return (<StepBox key={index} className={index === current ? 'stepper-selected' : ""}>
                   <StepNumber style={index === current ? {border: '1.5px solid #4E4BEE', color: '#4E4BEE'} : {border: '1.5px solid #000'}}>{index + 1}</StepNumber>{item.title}
                </StepBox>)
              })
            }
          </CampaignStepper>
          {/* <CloseButton className='close-butn'>Close</CloseButton> */}
        </Header>
        <Content style={{ marigiTop: 100 }}>
          <div>{steps[current].content}</div>
        </Content>
        <Footer>
          <SpaceBetweenContainer className='w-100'>
            {(current > 0 || current < steps.length - 1) && (
              <PreviousButton onClick={() => prev()}>
                <LeftOutlined />Back
              </PreviousButton>
            )}
            {current === 0 && (
              <NextButton type="primary" disabled={!campaignName || !briefDescription || !minValue || !maxValue} loading={addInfluencerLoading} onClick={handleCreateCampaign}>
                Add Deliverables
              </NextButton>
            )}
            {(current === 1 && selectedCardId == 102) && (
              <Link to={`/initial-influencer-details/${campaignId}`}>
                <NextButton type="secondary" disabled={!data.length}>
                  Select Influencers
                </NextButton>
              </Link>
            )}
            {(current === 1 && selectedCardId == 101) && (
              <Link to={`/campaign-summary/${campaignId}`}>
                <NextButton type="secondary" disabled={!data.length}>
                  Continue
                </NextButton>
              </Link>
            )}
          </SpaceBetweenContainer>
        </Footer>
      </Layout>
    </Container>
  )
}

export default CreateCampaigns;