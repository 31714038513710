import styled from 'styled-components';

export const Container = styled.div`
    padding: 20px;
    background : #FFFFFF;
    border-radius: 4px;
    margin : 24px 32px;
    width: 100%;

    .influencer-block {
        border: 1px solid #D8D7EA;
        border-radius: 10px;
        padding: 16px 20px;
    }

    .deliverable-selected {
        border: 1px solid #2C2B50;
    }
`
export const ListItemHeading = styled.p`
    margin: 0px;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: #494785; 
    padding-bottom: 4px;
`;
export const ListItemDescription = styled.p`
    margin: 0px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #0F0E1B; 
`;

export const DeliverableContainer = styled.div`
    margin-top: 12px;
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
    padding: 12px;
    cursor: pointer;
    border: 1px solid #D8D7EA;
    .ant-tag{
        margin-inline-end: 0px;
    }
`;

export const DeliverableText = styled.div`
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
    padding-bottom: 6px;
`;

export const DeliverableType = styled.div`
    font-size: 12px;
    color: #2C2B50;
`;

export const DeliverableFullDetailsBox = styled.div`
    border-radius: 10px;
    margin-top: 12px;
    padding: 12px;
    border: 1px solid #D8D7EA;
`;

export const FileDataContainer = styled.div`
    border-radius: 10px;
    border: 1px solid #D8D7EA;
    height: 240px;
    display: flex;
    justify-content: center;
`;

export const NoDataText = styled.div`
    color: #010043;
    font-size: 12px;
    font-weight: 400;
    padding-top: 4px;
    opacity: 0.5;
`;

export const GoLiveContainer = styled.div`
    background: #1551EA;
    color: #fff;
    font-size: 14px;
    border-radius: 10px;
    padding: 12px;
    margin-bottom: 12px;
`;

export const GoLiveSubHeading = styled.p`
   font-size: 12px;
`;

export const LiveContainer = styled.div`
    background: #17A600;
    color: #fff;
    font-size: 14px;
    border-radius: 10px;
    padding: 12px;
    margin-bottom: 12px;
`;

export const LiveLink = styled.div`
    background: #fff;
    border-radius: 10px;
    padding: 12px;
    display: flex;
    justify-content: space-between;
    color: #2C2B50;
    font-size: 14px;
`;

export const CopyText = styled.div`
    font-size: 12px;
    font-weight: 400;
    color: #1551EA;
    cursor: pointer;
`;
