import { Modal } from "antd";
import React from "react";
import { CancelModalButton, ConfirmModalButton, ModalContainer, ModalDetail, ModalHeading } from "./style";

const ConfirmModal = ({isVisible,setIsVisible,applyCampaign}) => {


    return(
        <Modal
        visible = {isVisible}
        onCancel={()=>{
            setIsVisible(false)
        }}
        maskClosable = {false}
        footer = {null}
        closable = {false}
        >
            <ModalContainer>
                <div style={{display: 'flex',padding: 'var(--Space-List-Sm, 8px) 0px',flexDirection: 'column',alignItems: 'center',gap: 'var(--Space-List-Md, 16px)'}}>
                    <ModalHeading>
                    Please confirm to proceed
                    </ModalHeading>
                    <ModalDetail>
                    You will be able to start working with this brand only after brand approves your application.
                    </ModalDetail>
                </div>
                <div style={{display : 'flex' , gap : '16px',alignItems : 'center'}}>
                    <CancelModalButton
                    onClick={()=>{
                        setIsVisible(false)
                    }}
                    >
                        Close
                    </CancelModalButton>
                    <ConfirmModalButton
                    onClick={()=>{
                        applyCampaign()
                    }}
                    >
                    Yes, Proceed
                    </ConfirmModalButton>
                </div>
                
            </ModalContainer>

        </Modal>
    )
}

export default ConfirmModal