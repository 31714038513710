import styled from 'styled-components';

export const Container = styled.div`
    padding: 20px;
    background : #FFFFFF;
    border-radius: 4px;
    margin : 24px 32px;
    width: 100%;
    .influencer-selected {
        border: 1px solid #00680A;
        border-radius: 8px;
        position: relative;
    }
`

export const InfluencerFooter = styled.div`
    background: #fff;
    width: 100%;
    padding: 0px 24px;
    position: fixed;
    bottom: 0px;
    color: #010043;
`;

export const EstimationText = styled.div`
    color: #010043;
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
`;

export const AmountText = styled.div`
    font-size: 16px;
    font-weight: 500;
    color: #010043;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: 6px;
`;

export const BackButtonText = styled.div`
    font-size: 14px;
    font-weight: 500;
    padding-left: 8px;
`;

export const CardContainer = styled.div`
    display: flex;
    padding: 16px;
    background-color: #F6FAFF;
    margin-bottom: 12px;

    .ant-typography {
        color: #010043;
        font-size: 12px;
        padding-left: 8px;
        padding-right: 8px;
    }
    .influencer-selected {
        border: 1px solid #00680A;
        border-radius: 8px;
        position: relative;
    }
`;

export const ContentHeading = styled.h4`
    font-size: 12px;
    font-weight: 400;
    color: #494785;
    text-transform: capitalize;
    margin-top: 0px;
    margin-bottom: 8px;
`;

export const ContentDescription = styled.p`
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
`;

export const PricingInformation = styled.p`
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin: 0px;
    color: #010043;
`;

export const ViewSummaryText = styled.div`
    font-size: 10px;
    font-weight: 300;
    margin-top: 4px
    color: #1551EA;
`;