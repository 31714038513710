import { Button, Col, Row } from "antd";
import styled from "styled-components";
import media from "../../utils/UIElements/media";

export const ConnectInstagramContainer = styled.div`
    padding: 0px 60px;
    background: #FFFFFF;
    border-radius: 10px;
    margin : 16px 48px;
    ${media.mobile`
        padding: 0px;
        margin : 12px 32px;
    `}
    .ant-btn-default:disabled{
        cursor: not-allowed !important;
        border-color: #d9d9d9 !important;
        color: #FFFFFF!important;
        background: #D8D7EA !important;
    }

    .ant-modal-content{
        padding : 40px;
    }
`
export const CustomButton = styled(Button)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 50px;
    background: linear-gradient(0deg, #F86624, #F86624), #F9C80E !important;
    border-radius: 60px;
    border : 0px !important;
    color: #FFFFFF !important;
    font-weight : 500;
    font-size : 18px;
    ${media.mobile`
        font-size : 16px; 
    `}
`

export const SocialMediaButton = styled(Button)`
    display: flex;
    padding: 20px ;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 60px;
    border: 1px solid var(--gray-1, #2C2B50) !important;
    color: var(--typo-high, #0F0E1B) !important;
    margin-bottom : 20px;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; 
    ${media.mobile`
        font-size: 12px;
    `}
`
export const AccountPageButton = styled(Button)`
    display: flex;
    height: 56px;
    padding: 10px var(--space-list-medium, 16px);
    align-items: center;
    gap: var(--space-list-medium, 16px);
    align-self: stretch;
    border-radius: var(--radius-modal-sm, 12px);
    background: var(--surface-medium, #EBF4FF) !important;
    border : 0px !important;
    margin-bottom : 20px;
    color: var(--typo-high, #0F0E1B) !important;
    font-size : 18px;
`

export const ModalContainer = styled.div`
    .ant-modal{
        // width : 40px;
    }
    .ant-modal-content{
        // padding : 0px;
    }
`

export const CarouselContainer = styled.div`
    .ant-carousel .slick-dots li.slick-active button{
        background: #F86624 !important;
        border : 0px !important;
        opacity: 1 !important;
        height: 10px !important;
        width: 10px !important;
        border-radius: 50% !important;
        margin: 0px 2px !important;
        margin-right: 9px !important;
        margin-left: 3px !important;
    }
    .ant-carousel .slick-dots li button{
        width: 100%;
        height: 3px;
        padding: 0;
        color: transparent;
        font-size: 0;
        border: 0;
        outline: none;
        cursor: pointer;
        transition: all 0.3s;
        background: #D8D7EA !important;
        opacity: 1 !important;
        height: 10px !important;
        width: 10px !important;
        border-radius: 50% !important;
    }
    .ant-carousel .slick-dots{
        position : relative !important;
        top : 8px;
        // display : inline-block !important;
    }
`

export const ConnectInfoCarouselBox = styled.div`
    display : flex !important;
    flex-direction : column;
    width : 100%;
    justify-content : flex-start;
    align-items : center;

`
export const ConnectInfoModalHeading = styled.span`
    color: var(--Blue, #010043);
    text-align: center;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding : 30px 0px;
    ${media.mobile`
        padding : 16px 0px;
        font-size: 14px;
    `}
`
export const ConnectInfoModalCation = styled.div`
    color: var(--Blue, #010043);
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    ${media.mobile`
        font-size: 10px;
        word-break: break-all;
        display: none;
    `}
`

export const GetStartedHeading = styled.div`
    font-size : 42px; 
    line-height : 50px;
    color : #010043;
    font-weight: 700; 
    margin-bottom : 24px;
    ${media.mobile`
        margin-bottom : 18px;
        font-size : 32px;
        line-height: 40px; 
    `}
    background: linear-gradient(96.74deg, #4E4BEE -1.75%, #F86624 112.67%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
`

export const RequirementInformation = styled.div`
    color: var(--typo-medium, #494785);
    font-size: 20px;
    line-height: 28px;
    margin-bottom : 20px;
    ${media.mobile`
        font-size: 16px;
        line-height: 22px;
    `}
`;

export const StepsContainer = styled.div`
    color: var(--typo-medium, #494785); 
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 20px;
    ${media.mobile`
        font-size: 13px;
        line-height: 18px;
    `}
`;

export const HowToDoText = styled.div`
    color: #494785;
    font-size: 16px;
    line-height: 20px;
    margin-bottom : 20px;
    ${media.mobile`
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 16px;
    `}
`;

export const SocialAccountHeading = styled.div`
    font-size: 42px; 
    line-height : 50px;
    color: #010043;
    font-weight : 700; 
    margin-bottom : 30px;
    margin-top: 30px;
    ${media.mobile`
        font-size: 22px;
        line-height: unset;
        margin-top: 18px;
        margin-bottom: 18px;
    `}
`;

export const SocialMediaText = styled.span`
    font-size: 12px; 
    font-weight: lighter; 
    color: grey; 
`;

export const RightArrowAlignment = styled.span`
    display : flex; 
    width : 100%;
    justify-content : flex-end;
`;

export const ComingSoonAlignment = styled.span`
    display : flex; 
    width : 100%;
    justify-content : flex-end;
    color : #F86624; 
    font-size : 14px;
`;

export const StepsText = styled.div`
    display: flex;
    margin-bottom: 20px;
    align-items: baseline;
`;

export const SaveAndSecureText = styled.div`
    display : flex; 
    font-size : 16px;
    line-height : 24px; 
    color : var(--typo-medium, #494785);
    margin-bottom : 16px;
`;

export const DifferentAccountText = styled.div`
    margin : 20px 0px;
    color : #010043;
    font-size : 16px;
    cursor : pointer;
    ${media.mobile`
        font-size: 14px;
    `}
`;

export const ChoosingInformation = styled.div`
    color: #0F0E1B;
    font-size: 18px;
    margin-bottom: 20px;
`;

export const SelectedPlatformPage = styled.div`
    width: 100%;
    color: #0F0E1B; 
    font-size: 16px;
    margin-bottom: 40px;
`;

export const SelectedPlatform = styled.div`
    display: flex;
    padding: 10px;
    align-items: center;
    gap: var(--space-list-medium, 20px);
    align-self: stretch;
    border-radius: var(--radius-modal-sm, 12px);
    background: var(--surface-medium, #EBF4FF);
    margin-bottom : 20px;
`;

export const SelectedPlatformContent= styled.div`
    width: 40px; 
    height: 40px; 
    position: relative;
`;

export const IconContainer = styled.div`
    width: 12px;
    height: 12px;
    left: 28px;
    top: 0px;
    position: absolute;
    justify-content: flex-start;
    align-items: flex-start;
    display: inline-flex;
`;

export const BackButton = styled.div`
    font-size: 18px; 
    color: #494785;
    ${media.mobile`
        font-size: 14px; 
    `}
`;

export const CustomRow = styled(Row)`
    width : 590px;
    margin-top : 10px;
    display : flex; 
    justify-content : space-between;
`;

export const CustomColumn = styled(Col)`
    display : flex;
    justify-content : center;
`;