import { Button, Col, Image, Row, notification } from "antd";
import React, { useState } from "react";
import SignInForm from "../../components/Home/SignInForm";
import { FooterContainer, Heading8 } from "../../components/Home/style";
import { Container, FormContainer, MotoContainer, OtpBox, OtpHeading, OtpSubHeading, ResendOtp } from "./style";
import { useLocation, useNavigate } from "react-router-dom";
import OtpInput from 'react18-input-otp';
import axios from "axios";
import { BASE_URL } from "../../utils/HelperFunctions/constants";
import { AdmirianButton, JustifyCenterContainer } from "../../utils/common";
import Footer from "../../components/Footer";

const OtpVerify = () => {
    const { state } = useLocation();
    const navigate = useNavigate()
    const [otpValue, setOtpValue] = useState('')
    const [verifyingEmail, setVerifyingEmail] = useState(false)

    const userEmail = state?.email
    const userPassword = state?.password

    const SignIn = async (values) => {
        const postData = {
            email: userEmail,
            password: userPassword
        }
        try {
            const response = await axios.post(`${BASE_URL}/users/token/obtain/`,
                postData
            )
            if (response?.data?.error) {
                notification.error({ message: response?.data?.error?.message })
            }
            else {
                localStorage.setItem(`access_token`, response?.data?.access)
                localStorage.setItem(`refresh_token`, response?.data?.refresh)
                navigate("/Sign-in/flow")
            }
        } catch (error) {
            notification.error({ message: error?.response?.data?.message })
        }
    }

    const handleResendOtp = async () => {
        try {
            const postData = {
                "email": userEmail,
            }
            const response = await axios.post(`${BASE_URL}/users/resend-otp/`,
                postData
            )
            if (!response?.data?.error) {
                notification.success({
                    message: response?.data?.message
                })
            }
        } catch (error) {
            notification.error({ message: error?.response?.data?.message })
        }
    }

    const handleVerifyOtp = async () => {
        try {
            setVerifyingEmail(true)
            const postData = {
                "email": userEmail,
                "otp_code": `${otpValue}`
            }
            const response = await axios.post(`${BASE_URL}/users/verify-otp/`,
                postData
            )
            if (!response?.data?.is_email_verified) {
                notification.error({
                    message: response?.data?.message
                })
                setVerifyingEmail(false)
            } else {
                notification.success({
                    message: response?.data?.message
                })
                SignIn()
            }

        } catch (error) {
            notification.error({ message: error?.response?.data?.message })
        }
    }

    return (
        <div className="overflow-hidden">
            <JustifyCenterContainer className="w-100" style={{ minHeight: 50, alignItems: 'center', padding: '6px 0px' }}>
                <Image height={50} style={{ margin: "4px 0px" }} preview={false} src="https://static.admirian.com/9a312601-984e-4237-bfad-1454204ce38f_BrandLogo.svg" alt="brand-logo" />
            </JustifyCenterContainer>
            <Container>
                <Row>
                    <Col xl={11} lg={11} md={12} sm={24} xs={24} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <FormContainer>
                            <img src="https://static.admirian.com/90181a30-954a-482d-88cf-fc900442f205_otp.svg" width={120} height={120} alt="lock" />
                            <OtpHeading>Enter OTP sent to</OtpHeading>
                            <OtpSubHeading>{userEmail}</OtpSubHeading>
                            <OtpBox id="otpbox">
                                <OtpInput
                                    onChange={(otp) => { setOtpValue(otp) }}
                                    numInputs={6}
                                    inputProps={{ placeholder: '-' }}
                                    value={otpValue}
                                    separator={<span style={{ margin: '10px' }} />}
                                    inputStyle={{
                                        width: '100%',
                                        height: '40px',
                                        fontSize: '16px',
                                    }}
                                    // focusStyle={{
                                    //   outline: '2px solid #6f57e9'
                                    // }}
                                    containerStyle={{
                                        justifyContent: 'space-between'
                                    }}
                                    //shouldAutoFocus={true}
                                    isInputNum={true}
                                    isDisabled={verifyingEmail}
                                    autoComplete="one-time-code"
                                // shouldAutoFocus={ua.getResult().device.type === "mobile" ? false : true}
                                />
                            </OtpBox>
                            <JustifyCenterContainer className="w-100" style={{ alignItems: 'center', marginBottom: '24px' }}>
                                <AdmirianButton
                                    size='large'
                                    shape='round'
                                    block
                                    onClick={() => {
                                        handleVerifyOtp()
                                    }}
                                    className="f-bold"
                                >
                                    Confirm OTP
                                </AdmirianButton>
                            </JustifyCenterContainer>
                            {/* </Link> */}
                            <ResendOtp
                                onClick={() => {
                                    handleResendOtp()
                                }}>
                                Resend OTP
                            </ResendOtp>
                        </FormContainer>
                    </Col>
                    <Col xl={13} lg={13} md={12} sm={8} xs={0} style={{ padding: '20px' }}>
                        <MotoContainer>
                            Collaborate.
                            Measure.
                            Grow.
                        </MotoContainer>

                    </Col>
                </Row>
            </Container>
            <Footer />
        </div>
    )
}

export default OtpVerify