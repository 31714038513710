import React from 'react';
import { AdmirianOutlinedButton, JustifyCenterContainer, SubHeading } from '../../utils/common';
import { Col, Row } from 'antd';
import { CampaignContentContainer, CampaignContentDescription, CampaignDetailsDescription, PaymentSuccessfulContainer, SuccessfulHeading } from './style';
import { useNavigate, useParams } from 'react-router-dom';

const CampaignSummarySuccessful = ({ campaignCreatorsTotal, campaignData = {} }) => {
    const navigate = useNavigate();
    const { campaignId } = useParams();

    const handleViewCampaign = () => {
        navigate(`/manage-campaign-view/${campaignId}`)
    }
    const { objective = "", category = "", max_budget, min_budget, campaign_type = "" } = campaignData;

    return (
        <JustifyCenterContainer>
            <PaymentSuccessfulContainer>
                <img
                    src='https://static.admirian.com/cc5f1e2b-ed85-4383-b028-d91365a41212_thumb-icon.svg'
                    alt='thumb'
                    width={180}
                    height={180}
                />
                {
                    campaign_type.toLowerCase === "broadcast" ? <SuccessfulHeading>Hooray! your campaign is successfully <br /> posted</SuccessfulHeading> : <>
                        {
                            campaignCreatorsTotal ? 
                            <SuccessfulHeading>Great! Proposal sent to {campaignCreatorsTotal} {campaignCreatorsTotal === 1? 'influencer': 'influencers'} <br /> successfully</SuccessfulHeading> : <SuccessfulHeading>Great! your campaign has been posted on Admirian Platform <br/> </SuccessfulHeading>
                        }
                    </>
                }
                <SubHeading>Track all your campaign updates from the <b>Manage Campaigns</b> page <br/> <br/>All the influencer activity will be updated in the campaign's <b>Activity Log</b> sidebar <br/><br/></SubHeading>
                <AdmirianOutlinedButton shape="round" onClick={handleViewCampaign} >View Campaign</AdmirianOutlinedButton>
                <CampaignContentContainer style={{ marginTop: 16 }}>
                    <Row gutter={[16, 20]}>
                        <Col span={8}>
                            <CampaignContentDescription>Campaign Objectives</CampaignContentDescription>
                            <CampaignDetailsDescription>{objective}</CampaignDetailsDescription>
                        </Col>
                        <Col span={8}>
                            <CampaignContentDescription>Category</CampaignContentDescription>
                            <CampaignDetailsDescription>{category}</CampaignDetailsDescription>
                        </Col>
                        <Col span={8}>
                            <CampaignContentDescription>Budget (₹)</CampaignContentDescription>
                            <CampaignDetailsDescription>{min_budget} - {max_budget}</CampaignDetailsDescription>
                        </Col>

                    </Row>
                </CampaignContentContainer>
            </PaymentSuccessfulContainer>
        </JustifyCenterContainer>
    )
}

export default CampaignSummarySuccessful;