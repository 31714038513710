import { Button } from "antd";
import styled from "styled-components";
import media from "../../utils/UIElements/media";

export const CreatorBioContainer = styled.div`
    display: flex;
    margin: 0px 20px;
    border-radius: 10px;
    background : #FFFFFF ;
    padding: 30px 20px 15px 60px;
    ${media.mobile`
       padding: 16px 20px;
    `}
`

export const CustomButton = styled.div`
    display : flex;
    height : 40px;
    margin: 10px;
    margin-left : 0px;
    border-radius : 60px;
    justify-content: center;
    align-items: center;
    background: #F6F6FF !important;
    border: 0px !important;
    color: #010043 !important;
    padding: 0px 16px;
    ${media.mobile`
        height: 32px;
        margin: 8px;
        margin-left: 0px;
    `}
`

export const LikeButton = styled(Button)`
    display: flex !important;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 40px;
    height: 40px;
    border-radius: 60px;
    background: #010043 !important;
    margin-right : 10px;
    color: #FFFFFF !important;
    font-weight : 500;
    ${media.mobile`
        padding: 12px 24px;
        height: 36px;
    `}
`

export const SaveButton = styled(Button)`
    display: flex !important;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 40px;
    gap: 10px;
    height: 40px;
    background: linear-gradient(0deg, #F86624, #F86624), #F9C80E !important;
    border-radius: 60px;
    color: #FFFFFF !important;
    font-weight : 500;
    ${media.mobile`
        padding: 12px 24px;
        height: 36px;
    `}
`

export const ProfilePicContainer = styled.div`
    display : flex;
    padding : 5px;
    border-radius : 50%;
    align-items : center;
    justify-content : center;
`

export const ProfileImg = styled.img`
    height: 200px;
    width: 200px;
    align-items: center;
    justify-content: center;
    ${media.mobile`
        width: 100px;
        height: 100px;
    `}
`;

export const ProfileName = styled.div`
    font-size: 32px;
    font-weight : 600;
    color : #010043;
    line-height : 40px;
    margin-bottom : 16px;
    ${media.mobile`
        font-size: 24px;
        text-align: center;
        margin-bottom : 8px;
        line-height : unset;
    `}
`;

export const ProfileBio = styled.div`
    margin-bottom: 12px;
    color : #010043;
    font-size : 16px;
    ${media.mobile`
        font-size: 13px;
        text-align: center;
        margin-bottom : 8px;
    `}
`;

export const InflencerCountInformation = styled.div`
    font-size : 16px;
    font-weight : 600;
    color : #010043;
     ${media.mobile`
        font-size: 14px;
        font-weight : 600;
    `}
`;

export const InfluencerTextInformation = styled.div`
    font-size : 12px;
    color : #01004380;
    margin-top: 4px;
`;
