import styled from 'styled-components';


export const Container = styled.div`
    display: flex;
    flex-direction : column;
    width : 100%;
    
`
export const Heading = styled.div`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #010043;
`
export const SortByContainer = styled.div`
    display : flex;
    justify-content: flex-end;
    .ant-select-selector{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: baseline;
        padding: 12px 24px;
        gap: 16px;
        width: 126.95px;
        height: 41px;
        border: 1px solid rgba(1, 0, 67, 0.2);
        border-radius: 24px;
    }
`