import React, { useEffect, useState } from "react";
import {
    AmountInformationContainer, BannerNameHeadingText, BannerNameText, CampaignContentContainer, CampaignInfoDescription, CampaignInfoHeading, CampaignObjectiveBox, CampaignObjectiveDescription, CampaignObjectiveHeading,
    Container, ListBox, ListContainer, ListContentBox, ListItemDescription, ListItemHeading, SecureText
} from "./style";
import { FlexContainer, Heading, SpaceBetweenContainer, SubHeading } from "../../utils/common";
import { Avatar, Col, Divider, Row, Typography, Button, notification, Popconfirm } from "antd";
import { LeftOutlined, DeleteOutlined, UserOutlined } from '@ant-design/icons';
import { SecureGreenTickIcon } from "../../assets/icons";
import HowPaymentsWorksModal from "./HowPaymentsWorksModal";
import { useCampaignSendProposalInitiatedMutation, useGetInfluencerSummaryQuery, useGetParticularCampaignQuery, useSelectedInfluencerDeleteInitiatedMutation, useGetAllDeliverablesQuery } from "../../services/manageCampaignsApi";
import CampaignSummarySuccessful from "./CampaignSummarySuccessful";
import { Link, useNavigate, useParams } from "react-router-dom";

const CampaignSummary = () => {
    const [isHowPaymentsWorksModal, setIsHowPaymentsWorksModal] = useState(false);
    const navigate = useNavigate();
    const { campaignId, campaignCreatorIds } = useParams();
    const { data = [], refetch: deliverableRefetch } = useGetAllDeliverablesQuery({ campaignId });
    const { data: campaignData = {}, refetch: getParticularCampaignRefetch } = useGetParticularCampaignQuery({ campaignId });
    const { data: { campaign_creators = [] } = {}, isLoading, refetch } = useGetInfluencerSummaryQuery(campaignData?.status?.toLowerCase() === "draft" ? { campaignId } : { campaignId, campaignCreatorIds });
    const [totalAmount, setTotalAmount] = useState(0);
    const [screenId, setScreenId] = useState(1);
    const [sendProposalLoading, setSendProposalLoading] = useState(false);
    const [campaignSendProposalInitiated] = useCampaignSendProposalInitiatedMutation();
    const [selectedInfluencerDeleteInitiated] = useSelectedInfluencerDeleteInitiatedMutation();

    useEffect(() => {
        getParticularCampaignRefetch();
        refetch();
    }, []);

    useEffect(() => {
        if (campaignData?.campaign_type?.toLowerCase() === "broadcast") {
            deliverableRefetch();
        }
    }, [campaignData])

    useEffect(() => {
        if (campaign_creators.length) {
            let amount = 0;
            campaign_creators.forEach((element) => {
                amount = amount + element.campaign_rate
            })
            setTotalAmount(amount);
        }
    }, [campaign_creators])

    const handleSendProposal = async () => {
        const response = await campaignSendProposalInitiated({ campaignId })
        setSendProposalLoading(true);
        try {
            if (response && !response.error) {
                setSendProposalLoading(false);
                notification.success({
                    message: 'Campaign published successfully. Proposal sent to selected influencers'
                })
                setScreenId(2);
            } else if (response.error) {
                setSendProposalLoading(false);
                notification.error({
                    message: response?.error?.data?.message
                })
            }

        } catch (error) {
            setSendProposalLoading(false);
            notification.error({
                message: error?.response?.error?.data?.message
            })
        }
    }

    const confirm = async (campaignCreatorId) => {
        const response = await selectedInfluencerDeleteInitiated({ campaignId, campaignCreatorId });
        try {
            if (response && !response.error) {
                notification.success({
                    message: 'Influencer removed from campaign'
                })
                refetch();
            } else if (response.error) {
                notification.error({
                    message: response?.error?.data?.error
                })
            }
        }
        catch (error) {
            notification.error({
                message: response?.error?.data?.error
            })
        }
    };
    const cancel = (e) => {
        console.log(e);
    }

    return (
        <Container>
            {
                screenId === 1 ? <>
                    <Typography onClick={() => navigate(-1)} className="cursor-pointer" style={{ color: '#494785', fontSize: 14 }}>
                        <LeftOutlined style={{ marginRight: 4 }} /> Back
                    </Typography>
                    <Divider />
                    <Row gutter={[16, 20]}>
                        <Col span={16}>
                            <Heading>Campaign Summary</Heading>
                            {
                                campaignData?.status?.toLowerCase() === "draft" ?
                                <SubHeading>Verify your campaign details before publishing the campaign</SubHeading> :
                                <SubHeading>Review the newly added influencers and additional campaign cost</SubHeading>
                            }
                            <div style={{ position: 'relative' }}>
                                <img src={campaignData?.banner_file?.length ? campaignData?.banner_file : "https://static.admirian.com/0d565fa0-d51b-4cd9-9459-9c8521fc5d69_Banner_placeholder.svg"} width={"100%"} height={290} style={{ objectFit: 'contain' }} />
                                <Avatar shape="square" size={120} src={campaignData?.brand_logo} style={{ position: 'absolute', left: 12, bottom: '-32px' }} />
                                <div style={{ position: 'absolute', left: 140 }}>
                                    <BannerNameHeadingText>Brand Name</BannerNameHeadingText>
                                    <BannerNameText>{campaignData?.brand_name}</BannerNameText>
                                </div>
                            </div>
                            <CampaignContentContainer style={{ marginTop: 42 }}>
                                <SpaceBetweenContainer>
                                    <div>
                                        <SubHeading style={{ marginTop: 0 }}>Campaign Name</SubHeading>
                                        <CampaignInfoHeading>{campaignData?.name}</CampaignInfoHeading>
                                    </div>
                                    <div>
                                        {/* <Typography style={{ color: '#1551EA', fontSize: 12 }}>Edit</Typography> */}
                                    </div>
                                </SpaceBetweenContainer>
                                <CampaignInfoDescription>{campaignData?.brief}</CampaignInfoDescription>
                            </CampaignContentContainer>
                            <CampaignContentContainer>
                                <SpaceBetweenContainer style={{ flexWrap: 'wrap' }}>
                                    <CampaignObjectiveBox>
                                        <CampaignObjectiveHeading>Campaign Objectives</CampaignObjectiveHeading>
                                        <CampaignObjectiveDescription>{campaignData?.objective}</CampaignObjectiveDescription>
                                    </CampaignObjectiveBox>
                                    <CampaignObjectiveBox>
                                        <CampaignObjectiveHeading>Category</CampaignObjectiveHeading>
                                        <CampaignObjectiveDescription>{campaignData?.category}</CampaignObjectiveDescription>
                                    </CampaignObjectiveBox>
                                    <CampaignObjectiveBox>
                                        <CampaignObjectiveHeading>Budget (₹)</CampaignObjectiveHeading>
                                        <CampaignObjectiveDescription>{campaignData?.min_budget} - {campaignData?.max_budget}</CampaignObjectiveDescription>
                                    </CampaignObjectiveBox>
                                    {/* <CampaignObjectiveBox>
                                    <CampaignObjectiveHeading>Posting type</CampaignObjectiveHeading>
                                    <CampaignObjectiveDescription>Proposal to influencer</CampaignObjectiveDescription>
                                </CampaignObjectiveBox> */}
                                    <CampaignObjectiveBox style={{ textAlign: 'right' }}>
                                        {/* <Typography style={{ color: '#1551EA', fontSize: 12 }}>Edit</Typography> */}
                                    </CampaignObjectiveBox>
                                </SpaceBetweenContainer>
                            </CampaignContentContainer>
                            {
                                campaignData?.campaign_type?.toLowerCase() === "influencer" && <>
                                    {
                                        !!campaign_creators.length && (
                                            <>
                                                <Divider />
                                                <Heading>All Influencers</Heading>
                                                <SubHeading>View the influencers that you have added to this campaign</SubHeading>
                                                <ListContainer>
                                                    {
                                                        campaign_creators.map((campaignDataValue) => {
                                                            return (
                                                                <ListBox key={campaignDataValue.campaign_creator_id}>
                                                                    <ListContentBox style={{ width: "35%" }}>
                                                                        <FlexContainer>
                                                                            {
                                                                                campaignDataValue?.thumbnail?.length ? <Avatar size={'large'} src={campaignDataValue.thumbnail} style={{ marginRight: 8 }} /> : <Avatar size={'large'} icon={<UserOutlined />} style={{ marginRight: 8 }} />
                                                                            }
                                                                            <div>
                                                                                <ListItemHeading>
                                                                                    Name
                                                                                </ListItemHeading>
                                                                                <ListItemDescription style={{ color: '#1551EA' }}>{campaignDataValue.name}</ListItemDescription>
                                                                            </div>
                                                                        </FlexContainer>
                                                                    </ListContentBox>
                                                                    <ListContentBox>
                                                                        <ListItemHeading>
                                                                            Media Type
                                                                        </ListItemHeading>
                                                                        <ListItemDescription>{campaignDataValue.media_type.length === 1 ? campaignDataValue.media_type[0] : `${campaignDataValue.media_type[0]} + ${campaignDataValue.media_type.length - 1}`}</ListItemDescription>
                                                                    </ListContentBox>
                                                                    <ListContentBox>
                                                                        <ListItemHeading>
                                                                            Influencer Rate
                                                                        </ListItemHeading>
                                                                        <ListItemDescription>{campaignDataValue.creator_rate !== campaignDataValue.approved_rate && (
                                                                            <span style={{ color: "#8988BF", marginRight: 4, textDecoration: 'line-through' }}>₹{campaignDataValue.creator_rate}</span>
                                                                        )} ₹{campaignDataValue.approved_rate}</ListItemDescription>
                                                                    </ListContentBox>
                                                                    {
                                                                        (campaignDataValue?.status?.toLowerCase() === "pending") && (
                                                                            <ListContentBox style={{ textAlign: 'right', color: 'red', width: "15%" }}>
                                                                                <Popconfirm
                                                                                    title={`Are you sure want to remove ${campaignDataValue?.name} from this campaign?`}
                                                                                    onConfirm={() => confirm(campaignDataValue.campaign_creator_id)}
                                                                                    onCancel={cancel}
                                                                                    okText="Yes"
                                                                                    cancelText="No"
                                                                                >
                                                                                    {/* <DeleteOutlined className="cursor-pointer" /> */}
                                                                                    <DeleteOutlined className="cursor-pointer" style={{ fontSize: 18, color: 'red', marginLeft: '50px'  }} />
                                                                                </Popconfirm>
                                                                            </ListContentBox>
                                                                        )
                                                                    }
                                                                </ListBox>
                                                            )
                                                        })
                                                    }
                                                </ListContainer>
                                            </>
                                        )
                                    }
                                </>
                            }

                            {
                                (campaignData?.campaign_type?.toLowerCase() === "broadcast" && data?.length === 1) && (
                                    <CampaignContentContainer>
                                        <SpaceBetweenContainer style={{ flexWrap: 'wrap' }}>
                                            <CampaignObjectiveBox>
                                                <CampaignObjectiveHeading>Name</CampaignObjectiveHeading>
                                                <CampaignObjectiveDescription>{data[0]?.name}</CampaignObjectiveDescription>
                                            </CampaignObjectiveBox>
                                            <CampaignObjectiveBox>
                                                <CampaignObjectiveHeading>Type</CampaignObjectiveHeading>
                                                <CampaignObjectiveDescription>{data[0]?.media_type}</CampaignObjectiveDescription>
                                            </CampaignObjectiveBox>
                                            <CampaignObjectiveBox>
                                                <CampaignObjectiveHeading>Duration</CampaignObjectiveHeading>
                                                <CampaignObjectiveDescription>{data[0]?.duration?.length ? data[0]?.duration : "-"}</CampaignObjectiveDescription>
                                            </CampaignObjectiveBox>
                                        </SpaceBetweenContainer>
                                        <CampaignObjectiveBox style={{ marginTop: 22, marginBottom: 22, width: '100%' }}>
                                            <CampaignObjectiveHeading>Reference</CampaignObjectiveHeading>
                                            <CampaignObjectiveDescription style={{textTransform: 'unset'}}>{data[0]?.reference?.length ? data[0]?.reference : "-"}</CampaignObjectiveDescription>
                                        </CampaignObjectiveBox>
                                        <CampaignObjectiveBox style={{width: '100%'}}>
                                            <CampaignObjectiveHeading>Brief</CampaignObjectiveHeading>
                                            <CampaignObjectiveDescription style={{textTransform: 'none'}}>{data[0]?.content_inspiration}</CampaignObjectiveDescription>
                                        </CampaignObjectiveBox>
                                    </CampaignContentContainer>
                                )
                            }
                        </Col>
                        <Col span={8}>
                            <AmountInformationContainer>
                                {
                                    campaignData?.status?.toLowerCase() === "draft" ?
                                        campaignData?.campaign_type === "Broadcast" ? <Heading style={{ fontWeight: 'unset' }}>Estimated Campaign Budget</Heading> 
                                            : <Heading style={{ fontWeight: 'unset' }}>Overall Campaign Cost</Heading> 
                                                :   <Heading style={{ fontWeight: 'unset' }}>Additional Campaign Cost</Heading>
                                }
                                <div>
                                    {
                                        campaign_creators.length ? <Heading>₹{totalAmount}</Heading> : <Heading>₹{campaignData?.min_budget} - ₹{campaignData?.max_budget}</Heading>
                                    }

                                    <SubHeading>Inclusive of Admirian Platform Fee</SubHeading>
                                </div>
                                <div>
                                    {
                                        campaignData?.status?.toLowerCase() === "draft" && campaignData?.campaign_type?.toLowerCase() === "influencer" ? <Button size='large' shape='round' type="primary" block loading={sendProposalLoading} onClick={handleSendProposal}>
                                            Send Proposal
                                        </Button> : campaignData?.status?.toLowerCase() === "draft" && campaignData?.campaign_type?.toLowerCase() === "broadcast" ? <Button size='large' shape='round' type="primary" block loading={sendProposalLoading} onClick={handleSendProposal}>
                                            Post Campaign
                                        </Button> :
                                            <Link to={`/manage-campaign-view/${campaignId}`}>
                                                <Button size='large' shape='round' type="primary" block>
                                                    View Campaign Overview
                                                </Button>
                                            </Link>
                                    }
                                    {
                                        campaignData?.campaign_type?.toLowerCase() === "influencer" && (
                                            <>
                                                <SecureText>
                                                    <SecureGreenTickIcon style={{ marginRight: 8 }} /> Safe and Secure
                                                </SecureText>
                                                {/* <div onClick={() => setIsHowPaymentsWorksModal(true)} style={{ fontSize: 12, color: '#1551EA', cursor: 'pointer' }}>
                                                    See how payments works
                                                </div> */}
                                            </>
                                        )
                                    }
                                </div>
                            </AmountInformationContainer>
                        </Col>
                    </Row>
                    {
                        isHowPaymentsWorksModal && (
                            <HowPaymentsWorksModal isHowPaymentsWorksModal={isHowPaymentsWorksModal} setIsHowPaymentsWorksModal={setIsHowPaymentsWorksModal} />
                        )
                    }
                </> : <CampaignSummarySuccessful campaignCreatorsTotal={campaign_creators.length} campaignData={campaignData} />
            }

        </Container>
    )
}

export default CampaignSummary;