import { notification } from 'antd';
import axios from 'axios';

export const parseJwtToken = (token) => {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join('')
      );
  
      return JSON.parse(jsonPayload);
    } catch (error) {
      // localStorage.removeItem('auth_token');
      // localStorage.removeItem('user_role_auth_token');
      localStorage.removeItem('jwt_auth_token');
      document.cookie = `user_logged_in=false;domain=pickrr.com`;
      // window.location.replace('/sign-in');
      return null;
    }
  };

export const removeElementFromArray =(arr, element) => {
  const index = arr.indexOf(element);
  if (index !== -1) {
      arr.splice(index, 1);
  }
}
 
const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const isValidEmail = (email) => {
  return emailRegex.test(email);
};