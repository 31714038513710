import styled from "styled-components";

export const Container = styled.div`
    display : flex;
    flex-direction : column;
    background : white;
    background-image: url(https://static.admirian.com/55a34dd1-7371-4791-83af-ed8f067400ef_BGSignInFlow.svg),linear-gradient(180deg,#241046 0%,#130D58 100%);
    background-repeat: no-repeat;
    min-height : 100vh;
    padding : 20px;
    color : white !important;
`

export const MainHeading = styled.div`
    display : flex;
    justify-content : center ;
    width : 100% ;
    font-size : 32px;
    font-weight : 900;
`
export const ContentContainer = styled.div`
    display : flex;
    justify-content : flex-start ;
    width : 70% ;
    margin : 0px  auto ;
    flex-direction : column ;
`

export const SubHeading = styled.div`
    display : flex;
    justify-content : flex-start ;
    width : 100% ;
    font-size : 28px;
    font-weight : 700;
    margin-bottom : 10px ;
`
export const Paragraph = styled.div`
    margin-bottom : 15px;
    font-size : 18px;
`