import styled from "styled-components";

export const Container = styled.div`
    .ant-tabs .ant-tabs-tab:hover {
        color: #010043;
    }
`;


export const TabContainer = styled.div`
    padding-top : 20px;
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        border-radius: 20px;
        border: 1px solid #494785;
        color: #494785;
    }
    .ant-select .ant-select-arrow {
        color: #494785;
    }
    .ant-radio-button-wrapper {
        margin-right: 12px;
        border-radius: 16px;
        font-size: 12px;
        border: 1px solid #D8D7EA;
        padding-left: 20px;
        padding-right: 20px;
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
        background-color: unset;
    }
    .ant-radio-button-wrapper:not(:first-child)::before {
        background-color: unset;
    }
    .ant-radio-button-wrapper-checked {
        background: #F86624 !important;
        border-color: #F86624 !important;
        font-weight: 500;
    }
    .campaign-details {
        border-radius: 12px; 
        padding: 20px;
        margin-top: 12px; 
        border: 1px solid #EBF4FF;
    }
    .ant-radio-button-wrapper:hover {
        color: #010043;
    }
`;

export const CampaignInformtaion = styled.div`
    text-align: left;
`;

export const CampaignHeading = styled.h3`
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    color: #494785;
`;

export const CampaignDescription = styled.p`
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #010043;
    margin: unset;
`;

export const PaginationContainer = styled.div`
    margin-top: 12px;
    text-align: right;
`;