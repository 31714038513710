export const getTimeVariable = {
    T00_02: "00-02 am",
    T02_04: "02-04 am",
    T04_06: "04-06 am",
    T06_08: "06-08 am",
    T08_10: "08-10 am",
    T10_12: "10-12 pm",
    T12_14: "12-02 pm",
    T14_16: "02-04 pm",
    T16_18: "04-06 pm",
    T18_20: "06-08 pm",
    T20_22: "08-10 pm",
    T22_24: "10-00 am",
}
