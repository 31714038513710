import { Button, Col, Input, Row, message } from 'antd';
import React from 'react';
import { NeftContentDescription, NeftContentHeading, NeftModeContainer, BankDetailsDescription, UpiIdCopy, TaxContainerBox, TaxLabel, BankDetailsContent } from './style';
import { FlexContainer } from '../../../utils/common';

const NEFTPayment = () => {

    const handleAccountNumCopy=()=>{
        navigator.clipboard.writeText("0048383899");
        message.success("copied!!")
    }
    const handleIFSCCopy=()=>{
        navigator.clipboard.writeText("KKBK0000677");
        message.success("copied!!")
    }

    return (
        <>
            <NeftModeContainer>
                <NeftContentHeading>For NEFT Payment</NeftContentHeading>
                <NeftContentDescription>Use the following bank account details</NeftContentDescription>
                <BankDetailsContent>
                    <NeftContentDescription>Bank Name</NeftContentDescription>
                    <BankDetailsDescription>Kotak Mahindra Bank</BankDetailsDescription>
                </BankDetailsContent>
                <BankDetailsContent>
                    <NeftContentDescription>Account Number</NeftContentDescription>
                    <FlexContainer>
                        <BankDetailsDescription>0048383899</BankDetailsDescription>
                        <UpiIdCopy onClick={handleAccountNumCopy} style={{ paddingLeft: 8 }}>Copy</UpiIdCopy>
                    </FlexContainer>
                </BankDetailsContent>
                <BankDetailsContent>
                    <NeftContentDescription>Account Name</NeftContentDescription>
                    <BankDetailsDescription>Admirian Technologies Private Limited</BankDetailsDescription>
                </BankDetailsContent>
                <BankDetailsContent>
                    <NeftContentDescription>IFSC</NeftContentDescription>
                    <FlexContainer>
                        <BankDetailsDescription>KKBK0000677</BankDetailsDescription>
                        <UpiIdCopy onClick={handleIFSCCopy} style={{ paddingLeft: 8 }}>Copy</UpiIdCopy>
                    </FlexContainer>
                </BankDetailsContent>
            </NeftModeContainer>
        </>
    )
}

export default NEFTPayment;