import React, { useState } from 'react';
import { isMobile } from '../../utils/constants/inedx';
import { Image, Input, notification} from "antd";

import { Heading6, Container, NewFormHeadingText, NewPasswordFormContainer, EmailAddressLabel, SubmitButton} from './style';
import { useLocation } from 'react-router-dom';
import { JustifyCenterContainer } from '../../utils/common';
import Footer from '../../components/Footer';
import { isValidEmail } from '../../utils';
import { useResetPasswordInitiatedMutation } from '../../services/resetPasswordApi';

const EmailAddress = () => {
    const { state : {email} ={} } = useLocation();
    const [emailAddressData, setEmailAddressData] = useState(email || "");
    const [buttonLoading, setButtonLoading] = useState(false);
    const [resetPasswordInitiated] = useResetPasswordInitiatedMutation()

    const handleSubmitClick = async() => {
        const validEmail = isValidEmail(emailAddressData);
        const postData = {
            email: emailAddressData
        }
        if(validEmail) {
           const response = await resetPasswordInitiated(postData);
           setButtonLoading(true); 
           try {
            if (response && !response.error) {
                notification.success({
                    message: response?.data?.message
                })
                setButtonLoading(false);
            } else if (response.error) {
                notification.error({
                    message: response?.error?.data?.message
                })
            }
            setButtonLoading(false);
        } catch (error) {
            notification.error({
                message: error?.response?.error?.data?.message
            })
            setButtonLoading(false);
        }
        }else {
            notification.error({
                message: "Please enter valid email address"
            })
            setButtonLoading(false);
        }
    }

    return (
        <>
         <JustifyCenterContainer className="w-100" style={{ minHeight: 50, alignItems: 'center', padding: '6px 0px' }}>
                <Image height={50} style={{ margin: "4px 0px" }} preview={false} src="https://static.admirian.com/9a312601-984e-4237-bfad-1454204ce38f_BrandLogo.svg" alt="brand-logo" />
            </JustifyCenterContainer>
        <Container>  
        <Heading6>
            <NewPasswordFormContainer
                style={{ minWidth: !isMobile ? '400px' : '300px'}}
            >
                <img src="https://static.admirian.com/90181a30-954a-482d-88cf-fc900442f205_otp.svg" width={120} height={120} alt="lock" />
                <NewFormHeadingText>Can't sign in?</NewFormHeadingText>
                <EmailAddressLabel>We'll send a password reset link to</EmailAddressLabel>
                <Input value={emailAddressData} required placeholder="Enter email address" onChange={(e) => setEmailAddressData(e.target.value)} />
                <SubmitButton disabled={!emailAddressData.length} loading={buttonLoading} onClick={handleSubmitClick}>Send reset link</SubmitButton>
            </NewPasswordFormContainer>
        </Heading6>
     </ Container>
     <Footer/>
     </>
    )
}

export default EmailAddress;