import styled from 'styled-components';
import { Button, Steps, Form } from 'antd';

export const Container = styled.div`
    .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #F86624;
    };
    .ant-tabs .ant-tabs-ink-bar {
        background: #F86624;
    }
    .ant-layout-content {
        margin-top: 50px;
        margin-bottom: 50px;
    }
    .ant-layout-footer {
        position: fixed;
        bottom: 0;
        width: 100%;
        height: auto;
        z-index: 10;
        padding-top: 16px;
        padding-bottom: 16px;
        background: #fff;
    }
`;

export const SubHeading = styled.p`
    font-size: 12px;
    font-weight: 300;
    color: #2C2B50;
    margin-top: 12px;
`;

export const TopBar = styled.div`
    width: 100%;
    height: 100%;
    padding-left: 48px;
    padding-right: 48px;
    padding-top: 24px;
    padding-bottom: 24px;
    background-color: white;
    border-radius: 10px;
    justify-content: space-between;
    align-items: center;
    display: flex;
    flex-direction: column; 
`;

export const CampaignStepper = styled.div`
    display: flex;
    width: 90%;
    align-items: center;
    .stepper-selected {
        border-bottom: 2px solid #1677ff;
        padding: 16px 0px;
    }
`;

export const StepBox = styled.div`
    width: 45%;
    font-size: 16px;
    line-height: 32px;
    text-align: center;
`;

export const StepNumber = styled.span`
    margin-right: 12px;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 50%;
`;

export const Stepper = styled(Steps)`
    .ant-steps-item.ant-steps-item-active::before {
        inset-inline-start: 0% !important;
        width: 100% !important;
    }
    .ant-steps-item-icon{
            background-color: white !important;
        border-color: white !important;
    }
    .ant-steps-icon{
        color:black !important;
    }
    .ant-steps-item::after{
            content:"" !important;
            border: none !important;
    } 
    .ant-progress-circle-path {
    stroke: #4E4BEE !important;
    }
`;

export const CloseButton = styled(Button)`
    border: 0px solid transparent;
    margin-left: 65px;
    margin-top: 2px;
    font-size: 12px;
    font-weight: 300;
    line-height: normal;
    color: #0F0E1B;
    &:focus{
        outline : none !important;
     }
    &:active {
      outline : none !important;
    }
    &:hover {
        color: #000 !important;
    }
`;

export const BrandBox = styled.div`
    margin-right: 6%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const NextButton = styled(Button)`
    display: flex;
    min-width: 150px;
    padding: 16px 32px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-right : 20px;
    margin-top: -7px;
    box-shadow : none;
    border-radius: 60px;
    background: linear-gradient(97deg, #F86624 4.34%, #C83E00 101.42%);
    color: white;
    &:disabled {
        background-color: #BDBDDB !important;
        color: #fff;
        background: #BDBDDB !important;
    }
    &:hover{}
`;

export const PreviousButton = styled(Button)`
    display: flex;
    min-width: 100px;
    max-width: 200px;
    padding: 10px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 60px;
    margin-right : 20px;
    box-shadow : none;
    border:none;
    &:hover{
        color: #F86624 !important;
    }
`;