import React, { useEffect, useState } from 'react'
import { Container, ListContainer, ListContentBox, ListItemDescription, ListItemHeading, ListBox, AmountInformationContainer } from './style'
import { FlexContainer, Heading, SubHeading } from '../../utils/common'
import { Avatar, Button, Col, Popconfirm, Row, notification } from 'antd';
import { DeleteOutlined } from '@ant-design/icons'
import { useGetInfluencerSummaryQuery, useSelectedInfluencerDeleteInitiatedMutation } from '../../services/manageCampaignsApi';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';


const InfluencerSummary = () => {
    const navigate = useNavigate();
    const { campaignId } = useParams();
    const { data: { campaign_creators = [] } = {}, isLoading, refetch } = useGetInfluencerSummaryQuery({ campaignId });
    const [selectedInfluencerDeleteInitiated] = useSelectedInfluencerDeleteInitiatedMutation()
    const [totalAmount, setTotalAmount] = useState(0);

    useEffect(() => {
        if (campaign_creators.length) {
            let amount = 0;
            campaign_creators.forEach((element) => {
                amount = amount + element.approved_rate
            })
            setTotalAmount(amount);
        }
    }, [campaign_creators])


    const handleDeleteConfirm = async (campaignCreatorId) => {
        const response = await selectedInfluencerDeleteInitiated({ campaignId, campaignCreatorId });
        try {
            if (response && !response.error) {
                notification.success({
                    message: 'Influencer removed from the campaign'
                })
                refetch();
            } else if (response.error) {
                notification.error({
                    message: response?.error?.data?.error
                })
            }
        }
        catch (error) {
            notification.error({
                message: error?.response?.error?.data?.error
            })
        }
    };

    const cancel = (e) => {
        console.log(e);
    }


    const handleContinue = () => {
        navigate(`/campaign-summary/${campaignId}`)
    }

    return (
        <Container>
            <Heading>All Influencer</Heading>
            <SubHeading>Your basic details will help you to get discovered</SubHeading>
            <Row gutter={[20, 20]}>
                <Col span={16}>
                    <ListContainer>
                        {
                            campaign_creators.map((campaignData) => {
                                return (
                                    <ListBox key={campaignData.campaign_creator_id}>
                                        <ListContentBox style={{ width: "35%" }}>
                                            <FlexContainer>
                                                <Avatar size={'large'} src={campaignData.thumbnail} style={{ marginRight: 8 }} />
                                                <div>
                                                    <ListItemHeading>
                                                        Username
                                                    </ListItemHeading>
                                                    <ListItemDescription style={{ color: '#1551EA' }}>{campaignData.name}</ListItemDescription>
                                                </div>
                                            </FlexContainer>
                                        </ListContentBox>
                                        <ListContentBox>
                                            <ListItemHeading>
                                                Media Type
                                            </ListItemHeading>
                                            <ListItemDescription>{campaignData.media_type.length === 1 ? campaignData.media_type[0] : `${campaignData.media_type[0]} + ${campaignData.media_type.length - 1}`}</ListItemDescription>
                                        </ListContentBox>
                                        <ListContentBox>
                                            <ListItemHeading>
                                                Total Estimation
                                            </ListItemHeading>
                                            <ListItemDescription>{campaignData.creator_rate !== campaignData.approved_rate && (
                                                <span style={{ color: "#8988BF", marginRight: 4, textDecoration: 'line-through' }}>₹{campaignData.creator_rate}</span>
                                            )
                                            }₹{campaignData.approved_rate}</ListItemDescription>
                                        </ListContentBox>
                                        {
                                            campaignData?.status?.toLowerCase() === "pending" && (
                                                <ListContentBox style={{ textAlign: 'right', color: 'red', width: "15%" }}>
                                                    <Popconfirm
                                                        title="Are you sure want to delete this Influencer details?"
                                                        onConfirm={() => handleDeleteConfirm(campaignData.campaign_creator_id)}
                                                        onCancel={cancel}
                                                        okText="Yes"
                                                        cancelText="No"
                                                    >
                                                        <DeleteOutlined className='cursor-pointer' />
                                                    </Popconfirm>
                                                </ListContentBox>
                                            )
                                        }
                                    </ListBox>
                                )
                            })
                        }
                    </ListContainer>
                </Col>
                <Col span={8}>
                    <AmountInformationContainer>
                        <Heading style={{ fontWeight: 'unset' }}>Overall campaign Estimation</Heading>
                        <div>
                            <Heading>₹{totalAmount}</Heading>
                            <SubHeading>Approx price of the campaign</SubHeading>
                        </div>
                        <div>
                            <Button size='large' shape='round' type="primary" block onClick={handleContinue}>
                                Continue
                            </Button>
                        </div>
                    </AmountInformationContainer>
                </Col>
            </Row>
        </Container>
    )
}

export default InfluencerSummary