import {configureStore} from '@reduxjs/toolkit'
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import { combineReducers } from "redux";
import { manageCampaignsApi } from '../services/manageCampaignsApi';
import ManageCampaignReducer from '../components/ManageCampaigns/Reducers/ManageCampaignReducer';
import { resetPasswordApi } from '../services/resetPasswordApi';


const reducer = combineReducers({
    //onboarding: onboardingReducer,
    [manageCampaignsApi.reducerPath]: manageCampaignsApi.reducer,
    [resetPasswordApi.reducerPath]: resetPasswordApi.reducer,
    ManageCampaign: ManageCampaignReducer,
});

const persistConfig = {
    key: "persistRoot",
    storage,
    whitelist: ["onboarding", "config", "onboardingFormFlag", "wishList"],
};

const persistedReducer = persistReducer(persistConfig, reducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat(manageCampaignsApi.middleware, resetPasswordApi.middleware)
});

const persistor = persistStore(store);

export { store, persistor };