import styled from 'styled-components';

export const  HeaderContainer = styled.div`
    height : 72px;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    background:linear-gradient(rgba(246, 246, 255, 0.3),transparent);
    background-color:orange /*this your primary color*/
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    padding-top: 0px;
    padding-bottom: 0px;
    backdrop-filter: blur(17px);
    position : fixed;
    // box-shadow: 0 3px 7px -5px grey;
`

export const MasterLayoutStyle = styled.div`
    display : flex;
    justify-content : center;
    width : 100%;
`
export const MasterComponentStyle = styled.div`
    margin: 24px;
    background : var(--surface-medium, #EBF4FF);
    width : 100%;
`