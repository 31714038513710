import { Col, Divider, Form, Modal, notification, Row, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { CancelButton, CustomInputPassword, ModalContainer, ModalTitle, SaveButton, UploadContainer } from "./style";
import axios from "axios";
import { parseJwtToken } from "../../utils";
import { BASE_URL } from "../../utils/HelperFunctions/constants";

const UpdatePasswordModal = ({visible,setVisible,brandDetails,getBrandDetail})=>{
    const [form] = Form.useForm()
    const refresh_token = parseJwtToken(localStorage.getItem('refresh_token'))

      const handleFormSubmit = async(values) => {
        const postData = {
            old_pwd: values.oldPassword,
            new_pwd_1: values.newPassword,
            new_pwd_2: values.confirmPassword
        }    
        try{
            const response =  axios.patch(`${BASE_URL}/users/update_password/${refresh_token?.user_uuid}/`,
            postData
            )

            if(!response?.data?.error){
                notification.success({
                    message : "Password Updated SuccessFully"
                })
                getBrandDetail()
                setVisible(false)
                form.resetFields()
                // carouselRef.current.next()
            }
            else{
                notification.error({
                    message : response?.data?.error?.message
                })
            }
            
        }catch(error){
            notification.error({message : error?.response?.data?.message})
        }
      }

    return(
        <ModalContainer>
        <Modal
        title = {<ModalTitle>Change Password</ModalTitle>}
        visible = {visible}
        destroyOnClose = {true}
        onCancel = {()=>{
            form.resetFields()
            setVisible(false)
        }}
        footer = {false}
        >
            <Form
            form={form}
            onFinish = {handleFormSubmit}
            >
            <Row gutter={[20,20]} style={{marginTop : '30px'}}>
                <Col span={24}>
                    <Form.Item
                    name="oldPassword"
                    label="Old Password"
                    rules={[
                        {
                        required: true,

                        message: 'Old Password is required',
                        },
                    ]}
                    >
                        <CustomInputPassword/>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                    name="newPassword"
                    label="New Password"
                    rules={[
                        {
                        required: true,

                        message: 'New Password is required',
                        },
                    ]}
                    >
                        <CustomInputPassword/>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                    name="confirmPassword"
                    label="Confirm Password"
                    rules={[
                        {
                        required: true,

                        message: 'Confirm Password is required',
                        },
                    ]}
                    >
                        <CustomInputPassword/>
                    </Form.Item>
                </Col>
            </Row>
            <Divider />
            <Col span={24} style={{display : 'flex',justifyContent : 'flex-end',marginTop : '20px'}}>
                <CancelButton onClick={()=>{
                    form.resetFields()
                    setVisible(false)
                }}
                >
                    Cancel
                </CancelButton>
                <SaveButton 
                htmlType="submit"
                >
                    Save Changes
                </SaveButton>
            </Col>
            </Form>
        </Modal>
        </ModalContainer>
    )
}

export default UpdatePasswordModal