import React from "react";
import {
    BannerContainer, BannerDetails, BannerHeading, CreatorAnalyticsContainer, CustomButton, CustomColumn, FooterContainer,
    HomePageContainer, InformationText, MatricsContainer, MetricCountText, MetricsCount, MetricsPercentageInformation, NavigationCard, NavigationTitle, AnalyticsDetailCard,
    CardHeadingAnalytics, CompanyLogoContainer, CreatorMediaContainer, FeaturedMediaCard, ToggleButton, FeaturedMediaContainer, NoDataContainer, FeaturedMedia, MostViewedText, MostViewHeading, ViewedText, LocationContainer, UserName, CommentedDate, CommentText, PostDensityContainer
} from "./style";
import { Col, Row } from "antd";
import { useNavigate } from "react-router-dom";
import { FlexColContainer, FlexContainer } from "../../utils/common";

const DemoProfile = ({ creatorDetails, getCreatorDetails }) => {

    const navigate = useNavigate();

    const handleNavigationClick = (id) => {
        const element = document.getElementById(id);
        element.scrollIntoView({ behavior: "smooth", inline: "nearest" });
    }

    const handleConnectSocialProfile = () => {
        navigate('/connect-accounts')
    }

    return (
        <HomePageContainer>
            <BannerContainer>
                <Row gutter={[16, 20]}>
                    <Col xl={10} lg={10} md={12} sm={24} xs={24}>
                        <BannerHeading>
                            Add your social account to view your profile analytics
                        </BannerHeading>
                        <BannerDetails>
                            Connect your Instagram Account and/or Youtube Channel to get advanced analytics
                        </BannerDetails>
                        <CustomButton
                            onClick={() => {
                                handleConnectSocialProfile()
                            }}
                        >
                            Add Social Profile
                        </CustomButton>
                        <FlexContainer style={{ gap: '16px' }}>
                            <img src="https://static.admirian.com/4f6aa157-d774-47d5-9a62-41fad222419a_insta.svg" width={24} height={24} style={{ marginLeft: '16px' }} />
                            <img src="https://static.admirian.com/c9243bf0-ae85-47fc-aaae-808e03b588c3_fb.svg" width={24} height={24} />
                            <img src="https://static.admirian.com/c265292d-7c3f-4c05-8e31-75e328be1f99_youtube.svg" width={24} height={24} />
                            <img src="https://static.admirian.com/f4c5ff08-1e4a-49c9-ab51-72669a6994ee_google.svg" width={24} height={24} />
                        </FlexContainer>
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={0} xs={0}>
                        <img src="https://static.admirian.com/a793130a-aa53-4e4f-aa8f-581911eaeae8_BannerImage.svg" />
                    </Col>
                </Row>
            </BannerContainer>
            <Row gutter={[0, 20]} className="w-100" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Col xl={5} lg={5} md={5} sm={0} xs={0}>
                    <NavigationCard>
                        <NavigationTitle onClick={() => handleNavigationClick('channelMetricsyt')}>
                            Channel Metrics
                        </NavigationTitle>
                        <NavigationTitle onClick={() => handleNavigationClick('featuredMedia')}>
                            Featured Media
                        </NavigationTitle>
                        <NavigationTitle onClick={() => handleNavigationClick('audienceDemographics')}>
                            Audience Demographics
                        </NavigationTitle>
                        <NavigationTitle onClick={() => handleNavigationClick('audienceEngagement')}>
                            Audience Engagement
                        </NavigationTitle>
                        <NavigationTitle onClick={() => handleNavigationClick('postDensityyt')}>
                            Post Density
                        </NavigationTitle>
                        <NavigationTitle onClick={() => handleNavigationClick('activityHeatMapyt')}>
                            Activity Heat Map
                        </NavigationTitle>

                    </NavigationCard>
                </Col>
                <Col xl={18} lg={18} md={18} sm={24} xs={24}>
                    <CreatorAnalyticsContainer>
                        <MatricsContainer id="channelMetricsyt">
                            <Row gutter={[20, 20]} style={{ display: 'flex', justifyContent: 'space-evenly', marginBottom: '24px' }}>
                                <FlexContainer style={{ fontSize: '12px', color: "#01004380" }}>
                                    This is a representation of how your profile analytics will look like once you connect your social profile
                                </FlexContainer>
                                <Col xl={24} lg={24} md={24} sm={24} xs={24} style={{ display: "flex", flexDirection: "row", marginBottom: '10px' }}>
                                    <CompanyLogoContainer>
                                        <img
                                            src='https://static.admirian.com/487d244e-1557-41a7-8aaf-e4566992db8c_demoprofilepic.svg'
                                            alt="logo"
                                            width={28}
                                            height={28}
                                            className="border-circle"
                                        />
                                        <img
                                            src="https://static.admirian.com/657ef4b3-0905-4fc4-ad7f-3bedec4362bc_youtube-bg-white.svg"
                                            alt="logo"
                                            width={18}
                                            height={18}
                                            className="border-circle"
                                            style={{
                                                marginLeft: '-8px'
                                            }}
                                        />
                                    </CompanyLogoContainer>
                                    <CardHeadingAnalytics>
                                        Channel Metrics
                                    </CardHeadingAnalytics>
                                </Col>
                                <CustomColumn xl={6} lg={6} md={6} sm={24} xs={24}>
                                    <AnalyticsDetailCard>
                                        <img src="https://static.admirian.com/25f14eaf-c40d-49d6-9500-c672e2ed499b_followers.svg" className="m-auto border-circle" width={48} height={48} style={{ border: '0px' }} />
                                        <MetricsCount>
                                            -
                                        </MetricsCount>
                                        <MetricCountText>
                                            Total Subscribers
                                        </MetricCountText>
                                        <MetricsPercentageInformation>
                                            <span style={{ marginRight: '4px' }}>30 %</span>
                                            <img src={"https://static.admirian.com/c66aab96-e919-4573-b46f-a534da62dfef_incrementLogo.svg"} width={24} height={24} style={{ display: 'flex' }} />
                                        </MetricsPercentageInformation>
                                        <InformationText>
                                            Higher than last 30 days
                                        </InformationText>
                                    </AnalyticsDetailCard>
                                </CustomColumn>
                                <CustomColumn xl={6} lg={6} md={6} sm={24} xs={24}>
                                    <AnalyticsDetailCard>
                                        <img src="https://static.admirian.com/f29a4bd9-b951-4b53-bcd4-cc5560d8bef2_profileVisit.svg" className="m-auto border-circle" width={48} height={48} style={{ border: 'unset' }} />
                                        <MetricsCount>
                                            -
                                        </MetricsCount>
                                        <MetricCountText>
                                            Total Views
                                        </MetricCountText>
                                        <MetricsPercentageInformation className="color-red">
                                            <span style={{ marginRight: '4px' }}>10%</span>
                                            <img src={"https://static.admirian.com/86efc633-e04b-4206-af73-07333c9ba3e3_decrementLogo.svg"} width={24} height={24} style={{ display: 'flex' }} />
                                        </MetricsPercentageInformation>
                                        <InformationText>
                                            Lower than last 30 days
                                        </InformationText>
                                    </AnalyticsDetailCard>
                                </CustomColumn>
                                <CustomColumn xl={6} lg={6} md={6} sm={24} xs={24}>
                                    <AnalyticsDetailCard>
                                        <img src="https://static.admirian.com/51e89da2-ac4b-45e4-b35e-7412084e23d5_likes.svg" className="m-auto border-circle" width={48} height={48} style={{ border: '0px' }} />
                                        <MetricsCount>
                                            -
                                        </MetricsCount>
                                        <MetricCountText>
                                            Total Likes
                                        </MetricCountText>
                                        <MetricsPercentageInformation>
                                            <span style={{ marginRight: '4px' }}>20 %</span>
                                            <img src={"https://static.admirian.com/c66aab96-e919-4573-b46f-a534da62dfef_incrementLogo.svg"} width={24} height={24} style={{ display: 'flex' }} />
                                        </MetricsPercentageInformation>
                                        <InformationText>
                                            Higher than last 30 days
                                        </InformationText>

                                    </AnalyticsDetailCard>
                                </CustomColumn>
                                <CustomColumn xl={6} lg={6} md={6} sm={24} xs={24}>
                                    <AnalyticsDetailCard>
                                        <img src="https://static.admirian.com/71043585-f913-4a1d-a116-913ed67a4092_comments.svg" className="m-auto border-circle" width={48} height={48} style={{ border: 'unset' }} />
                                        <MetricsCount>
                                            -
                                        </MetricsCount>
                                        <MetricCountText>
                                            Total Comments
                                        </MetricCountText>
                                        <MetricsPercentageInformation>
                                            <span style={{ marginRight: '4px' }}>15 %</span>
                                            <img src={"https://static.admirian.com/c66aab96-e919-4573-b46f-a534da62dfef_incrementLogo.svg"} width={24} height={24} style={{ display: 'flex' }} />
                                        </MetricsPercentageInformation>
                                        <InformationText>
                                            Higher than last 30 days
                                        </InformationText>
                                    </AnalyticsDetailCard>
                                </CustomColumn>
                                <CustomColumn xl={6} lg={6} md={6} sm={24} xs={24}>
                                    <AnalyticsDetailCard>
                                        <img src="https://static.admirian.com/64780a6d-7227-487e-a078-662efb16ae1f_share.svg" className="m-auto border-circle" width={48} height={48} style={{ border: '0px' }} />
                                        <MetricsCount>
                                            -
                                        </MetricsCount>
                                        <MetricCountText>
                                            Share Count
                                        </MetricCountText>
                                        <MetricsPercentageInformation className="color-red">
                                            <span style={{ marginRight: '4px' }}>10%</span>
                                            <img src={"https://static.admirian.com/86efc633-e04b-4206-af73-07333c9ba3e3_decrementLogo.svg"} width={24} height={24} style={{ display: 'flex' }} />
                                        </MetricsPercentageInformation>
                                        <InformationText>
                                            Lower than last 30 days
                                        </InformationText>
                                    </AnalyticsDetailCard>
                                </CustomColumn>
                                <CustomColumn xl={6} lg={6} md={6} sm={24} xs={24}>
                                    <AnalyticsDetailCard>
                                        <img src="https://static.admirian.com/399bd3a1-a2ac-4299-bdcc-a46d2cfb3a73_min_watched.svg" width={48} height={48} className="m-auto border-circle" style={{ border: '0px' }} />
                                        <MetricsCount>
                                            -
                                        </MetricsCount>
                                        <MetricCountText>
                                            Estimated mins watched
                                        </MetricCountText>
                                        <InformationText>
                                            In 3 years
                                        </InformationText>
                                    </AnalyticsDetailCard>
                                </CustomColumn>
                                <CustomColumn xl={6} lg={6} md={6} sm={24} xs={24}>
                                    <AnalyticsDetailCard>
                                        <img src="https://static.admirian.com/b59d6d49-4bb0-48d7-a6e3-e1fd21463c9b_viewDuration.svg" className="border-circle m-auto" style={{ border: '0px' }} />
                                        <MetricsCount>
                                            -
                                        </MetricsCount>
                                        <MetricCountText>
                                            Average View Duration
                                        </MetricCountText>
                                    </AnalyticsDetailCard>
                                </CustomColumn>
                                <CustomColumn xl={6} lg={6} md={6} sm={24} xs={24}>
                                    <AnalyticsDetailCard>
                                        <img src="https://static.admirian.com/3138538a-891b-4b3e-ab6c-2ce06cf46fc1_clickRate.svg" className="m-auto border-circle" width={48} height={48} style={{ border: '0px' }} />
                                        <MetricsCount>
                                            -
                                        </MetricsCount>
                                        <MetricCountText>
                                            Click Through Rate
                                        </MetricCountText>
                                    </AnalyticsDetailCard>
                                </CustomColumn>
                                <CustomColumn xl={6} lg={6} md={6} sm={24} xs={24}>
                                    <AnalyticsDetailCard>
                                        <img src="https://static.admirian.com/b59d6d49-4bb0-48d7-a6e3-e1fd21463c9b_viewDuration.svg" className="m-auto border-circle" width={48} height={48} style={{ border: '0px' }} />
                                        <MetricsCount>
                                            -
                                        </MetricsCount>
                                        <MetricCountText>
                                            Average View Percentage
                                        </MetricCountText>
                                    </AnalyticsDetailCard>
                                </CustomColumn>
                                <CustomColumn xl={6} lg={6} md={6} sm={24} xs={24} >
                                    <AnalyticsDetailCard>
                                        <img src="https://static.admirian.com/399bd3a1-a2ac-4299-bdcc-a46d2cfb3a73_min_watched.svg" className="m-auto border-circle" width={48} height={48} style={{ border: '0px', }} />
                                        <MetricsCount>
                                            -
                                        </MetricsCount>
                                        <MetricCountText>
                                            Channel Age
                                        </MetricCountText>
                                    </AnalyticsDetailCard>
                                </CustomColumn>
                            </Row>
                        </MatricsContainer>
                        <CreatorMediaContainer id="featuredMedia" style={{ marginTop: '20px' }}>
                            <>
                                <FeaturedMediaContainer>
                                    <FlexContainer style={{ alignItems: 'center' }}>
                                        <CompanyLogoContainer>
                                            <img
                                                src='https://static.admirian.com/487d244e-1557-41a7-8aaf-e4566992db8c_demoprofilepic.svg'
                                                alt="logo"
                                                height={28}
                                                width={28}
                                                className="border-radius"
                                            />
                                            <img
                                                src="https://static.admirian.com/657ef4b3-0905-4fc4-ad7f-3bedec4362bc_youtube-bg-white.svg"
                                                alt="logo"
                                                height={18}
                                                width={18}
                                                className="border-circle"
                                                style={{
                                                    marginLeft: '-8px'
                                                }}
                                            />
                                        </CompanyLogoContainer>
                                        <CardHeadingAnalytics>
                                            Featured Media
                                        </CardHeadingAnalytics>
                                    </FlexContainer>
                                    <FlexContainer>
                                        <ToggleButton
                                            style={{ marginRight: '16px' }}
                                        // onClick={() => {
                                        //     reelCarouselRef.current.prev()
                                        // }}
                                        // disabled={reelCarouselActiveIndex === 0}
                                        >
                                            {'<'}

                                        </ToggleButton>
                                        <ToggleButton
                                        // onClick={() => {
                                        //     reelCarouselRef.current.next()
                                        // }}
                                        // disabled={reelCarouselActiveIndex >= analyticsData?.featured_media?.featured_videos?.length - 4}
                                        >
                                            {'>'}

                                        </ToggleButton>
                                    </FlexContainer>
                                </FeaturedMediaContainer>
                                <NoDataContainer>
                                    <img
                                        src='https://static.admirian.com/97443610-74bc-47a1-bbe2-819897ffd40b_noData.svg'
                                        alt="logo"
                                        height={94}
                                        width={256}
                                    />
                                </NoDataContainer>
                            </>

                        </CreatorMediaContainer>
                        <Row gutter={[16, 20]} style={{ display: 'flex', justifyContent: 'space-between', padding: '24px 0px' }}>
                            <Col xl={8} lg={8} md={12} sm={24} xs={24}>
                                <FeaturedMedia>
                                    <MostViewedText>
                                        Most Viewed
                                    </MostViewedText>
                                    <div
                                        className="w-100"
                                        style={{ position: 'relative' }}
                                    >
                                        <img
                                            src='https://static.admirian.com/0d246c9c-d996-4a61-885f-4e17c603ba46_emptyYoutube.svg'
                                            height={160}
                                            className="w-100"
                                            style={{ display: 'flex', borderRadius: '10px' }}
                                        />
                                    </div>

                                    <FlexColContainer style={{ alignItems: 'center', alignSelf: 'center' }}>
                                        <img src="https://static.admirian.com/f29a4bd9-b951-4b53-bcd4-cc5560d8bef2_profileVisit.svg" width={48} height={48} className="border-circle" style={{ display: 'flex', border: '0px' }} />
                                        <MostViewHeading>-</MostViewHeading>
                                        <ViewedText>Audience Viewed</ViewedText>
                                    </FlexColContainer>
                                </FeaturedMedia>
                            </Col>
                            <Col xl={8} lg={8} md={12} sm={24} xs={24}>
                                <FeaturedMedia>
                                    <MostViewedText>
                                        Most liked
                                    </MostViewedText>
                                    <div
                                        className="w-100"
                                        style={{ position: 'relative' }}
                                    >
                                        <img src='https://static.admirian.com/0d246c9c-d996-4a61-885f-4e17c603ba46_emptyYoutube.svg'
                                            className="w-100"
                                            height={160}
                                            style={{ display: 'flex', borderRadius: '10px' }}
                                        />
                                    </div>
                                    <FlexColContainer style={{ alignItems: 'center', alignSelf: 'center' }}>
                                        <img src="https://static.admirian.com/51e89da2-ac4b-45e4-b35e-7412084e23d5_likes.svg" width={48} height={48} className="border-circle" style={{ display: 'flex', border: '0px' }} />
                                        <MostViewHeading>-</MostViewHeading>
                                        <ViewedText>Audience Liked</ViewedText>
                                    </FlexColContainer>
                                </FeaturedMedia>
                            </Col>
                            <Col xl={8} lg={8} md={12} sm={24} xs={24}>
                                <FeaturedMedia>
                                    <MostViewedText>
                                        Most Commmented
                                    </MostViewedText>
                                    <div
                                        className="w-100"
                                        style={{ position: 'relative' }}
                                    >
                                        <img src='https://static.admirian.com/0d246c9c-d996-4a61-885f-4e17c603ba46_emptyYoutube.svg'
                                            height={160}
                                            className="w-100"
                                            style={{ display: 'flex', borderRadius: '10px' }}
                                        />
                                    </div>
                                    <FlexColContainer style={{ alignItems: 'center', alignSelf: 'center' }}>
                                        <img src="https://static.admirian.com/71043585-f913-4a1d-a116-913ed67a4092_comments.svg" width={48} height={48} className="border-circle" style={{ display: 'flex', border: '0px' }} />
                                        <MostViewHeading>-</MostViewHeading>
                                        <ViewedText>Audience Commented</ViewedText>
                                    </FlexColContainer>
                                </FeaturedMedia>
                            </Col>
                        </Row>
                        <Row id="audienceDemographics" gutter={[20, 20]} style={{ display: 'flex', justifyContent: 'space-between', paddingTop: 24, paddingBottom: 24 }}>
                            <Col xl={24} lg={24} md={24} sm={24} xs={24} style={{ display: 'flex', alignItems: 'center', paddingBottom: '4px' }}>
                                <CompanyLogoContainer>
                                    <img
                                        src='https://static.admirian.com/487d244e-1557-41a7-8aaf-e4566992db8c_demoprofilepic.svg'
                                        alt="logo"
                                        height={28}
                                        width={28}
                                        className="border-circle"
                                    />
                                    <img
                                        src="https://static.admirian.com/657ef4b3-0905-4fc4-ad7f-3bedec4362bc_youtube-bg-white.svg"
                                        alt="logo"
                                        height={18}
                                        width={18}
                                        className="border-circle"
                                        style={{
                                            marginLeft: '-8px'
                                        }}
                                    />
                                </CompanyLogoContainer>
                                <CardHeadingAnalytics>
                                    Audience Demographics
                                </CardHeadingAnalytics>
                            </Col>
                            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                                <FeaturedMediaCard style={{ gap: '0' }}>
                                    <MostViewedText>
                                        Geographic Location
                                    </MostViewedText>
                                    <InformationText>Top audience locations</InformationText>
                                    <LocationContainer>
                                        <img
                                            src='https://static.admirian.com/97443610-74bc-47a1-bbe2-819897ffd40b_noData.svg'
                                            alt="logo"
                                            height={94}
                                            width={256}
                                        />
                                    </LocationContainer>
                                </FeaturedMediaCard>
                            </Col>
                            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                                <FeaturedMediaCard style={{ gap: '0' }}>
                                    <MostViewedText>
                                        Audience Age
                                    </MostViewedText>
                                    <InformationText>Age-wise audience distribution</InformationText>
                                    <LocationContainer>
                                        <img
                                            src='https://static.admirian.com/97443610-74bc-47a1-bbe2-819897ffd40b_noData.svg'
                                            alt="logo"
                                            height={96}
                                            width={256}
                                        />
                                    </LocationContainer>
                                </FeaturedMediaCard>
                            </Col>
                            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                                <FeaturedMediaCard style={{ gap: '0' }}>
                                    <MostViewedText>
                                        Gender
                                    </MostViewedText>
                                    <InformationText>Gender-wise audience distribution</InformationText>
                                    <LocationContainer>
                                        <img
                                            src='https://static.admirian.com/97443610-74bc-47a1-bbe2-819897ffd40b_noData.svg'
                                            alt="logo"
                                            width={256}
                                            height={94}
                                        />
                                    </LocationContainer>
                                </FeaturedMediaCard>
                            </Col>
                        </Row>

                        <Row id="audienceEngagement" gutter={[20, 20]} style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '24px', paddingBottom: '24px' }}>
                            <Col xl={24} lg={24} md={24} sm={24} xs={24} style={{ display: 'flex', alignItems: 'center' }}>
                                <CompanyLogoContainer>
                                    <img
                                        src='https://static.admirian.com/487d244e-1557-41a7-8aaf-e4566992db8c_demoprofilepic.svg'
                                        alt="logo"
                                        width={28}
                                        height={28}
                                        className="border-circle"
                                    />
                                    <img
                                        src="https://static.admirian.com/657ef4b3-0905-4fc4-ad7f-3bedec4362bc_youtube-bg-white.svg"
                                        alt="logo"
                                        height={18}
                                        width={18}
                                        className="border-circle"
                                        style={{
                                            marginLeft: '-8px'
                                        }}
                                    />
                                </CompanyLogoContainer>
                                <CardHeadingAnalytics>
                                    Audience Engagement
                                </CardHeadingAnalytics>
                            </Col>
                            <Col xl={8} lg={8} md={8} sm={24} xs={24} style={{ display: 'flex', alignItems: 'stretch' }}>
                                <FeaturedMediaCard className="w-100">
                                    <MostViewedText>
                                        Subscriber Interaction
                                    </MostViewedText>
                                    <FlexColContainer style={{ alignItems: 'center', alignSelf: 'center' }}>
                                        <img src="https://static.admirian.com/d4264d01-db86-4dd7-a3c7-aca5b73f0560_sharedshared.svg" />
                                        <MostViewHeading>-</MostViewHeading>
                                        <ViewedText>Lifetime Video Share Count</ViewedText>
                                    </FlexColContainer>
                                </FeaturedMediaCard>
                            </Col>
                            <Col xl={16} lg={16} md={16} sm={24} xs={24} style={{ display: 'flex' }}>
                                <FeaturedMediaCard>
                                    <MostViewedText>
                                        Comments
                                    </MostViewedText>
                                    <InformationText>Top engaging comments</InformationText>
                                    <Row gutter={[20, 20]} style={{ maxHeight: '350px', overflowX: 'scroll' }}>
                                        <Col xl={24} lg={24} md={24} sm={24} xs={24} className="w-100" style={{ flexDirection: 'row', display: 'flex' }}>
                                            <img src='https://static.admirian.com/demo_yt_user.png' height={32} width={32} className="border-circle" />
                                            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                                <UserName>@Monika <CommentedDate>01/02/2024</CommentedDate></UserName>
                                                <CommentText>
                                                    Great review! Always trust your recommendations when it comes to gadgets. Looking forward to your next video! 😍😍😍
                                                </CommentText>
                                                <FlexContainer style={{ color: '#494785', gap: '10px' }}>
                                                    <span style={{ fontWeight: 500 }}>10 <span style={{ fontWeight: 400, opacity: '0.5' }}>Likes </span></span>
                                                    <span style={{ fontWeight: 500 }}>2 <span style={{ fontWeight: 400, opacity: '0.5' }}>Reply </span></span>
                                                </FlexContainer>
                                            </Col>
                                        </Col>
                                    </Row>

                                </FeaturedMediaCard>
                            </Col>
                        </Row>
                        <PostDensityContainer id="postDensityyt">
                            <FlexContainer style={{ alignItems: 'center' }}>
                                <CompanyLogoContainer>
                                    <img
                                        src='https://static.admirian.com/487d244e-1557-41a7-8aaf-e4566992db8c_demoprofilepic.svg'
                                        alt="logo"
                                        height={28}
                                        width={28}
                                        className="border-circle"
                                    />
                                    <img
                                        src="https://static.admirian.com/657ef4b3-0905-4fc4-ad7f-3bedec4362bc_youtube-bg-white.svg"
                                        alt="logo"
                                        height={18}
                                        width={18}
                                        className="border-circle"
                                        style={{
                                            marginLeft: '-8px'
                                        }}
                                    />
                                </CompanyLogoContainer>
                                <div>
                                    <CardHeadingAnalytics>
                                        Post Density
                                    </CardHeadingAnalytics>
                                    <InformationText style={{ paddingLeft: 4 }}> Most posted day of week</InformationText>
                                </div>
                            </FlexContainer>

                            <div className="w-100">
                                <img
                                    src="https://static.admirian.com/ff0ddc4b-e33e-42b6-ab58-728fba7b5c96_emptyBarChart.svg"
                                    className="w-100"
                                    height={300}
                                    alt="Post"
                                />

                            </div>
                        </PostDensityContainer>

                        <PostDensityContainer id="activityHeatMapyt" style={{ height: '410px' }}>
                            <FlexContainer style={{ alignItems: 'center' }}>
                                <CompanyLogoContainer>
                                    <img
                                        src='https://static.admirian.com/487d244e-1557-41a7-8aaf-e4566992db8c_demoprofilepic.svg'
                                        alt="logo"
                                        height={28}
                                        width={28}
                                        className="border-circle"
                                    />
                                    <img
                                        src="https://static.admirian.com/657ef4b3-0905-4fc4-ad7f-3bedec4362bc_youtube-bg-white.svg"
                                        alt="logo"
                                        height={18}
                                        width={18}
                                        className="border-circle"
                                        style={{
                                            marginLeft: '-8px'
                                        }}
                                    />
                                </CompanyLogoContainer>
                                <div>
                                    <CardHeadingAnalytics>
                                        Activity Heat-map
                                    </CardHeadingAnalytics>
                                    <InformationText style={{ paddingLeft: 4 }}>
                                        Most Active time of week
                                    </InformationText>
                                </div>
                            </FlexContainer>

                            <div className="w-100" style={{ height: "400px", position: 'relative' }}>
                                <img
                                    src="https://static.admirian.com/9797c896-a78b-44a7-a0cd-5082b452616f_emptyActivityHeatMap.svg"
                                    style={{ height: '100%' }}
                                    className="w-100"
                                    alt="Activity Heat Map "
                                />
                                <img style={{ position: 'absolute', top: '30%', left: '35%' }} width={256} height={94} class="image2" src='https://static.admirian.com/97443610-74bc-47a1-bbe2-819897ffd40b_noData.svg' />
                            </div>
                        </PostDensityContainer>
                    </CreatorAnalyticsContainer>
                </Col>
            </Row>
            <FooterContainer
                onClick={() => {
                    handleConnectSocialProfile()
                }}
                className="cursor-pointer"
            >
                <div>
                    Add your social account to view your profile analytics
                </div>
                <div>
                    {`Add Social Profile >>`}
                </div>

            </FooterContainer>
        </HomePageContainer>
    )
}

export default DemoProfile