import { Carousel, Col, Row, Tabs, notification } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import React, { useEffect, useState } from "react";
import { ActivityCard, ActivityCardDetail, ActivityContainer, ActivityHeading, ActivitySubHeading, CampaignContainer, CampaignHeading, CampaignListingContainer, CustomButton, FeaturedMediaCard, MetricDetail, MetricHeading, PersonalRequestsHeading, PhotoContainer, TabHeading, TableActionButton, TableDataDiscription, TableDataHeading, TableImageContainer, ToggleButton, NoDataText, FeaturePostContainer, CustomRow, CommentedDate, UserNameText, CarouselContainer, CampaignBannerImage, CampaignNameText, NoBrandFoundContainer, CampaignBannerText } from "./style";
import {MehFilled} from '@ant-design/icons';
import  {RightOutlined, LeftOutlined} from '@ant-design/icons';
import InfluencerTable from "../../components/InfluencerTable";
import { FlexColContainer, FlexContainer, SpaceBetweenContainer } from "../../utils/common";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { parseJwtToken } from "../../utils";
import { campaignObjective } from "../../utils/HelperFunctions/common";
import moment from 'moment'
import { BASE_URL } from "../../utils/HelperFunctions/constants";
import { creatorStatusList, discoverstatuses, newRequestList } from "./utils";
import { isMobile } from "../../utils/constants/inedx";

const CampaignCreator = ({creatorDetails}) => {

    const navigate = useNavigate()
    const CarouselRef = React.createRef();
    const [carouselActiveIndex,setCarouselActiveIndex ] = useState(0)
    const [discoverCategorySelected, setDiscoverCategorySelected] = useState('All')
    const [myCampaignCategorySelected, setMyCampaignCategorySelected] = useState('IN_PROGRESS')
    const [requestCategorySelected, setRequestCategorySelected] = useState('PENDING')
    const refresh_token = parseJwtToken(localStorage.getItem('refresh_token'))
    const [discoverTableData, setDiscoverTableData] = useState([])
    const [discoverTotalData,setDiscoverTotalData] = useState(0)
    const [mycampaignTableData, setMyCampaignTableData] = useState([])
    const [mycampaignTotalData,setMycampaignTotalData] = useState(0)
    const [requestTableData, setRequestTableData] = useState([])
    const [requestCarouselData, setRequestCarouselData] = useState([])
    const [requestTotalData,setRequestTotalData] = useState(0)
    const [selectedTab,setSelectedTab] = useState('discoverCampaign')
    const [campaignStatus , setCampaignStatus] = useState('IN_PROGRESS')
    const [activeCategory,setActiveCategory] = useState('In progress')
    const [requestCampaignStatus , setRequestCampaignStatus] = useState('PUBLISHED,IN_PROGRESS')
    const [requestActiveCategory,setRequestActiveCategory] = useState('Pending')
    const [activityLog,setActivityLog] = useState([])

    const [discoverTablePageNumber,setDiscoverTablePageNumber] = useState(1)
    const [mycampaignTablePageNumber,setMycampaignTablePageNumber] = useState(1)
    const [requestTablePageNumber,setRequestTablePageNumber] = useState(1)

    const fetchActivityLog = async() => {
        try{
            const response = await axios.get(`${BASE_URL}/campaigns/${refresh_token?.user_uuid}/creator-activity-log/`)
            if(response?.data){
                setActivityLog(response?.data?.activity)
            }else{
                setActivityLog([])
            }
        }catch(error){
            console.log('error ',error)
            notification.error({
                message : error?.response?.data?.error || error?.message
            })
        }
    }

    useEffect(()=>{
            fetchActivityLog()
    },[])



    const fetchDiscoverTableData = async(pageNumber) => {
        try{
            if(discoverCategorySelected === 'All'){
                const response = await axios.get(`${BASE_URL}/campaigns/creator/${refresh_token?.user_uuid}/discover/?page=${pageNumber}`)
                if(response?.data?.campaigns?.length > 0){
                    setDiscoverTableData(response?.data?.campaigns)
                    setDiscoverTotalData(response?.data?.total_pages * 25)
                }else{
                    setDiscoverTableData([])
                    setDiscoverTotalData(0)
                }
            }else{
                const response = await axios.get(`${BASE_URL}/campaigns/creator/${refresh_token?.user_uuid}/?&campaign_type=broadcast&creator_status=accepted&page=${pageNumber}`)
                if(response?.data?.campaigns){
                    setDiscoverTableData(response?.data?.campaigns)
                    setDiscoverTotalData(response?.data?.total_pages * 25)
                }else{
                    setDiscoverTableData([])
                    setDiscoverTotalData(0)
                }
            }
            
        }catch(error){
            setDiscoverTableData([])
            setDiscoverTotalData(0)
            console.log(error);
            notification.error({
                message : error?.response?.data?.message
            })
        }
    }
    const fetchMycampaignTableData = async(pageNumber) => {
        try{
            const response = await axios.get(`${BASE_URL}/campaigns/creator/${refresh_token?.user_uuid}/?&campaign_status=${campaignStatus}&creator_status=${myCampaignCategorySelected}&page=${pageNumber}`)
            if(response?.data?.campaigns){
                setMyCampaignTableData(response?.data?.campaigns)
                setMycampaignTotalData(response?.data?.total_pages * 25)
            }else{
                setMyCampaignTableData([])
                setMycampaignTotalData(0)
            }
        }catch(error){
            setMyCampaignTableData([])
            setMycampaignTotalData(0)
            console.log(error);
            notification.error({
                message : error?.response?.data?.message
            })
        }
    }
    const fetchRequestTableData = async(pageNumber) => {
        try{
            const response = await axios.get(`${BASE_URL}/campaigns/creator/${refresh_token?.user_uuid}/?&campaign_status=${requestCampaignStatus}&creator_status=${requestCategorySelected}&page=${pageNumber}`)
            if(response?.data?.campaigns){
                setRequestTableData(response?.data?.campaigns)
                setRequestTotalData(response?.data?.total_pages * 25)
            }else{
                setRequestTableData([])
                setRequestTotalData(0)
            }
        }catch(error){
            console.log(error);
            setRequestTableData([])
            setRequestTotalData(0)
            notification.error({
                message : error?.response?.data?.message
            })
        }
    }

    const fetchRequstCarouselData = async() => {
        try{
            const response = await axios.get(`${BASE_URL}/campaigns/creator/${refresh_token?.user_uuid}/?&campaign_status=${requestCampaignStatus}&creator_status=${requestCategorySelected}&page=1`)
            if(response?.data?.campaigns){
                setRequestCarouselData(response?.data?.campaigns)
            }else{
                setRequestCarouselData([])
            }
        }catch(error){
            console.log(error);
            setRequestCarouselData([])
            notification.error({
                message : error?.response?.data?.message
            })
        }
    }

    useEffect(()=>{
        fetchRequstCarouselData()
    },[])

    useEffect(()=>{
        
        if(selectedTab === "discoverCampaign"){
            fetchDiscoverTableData(discoverTablePageNumber)
        }else if(selectedTab === "myCampaign"){
            fetchMycampaignTableData(mycampaignTablePageNumber)
        }else if(selectedTab === "newRequests"){
            fetchRequestTableData(requestTablePageNumber)
        }        
    },[selectedTab,activeCategory,requestActiveCategory,discoverCategorySelected,discoverTablePageNumber,mycampaignTablePageNumber,requestTablePageNumber])

    const discoverTableColumns = [
        {
          title: 'Payment Mode',
          key: 'brand_name',
          dataIndex: 'brand_name',
          editable: false,
          render : (data,record)=>{
            return(        
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <TableImageContainer>
                        <img
                        src={`${record?.objective?.toLowerCase() === campaignObjective.Other ? "https://static.admirian.com/193148c6-8070-4c61-90dd-68f2b82e8bd9_Property_1=Custom.svg" :
                        record?.objective?.toLowerCase() === campaignObjective.Promotion ? "https://static.admirian.com/13afd91f-0c43-4342-bf46-99ecb07c958e_Property_1=Promotion.svg" :
                        record?.objective?.toLowerCase() === campaignObjective.Review ? "https://static.admirian.com/a69a0475-1510-45a5-b537-8708b92d3c74_Property_1=Review.svg" :
                        record?.objective?.toLowerCase() === campaignObjective["Awareness"] ? "https://static.admirian.com/019e542d-dcd7-4c8d-b554-1928f498ca6a_Property_1=Awareness.svg" :
                                    "https://static.admirian.com/ad417c95-9480-43b3-9341-6246a1aee6e6_Property_1=Product_Launch.svg"
                        }`}
                        className="w-100"
                        style={{height : '100%',borderRadius : '10px'}}
                        />
                    </TableImageContainer>
                </Col>
            )
          }
        },
        {
            title: 'Payment Mode',
            key: 'brand_name',
            dataIndex: 'brand_name',
            editable: false,
            render : (data,record)=>{
              return(        
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  <FlexColContainer style={{gap : '16px'}}>
                      <TableDataHeading>
                          Brand
                      </TableDataHeading>
                      <TableDataDiscription>
                          {data}
                      </TableDataDiscription>
                  </FlexColContainer>
              </Col>
              )
            }
          },
        {
          title: 'Payment Mode',
          key: 'campaign_name',
          dataIndex: 'campaign_name',
          editable: false,
          render : (data,record)=>{
            return(
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <FlexColContainer style={{gap : '16px'}}>
                    <TableDataHeading>
                        Campaign Name
                    </TableDataHeading>
                    <TableDataDiscription>
                        {data}
                    </TableDataDiscription>
                </FlexColContainer>
            </Col>
            )
          }
        },
        {
          title: 'Payment Mode',
          dataIndex: 'objective',
          key: 'objective',
          editable: false,
          render : (data,record)=>{
            return(
           
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <FlexColContainer style={{gap : '16px'}}>
                    <TableDataHeading>
                    Campaign Objectives
                    </TableDataHeading>
                    <TableDataDiscription>
                        {data}
                    </TableDataDiscription>
                </FlexColContainer>
            </Col>
            )
          }
        },
        {
          title: 'Payment Mode',
          key: 'media_type',
          dataIndex: 'media_type',
          editable: false,
          render : (data,record)=>{
            return(          
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <FlexColContainer style={{gap : '16px'}}>
                    <TableDataHeading>
                    Media Type
                    </TableDataHeading>
                    <TableDataDiscription>
                        {data?.join(', ')}
                    </TableDataDiscription>
                </FlexColContainer>
            </Col>
            )
          }
        },
        {
          title: 'Payment Mode',
          key: 'RequestExpiry',
          dataIndex: 'RequestExpiry',
          editable: false,
          render : (data,record)=>{
            return(
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <FlexColContainer style={{gap : '16px'}}>
                    <TableDataHeading>
                    Request Expiry
                    </TableDataHeading>
                    <TableDataDiscription>
                        {data}
                    </TableDataDiscription>
                </FlexColContainer>
            </Col>
            )
          }
        },
        {
            title: 'Action',
            key: 'created_at',
            dataIndex: 'created_at',
            editable: false,
            render : (data,record)=>{
              return(
              <>
              {/* {console.log(data,record)} */}
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <FlexColContainer style={{gap : '16px',alignItems : 'flex-end'}}>
                  <TableActionButton
                  onClick={()=>{
                    navigate(`/campaign-summary-influencer/${record?.campaign_id}`)
                  }}
                  >
                        View
                  </TableActionButton>
                  <TableDataHeading>
                    {/* Created {data} */}
                    Created on {moment(data, 'DD-MM-YYYY hh:mm:ss A').format('DD/MM/YYYY')}
                  </TableDataHeading>
                  </FlexColContainer>
              </Col>
              </>
              )
            }
          },
    ]
    const myCampaignTableColumns = [
        {
          title: 'Payment Mode',
          dataIndex: 'brand_logo',
          key: 'brand_logo',
          editable: false,
          render : (data,record)=>{
            return(
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                <TableImageContainer>
                    <img
                    src={`${record?.objective?.toLowerCase() === campaignObjective.Other ? "https://static.admirian.com/193148c6-8070-4c61-90dd-68f2b82e8bd9_Property_1=Custom.svg" :
                    record?.objective?.toLowerCase() === campaignObjective.Promotion ? "https://static.admirian.com/13afd91f-0c43-4342-bf46-99ecb07c958e_Property_1=Promotion.svg" :
                    record?.objective?.toLowerCase() === campaignObjective.Review ? "https://static.admirian.com/a69a0475-1510-45a5-b537-8708b92d3c74_Property_1=Review.svg" :
                    record?.objective?.toLowerCase() === campaignObjective["Awareness"] ? "https://static.admirian.com/019e542d-dcd7-4c8d-b554-1928f498ca6a_Property_1=Awareness.svg" :
                                "https://static.admirian.com/ad417c95-9480-43b3-9341-6246a1aee6e6_Property_1=Product_Launch.svg"
                    }`}
                    className="w-100"
                    style={{height : '100%',borderRadius : '10px'}}
                    />

                </TableImageContainer>
            </Col>
            )
          }
        },
        {
            title: 'Payment Mode',
            dataIndex: 'brand_name',
            key: 'brand_name',
            editable: false,
            render : (data,record)=>{
              return(
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  <FlexColContainer style={{gap : '16px'}}>
                    <TableDataHeading>
                        Brand
                    </TableDataHeading>
                    <TableDataDiscription>
                        {data}
                    </TableDataDiscription>
                    </FlexColContainer>
              </Col>
              )
            }
        },
        {
          title: 'Payment Mode',
          dataIndex: 'campaign_name',
          key: 'campaign_name',
          editable: false,
          render : (data,record)=>{
            return(
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <FlexColContainer style={{gap : '16px'}}>
                    <TableDataHeading>
                        Campaign Name
                    </TableDataHeading>
                    <TableDataDiscription>
                        {data}
                    </TableDataDiscription>
                </FlexColContainer>
            </Col>
            )
          }
        },
        {
          title: 'Payment Mode',
          dataIndex: 'objective',
          key: 'objective',
          editable: false,
          render : (data,record)=>{
            return(
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <FlexColContainer style={{gap : '16px'}}>
                    <TableDataHeading>
                    Campaign Objectives
                    </TableDataHeading>
                    <TableDataDiscription>
                        {data}
                    </TableDataDiscription>
                </FlexColContainer>
            </Col>
            )
          }
        },
        {
          title: 'Payment Mode',
          dataIndex: 'media_type',
          key: 'media_type',
          editable: false,
          render : (data,record)=>{
            return(
            <Col  xl={24} lg={24} md={24} sm={24} xs={24}>
                <FlexColContainer style={{gap: '16px'}}>
                    <TableDataHeading>
                    Media Type
                    </TableDataHeading>
                    <TableDataDiscription>
                        {data?.join(', ')}
                    </TableDataDiscription>
                </FlexColContainer>
            </Col>
            )
          }
        },
        {
          title: 'Payment Mode',
          dataIndex: 'content_delivered',
          key: 'content_delivered',
          editable: false,
          render : (data,record)=>{
            return(
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <FlexColContainer style={{gap : '16px'}}>
                    <TableDataHeading>
                    Content delivered
                    </TableDataHeading>
                    <TableDataDiscription>
                        {data}
                    </TableDataDiscription>
                </FlexColContainer>
            </Col>
            )
          }
        },
        {
            title: 'Action',
            dataIndex: 'created_at',
            key: 'created_at',
            editable: false,
            render : (data,record)=>{
                return(
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <FlexColContainer style={{gap : '16px',alignItems : 'flex-end'}}>
                        <TableActionButton
                        onClick={()=>{
                        navigate(`/campaign-summary-influencer/${record?.campaign_id}`)
                        }}
                        >
                            View
                        </TableActionButton>
                        <TableDataHeading>
                        Created on {moment(data, 'DD-MM-YYYY hh:mm:ss A').format('DD/MM/YYYY')}
                        </TableDataHeading>
                    </FlexColContainer>
                </Col>
                )
              }
          },
    ]

    return(
        <CampaignContainer>
            <Row gutter={[20,32]} className="w-100">
                <Col xl={17} lg={17} md={12} sm={24} xs={24} style={{maxHeight : 'inherit'}}>
                <FeaturePostContainer id="featurePost">
                    <PersonalRequestsHeading>
                        Greetings, {creatorDetails?.name}! 
                        <span style={{fontWeight : 400}}>
                            {/* {requestCarouselData?.length > 0 ? requestCarouselData?.length : 'No'} New request for you */}
                            {requestCarouselData?.length > 0 ? 
                                " Review Your Latest Campaign Invites"
                                : " Stay Tuned for Brand Requests!"
                            }
                        </span>
                    </PersonalRequestsHeading>
                    {requestCarouselData?.length > 0 &&
                        <FlexContainer style={{flexDirection: 'row'}}>
                            <ToggleButton
                            style={{marginRight : '16px'}}
                            onClick={()=>{
                                CarouselRef.current.prev()
                            }}
                            disabled = {carouselActiveIndex === 0}
                            icon = {<LeftOutlined color="#000000"/>}
                            />                           
                            <ToggleButton
                            onClick={()=>{
                                CarouselRef.current.next()
                            }}
                            disabled = {isMobile ? carouselActiveIndex >= requestCarouselData?.length - 1 :  carouselActiveIndex >= requestCarouselData?.length - 3}
                            icon = {<RightOutlined color="#000000"/>}
                            />                            
                        </FlexContainer>
                    }
                </FeaturePostContainer>
                {requestCarouselData?.length > 0 ?
                <CarouselContainer>
                    <Carousel
                    slidesToShow={isMobile ? 1 : 3 } 
                    infinite = {false} 
                    ref ={CarouselRef}
                    beforeChange = {(current, next)=>setCarouselActiveIndex(next)}
                    >
                        {/* <Row gutter={[16,20]}> */}
                        {requestCarouselData?.map((featuredPost, index)=>{
                            return(
                                <Col xl={24} lg={24} md={24} sm={24} xs={24} key={index}>
                                    <FeaturedMediaCard
                                    className="cursor-pointer"
                                    onClick={()=>{
                                        navigate(`/campaign-summary-influencer/${featuredPost?.campaign_id}`)
                                    }}
                                    >
                                        <CampaignBannerImage 
                                        src={featuredPost?.campaign_banner ? featuredPost?.campaign_banner  : ''} 
                                        />
                                        <FlexColContainer>
                                            <CampaignBannerText>
                                            {featuredPost?.campaign_name}
                                            </CampaignBannerText>
                                            <CampaignNameText>
                                            Campaign Name
                                            </CampaignNameText>
                                        </FlexColContainer>
                                        <Row className="w-100" style={{display : 'flex'}}>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <MetricHeading>
                                            {featuredPost?.objective}
                                            </MetricHeading>
                                            <MetricDetail>
                                            Campaign objective
                                            </MetricDetail>
                                        </Col>
                                        {/* <Col span={12}>
                                            <MetricHeading>
                                            Fashion
                                            </MetricHeading>
                                            <MetricDetail>
                                            Category
                                            </MetricDetail>
                                        </Col> */}
                                        </Row>
                                    </FeaturedMediaCard>
                                </Col>
                            )
                        })}
                        {/* </Row> */}
                    </Carousel>
                    </CarouselContainer>
                    :
                    <NoBrandFoundContainer>
                        <img src="https://static.admirian.com/c224b1b7-bd85-476a-bff2-18a2a510b321_no-file.svg" height={95} width={250} alt="No files"/>
                        <NoDataText>No new brand requests yet? Don't worry! Head over to "Discover Campaigns" section to explore exciting open campaigns</NoDataText>
                    </NoBrandFoundContainer>
                }
                </Col>
                <Col xl={7} lg={7} md={12} sm={24} xs={24}>
                    <ActivityContainer>
                        <ActivityHeading>
                            Activity
                        </ActivityHeading>
                        <ActivitySubHeading>
                        Find the most recent brand and campaign activity updates here
                        </ActivitySubHeading>
                        <CustomRow gutter={[0,16]}>
                        {activityLog?.map((activities, index)=>{
                            return(
                            <Col xl={24} lg={24} md={24} sm={24} xs={24} key={index}>
                                <ActivityCard>
                                        <PhotoContainer>
                                        {activities?.author_thumbnail ? 
                                            <img
                                            src={activities?.author_thumbnail}
                                            alt="logo"
                                            height={24}
                                            width={24}
                                            className="border-circle"
                                            />
                                            :  
                                            <MehFilled className="border-circle" style={{fontSize : 24, color : 'gray'}} />
                                        }
                                        </PhotoContainer>
                                    <div className="w-100">
                                        <SpaceBetweenContainer className="w-100" style={{alignItems : 'center', marginBottom : 12}}>
                                            <UserNameText>{activities?.author_name}</UserNameText>
                                            <CommentedDate>{activities?.timestamp}</CommentedDate>
                                        </SpaceBetweenContainer>
                                        <ActivityCardDetail>{activities?.message}</ActivityCardDetail>                                        
                                    </div>
                                </ActivityCard>
                            </Col>
                            )
                        })}
                        </CustomRow>
                    </ActivityContainer>                  
                </Col>
            </Row> 
            <CampaignHeading>
            Find campaigns that suit you
            </CampaignHeading>
            <CampaignListingContainer>
            <Tabs
            defaultActiveKey="discoverCampaign"
            onChange={(value)=>setSelectedTab(value)}
            >
                <TabPane
                tab={
                    <TabHeading>
                        Discover Campaigns
                    </TabHeading>
                    }
                key="discoverCampaign"
                >
                    <Row gutter={[12,12]} style={{marginBottom : '16px'}}>
                    {discoverstatuses.map((type) => {                                     
                        return (
                            <CustomButton
                                isActive = {discoverCategorySelected === type.value}
                                key={type.value}
                                onClick={() => {
                                    setDiscoverTablePageNumber(1)
                                    setDiscoverCategorySelected(type.value)
                                }}
                            >
                                {type.name}
                            </CustomButton>
                            )
                    })}
                    </Row>
                    <InfluencerTable
                    tableData = {discoverTableData}
                    columns = {discoverTableColumns}
                    total = {discoverTotalData}
                    setPageNumber = {setDiscoverTablePageNumber}
                    pageNumber = {discoverTablePageNumber}
                    />
                </TabPane>
                
                <TabPane
                tab={
                    <TabHeading>
                       My Campaigns
                    </TabHeading>
                    }
                key="myCampaign"
                >
                    <Row gutter={[12,12]} style={{marginBottom : '16px'}}>
                    {creatorStatusList?.map((type) => {                                     
                        return (
                            <CustomButton
                                isActive = {activeCategory === type?.name}
                                key={type}
                                onClick={() => {
                                    setMycampaignTablePageNumber(1)
                                    setActiveCategory(type?.name)
                                    setMyCampaignCategorySelected(type?.value)
                                    setCampaignStatus(type?.campaignStatus)
                                }}
                            >
                                {type?.name}
                            </CustomButton>
                            )
                    })}
                    </Row>
                    <InfluencerTable
                    tableData = {mycampaignTableData}
                    columns = {myCampaignTableColumns}
                    total = {mycampaignTotalData}
                    setPageNumber = {setMycampaignTablePageNumber}
                    pageNumber = {mycampaignTablePageNumber}
                    />
                </TabPane>
                <TabPane
                tab={
                    <TabHeading>
                       New Requests
                    </TabHeading>
                    }
                key="newRequests"
                >
                    <Row gutter={[12,12]} style={{marginBottom : '16px'}}>
                    {newRequestList?.map((type) => {                                     
                        return (
                            <CustomButton
                                isActive = {requestActiveCategory === type?.name}
                                key={type}
                                onClick={() => {
                                    setRequestTablePageNumber(1)
                                    setRequestActiveCategory(type?.name)
                                    setRequestCategorySelected(type?.value)
                                    setRequestCampaignStatus(type?.campaignStatus)
                                }}
                            >
                                {type?.name}
                            </CustomButton>
                            )
                    })}
                    </Row>
                    <InfluencerTable
                    tableData = {requestTableData}
                    columns = {myCampaignTableColumns}
                    total = {requestTotalData}
                    setPageNumber = {setRequestTablePageNumber}
                    pageNumber = {requestTablePageNumber}
                    />
                </TabPane>
            </Tabs>
            </CampaignListingContainer>
       </CampaignContainer>
    )
}

export default CampaignCreator