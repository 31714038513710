import { Button } from "antd";
import styled from "styled-components";

export const CompleteprofileContainer = styled.div`
    display : flex;
    background : #FFFFFF;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 32px;
    gap: 34px;
    border-top: 3px solid #F6F6FF;
    border-radius: 10px;
    min-height : 80px;
    margin : 16px 0px;
`

export const CompleteProfileButton = styled(Button)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 190px;
    height: 50px;
    background: linear-gradient(0deg, #F86624, #F86624), #F9C80E !important;
    border-radius: 60px;
    border : 0px !important;
    color: #FFFFFF !important;
    font-weight : 500;
    font-size : 18px;
`
