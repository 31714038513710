import styled from 'styled-components';
import {Input } from 'antd';

export const FormContainer = styled.div`
    display : flex;
    width : 100%;
    justify-content : center;
    align-items : center;

    .ant-input{
        border-radius : 40px !important;
        height : 50px;
    }
`
export const CustomInputPassword = styled(Input.Password)`
  border-radius : 40px !important;
  input:-internal-autofill-selected {
    background-color : #ffffff !important
  }
`;

export const RadioContainer = styled.div`
  .selected-radio-button {
    background: #4E4BEE1A !important;
    border: 1px solid #4E4BEE !important;
    border-color: #4E4BEE !important;
    color: #4E4BEE !important;
    line-height : 50px !important;
    border-radius: 50px !important;
    font-weight : 400 !important;
  }
  .ant-radio-button-wrapper {
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(1, 0, 67, 0.2);
    border-radius: 50px;
    font-weight : 400;
    color : #010043;
    height : 50px;
    font-size : 16px;
    &:first-child {
      padding:0px 20px;
      margin-right : 15px;
    }
    &:last-child {
      padding:0px 20px;
      border-inline-start-width : 1px;
    }
    text-align: center;
  }
  .ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: #4E4BEE1A;
    border: 1px solid #4E4BEE;
    border-color: #4E4BEE;
    color: #4E4BEE;
    line-height : 50px;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    display: none;
  }
  .ant-radio-button-wrapper:not(:first-child)::before {
    display: none;
  }
`;