import styled from 'styled-components';
import media from '../../utils/UIElements/media';

export const Container = styled.div`
    margin : 32px 48px;
    border-radius: 10px;
    background: var(--Typo-White, #FFF);
    padding : 0 32px;
    display : flex;
    flex-direction : column;
    ${media.mobile`
        padding: 0px 16px;
        margin: 16px 24px;
    `}
    .ant-divider-horizontal{
        margin : 0;
    }
`;

export const CardContainer = styled.div`
    display: flex;
    padding: var(--Space-List-Lg, 24px);
    justify-content: center;
    align-items: flex-start;
    gap: 24px;
    border-radius: 10px;
    flex-direction : column;
    border: 1px solid var(--UI-Component-Stroke-light, #D8D7EA);
`

export const CardHeading = styled.div`
    color: var(--Typo-Mid-High, #2C2B50);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom : 8px;
    ${media.mobile`
        font-size: 10px;
    `}
`

export const CardDetail = styled.div`
    color: var(--Typo-High, #0F0E1B);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    ${media.mobile`
        font-size: 12px;
        line-height: 18px;
    `}

`

