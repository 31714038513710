import React, { useEffect, useState } from "react";
import { CardContainer, CardDetail, CardHeading, Container } from "./style";
import { Col, Divider, Row, notification } from "antd";
import { LeftOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { parseJwtToken } from "../../utils";
import { BASE_URL } from "../../utils/HelperFunctions/constants";
import { FlexContainer } from "../../utils/common";

const DeliverableInformation = (props) => {
    const refresh_token = parseJwtToken(localStorage.getItem('refresh_token'));
    const [campaignData, setCampaignData] = useState({})

    const fetchCampaignDetails = async (campaignid) => {
        try {
            const response = await axios.get(`${BASE_URL}/campaigns/${refresh_token?.user_uuid}/${campaignid}/deliverables/`)
            if (response?.data) {
                setCampaignData(response?.data)
            } else {
                setCampaignData({})
            }
        } catch (error) {
            console.log('error ', error)
            notification.error({
                message: error?.response?.data?.error || error?.message
            })
        }
    }

    useEffect(() => {
        if (refresh_token?.user_type !== "Brand") {
            fetchCampaignDetails(props?.campaignid)
        }
    }, [props?.campaignid]) 

    const navigate = useNavigate()
    return (
        <Container style={refresh_token?.user_type === "Brand" ? { width: "unset", margin: "unset" } : { width: "100%" }}>
            {
                refresh_token?.user_type !== "Brand" && (
                    <>
                        <FlexContainer
                            className="cursor-pointer w-100"
                            style={{ minHeight: '64px', alignItems: 'center'}}
                            onClick={() => {
                                navigate(`/campaign-summary-influencer/${props?.campaignid}`)
                            }}
                        >
                            <LeftOutlined style={{ marginRight: 4 }} /> Back
                        </FlexContainer>
                        <Divider />
                    </>
                )
            }
            <Row gutter={[16, 20]} style={{ marginTop: refresh_token?.user_type !== "Brand" ? "32px" : 'unset', justifyContent: 'center' }}>
                <Col
                    xl={refresh_token?.user_type === "Brand" ? "" : 16}
                    lg={refresh_token?.user_type === "Brand" ? "" : 16}
                    md={refresh_token?.user_type === "Brand" ? "" : 18}
                    sm={24}
                    xs={24}
                >
                    {
                        refresh_token?.user_type !== "Brand" && (
                            <CardContainer style={{ marginBottom: '32px' }}>
                                <Row gutter={[12, 12]} className="w-100">
                                    <Col xl={8} lg={8} md={8} sm={8} xs={24}>
                                        <FlexContainer>
                                            <img
                                                src={campaignData?.campaign_details?.brand_thumbnail}
                                                className="border-circle"
                                                width={48}
                                                height={48}
                                                style={{marginRight: 12}}
                                            />
                                            <div>
                                                <CardHeading>
                                                    Brand Name
                                                </CardHeading>
                                                <CardDetail>
                                                    {campaignData?.campaign_details?.brand_name}
                                                </CardDetail>
                                            </div>
                                        </FlexContainer>
                                    </Col>
                                    <Col xl={8} lg={8} md={8} sm={8} xs={12}>
                                        <CardHeading>
                                            Budget
                                        </CardHeading>
                                        <CardDetail>
                                            ₹{campaignData?.working_rate || campaignData?.overall_estimation}
                                        </CardDetail>
                                    </Col>
                                    <Col xl={8} lg={8} md={8} sm={8} xs={12}>
                                        <CardHeading>
                                            Status
                                        </CardHeading>
                                        <CardDetail>
                                            {campaignData?.campaign_creator_status}
                                        </CardDetail>
                                    </Col>
                                </Row>
                            </CardContainer>
                        )
                    }
                    <CardContainer style={{ marginBottom: '32px' }}>
                        <Row gutter={[12, 12]} className="w-100">
                            <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                                <CardHeading>
                                    Deliverable Name
                                </CardHeading>
                                <CardDetail>
                                {campaignData?.deliverables?.filter((deliverableData)=>deliverableData?.campaign_creator_deliverable_id == props?.creatorDeliverableId)[0]?.deliverable_name }
                                </CardDetail>
                            </Col>
                            <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                                <CardHeading>
                                    Type
                                </CardHeading>
                                <CardDetail>
                                {campaignData?.deliverables?.filter((deliverableData)=>deliverableData?.campaign_creator_deliverable_id == props?.creatorDeliverableId)[0]?.deliverable_type }
                                </CardDetail>
                            </Col>
                            <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                                <CardHeading>
                                    Duration
                                </CardHeading>
                                <CardDetail>
                                {campaignData?.deliverables?.filter((deliverableData)=>deliverableData?.campaign_creator_deliverable_id == props?.creatorDeliverableId)[0]?.duration }
                                </CardDetail>
                            </Col>
                        </Row>
                        <Row gutter={[12, 12]} className="w-100">
                            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <CardHeading>
                                Brief
                            </CardHeading>
                            <CardDetail>
                            {campaignData?.deliverables?.filter((deliverableData)=>deliverableData?.campaign_creator_deliverable_id == props?.creatorDeliverableId)[0]?.brief }
                            </CardDetail>
                            </Col>
                        </Row>
                        <Row gutter={[12, 12]} className="w-100">
                            <Col span={24}>
                            <CardHeading>
                                Reference
                            </CardHeading>
                            <CardDetail>
                            {campaignData?.deliverables?.filter((deliverableData)=>deliverableData?.campaign_creator_deliverable_id == props?.creatorDeliverableId)[0]?.reference }
                            </CardDetail>
                            </Col>
                        </Row>
                    </CardContainer>
                </Col>
            </Row>
        </Container>
    )
}

export default DeliverableInformation;