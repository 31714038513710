import { Button, Col, Divider, Input, notification, Row, Tabs } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import React, { useEffect, useState } from "react";
import { CancelButton, CompanyDetails, CompanyLogoContainer, Container, CustomButton, FormContainer, LogoContainer, SaveButton, TabContainer } from "./style";
import { EditOutlined } from '@ant-design/icons';
import UpdateLogoModal from "./UpdateLogoModal";
import UpdatePasswordModal from "./UpdatePasswordModal";
import { parseJwtToken } from "../../utils";
import axios from "axios";
import {MehFilled} from '@ant-design/icons';
import UpdateBasicInfoModal from "./UpdateBasicInfoModal";
import { BASE_URL } from "../../utils/HelperFunctions/constants";

const Profile =({brandDetails,getBrandDetail})=>{
    const buttons = ["Fashion","Education","Fitness","Automotive","Education","Fitness","More"]

    const [isEdit, setIsEdit] = useState([])
    const [categoryList, setCategoryList] = useState([])
    const [userEmail, setUserEmail] = useState('')
    const [userPhone, setUserPhone] = useState('')
    const [userWebsite, setUserWebsite] = useState('')
    const [updateLogoModalVisible,setUpdateLogoModalVisible] = useState(false)
    const [updatePasswordModalVisible,setUpdatePasswordModalVisible] = useState(false)
    const [updateBasicInfoModalVisible,setUpdateBasicInfoModalVisible] = useState(false)
    const [isHovering,setIsHovering] = useState(false)
    const refresh_token = parseJwtToken(localStorage.getItem('refresh_token'))
    const removeIsEdit = (value) => {
        const newArr = [...isEdit]
        var i = 0;
        while (i < newArr.length) {
          if (newArr[i] === value) {
            newArr.splice(i, 1);
          } else {
            ++i;
          }
        }
        setIsEdit(newArr)
    }
    useEffect(()=>{
        if(updatePasswordModalVisible === false){
            removeIsEdit("password")
        }
    },[updatePasswordModalVisible])

    const isNumber = (e) => {
        e.target.value = e.target.value.replace(/[^0-9]/g, "");
    }

    useEffect(()=>{
        setUserEmail(brandDetails?.email)
        setUserPhone(brandDetails?.phone_number)
        setUserWebsite(brandDetails?.website)
    },[brandDetails])

    const getCategoryList = async() =>{
        const response = await axios.get(`${BASE_URL}/profiles/categories/`)
        if(!response?.data?.error){
            setCategoryList(response?.data)
        }
    }
    useEffect(()=>{
        getCategoryList()
    },[])


    const handleChangeEmail= async()=>{
        let formData = new FormData();
        const re = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
        // var re = new RegExp("^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$")
        if(userEmail.length >= 1 && re.test(userEmail)){
            formData.append("email", userEmail)
        
        try{
            const response =  await axios.patch(`${BASE_URL}/profiles/brands/${refresh_token?.user_uuid}/`,
            formData
            )

            if(!response?.data?.error){
                notification.success({message :'updated Successfully'})
                getBrandDetail()
                removeIsEdit("email")
                // carouselRef.current.next()
            }
            else{
                notification.error({
                    message : response?.data?.error?.message
                })
            }
            
        }catch(error){
            notification.error({message : error?.response?.data?.message})
        }
        }else{
            notification.error({message : "Please enter valid Email Id"})
        }
    }
    const handleChangePhone= async()=>{
        let formData = new FormData();
        const re  = /^((\+91?)|\+)?[7-9][0-9]{9}$/g
        if(re.test(userPhone)){
            formData.append("phone_number", userPhone)
        
        try{
            const response =  await axios.patch(`${BASE_URL}/profiles/brands/${refresh_token?.user_uuid}/`,
            formData
            )

            if(!response?.data?.error){
                notification.success({message :'Updated Successfully'})
                getBrandDetail()
                removeIsEdit("phone")
                // carouselRef.current.next()
            }
            else{
                notification.error({
                    message : response?.data?.error?.message
                })
            }
            
        }catch(error){
            notification.error({message : error?.response?.data?.message})
        }
        }else{
            notification.error({message : "Please enter valid Phone number. (Valid Format : +91XXXXXXXXXXXX)"})
        }
    }
    const handleChangeWebsite= async()=>{
        let formData = new FormData();
        if(userWebsite.length >= 1){
            formData.append("website", userWebsite)
            try{
                const response =  await axios.patch(`${BASE_URL}/profiles/brands/${refresh_token?.user_uuid}/`,
                formData
                )

                if(!response?.data?.error){
                    notification.success({message :'updated Successfully'})
                    getBrandDetail()
                    removeIsEdit("website")
                    // carouselRef.current.next()
                }
                else{
                    notification.error({
                        message : response?.data?.error?.message
                    })
                }
                
            }catch(error){
                notification.error({message : error?.response?.data?.message})
            }
        }else{
            notification.error({message : "Please enter valid Website"})
        }
    }
    return(
        <Container>
            <Tabs
            defaultActiveKey="profile"

            tabBarExtraContent={
                <Col span={24} style={{display : 'flex',alignItems : 'center',justifyContent : "flex-end"}}>
                    <CompanyDetails>
                    {brandDetails && brandDetails?.brand_name}
                    </CompanyDetails>
                    <CompanyLogoContainer>
                    {brandDetails?.logo ? 
                        <img
                        src={brandDetails?.logo}
                        alt="logo"
                        style={{
                        height: '28px',
                        width: '28px',
                        borderRadius: '50%',
                        }}
                        />
                        :
                        <MehFilled style={{borderRadius : '50%', fontSize : '28px',color : "gray"}} />
                    }
                    </CompanyLogoContainer>
                    
                </Col>
              }
            >
                <TabPane
                tab="Profile"
                key="Profile"
                >
                    <TabContainer>
                        <Row gutter={[20,20]}>
                        {/* <CompanyLogoContainer> */}
                        <Col span={4}>
                        <LogoContainer
                        onMouseOver={()=>{
                            setIsHovering(true)
                        }}
                        onMouseOut={()=>{
                            setIsHovering(false)
                        }}  
                        backGroundImageUrl = {brandDetails?.logo}
                        >
                            {isHovering ? 
                                <div style={{display : "flex", justifyContent : "center",alignItems : 'center',width :'100%'}}>
                                    <EditOutlined 
                                    style={{background : '#F6F6FF', padding : '8px',borderRadius : "50%",cursor : 'pointer'}} 
                                    onClick={()=>{
                                        setUpdateLogoModalVisible(true)
                                    }}
                                    />
                                </div>
                                :
                                null
                            }
                        </LogoContainer>
                        </Col>
                        {/* </CompanyLogoContainer> */}
                        <Col span={20}>
                        <div style={{display : 'flex',alignItems: "center"}}>
                            <span style={{display : 'flex',fontSize: '32px',fontWeight : 600,color : "#010043",lineHeight : '39px',marginRight :'15px'}}>
                            {brandDetails?.brand_name}
                            </span>
                            <EditOutlined 
                            style={{background : '#F6F6FF', padding : '8px',borderRadius : "50%",cursor : 'pointer'}} 
                            onClick={()=>{
                                setUpdateBasicInfoModalVisible(true)
                            }}
                            />
                        </div>
                        <Row style={{display : 'flex',alignItems: "center"}}>
                            {
                                // brandDetails?.categories && brandDetails?.categories?.map((category)=>{
                                    brandDetails?.categories?.map((category)=>{
                                    return(
                                        <CustomButton>
                                            {category}
                                        </CustomButton>
                                    )
                                })
                            }
                        </Row>
                        </Col>

                        
                        </Row>
                        <Divider/>
                        <Col span={24}>
                            <span style={{display : 'flex',fontWeight : '600',fontSize : "14px",color:'#010043', marginBottom : '30px'}}>
                            Account Details
                            </span>
                        </Col>
                        <FormContainer>
                            <Row gutter={[20,30]} style={{display : 'flex', justifyContent : 'space-between'}}>
                                <Col span={6}>
                                    <span style={{display : 'flex',fontSize : '14px',color : '#010043',marginTop : '5px'}}>
                                    Email Address
                                    </span>
                                </Col>
                                <Col span={18}>
                                    <Input 
                                    disabled = {!isEdit.includes("email") && true}
                                    addonAfter={
                                        !isEdit.includes("email") ?
                                        <a
                                        style={{fontSize : '14px',color : '#1551EA'}}
                                        onClick={()=>{setIsEdit(prevValue=>[...prevValue,"email"])}}
                                        >Edit
                                        </a>
                                        : <></>
                                    }
                                    value = {userEmail}
                                    onChange = {(e)=>{setUserEmail(e?.target?.value)}}
                                    />
                                    {isEdit.includes("email") &&
                                        <Col span={24} style={{display : 'flex',justifyContent : 'flex-end',marginTop : '20px'}}>
                                        <CancelButton onClick={()=>{
                                            setUserEmail(brandDetails?.email)
                                            removeIsEdit("email")
                                        }}
                                        >
                                            Cancel
                                        </CancelButton>
                                        <SaveButton onClick={()=>{
                                            handleChangeEmail()
                                        }}
                                        >
                                            Save
                                        </SaveButton>
                                    </Col>}
                                </Col>
                                <Col span={6}>
                                    <span style={{display : 'flex',fontSize : '14px',color : '#010043',marginTop : '5px'}}>
                                    Mobile Number
                                    </span>
                                </Col>
                                <Col span={18}>
                                    <Input 
                                    disabled = {!isEdit.includes("phone") && true}
                                    addonAfter={
                                        !isEdit.includes("phone") ?
                                        <a
                                        style={{fontSize : '14px',color : '#1551EA'}}
                                        onClick={()=>{setIsEdit(prevValue=>[...prevValue,"phone"])}}
                                        >Edit
                                        </a>
                                        : <></>
                                    }
                                    value = {userPhone}
                                    onChange = {(e)=>{setUserPhone(e?.target?.value)}}
                                    onInput={isNumber}
                                    />
                                    {isEdit.includes("phone") &&
                                        <Col span={24} style={{display : 'flex',justifyContent : 'flex-end',marginTop : '20px'}}>
                                        <CancelButton onClick={()=>{
                                            setUserPhone(brandDetails?.phone_number)
                                            removeIsEdit("phone")
                                        }}
                                        >
                                            Cancel
                                        </CancelButton>
                                        <SaveButton onClick={()=>{
                                             handleChangePhone()
                                        }}
                                        >
                                            Save
                                        </SaveButton>
                                    </Col>}
                                </Col>
                                <Col span={6}>
                                    <span style={{display : 'flex',fontSize : '14px',color : '#010043',marginTop : '5px'}}>
                                    Website
                                    </span>
                                </Col>
                                <Col span={18}>
                                    <Input 
                                    disabled = {!isEdit.includes("website") && true}
                                    addonAfter={
                                        !isEdit.includes("website") ?
                                        <a
                                        style={{fontSize : '14px',color : '#1551EA'}}
                                        onClick={()=>{setIsEdit(prevValue=>[...prevValue,"website"])}}
                                        >Edit
                                        </a>
                                        : <></>
                                    }
                                    value = {userWebsite}
                                    onChange = {(e)=>{setUserWebsite(e?.target?.value)}}
                                    />
                                    {isEdit.includes("website") &&
                                        <Col span={24} style={{display : 'flex',justifyContent : 'flex-end',marginTop : '20px'}}>
                                        <CancelButton onClick={()=>{
                                            setUserWebsite(brandDetails?.websites)
                                            removeIsEdit("website")
                                        }}
                                        >
                                            Cancel
                                        </CancelButton>
                                        <SaveButton onClick={()=>{
                                            handleChangeWebsite()
                                        }}
                                        >
                                            Save
                                        </SaveButton>
                                    </Col>}
                                </Col>
                                <Col span={6}>
                                    <span style={{display : 'flex',fontSize : '14px',color : '#010043',marginTop : '5px'}}>
                                    Password
                                    </span>
                                </Col>
                                <Col span={18}>
                                    <Input 
                                    disabled = {true}
                                    type="password"
                                    addonAfter={
                                        !isEdit.includes("password") ?
                                        <a
                                        style={{fontSize : '14px',color : '#1551EA'}}
                                        onClick={()=>{
                                            setIsEdit(prevValue=>[...prevValue,"password"])
                                            setUpdatePasswordModalVisible(true)
                                        }}
                                        >Change password
                                        </a>
                                        : <></>
                                    }
                                    />
                                </Col>
                            </Row>
                        </FormContainer>
                    </TabContainer>
                </TabPane>
            </Tabs>
        <UpdateLogoModal
        visible={updateLogoModalVisible}
        setVisible = {setUpdateLogoModalVisible}
        brandDetails = {brandDetails}
        getBrandDetail ={getBrandDetail}
        />
        <UpdatePasswordModal
        visible={updatePasswordModalVisible}
        setVisible = {setUpdatePasswordModalVisible}
        brandDetails = {brandDetails}
        getBrandDetail = {getBrandDetail}
        />
        <UpdateBasicInfoModal
        visible={updateBasicInfoModalVisible}
        setVisible = {setUpdateBasicInfoModalVisible}
        brandDetails = {brandDetails}
        getBrandDetail = {getBrandDetail}
        categoryList ={categoryList}
        />
       </Container>
    )

}

export default Profile
