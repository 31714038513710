import styled from 'styled-components';

export const Container = styled.div`
    padding: 20px;
    background : #FFFFFF;
    border-radius: 4px;
    margin : 24px 32px;
    width: 100%;
`;

export const ListContainer = styled.div`
    padding: 16px;
    border: 1px solid #D8D7EA;
    border-radius: 12px;
`;

export const ListBox = styled.div`
    border-bottom : 1px solid #D8D7EA;
    display: flex;
    flex-wrap: wrap;
    padding-top: 12px;
    padding-bottom: 12px;

    :last-child {
        border-bottom: none;
        padding-bottom: 0px;
    };

    :first-child {
        padding-top: 0px;
    };
`;

export const ListContentBox = styled.div`
    width: 25%;
`;

export const ListItemHeading = styled.p`
    margin: 0px;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: #494785; 
    padding-bottom: 8px;
`;
export const ListItemDescription = styled.p`
    margin: 0px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #0F0E1B; 
`;

export const AmountInformationContainer = styled.div`
    border-radius: 12px;
    text-align: center;
    padding: 20px;
    box-shadow: 16px 8px 40px 0px rgba(0, 0, 0, 0.08);
    height: 254px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .ant-btn-primary {
        color: #fff;
        background-color: #C83E00;
        background-image: linear-gradient(97deg, #F86624 4.34%, #C83E00 101.42%);
        box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
    }
`;
