import styled from "styled-components";

export const TableContainer = styled.div`
    display : flex;
    width : 100%;
    .ant-table-wrapper {
        width : 100% !important;
    }
    .ant-table-wrapper .ant-table-tbody >tr >td{
        border-bottom : 0px;
        display : block;
        width : 100%;
        overflow: hidden;
    }
    .ant-table-tbody > .ant-table-row{
        display : flex;
        border-radius: 10px;
        border: 1px solid var(--UI-Component-Stroke-Low, #EBF4FF);
        background: var(--Typo-White, #FFF);
    }
    .ant-table-tbody {
        gap: 16px;
        display: flex;
        flex-direction: column;
        width: 100%;
    }
`