import React from 'react';
import { CampaignContentDescription, CampaignDetailsDescription, PaymentSuccessfulContainer, SuccessfulHeading, CampaignContentContainer } from './style';
import { AdmirianOutlinedButton, JustifyCenterContainer, SubHeading } from '../../../utils/common';
import { Col, Row } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { useGetParticularCampaignQuery } from '../../../services/manageCampaignsApi';

const Successful = () => {
    const {campaignId} = useParams();
    const { data: campaignData = {} } = useGetParticularCampaignQuery({ campaignId });
    console.log("campaignData", campaignData);
    return (
        <JustifyCenterContainer>
            <PaymentSuccessfulContainer>
                <img
                    src='https://static.admirian.com/cc5f1e2b-ed85-4383-b028-d91365a41212_thumb-icon.svg'
                    alt='thumb'
                    width={180}
                    height={180}
                />
                <SuccessfulHeading>Thanks! Please wait until we <br/> verify your payment</SuccessfulHeading>
                <SubHeading> Track all your campaign updates from the <b>Manage Campaigns</b> page <br/> <br/>Influencer activity and payment verification update will be available in the campaign's <b>Activity Log</b> sidebar</SubHeading>
                <Link to={`/manage-campaign-view/${campaignId}`} >
                    <AdmirianOutlinedButton shape="round" >View Campaign</AdmirianOutlinedButton>
                </Link>
                <CampaignContentContainer  style={{ marginTop: 16 }}>
                    <Row gutter={[16, 20]}>
                        <Col span={6}>
                            <CampaignContentDescription>Campaign Objectives</CampaignContentDescription>
                            <CampaignDetailsDescription>{campaignData?.objective}</CampaignDetailsDescription>
                        </Col>
                        <Col span={6}>
                            <CampaignContentDescription>Category</CampaignContentDescription>
                            <CampaignDetailsDescription>{campaignData?.category}</CampaignDetailsDescription>
                        </Col>
                        <Col span={6}>
                            <CampaignContentDescription>Budget (₹)</CampaignContentDescription>
                            <CampaignDetailsDescription>{campaignData?.min_budget} - {campaignData?.max_budget}</CampaignDetailsDescription>
                        </Col>
                        <Col span={6}>
                            <CampaignContentDescription>Campaign Type</CampaignContentDescription>
                            <CampaignDetailsDescription>{campaignData?.campaign_type}</CampaignDetailsDescription>
                        </Col>
                    </Row>
                </CampaignContentContainer>
            </PaymentSuccessfulContainer>
        </JustifyCenterContainer>
    )
}

export default Successful;