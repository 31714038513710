import { Button, Row } from "antd";
import styled from "styled-components";
import media from "../../utils/UIElements/media";

export const CampaignContainer = styled.div`
    padding : 32px 48px;
    display : flex;
    width : 100%;
    flex-direction : column;
    .ant-carousel{
        width : 100%;
    }
    .slick-track{
        display : flex;
    }
    .slick-dots{
        width : 0px;
    }
    .ant-tabs-content{
        background : #fff;
        padding : 24px;
    }
    ${media.mobile`
        padding: 24px;
    `}
`

export const ActivityContainer = styled.div`
    display: flex;
    padding: var(--Space-List-Lg, 24px);
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: var(--Space-List-Sm, 8px);
    background: var(--Typo-White, #FFF);
    max-height: inherit;
    min-height: 300px;
`

export const ActivityHeading = styled.div`
    color: #010043;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`
export const ActivitySubHeading = styled.div`
    color: var(--Typo-Med, var(--Typo-Mid-High, #2C2B50));
    leading-trim: both;
    text-edge: cap;

    /* Caption Light */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    padding-top: 4px;
`
export const ActivityCard = styled.div`
    display: flex;
    padding: var(--Radius-Modal-Md, 12px);
    justify-content : space-between;
    align-items: flex-start;
    border-radius: var(--Space-List-Sm, 8px);
    background: var(--Surface-Light, #F6FAFF);
    overflow-y : auto;
`
export const PhotoContainer = styled.div`
    width: 24px;
    height: 24px;
    background: #FFFFFF;
    border: none;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 4px;
`

export const ActivityCardDetail = styled.div`
    color: var(--Typo-Medium, #494785);

    /* Body S */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
`
export const ToggleButton = styled(Button)`
    display: flex;
    border: 0px;
    border-radius: 50%;
    background: rgb(255, 255, 255);
    height: 35px;
    width: 35px;
    justify-content: center;
    align-items: center;
`
export const FeaturedMediaCard = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    gap: 16px;
    background: #FFFFFF;
    border-radius: 10px;
    height : 308px;
` 
export const MetricHeading = styled.div`
    color: var(--Typo-High, #0F0E1B);
    /* Title/Title 4 */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`
export const MetricDetail = styled.div`
    color: var(--Typo-Medium, #494785);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`
export const PersonalRequestsHeading = styled.div`
    color: var(--Typo-High, #0F0E1B);
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    ${media.mobile`
        font-size: 20px;
    `}
`
export const CampaignHeading = styled.div`
    color: var(--Typo-High, #0F0E1B);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 32px;
    margin-bottom : 24px;
    ${media.mobile`
        font-size: 16px;
        margin-top: 20px;
        margin-bottom: 16px;
    `}
`
export const CampaignListingContainer = styled.div`
    display  : flex ;
    margin : 0 20px 0 10px;
    .ant-tabs .ant-tabs-tab-btn{
        color : #010043 !important;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        width : 100% ;
    }
    .ant-tabs{
        width : 100% !important;
    }
    .ant-tabs-ink-bar{
        background : #ff756c !important;
    }
    .ant-tabs >.ant-tabs-nav{
        background: #FFFFFF;
        padding: 1px 16px;
        border-top-right-radius: 10px;
        border-top-left-radius : 10px;
    }
    .ant-tabs-top >.ant-tabs-nav{
        margin : 0 0 2px 0;
    }
`
export const TabHeading = styled.div`
    color: var(--Typo-High, #0F0E1B);

    /* Title/Title 4 */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`

export const CustomButton = styled(Button)`
    display : flex;
    margin: 5px;
    padding : var(--Radius-Modal-Md, 12px) var(--Space-List-Lg, 24px);
    border-radius : 60px;
    justify-content: center;
    align-items: center;
    background: ${(props) => {
        switch (props.isActive) {
          case true:
            return 'var(--UI-Young, linear-gradient(0deg, var(--Primitives-Orange, #F86624) 0%, var(--Primitives-Orange, #F86624) 100%), #F9C80E) !important'
          default:
            return 'var(--Typo-White, #FFF) !important';
        }
    }};
    border: ${(props) => {
        switch (props.isActive) {
          case true:
            return '0px !important'
          default:
            return '1px solid var(--UI-Component-Stroke-light, #D8D7EA) !important';
        }
    }};
    color: ${(props) => {
        switch (props.isActive) {
          case true:
            return 'var(--Typo-White, #FFF) !important'
          default:
            return 'var(--UI-Component-accent, #010043) !important';
        }
    }};
    ${media.mobile`
        font-size: 12px;
        padding: 8px 16px;
    `}
`

export const TableImageContainer = styled.div`
    width: 80px;
    height: 80px;
    flex-shrink: 0;
    margin-right : 24px;
    ${media.mobile`
        width: 60px;
        height: 60px;
        margin-right: 12px;
    `}
`

export const TableDataHeading = styled.div`
    color: var(--Typo-Medium, #494785);
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    ${media.mobile`
        font-size: 10px;
    `}
`

export const TableDataDiscription = styled.div`
    color: #010043;
    overflow: hidden;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    ${media.mobile`
        font-size: 14px;
    `}
`

export const TableActionButton = styled(Button)`
    display: flex;
    min-width: 100px;
    min-height : 40px;
    padding: 10px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 60px;
    border: 1px solid var(--UI-Component-accent, #010043) !important;
    color: var(--UI-Component-accent, #010043) !important;
    text-align: center;

    /* Button/small */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    ${media.mobile`
        min-width: 60px;
        min-height : 20px;
        padding: 6px 12px;
        font-size: 12px;
    `}
`
export const NoDataText = styled.div`
    color: #010043;
    font-size: 12px;
    font-weight: 400;
    padding-top: 4px;
    opacity: 0.5;
`;

export const FeaturePostContainer = styled.div`
    display : flex;
    justify-content : space-between;
    font-size : 18px; 
    font-weight : 700; 
    color : #010043; 
    margin-bottom : 24px;
    align0-items : center;
`;

export const CustomRow = styled(Row)`
    margin-top : 32px;
    display : flex;
    max-height : 270px;
    overflow-y : scroll
`;

export const UserNameText = styled.div`
    color : #0F0E1B;
    font-size : 14px;
`;

export const CommentedDate = styled.div`
    color : #8988BF;
    font-size : 10px;
    font-weight : 300;
`;

export const CarouselContainer = styled.div`
    display : flex;
    height : calc(100% - 51.5px);
    border-radius : var(--Space-List-Sm,8px);
    min-height : 300px;
    max-width : 90vw;
`;

export const CampaignBannerImage = styled.img`
    display : flex;
    min-height : 200px; 
    width: 100%;
    border-radius : 10px;
`;

export const CampaignBannerText = styled.div`
    color : #1551EA;
    font-size : 18px;
    font0-weight : 500;
`;

export const CampaignNameText = styled.div`
    color : #494785;
    font-size : 12px;
`;

export const NoBrandFoundContainer = styled.div`
    display : flex;
    justify-content : center;
    align-items : center;
    background : #ffffff;
    width : 100%;
    height : calc(100% - 51.5px);
    border-radius : var(--Space-List-Sm,8px);
    min-height : 300px; 
    flex-direction: column;
`;