import { Col, Row } from "antd";
import React from "react";
import { CompleteProfileButton, CompleteprofileContainer } from "./style";
import { useNavigate } from "react-router-dom";

const CompleteYourProfile = () =>{
    const navigate = useNavigate();
    return(
        <CompleteprofileContainer>
            <Col span={20} style={{margin : '20px 0px'}}>
                <span style={{display : 'flex', fontWeight : 500, fontSize : '18px', color : '#010043', lineHeight : '22px', marginBottom: '8px'}}>
                Complete your profile to get started
                </span>
                <span style={{display : 'flex', fontSize : '12px', color : '#2C2B50', lineHeight : '15px'}}>
                Your basic details will help you to get discovered and collaborate with right brands.
                </span>

            </Col>
            <Col span={4}>
                <CompleteProfileButton
                onClick={()=>{
                    navigate(`/profile`)
                }}
                >
                Complete Now
                </CompleteProfileButton>
            </Col>

        </CompleteprofileContainer>
    )
}

export default CompleteYourProfile