import React, { useState } from 'react';
import { Container, FormContainer } from './style';
import { AdmirianForm, FlexEndContainer, Heading, SubHeading } from '../../../utils/common';
import {
    Button,
    Col,
    Divider,
    Space,
    Form,
    Input,
    Row,
    Select,
    notification
} from 'antd';

import AddProductDetails from './AddProductDetails'
import { useEditDeliverablesInitiatedMutation } from '../../../services/manageCampaignsApi';
import { useParams } from 'react-router-dom';

const { Option } = Select;

const EditDeliverableForm = ({ refetch, particularDeliverableData, setParticularDeliverable, setScreenId }) => {

    const { name, platform, media_type, min_budget,
        max_budget,
        content_inspiration, 
        reference, associated_product_name, product_gifting_type, id  } = particularDeliverableData; 

    const [platformType, setPlatformType] = useState('');
    const [updateDeliverablesButtonLoading, setUpdateDeliverablesButtonLoading] = useState(false);
    const [associatedProductName, setAssociatedProductName] = useState(associated_product_name ? associated_product_name : "");
    const [productGiftingType, setProductGiftingType] = useState(product_gifting_type === "GIFT" ? "401" : product_gifting_type === "TRIAL_PRODUCT" ? "402" : "");
    const [editDeliverablesInitiatedMutation] = useEditDeliverablesInitiatedMutation();
    const {campaignId} = useParams();

    const [form] = Form.useForm();

    const onFinish = async (values) => {
        try {
            setUpdateDeliverablesButtonLoading(true);
            const deliverableUpdatedData = {
                name: values.name,
                platform: values.platform,
                media_type: values.media_type,
                min_budget: values.min_budget,
                max_budget: values.max_budget,
                content_inspiration: values.content_inspiration,
            }
            if(associatedProductName.length){
                deliverableUpdatedData.associated_product_name = associatedProductName;
            }
            if(productGiftingType.length){
                deliverableUpdatedData.product_gifting_type = productGiftingType;
            }
            if(values.reference.length){
                deliverableUpdatedData.reference = values.reference;
            }
            const response = await editDeliverablesInitiatedMutation({ deliverableUpdatedData, campaignId, deliverableId: id  });
            if (response && !response.error) {
                setUpdateDeliverablesButtonLoading(false);
                notification.success({
                    message: response?.data?.message
                })
                refetch();
                setParticularDeliverable({});
                setScreenId("1");
                form.resetFields();
            } else if (response.error) {
                setUpdateDeliverablesButtonLoading(false);
                notification.error({
                    message: response?.error?.data?.message
                })
            }
        } catch (error) {
            setUpdateDeliverablesButtonLoading(false);
            notification.error({
                message: error?.response?.error?.data?.message
            })
        }
    };

    const onReset = () => {
        form.resetFields();
    };

    return (
        <FormContainer>
            <AdmirianForm
                form={form}
                name="editDeliverables"
                onFinish={onFinish}
                layout='vertical'
                initialValues={{
                    name,
                    platform,
                    media_type,
                    min_budget: String(min_budget),
                    max_budget: String(max_budget),
                    content_inspiration,
                    reference
                }}
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="name"
                            label="Deliverable name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please provide deliverable name',
                                    whitespace: true,
                                },
                            ]}
                        >
                            <Input placeholder='e.g. Makeup Tutorial Reel' />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="platform"
                            label="Platform"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select a platform',
                                },
                            ]}
                        >
                            <Select placeholder="Select Platform" onChange={(value) => setPlatformType(value)}>
                                <Option value="instagram">Instagram</Option>
                                <Option value="youtube">Youtube</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="media_type"
                            label="Media Type"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select a media type',
                                },
                            ]}

                        >
                            {
                                platformType === 'instagram' ? <Select placeholder="Select Media Type">
                                    <Option value="image">Image</Option>
                                    <Option value="story">Story</Option>
                                    <Option value="reel">Reel</Option>
                                    <Option value="carousel_album">Carousel Album</Option>
                                </Select> : <Select placeholder="Select Media Type">
                                    <Option value="shorts">Shorts</Option>
                                    <Option value="video">Video</Option>
                                </Select>
                            }
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="min_budget"
                            label="Deliverable Min Budget"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter min budget',
                                    whitespace: true,
                                    // type: 'number'
                                }
                            ]}
                        >
                            <Input placeholder='Enter Amount' />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="max_budget"
                            label="Deliverable Max Budget"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter max budget',
                                    whitespace: true,
                                    // type: 'number'
                                }
                            ]}
                        >
                            <Input placeholder='Enter Amount' />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="content_inspiration"
                            label="Provide a brief about deliverable"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please provide a brief',
                                    whitespace: true,
                                    // type: 'number'
                                }
                            ]}
                        >
                            <Input placeholder='e.g. Create a vibrant makeup tutorial reel showcasing beach-friendly products and summer beauty tips.' />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="reference"
                            label="Add Reference URL"
                        >
                            <Input placeholder='https://www.abc.com/' />
                        </Form.Item>
                        {/* <Upload name="logo" action="/upload.do">
                        <Button icon={<PlusOutlined />}>Upload File</Button>
                    </Upload> */}
                    </Col>
                    <Col span={24}>
                        <AddProductDetails associatedProductName={associatedProductName} setAssociatedProductName={setAssociatedProductName} productGiftingType={productGiftingType} setProductGiftingType={setProductGiftingType} />
                        <Divider />
                    </Col>
                </Row>
                <FlexEndContainer>
                    <Space>
                        {/* <Button style={{ color: '#494785' }} type='text' onClick={onReset} htmlType="reset">Cancel</Button> */}
                        <Button htmlType="submit" loading={updateDeliverablesButtonLoading}>
                            Save Changes
                        </Button>
                    </Space>
                </FlexEndContainer>
            </AdmirianForm>
        </FormContainer>
    )
}

export default EditDeliverableForm;