import { Button } from 'antd';
import styled from 'styled-components';


export const UserCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    gap: 20px;
    width: 80%;
    background: #FFFFFF;
    border-radius: 20px;
`
export const ImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background:${(props) => {
        return `linear-gradient(180deg, rgba(1, 0, 67, 0) 0%, rgba(1, 0, 67, 0.28) 44.27%, #010043 100%),url("${props?.backGroundImageUrl}")`
    }};
    background-repeat : no-repeat;
    border-radius: 10px;
    width : 80%;
    min-height: 235px;
    background-size : contain;
    margin-bottom: 20px;
    justify-content : flex-end;
    padding : 13px 20px;
`

export const CategoryButton = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 10px;
    gap: 10px;
    height: 24px;
    background: #F6F6FF;
    border-radius: 50px;
    margin-right : 10px;
`

export const ViewMoreButton = styled(Button)`
    width: 117px;
    height: 36px;
    border: 1px solid #FFFFFF !important;
    border-radius: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 32px;
    gap: 32px;
    font-size: 10px;
    line-height: 12px;
    color: #FFFFFF !important;
    background: transparent !important;
`
export const Container = styled.div`
    text-align: center;
    padding: 120px;
`;

export const SubHeading = styled.p`
    font-size: 12px;
    font-weight: 300;
    color: #2C2B50;
    margin-top: 5px;
`;