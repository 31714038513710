import React, { Fragment, useEffect, useState } from 'react';
import { AmountText, BackButtonText, Container, DetailsContainer, EstimationText, FilterRadioContainer, InfluencerFooter, ViewSummaryText, PaginationContainer } from './style';
import { FlexContainer, Heading, AdmirianButton, SpaceBetweenContainer, SubHeading } from '../../utils/common';
import { Pagination, Select, Space, notification } from 'antd';
import InfluencerDetailListCard from '../../components/ManageCampaigns/InfluencerDetailListCard';
import { useAllAddedInfluencerInitiatedMutation, useAllInfluencersInitiatedMutation } from '../../services/manageCampaignsApi';
import SelectDeliverablesModal from '../../components/ManageCampaigns/InfluencerDetailListCard/SelectDeliverablesModal';
import RatecardModal from '../../components/ManageCampaigns/InfluencerDetailListCard/RatecardModal';
import { LeftOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CustomDrawer } from '../../components/Dashboard/CustomDrawer';
import NoDataFound from '../../components/NoDataFound';

const { Option } = Select;

const InfluencerDetails = () => {
    const navigate = useNavigate();
    const { campaignId } = useParams();
    const [page, setPage] = useState(1);
    const [allInfluencersInitiatedMutation] = useAllInfluencersInitiatedMutation();
    const [influencerDetails, setInfluencerDetails] = useState({});
    const [selectDeliverableModal, setSelectDeliverableModal] = useState(false);
    const [rateCardModal, setRateCardModal] = useState(false);
    const [selectedInfluencerId, setSelectedInfluencerId] = useState('');
    const [selectedCreatorId, setSelectedCreatorId] = useState('');
    const [selectedInfluencerData, setSelectedInflencerData] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [rateWithIds, setRateWithIds] = useState({});
    const [continueButtonLoading, setContinueButtonLoading] = useState(false);
    const [allAddedInfluencerInitiatedMutation] = useAllAddedInfluencerInitiatedMutation();
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [drawerData, setDrawerData] = useState(null);
    const [genderSelected, setGenderSelected] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [locationSelected, setLocationSelected] = useState(null);
    const [fansCount, setFansCount] = useState("");
    const [ageRange, setAgeRange] = useState("");
    const [platformType, setPlatformType] = useState('');
    const [particularInfluencerData, setParticularInfluencerData] = useState({});
    useEffect(() => {
        async function fetchInfluencerData() {
            const filters = {"campaign_id" : Number(campaignId) };
            if (genderSelected === "male" || genderSelected === "female" || genderSelected === "non_binary") {
                filters.gender = [genderSelected];
            }
            if (selectedCategory.length) {
                filters.category = selectedCategory;
            }
            if(platformType?.length && platformType !== undefined){
                filters.platform = platformType
            }
            if (locationSelected) {
                filters.location = locationSelected;
            }
            if (locationSelected?.[0] === undefined) {
                delete filters.location;
            }
            if (fansCount?.length) {
                const followerData = fansCount.split("-");
                filters.min_followers = Number(followerData?.[0]);
                if (followerData[1].length) {
                    filters.max_followers = Number(followerData[1]);
                }
            }
            if (ageRange?.length) {
                const ageData = ageRange.split("-");
                filters.min_age = Number(ageData[0]);
                filters.max_age = Number(ageData[1]);
            }
            const response = await allInfluencersInitiatedMutation({ "filters": filters, page: page });
            try {
                if (response && !response.error) {
                    setInfluencerDetails(response.data)
                } else if (response.error) {
                    notification.error({
                        message: response?.error?.data?.message
                    })
                }
            } catch (error) {
                notification.error({
                    message: error?.response?.error?.data?.message
                })
            }
        }
        fetchInfluencerData();
    }, [page, genderSelected, selectedCategory, locationSelected, fansCount, ageRange, platformType])


    const { creators = [], total_pages } = influencerDetails;

    const handleRateCard = (id, creId) => {
        setRateCardModal(true);
        setSelectedInfluencerId(id);
        setSelectedCreatorId(creId);
    }

    const handleSelectDeliverable = (id, creId) => {
        setSelectDeliverableModal(true);
        setSelectedInfluencerId(id);
        setSelectedCreatorId(creId);
    }

    const handleAddToCampaign = (campaignData, ratecardObj) => {
        const campaignDetails = [...selectedInfluencerData];
        campaignDetails.push(campaignData)
        setSelectedInflencerData(campaignDetails);
        setSelectDeliverableModal(false);
        let amount = 0;
        Object.keys(ratecardObj).forEach(function (key) {
            amount = amount + ratecardObj[key];
            setTotalAmount(amount);
        });
    }

    const handleRemoveCampaignData = (id) => {
        const filteredData = selectedInfluencerData.filter(item => item.creator_id !== id);
        if (totalAmount > 0) {
            setTotalAmount(totalAmount - rateWithIds[id])
            delete rateWithIds[id];
        }
        setSelectedInflencerData(filteredData);
    }

    const onPageChange = (pageValue) => {
        setPage(pageValue);
    };

    const handleCreaterData = async () => {
        try {
            const response = await allAddedInfluencerInitiatedMutation({ "creators": selectedInfluencerData, campaignId });
            setContinueButtonLoading(true);
            if (response && !response.error) {
                setContinueButtonLoading(false);
                notification.success({
                    message: response?.data?.message
                })
                const campaignCreatorIds = response?.data?.campaign_creators?.map(creator => creator.campaign_creator_id).join(",");
                navigate(`/campaign-summary/${campaignId}/${campaignCreatorIds}`)
                //navigate(`/influencer-summary/${campaignId}`)
            } else if (response.error) {
                setContinueButtonLoading(false);
                notification.error({
                    message: response?.error?.data?.message
                })
            }
        } catch (error) {
            setContinueButtonLoading(false);
            notification.error({
                message: error?.response?.error?.data?.message
            })
        }
    }

    const onClose = () => {
        setDrawerData('')
        setDrawerVisible(false)
    }

    return (
        <>
            <Container>
                <Heading>Select Influencers</Heading>
                <SubHeading>Select influencers you want to work with among our partnered creators</SubHeading>
                <DetailsContainer>
                    <Space wrap>
                        <Select
                            defaultValue="Location"
                            placeholder="Location"
                            style={{ width: 120 }}
                            onChange={(value) => setLocationSelected([value])}
                            // options={[
                            //     { value: 'delhi', label: 'Delhi' },
                            //     { value: 'mumbai', label: 'Mumbai' },
                            //     { value: 'gurugram', label: 'Gurugram' },
                            //     { value: 'haryana', label: 'Haryana' },
                            // ]}
                            allowClear
                        >
                            <Option value="AF">Afghanistan</Option>
                            <Option value="AX">Aland Islands</Option>
                            <Option value="AL">Albania</Option>
                            <Option value="DZ">Algeria</Option>
                            <Option value="AS">American Samoa</Option>
                            <Option value="AD">Andorra</Option>
                            <Option value="AO">Angola</Option>
                            <Option value="AI">Anguilla</Option>
                            <Option value="AQ">Antarctica</Option>
                            <Option value="AG">Antigua and Barbuda</Option>
                            <Option value="AR">Argentina</Option>
                            <Option value="AM">Armenia</Option>
                            <Option value="AW">Aruba</Option>
                            <Option value="AU">Australia</Option>
                            <Option value="AT">Austria</Option>
                            <Option value="AZ">Azerbaijan</Option>
                            <Option value="BS">Bahamas</Option>
                            <Option value="BH">Bahrain</Option>
                            <Option value="BD">Bangladesh</Option>
                            <Option value="BB">Barbados</Option>
                            <Option value="BY">Belarus</Option>
                            <Option value="BE">Belgium</Option>
                            <Option value="BZ">Belize</Option>
                            <Option value="BJ">Benin</Option>
                            <Option value="BM">Bermuda</Option>
                            <Option value="BT">Bhutan</Option>
                            <Option value="BO">Bolivia</Option>
                            <Option value="BQ">Bonaire, Sint Eustatius and Saba</Option>
                            <Option value="BA">Bosnia and Herzegovina</Option>
                            <Option value="BW">Botswana</Option>
                            <Option value="BV">Bouvet Island</Option>
                            <Option value="BR">Brazil</Option>
                            <Option value="IO">British Indian Ocean Territory</Option>
                            <Option value="BN">Brunei Darussalam</Option>
                            <Option value="BG">Bulgaria</Option>
                            <Option value="BF">Burkina Faso</Option>
                            <Option value="BI">Burundi</Option>
                            <Option value="KH">Cambodia</Option>
                            <Option value="CM">Cameroon</Option>
                            <Option value="CA">Canada</Option>
                            <Option value="CV">Cape Verde</Option>
                            <Option value="KY">Cayman Islands</Option>
                            <Option value="CF">Central African Republic</Option>
                            <Option value="TD">Chad</Option>
                            <Option value="CL">Chile</Option>
                            <Option value="CN">China</Option>
                            <Option value="CX">Christmas Island</Option>
                            <Option value="CC">Cocos (Keeling) Islands</Option>
                            <Option value="CO">Colombia</Option>
                            <Option value="KM">Comoros</Option>
                            <Option value="CG">Congo</Option>
                            <Option value="CD">Congo, Democratic Republic of the Congo</Option>
                            <Option value="CK">Cook Islands</Option>
                            <Option value="CR">Costa Rica</Option>
                            <Option value="CI">Cote D'Ivoire</Option>
                            <Option value="HR">Croatia</Option>
                            <Option value="CU">Cuba</Option>
                            <Option value="CW">Curacao</Option>
                            <Option value="CY">Cyprus</Option>
                            <Option value="CZ">Czech Republic</Option>
                            <Option value="DK">Denmark</Option>
                            <Option value="DJ">Djibouti</Option>
                            <Option value="DM">Dominica</Option>
                            <Option value="DO">Dominican Republic</Option>
                            <Option value="EC">Ecuador</Option>
                            <Option value="EG">Egypt</Option>
                            <Option value="SV">El Salvador</Option>
                            <Option value="GQ">Equatorial Guinea</Option>
                            <Option value="ER">Eritrea</Option>
                            <Option value="EE">Estonia</Option>
                            <Option value="ET">Ethiopia</Option>
                            <Option value="FK">Falkland Islands (Malvinas)</Option>
                            <Option value="FO">Faroe Islands</Option>
                            <Option value="FJ">Fiji</Option>
                            <Option value="FI">Finland</Option>
                            <Option value="FR">France</Option>
                            <Option value="GF">French Guiana</Option>
                            <Option value="PF">French Polynesia</Option>
                            <Option value="TF">French Southern Territories</Option>
                            <Option value="GA">Gabon</Option>
                            <Option value="GM">Gambia</Option>
                            <Option value="GE">Georgia</Option>
                            <Option value="DE">Germany</Option>
                            <Option value="GH">Ghana</Option>
                            <Option value="GI">Gibraltar</Option>
                            <Option value="GR">Greece</Option>
                            <Option value="GL">Greenland</Option>
                            <Option value="GD">Grenada</Option>
                            <Option value="GP">Guadeloupe</Option>
                            <Option value="GU">Guam</Option>
                            <Option value="GT">Guatemala</Option>
                            <Option value="GG">Guernsey</Option>
                            <Option value="GN">Guinea</Option>
                            <Option value="GW">Guinea-Bissau</Option>
                            <Option value="GY">Guyana</Option>
                            <Option value="HT">Haiti</Option>
                            <Option value="HM">Heard Island and Mcdonald Islands</Option>
                            <Option value="VA">Holy See (Vatican City State)</Option>
                            <Option value="HN">Honduras</Option>
                            <Option value="HK">Hong Kong</Option>
                            <Option value="HU">Hungary</Option>
                            <Option value="IS">Iceland</Option>
                            <Option value="IN">India</Option>
                            <Option value="ID">Indonesia</Option>
                            <Option value="IR">Iran, Islamic Republic of</Option>
                            <Option value="IQ">Iraq</Option>
                            <Option value="IE">Ireland</Option>
                            <Option value="IM">Isle of Man</Option>
                            <Option value="IL">Israel</Option>
                            <Option value="IT">Italy</Option>
                            <Option value="JM">Jamaica</Option>
                            <Option value="JP">Japan</Option>
                            <Option value="JE">Jersey</Option>
                            <Option value="JO">Jordan</Option>
                            <Option value="KZ">Kazakhstan</Option>
                            <Option value="KE">Kenya</Option>
                            <Option value="KI">Kiribati</Option>
                            <Option value="KP">Korea, Democratic People's Republic of</Option>
                            <Option value="KR">Korea, Republic of</Option>
                            <Option value="XK">Kosovo</Option>
                            <Option value="KW">Kuwait</Option>
                            <Option value="KG">Kyrgyzstan</Option>
                            <Option value="LA">Lao People's Democratic Republic</Option>
                            <Option value="LV">Latvia</Option>
                            <Option value="LB">Lebanon</Option>
                            <Option value="LS">Lesotho</Option>
                            <Option value="LR">Liberia</Option>
                            <Option value="LY">Libyan Arab Jamahiriya</Option>
                            <Option value="LI">Liechtenstein</Option>
                            <Option value="LT">Lithuania</Option>
                            <Option value="LU">Luxembourg</Option>
                            <Option value="MO">Macao</Option>
                            <Option value="MK">Macedonia, the Former Yugoslav Republic of</Option>
                            <Option value="MG">Madagascar</Option>
                            <Option value="MW">Malawi</Option>
                            <Option value="MY">Malaysia</Option>
                            <Option value="MV">Maldives</Option>
                            <Option value="ML">Mali</Option>
                            <Option value="MT">Malta</Option>
                            <Option value="MH">Marshall Islands</Option>
                            <Option value="MQ">Martinique</Option>
                            <Option value="MR">Mauritania</Option>
                            <Option value="MU">Mauritius</Option>
                            <Option value="YT">Mayotte</Option>
                            <Option value="MX">Mexico</Option>
                            <Option value="FM">Micronesia, Federated States of</Option>
                            <Option value="MD">Moldova, Republic of</Option>
                            <Option value="MC">Monaco</Option>
                            <Option value="MN">Mongolia</Option>
                            <Option value="ME">Montenegro</Option>
                            <Option value="MS">Montserrat</Option>
                            <Option value="MA">Morocco</Option>
                            <Option value="MZ">Mozambique</Option>
                            <Option value="MM">Myanmar</Option>
                            <Option value="NA">Namibia</Option>
                            <Option value="NR">Nauru</Option>
                            <Option value="NP">Nepal</Option>
                            <Option value="NL">Netherlands</Option>
                            <Option value="AN">Netherlands Antilles</Option>
                            <Option value="NC">New Caledonia</Option>
                            <Option value="NZ">New Zealand</Option>
                            <Option value="NI">Nicaragua</Option>
                            <Option value="NE">Niger</Option>
                            <Option value="NG">Nigeria</Option>
                            <Option value="NU">Niue</Option>
                            <Option value="NF">Norfolk Island</Option>
                            <Option value="MP">Northern Mariana Islands</Option>
                            <Option value="NO">Norway</Option>
                            <Option value="OM">Oman</Option>
                            <Option value="PK">Pakistan</Option>
                            <Option value="PW">Palau</Option>
                            <Option value="PS">Palestinian Territory, Occupied</Option>
                            <Option value="PA">Panama</Option>
                            <Option value="PG">Papua New Guinea</Option>
                            <Option value="PY">Paraguay</Option>
                            <Option value="PE">Peru</Option>
                            <Option value="PH">Philippines</Option>
                            <Option value="PN">Pitcairn</Option>
                            <Option value="PL">Poland</Option>
                            <Option value="PT">Portugal</Option>
                            <Option value="PR">Puerto Rico</Option>
                            <Option value="QA">Qatar</Option>
                            <Option value="RE">Reunion</Option>
                            <Option value="RO">Romania</Option>
                            <Option value="RU">Russian Federation</Option>
                            <Option value="RW">Rwanda</Option>
                            <Option value="BL">Saint Barthelemy</Option>
                            <Option value="SH">Saint Helena</Option>
                            <Option value="KN">Saint Kitts and Nevis</Option>
                            <Option value="LC">Saint Lucia</Option>
                            <Option value="MF">Saint Martin</Option>
                            <Option value="PM">Saint Pierre and Miquelon</Option>
                            <Option value="VC">Saint Vincent and the Grenadines</Option>
                            <Option value="WS">Samoa</Option>
                            <Option value="SM">San Marino</Option>
                            <Option value="ST">Sao Tome and Principe</Option>
                            <Option value="SA">Saudi Arabia</Option>
                            <Option value="SN">Senegal</Option>
                            <Option value="RS">Serbia</Option>
                            <Option value="CS">Serbia and Montenegro</Option>
                            <Option value="SC">Seychelles</Option>
                            <Option value="SL">Sierra Leone</Option>
                            <Option value="SG">Singapore</Option>
                            <Option value="SX">Sint Maarten</Option>
                            <Option value="SK">Slovakia</Option>
                            <Option value="SI">Slovenia</Option>
                            <Option value="SB">Solomon Islands</Option>
                            <Option value="SO">Somalia</Option>
                            <Option value="ZA">South Africa</Option>
                            <Option value="GS">South Georgia and the South Sandwich Islands</Option>
                            <Option value="SS">South Sudan</Option>
                            <Option value="ES">Spain</Option>
                            <Option value="LK">Sri Lanka</Option>
                            <Option value="SD">Sudan</Option>
                            <Option value="SR">Suriname</Option>
                            <Option value="SJ">Svalbard and Jan Mayen</Option>
                            <Option value="SZ">Swaziland</Option>
                            <Option value="SE">Sweden</Option>
                            <Option value="CH">Switzerland</Option>
                            <Option value="SY">Syrian Arab Republic</Option>
                            <Option value="TW">Taiwan, Province of China</Option>
                            <Option value="TJ">Tajikistan</Option>
                            <Option value="TZ">Tanzania, United Republic of</Option>
                            <Option value="TH">Thailand</Option>
                            <Option value="TL">Timor-Leste</Option>
                            <Option value="TG">Togo</Option>
                            <Option value="TK">Tokelau</Option>
                            <Option value="TO">Tonga</Option>
                            <Option value="TT">Trinidad and Tobago</Option>
                            <Option value="TN">Tunisia</Option>
                            <Option value="TR">Turkey</Option>
                            <Option value="TM">Turkmenistan</Option>
                            <Option value="TC">Turks and Caicos Islands</Option>
                            <Option value="TV">Tuvalu</Option>
                            <Option value="UG">Uganda</Option>
                            <Option value="UA">Ukraine</Option>
                            <Option value="AE">United Arab Emirates</Option>
                            <Option value="GB">United Kingdom</Option>
                            <Option value="US">United States</Option>
                            <Option value="UM">United States Minor Outlying Islands</Option>
                            <Option value="UY">Uruguay</Option>
                            <Option value="UZ">Uzbekistan</Option>
                            <Option value="VU">Vanuatu</Option>
                            <Option value="VE">Venezuela</Option>
                            <Option value="VN">Viet Nam</Option>
                            <Option value="VG">Virgin Islands, British</Option>
                            <Option value="VI">Virgin Islands, U.s.</Option>
                            <Option value="WF">Wallis and Futuna</Option>
                            <Option value="EH">Western Sahara</Option>
                            <Option value="YE">Yemen</Option>
                            <Option value="ZM">Zambia</Option>
                            <Option value="ZW">Zimbabwe</Option>
                        </Select>
                        <Select
                            defaultValue="Followers"
                            placeholder="Followers"
                            style={{ width: 120 }}
                            onChange={(value) => setFansCount(value)}
                            options={[
                                { value: '0-5000', label: '1k - 5k' },
                                { value: '5000-10000', label: '5k - 10k' },
                                { value: '10000-100000', label: '10k - 100k' },
                                { value: '100000-500000', label: '100k - 500k' },
                                { value: '500000-', label: '500k +' },
                            ]}
                            allowClear
                        />
                        <Select
                            defaultValue="Gender"
                            placeholder="Gender"
                            style={{ width: 120 }}
                            onChange={(value) => setGenderSelected(value)}
                            options={[
                                { value: 'all', label: 'All' },
                                { value: 'male', label: 'Male' },
                                { value: 'female', label: 'Female' },
                                { value: 'non_binary', label: 'Non Binary' },
                            ]}
                            allowClear
                        />
                        <Select
                            defaultValue="Age Range"
                            placeholder="Age Range"
                            style={{ width: 120 }}
                            onChange={(value) => setAgeRange(value)}
                            options={[
                                { value: '13-18', label: '13-18yrs' },
                                { value: '18-25', label: '18-25yrs' },
                                { value: '25-40', label: '25-40yrs' },
                                { value: '40-65', label: '40-65yrs' },
                                { value: '65-100', label: '65+' },
                            ]}
                            allowClear
                        />
                        <Select
                            defaultValue="Platform"
                            placeholder="Platform"
                            style={{ width: 120 }}
                            onChange={(value) => setPlatformType(value)}
                            options={[
                                { value: 'instagram', label: 'Instagram' },
                                { value: 'youtube', label: 'Youtube' },
                            ]}
                            allowClear
                        />
                        <Select
                            placeholder="Category"
                            mode="multiple"
                            style={{ width: 340 }}
                            onChange={(value) => setSelectedCategory(value)}
                            options={[
                                { value: 'fashion', label: 'Fashion' },
                                { value: 'education', label: 'Education' },
                                { value: 'fitness', label: 'Fitness' },
                                { value: 'lifestyle', label: 'Lifestyle' },
                                { value: 'travel', label: 'Travel' },
                                { value: 'automotive', label: 'Automotive' },
                            ]}
                            allowClear
                        />
                    </Space>
                    {/* <FilterRadioContainer>
                    <Radio.Group defaultValue="best_match" buttonStyle='solid' >
                        <Radio.Button value="best_match">Best match</Radio.Button>
                        <Radio.Button value="our_recomendations">Our recomendations</Radio.Button>
                        <Radio.Button value="top_rated">Top rated</Radio.Button>
                        <Radio.Button value="best_price">Best price</Radio.Button>
                        <Radio.Button value="cheapest">Cheapest</Radio.Button>
                    </Radio.Group>
                </FilterRadioContainer> */}
                </DetailsContainer>
                {
                    creators.length ?
                        <>
                            {
                                creators.map((influencerData) => {
                                    return (
                                        <Fragment key={influencerData.creator_uuid}>
                                            <InfluencerDetailListCard setDrawerVisible={setDrawerVisible} setDrawerData={setDrawerData} influencerData={influencerData} handleRateCard={handleRateCard} handleSelectDeliverable={handleSelectDeliverable} selectedInfluencerData={selectedInfluencerData} handleRemoveCampaignData={handleRemoveCampaignData} setParticularInfluencerData={setParticularInfluencerData} />
                                        </Fragment>
                                    )
                                })
                            }
                            {
                                selectDeliverableModal && (
                                    <SelectDeliverablesModal rateWithIds={rateWithIds} setRateWithIds={setRateWithIds} selectedInfluencerId={selectedInfluencerId} selectDeliverableModal={selectDeliverableModal} setSelectDeliverableModal={setSelectDeliverableModal} handleAddToCampaign={handleAddToCampaign} selectedCreatorId={selectedCreatorId} particularInfluencerData={particularInfluencerData} />
                                )
                            }
                            {
                                rateCardModal && (
                                    <RatecardModal selectedInfluencerId={selectedInfluencerId} rateCardModal={rateCardModal} setRateCardModal={setRateCardModal} />
                                )
                            }
                            <PaginationContainer>
                                <Pagination current={page} onChange={onPageChange} pageSize={10} total={total_pages * 10} showSizeChanger={false} />
                            </PaginationContainer>
                        </> :
                        <NoDataFound />
                }
            </Container>
            <InfluencerFooter>
                <SpaceBetweenContainer style={{ padding: "16px 24px", alignItems: 'center' }}>
                    <FlexContainer>
                        <FlexContainer onClick={() => navigate(-1)}>
                            <LeftOutlined />
                            <BackButtonText>Back</BackButtonText>
                        </FlexContainer>
                    </FlexContainer>
                    <FlexContainer>
                        <div>
                            <EstimationText>Cost</EstimationText>
                            <ViewSummaryText></ViewSummaryText>
                        </div>
                        <AmountText>₹ {totalAmount}</AmountText>
                        <div>
                            <AdmirianButton size='large' shape='round' disabled={totalAmount === 0} loading={continueButtonLoading} onClick={handleCreaterData}>Continue</AdmirianButton>
                        </div>
                    </FlexContainer>
                </SpaceBetweenContainer>
            </InfluencerFooter>
            <div>
                {
                    drawerVisible && (

                        <CustomDrawer
                            onClose={onClose}
                            drawerVisible={drawerVisible}
                            drawerData={drawerData}
                            hideConnectionButton={true}
                        // likedUserUuid={likedUserUuid}
                        // getLikedUseIdList={getLikedUseIdList}
                        // getCategoryCount={getCategoryCount}
                        />
                    )
                }
            </div>
        </>
    )
}
export default InfluencerDetails;