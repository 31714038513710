import { Button, Col } from "antd"
import styled from "styled-components"
import media from "../../utils/UIElements/media"

export const HomePageContainer =styled.div`
    display : flex;
    width : 100%;
    padding : 32px 48px;
    flex-direction : column;
    ${media.mobile`
        padding: 24px;
    `}
`
export const CreatorAnalyticsContainer = styled.div`
    display : flex;
    flex-direction : column;
    .ant-carousel{
        width : 100%;
    }
    .slick-dots{
        width : 0px;
    }
`
export const NavigationCard = styled.div`
    position : sticky;
    display: flex;
    padding: var(--space-list-high, 24px) 18px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    border-radius: 10px;
    background: var(--typo-white, #FFF);
`
export const NavigationTitle = styled.div`
    display: flex;
    height: 32px;
    padding: 8px 16px;
    align-items: center;
    gap: 34px;
    align-self: stretch;
    border-radius: 8px;
    color : #494785 ;
    &:hover {
        background : var(--surface-medium, #EBF4FF);
        cursor : pointer;
        color : #0F0E1B ;
    }
`
export const BannerContainer = styled.div`
    height: 400px;
    padding: 40px 48px;
    border-radius: 10px;
    border-top: 3px solid var(--ui-component-soft-bg, #F6F6FF);
    background: #FFF;
    margin-bottom : 32px;
    ${media.mobile`
        padding: 24px;
        height: unset;
    `}
`
export const BannerHeading = styled.div`
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    background: var(--Brand-Gradient-1, linear-gradient(97deg, #4E4BEE -1.75%, #F86624 112.67%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    ${media.mobile`
        font-size: 20px;
    `}
`
export const BannerDetails = styled.div`
    color: var(--typo-medium, #494785);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    margin-top: 12px;
    ${media.mobile`
        font-size: 14px;
    `}
`
export const CustomButton = styled(Button)`
    display: flex;
    min-width: 100px;
    max-width: 200px;
    min-height : 40px;
    padding: 10px 24px;
    justify-content: center;
    align-items: center;
    border-radius: 60px;
    background: var(--primitives-orange, #F86624) !important;
    color: var(--typo-white, #FFF) !important;
    border : none !important;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    margin-top: 12px;
    margin-bottom: 12px;
`

export const FooterContainer = styled.div`
    display : flex;
    position : fixed;
    width : 90%;
    bottom : 20px;
    padding: var(--space-list-medium, 16px) 24px;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    background: var(--Brand-Gradient-1, linear-gradient(97deg, #4E4BEE -1.75%, #F86624 112.67%));
    color: var(--system-white, #FFF);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    ${media.mobile`
        width: unset;
        font-size: 12px;
        line-height: 16px;
    `}
`

export const MatricsContainer = styled.div`
    justify-content: space-between;
    margin-bottom: 24px; 
    background: #FFFFFF; 
    padding: 32px; 
    border-radius: 10px;
`;

export const CustomColumn = styled(Col)`
    display: flex; 
    justify-content: center; 
    align-items: stretch; 
    text-align: center;
`;

export const MetricsCount = styled.div`
    font-size: 32px; 
    font-weight: 700; 
    color: #010043;
    text-align: center; 
`;

export const MetricCountText = styled.div`
    text-align: center;
    width: 100%;
    font-size: 18px !important; 
    color: #01004380 !important; 
    padding-top: 8px !important;
    padding-bottom: 8px !important;
`;

export const MetricsPercentageInformation = styled.div`
    display: flex; 
    color:  #17A600; 
    font-size: 18px; 
    font-weight: 500; 
    line-height: 22px; 
    justify-content: center; 
    align-items: center;
    padding-bottom: 6px;
    text-align: center;
`;

export const InformationText = styled.div`
    font-size: 12px; 
    color: #010043;
    text-align: center;
    padding-top: 6px;
`;

export const AnalyticsDetailCard = styled.div`
    padding: 28px 16px;
    background: var(--surface-light, #F6FAFF);
    border-radius: 10px;
    width : 100%;
    text-align: center;
`

export const CardHeadingAnalytics = styled.div`
    color: var(--typo-high, #0F0E1B);

    /* H3 */
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-left: 4px;
`
export const CompanyLogoContainer = styled.div`
  width: 32px;
  height: 32px;
  background: #FFFFFF;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  margin-right: 10px;
  align-items : flex-start;
  border: 0px; 
  margin-right : 4px;
  background: inherit;
`


export const CreatorMediaContainer = styled.div`
    display : flex;
    flex-direction : column;
    .ant-carousel{
        width : 100%;
    }
    .slick-dots{
        width : 0px;
    }
    // .slick-track{
    //     width : 100% !important;
    // }
`
export const FeaturedMediaCard = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    gap: 16px;
    background: #FFFFFF;
    border-radius: 10px;
` 
export const FeaturedMedia = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 24px; 
    gap: 20px;
` 
export const ToggleButton = styled(Button)`
    display: flex;
    border: 0px;
    border-radius: 50%;
    background: rgb(255, 255, 255);
    height: 35px;
    width: 35px;
    justify-content: center;
    align-items: center;
`
export const FeaturedMediaContainer = styled.div`
    display: flex; 
    width: 100%; 
    justify-content: space-between; 
    font-size: 18px; 
    font-weight: 700; 
    color: #010043; 
    margin-bottom: 24px;
`;

export const NoDataContainer = styled.div`
    min-height : 350px;
    display : flex;
    justify-content : center;
    align-items : center;
    background : #ffffff;
    border-radius : 10px;
`;

export const MostViewedText = styled.div`
    color: #010043; 
    font-size: 18px; 
    font-weight: 600; 
`;
export const MostViewHeading = styled.div`
    font-size: 24px; 
    font-weight: 600; 
    color: #010043 
`;

export const ViewedText = styled.div`
    font-size: 16px; 
    color: #01004380; 
`;

export const LocationContainer = styled.div`
    min-height : 350px;
    display : flex;
    justify-content : center;
    align-items : center;
    background : #ffffff;
    border-radius : 10px;
    width : 100%;
`;

export const UserName = styled.div`
    color: #0F0E1B; 
    font-size: 14px; 
    font-weight: 400;
`;

export const CommentedDate = styled.span`
    color: #8988BF;
    font-size: 12px; 
    margin-left: 8px;
`;

export const CommentText = styled.div`
    color: #0F0E1B; 
    font-size: 14px; 
    line-height: 20px; 
`;

export const PostDensityContainer = styled.div`
    display: flex; 
    height: 330px; 
    flex-direction: column; 
    padding: 24px; 
    background: #FFFFFF;
    border-radius: 10px; 
    margin: 24px 0px;
`;