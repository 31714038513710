import { Button } from 'antd';
import styled from 'styled-components';


export const FormContainer = styled.div`
    display : flex;
    width : 100%;
    justify-content : center;
    align-items : center;
    .ant-input-affix-wrapper {
        border-radius: 30px;
        height: 40px;
    }
`

export const ChangePasswordButton = styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #FFFFFF;
    background: #F86624;
    border-radius: 70px;
    width: 100%;
    height: 50px;
    cursor: pointer;
    margin-top: 20px;
`;

