import styled from 'styled-components';

export const Heading6 = styled.div`
    display : flex ;
    align-items : center;
    flex-direction : column;
    background-image: url(https://static.admirian.com/bf8a5a12-22bf-46fa-a0b4-74eca567ac31_profile.png) , linear-gradient(180deg, #241046 0%, #130D58 100%);
    background-position-y: center;
    background-size : contain ;
    background-position: top;
    background-repeat : no-repeat; 
    font-weight : 700;
    font-size : 16px;
    line-height : 19px;
    padding-bottom : 30px ;
`

export const  FooterContainer = styled.div`
    height : 60px;
    display: flex;
    background:#FFFFFF;
    justify-content : space-between;
`

export const Container = styled.div`
    display : flex;
    padding-top: 70px;
    flex-direction : column;
    background : white;
    background-image: url(https://static.admirian.com/11d72d78-559f-42ae-abe9-f11902b534ed_background.svg);
    background-color : #F6F6FF;
    background-repeat : no-repeat;
    // overflow-x : hidden;
`