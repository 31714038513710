import React from 'react';
import { AdmirianOutlinedButton } from '../../../utils/common';
import { AntDesignOutlined, UserOutlined } from '@ant-design/icons';
import { CampaignInformtaion, CampaignHeading, CampaignDescription } from './style';
import { Row, Col, Avatar, Tooltip } from 'antd';
import moment from 'moment'
import { campaignObjective } from '../../../utils/HelperFunctions/common';
import { useNavigate } from 'react-router-dom';

const CampaignDetails = ({ campaignData }) => {
    const { name = "", objective = "", deliverable_count, status = "", created_at = "", min_budget, max_budget, id, influencer_thumbnail_urls, 
    influencer_count, next_action = "", campaign_type } = campaignData;
    const navigate = useNavigate();
    
    const handleView = () => {
        const nextAction = next_action?.toLowerCase();
        if(nextAction === "overview"){
            navigate(`/manage-campaign-view/${id}`);
        }else if(nextAction === "select_influencer"){
            navigate(`/initial-influencer-details/${id}`);
        }else if(nextAction === "send_proposal" || nextAction === "post_campaign"){
            navigate(`/campaign-summary/${id}`);
        }else if(nextAction === "add_deliverable"){
            navigate(`/add-deliverables/${id}/${campaign_type?.toLowerCase() === "broadcast" ? "101" : "102"}`);
        }else {
            navigate(`/manage-campaign-view/${id}`);
        }
    }

    return (
        <Row className='campaign-details'>
            <Col span={3}>
                <img
                    src={`${objective?.toLowerCase() === campaignObjective.Other ? "https://static.admirian.com/193148c6-8070-4c61-90dd-68f2b82e8bd9_Property_1=Custom.svg" :
                        objective?.toLowerCase() === campaignObjective.Promotion ? "https://static.admirian.com/13afd91f-0c43-4342-bf46-99ecb07c958e_Property_1=Promotion.svg" :
                            objective?.toLowerCase() === campaignObjective.Review ? "https://static.admirian.com/a69a0475-1510-45a5-b537-8708b92d3c74_Property_1=Review.svg" :
                                objective?.toLowerCase() === campaignObjective.Awareness ? "https://static.admirian.com/019e542d-dcd7-4c8d-b554-1928f498ca6a_Property_1=Awareness.svg" :
                                    "https://static.admirian.com/ad417c95-9480-43b3-9341-6246a1aee6e6_Property_1=Product_Launch.svg"
                        }`}
                    width={80}
                    height={80}
                    style={{ objectFit: 'cover', borderRadius: 12 }}
                />
            </Col>
            <Col span={3}>
                <CampaignInformtaion>
                    <CampaignHeading>Campaign Name</CampaignHeading>
                    <CampaignDescription style={{ color: '#1551EA' }}>{name}</CampaignDescription>
                </CampaignInformtaion>
            </Col>
            <Col span={3}>
                <CampaignInformtaion>
                    <CampaignHeading>Campaign Objectives</CampaignHeading>
                    <CampaignDescription>{objective}</CampaignDescription>
                </CampaignInformtaion>
            </Col>
            <Col span={3}>
                <CampaignInformtaion>
                    <CampaignHeading>No. of Deliverables</CampaignHeading>
                    <CampaignDescription>{deliverable_count}</CampaignDescription>
                </CampaignInformtaion>
            </Col>
            <Col span={3}>
                <CampaignInformtaion>
                    <CampaignHeading>Influencers</CampaignHeading>
                    <CampaignDescription>
                        {/* <Avatar.Group
                            maxCount={2}
                            maxPopoverTrigger="click"
                            maxStyle={{
                                color: '#f56a00',
                                backgroundColor: '#fde3cf',
                                cursor: 'pointer',
                            }}
                        >
                            {
                                influencer_thumbnail_urls.length ?
                                    influencer_thumbnail_urls.map((thumbUrl, index) => {
                                        return (
                                            <Avatar src={thumbUrl} key={index} />
                                        )
                                    }) :
                                    <Avatar
                                        icon={<UserOutlined />}
                                    />
                            }
                        </Avatar.Group> */}
                        {influencer_count}
                    </CampaignDescription>
                </CampaignInformtaion>
            </Col>
            <Col span={2}>
                <CampaignInformtaion>
                    <CampaignHeading>Status</CampaignHeading>
                    <CampaignDescription style={{ color: '#00680A' }}>{status}</CampaignDescription>
                </CampaignInformtaion>
            </Col>
            <Col span={4}>
                <CampaignInformtaion>
                    <CampaignHeading>Budget</CampaignHeading>
                    <CampaignDescription>₹{min_budget} - ₹{max_budget}</CampaignDescription>
                </CampaignInformtaion>
            </Col>
            <Col span={3}>
                <CampaignInformtaion style={{ textAlign: 'right' }}>
                    <AdmirianOutlinedButton shape="round" onClick={handleView}>View</AdmirianOutlinedButton>
                    <CampaignHeading>Created on {moment(created_at, 'DD-MM-YYYY hh:mm:ss A').format('DD/MM/YYYY')}</CampaignHeading>
                </CampaignInformtaion>
            </Col>
        </Row>
    )
}

export default CampaignDetails;