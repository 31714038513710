import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../utils/HelperFunctions/constants";

export const resetPasswordApi = createApi({
    reducerPath: "resetPasswordApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_URL}`,
    }),
    endpoints:  (builder) => ({
        resetPasswordInitiated: builder.mutation({
            query(params) {
            const data = { ...params };
            return {
                url: `/users/password/reset/`,
                method: "POST",
                headers: {
                //clientid: getClientId(),
                Authorization: "",
                },
                body: data,
            };
            },
        }),
        resetUpdatedPasswordInitiated: builder.mutation({
            query(params) {
            const data = { password: params.password };
            return {
                url: `/users/password/reset/confirm/?uidb64=${params?.uidb64}&token=${params?.token}`,
                method: "POST",
                headers: {
                //clientid: getClientId(),
                Authorization: "",
                },
                body: data,
            };
            },
        }),
    })
})

export const { useResetPasswordInitiatedMutation, useResetUpdatedPasswordInitiatedMutation } = resetPasswordApi