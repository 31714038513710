
import styled from 'styled-components';

export const Container = styled.div`
    padding: 20px;
    background : #FFFFFF;
    border-radius: 4px;
    margin : 24px 32px;
    width: 100%;
`;

export const CampaignContentContainer = styled.div`
    padding: 16px 20px;
    border-radius: 8px;
    background-color: #F6FAFF;
    margin-top: 12px;
`;

export const CampaignInfoHeading = styled.h2`
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
    color: #0F0E1B;
    margin-top: 0px;
`;

export const CampaignInfoDescription = styled.div`
    font-size: 14px;
    line-height: 20px;
    color: #0F0E1B;
`;

export const CampaignObjectiveBox = styled.div`
    width: 20%
`;

export const CampaignObjectiveHeading = styled.h4`
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    color: #2C2B50;
    margin-top: 0px;
    margin-bottom: 8px;
`;

export const CampaignObjectiveDescription = styled.p`
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    text-transform: capitalize;
    color: #0F0E1B;
    margin: 0px;
`;

export const ListContainer = styled.div`
    padding: 16px;
    border: 1px solid #D8D7EA;
    border-radius: 12px;
`;

export const ListBox = styled.div`
    border-bottom : 1px solid #D8D7EA;
    display: flex;
    flex-wrap: wrap;
    padding-top: 12px;
    padding-bottom: 12px;

    :last-child {
        border-bottom: none;
        padding-bottom: 0px;
    };

    :first-child {
        padding-top: 0px;
    };
`;

export const ListContentBox = styled.div`
    width: 25%;
`;

export const ListItemHeading = styled.p`
    margin: 0px;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: #494785; 
    padding-bottom: 8px;
`;
export const ListItemDescription = styled.p`
    // margin: 0px;
    // font-size: 14px;
    // font-weight: 400;
    // line-height: 20px;
    // color: #0F0E1B; 
`;

export const AmountInformationContainer = styled.div`
    border-radius: 12px;
    text-align: center;
    padding: 20px;
    box-shadow: 16px 8px 40px 0px rgba(0, 0, 0, 0.08);
    height: 254px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .ant-btn-primary {
        color: #fff;
        background-color: #C83E00;
        background-image: linear-gradient(97deg, #F86624 4.34%, #C83E00 101.42%);
        box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
    }
`;

export const SecureText = styled.div`
    padding-top: 12px;
    padding-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #494785;
    font-size: 14px;
`;

export const PaymentHeading = styled.div`
    font-size: 14px;
    font-weight: 500;
    color: #0F0E1B;
    margin-bottom: 4px;
`;

export const PaymentDescription = styled.p`
    font-size: 12px;
    font-weight: 400;
    color: #2C2B50;
    margin-top: 0px;
`;

export const PaymentListContent = styled.ul`
    padding-inline-start: 12px;
    margin-block-start: 0px;
    margin-block-end: 0px;
    font-size: 12px;
    color: #2C2B50;
`;

export const BannerNameText = styled.div`
    color: #010043;
    font-size: 14px;
    font-weight: 500;
`;

export const BannerNameHeadingText = styled.div`
    color: #010043;
    font-size: 10px;
    font-weight: 400;
    margin-bottom: 4px;
`;

export const SuccessfulHeading = styled.h1`
    font-size: 28px;
    font-weight: 600;
    background: var(--Brand-Gradient-1, linear-gradient(97deg, #4E4BEE -1.75%, #F86624 112.67%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`;

export const PaymentSuccessfulContainer = styled.div`
    width: 600px;
    text-align: center;
    padding: 0px;
`;

export const CampaignContentDescription = styled.p`
    font-size: 12px;
    color: #494785;
    margin-top: 4px;
    margin-bottom: 8px;
`;

export const CampaignDetailsDescription = styled.div`
    font-size: 14px;
    font-weight: 400;
    color: #0F0E1B;
`;


