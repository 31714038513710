import styled from 'styled-components';
import media from '../../utils/UIElements/media';

export const Container = styled.div`
    display :flex;
    width : 100%;
    background-image: url(https://static.admirian.com/55a34dd1-7371-4791-83af-ed8f067400ef_BGSignInFlow.svg),linear-gradient(180deg, #241046 0%, #130D58 100%);
    background-repeat : no-repeat;
    flex-direction : column; 
    min-height : 80vh;
`

export const MotoContainer = styled.div`
    display :flex;
    align-items : center;
    justify-content : flex-start;
    width : 100%;
    height : 100%;
    font-weight : 700;
    color : #FFFFFF;
    font-size : 120px;
    line-height : 145px;
`
export const SignInFormContainer = styled.div`
    display : flex;
    padding : 24px 24px;
    justify-content: center;
    align-items : center;
    text-align : center;
    width : 400px;
    flex-direction : column;
    min-height : 400px; 
    background : #FFFFFF;
    margin : 80px 0px 40px 0px; 
    border-radius : 10px; 
    box-shadow : 0px 0px 33px 16px rgba(67, 184, 206, 0.2);
    ${media.mobile`
        margin : 40px 0px 40px 0px; 
    `}
`;

export const SignInText = styled.div`
    display : flex; 
    width : 100%; 
    font-weight : 600;
    color : #010043; 
    font-size : 32px;
    line-height : 40px;
    margin-bottom: 12px;
    ${media.mobile`
        font-size : 24px;
        text-align: center;
        justify-content: center;
    `}
`;