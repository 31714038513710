import { Button, Col, DatePicker, Divider, Input, InputNumber, notification, Radio, Row, Select, Tabs } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import React, { useEffect, useState } from "react";
import {
  AddRateButton,
  CancelButton,
  CompanyDetails,
  CompanyLogoContainer,
  Container,
  CustomButton,
  FormContainer,
  LabelSpan,
  LogoContainer,
  RateCardContainer,
  RateSummary,
  SaveButton,
  TabContainer
} from "./style";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import UpdateLogoModal from "./UpdateLogoModal";
import UpdatePasswordModal from "./UpdatePasswordModal";
import { parseJwtToken } from "../../utils";
import axios from "axios";
import { createTheme } from "@ant-design/cssinjs";
import moment from "moment";
import UpdateBasicInfoModal from "../Profile/UpdateBasicInfoModal";
import RateCardModalCreator from "./RateCardModalCreator";
import { BASE_URL } from "../../utils/HelperFunctions/constants";
import CompleteYourProfile from "../../components/CompleteYourProfile";
import { LandingPageContainer } from "../Analytics/style";

const { Option } = Select;
const ProfileCreator = ({ creatorDetails, getCreatorDetails }) => {
  const [isEdit, setIsEdit] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [userEmail, setUserEmail] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [userGender, setUserGender] = useState("");
  const [userDob, setUserDob] = useState("");
  const [userCountry, setUserCountry] = useState("");
  const [updateBasicInfoModalVisible, setUpdateBasicInfoModalVisible] = useState(false);
  const [instaRate, setInstaRate] = useState({
    reel: "",
    carousel_album: "",
    story: "",
    image: ""
  });
  const [youtubeRate, setYoutubeRate] = useState({
    shorts: "",
    video: ""
  });

  const [date, setDate] = useState("");
  const [gender, setGender] = useState("");
  const [location, setLocation] = useState("");
  const [updateLogoModalVisible, setUpdateLogoModalVisible] = useState(false);
  const [updatePasswordModalVisible, setUpdatePasswordModalVisible] = useState(false);
  const [rateCardModalVisible, setRateCardModalVisible] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const refresh_token = parseJwtToken(localStorage.getItem("refresh_token"));
  const [insagramRateCard, setInstagramRateCard] = useState([]);
  const [youtubeRateCard, setYoutubeRateCard] = useState([]);
  const [rateCardData, setRateCardData] = useState([]);
  const disabledDate = (current) => {
    return current > moment().subtract(13, "years");
  };

  console.log("instaimage rate", insagramRateCard?.filter((instaRate) => instaRate?.media_type === "image")?.[0]?.rate);
  const removeIsEdit = (value) => {
    const newArr = [...isEdit];
    var i = 0;
    while (i < newArr.length) {
      if (newArr[i] === value) {
        newArr.splice(i, 1);
      } else {
        ++i;
      }
    }
    setIsEdit(newArr);
  };
  useEffect(() => {
    if (updatePasswordModalVisible === false) {
      removeIsEdit("password");
    }
  }, [updatePasswordModalVisible]);

  useEffect(() => {
    setUserGender(creatorDetails?.gender);
    setUserEmail(creatorDetails?.email);
    setUserPhone(creatorDetails?.phone_number);
    setUserDob(creatorDetails?.date_of_birth);
    setUserCountry(creatorDetails?.location);
  }, [creatorDetails]);

  const platformRateCard = {
    instagram: insagramRateCard,
    youtube: youtubeRateCard
  };

  const handleUpdateRate = async (platform, type, value, removeEdit) => {
    if (value) {
      const postData = {
        ratecard: [
          {
            platform: platform,
            media_type: type,
            rate: value
          }
        ]
      };
      try {
        const response = !platformRateCard[platform]?.filter((instaRate) => instaRate?.media_type === type)?.[0]?.rate
          ? await axios.post(`${BASE_URL}/pricing/${refresh_token?.user_uuid}/ratecard/`, postData)
          : await axios.patch(`${BASE_URL}/pricing/${refresh_token?.user_uuid}/ratecard/`, postData);
        if (response?.status === 200 || response?.status === 201) {
          notification.success({
            message: rateCardData?.length > 0 ? "Rate updated successfully" : "Rate added successfully"
          });
          removeIsEdit(removeEdit);
          getRateCard();
        }
      } catch (error) {
        notification.error({
          message: error?.response?.data?.message
        });
      }
    }
  };

  const handleChangeEmail = async () => {
    let formData = new FormData();
    const re = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    // var re = new RegExp("^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$")
    if (userEmail.length >= 1 && re.test(userEmail)) {
      formData.append("email", userEmail);

      try {
        const response = await axios.patch(`${BASE_URL}/profiles/creators/${refresh_token?.user_uuid}/`, formData);

        if (!response?.data?.error) {
          notification.success({ message: "updated Successfully" });
          getCreatorDetails();
          removeIsEdit("email");
          // carouselRef.current.next()
        } else {
          notification.error({
            message: response?.data?.error?.message
          });
        }
      } catch (error) {
        notification.error({ message: error?.response?.data?.message });
      }
    } else {
      notification.error({ message: "Please enter valid Email Id" });
    }
  };

  const isNumber = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "");
  };

  const handleGenderChange = async () => {
    let formData = new FormData();
    if (gender) {
      formData.append("gender", gender);
      try {
        const response = await axios.patch(`${BASE_URL}/profiles/creators/${refresh_token?.user_uuid}/`, formData);
        if (!response?.data?.error) {
          notification.success({ message: "updated Successfully" });
          getCreatorDetails();
          removeIsEdit("gender");
          setGender("");
          // carouselRef.current.next()
        } else {
          notification.error({
            message: response?.data?.error?.message
          });
        }
      } catch (error) {
        notification.error({ message: error?.response?.data?.message });
      }
    } else {
      notification.error({ message: "Please select gender" });
    }
  };

  const getCategoryList = async () => {
    const response = await axios.get(`${BASE_URL}/profiles/categories/`);
    if (!response?.data?.error) {
      setCategoryList(response?.data);
    }
  };

  const getRateCard = async () => {
    const refresh_token = parseJwtToken(localStorage.getItem("refresh_token"));
    const response = await axios.get(`${BASE_URL}/pricing/${refresh_token?.user_uuid}/ratecard/`);
    if (response?.data?.ratecard) {
      setRateCardData(response?.data?.ratecard);
      setInstagramRateCard(
        response?.data?.ratecard?.filter((rateCards) => rateCards?.platform === "instagram")?.length > 0
          ? response?.data?.ratecard?.filter((rateCards) => rateCards?.platform === "instagram")
          : []
      );
      setYoutubeRateCard(
        response?.data?.ratecard?.filter((rateCards) => rateCards?.platform === "youtube")?.length > 0
          ? response?.data?.ratecard?.filter((rateCards) => rateCards?.platform === "youtube")
          : []
      );
    }
  };

  useEffect(() => {
    if (insagramRateCard?.length > 0) {
      insagramRateCard?.map((instaRate) => {
        if (instaRate.rate) {
          setInstaRate((prev) => {
            return { ...prev, [instaRate.media_type]: instaRate.rate };
          });
        }
      });
    }
    if (youtubeRateCard?.length > 0) {
      youtubeRateCard?.map((youtubeRate) => {
        if (youtubeRate.rate) {
          setYoutubeRate((prev) => {
            return { ...prev, [youtubeRate.media_type]: youtubeRate.rate };
          });
        }
      });
    }
  }, [insagramRateCard, youtubeRateCard]);

  useEffect(() => {
    getCategoryList();
    getRateCard();
  }, []);

  const handleChangeDob = async () => {
    let formData = new FormData();
    if (date) {
      formData.append("date_of_birth", date);
      try {
        const response = await axios.patch(`${BASE_URL}/profiles/creators/${refresh_token?.user_uuid}/`, formData);
        if (!response?.data?.error) {
          notification.success({ message: "updated Successfully" });
          getCreatorDetails();
          removeIsEdit("dob");
          setDate("");
          // carouselRef.current.next()
        } else {
          notification.error({
            message: response?.data?.error?.message
          });
        }
      } catch (error) {
        notification.error({ message: error?.response?.data?.message });
      }
    } else {
      notification.error({ message: "Please select a valid date" });
    }
  };
  const handleChangeCountry = async () => {
    let formData = new FormData();
    if (location) {
      formData.append("location", location);
      try {
        const response = await axios.patch(`${BASE_URL}/profiles/creators/${refresh_token?.user_uuid}/`, formData);
        if (!response?.data?.error) {
          notification.success({ message: "updated Successfully" });
          getCreatorDetails();
          removeIsEdit("country");
          setLocation("");
          // carouselRef.current.next()
        } else {
          notification.error({
            message: response?.data?.error?.message
          });
        }
      } catch (error) {
        notification.error({ message: error?.response?.data?.message });
      }
    } else {
      notification.error({ message: "Please select Country" });
    }
  };

  const handleChangePhone = async () => {
    let formData = new FormData();
    const re = /^((\+91?)|\+)?[7-9][0-9]{9}$/g;
    if (re.test(userPhone)) {
      formData.append("phone_number", userPhone);

      try {
        const response = await axios.patch(`${BASE_URL}/profiles/creators/${refresh_token?.user_uuid}/`, formData);

        if (!response?.data?.error) {
          notification.success({ message: "updated Successfully" });
          getCreatorDetails();
          removeIsEdit("phone");
          // carouselRef.current.next()
        } else {
          notification.error({
            message: response?.data?.error?.message
          });
        }
      } catch (error) {
        notification.error({ message: error?.response?.data?.message });
      }
    } else {
      notification.error({ message: "Please enter valid Phone number. (Valid Format : +91XXXXXXXXXXXX)" });
    }
  };
  return (
    <>
    
    {!creatorDetails?.is_profile_completed && <div className="w-100"><CompleteYourProfile /></div>}
      <Container>
        <Tabs defaultActiveKey="profile">
          <TabPane tab="Profile" key="Profile">
            <TabContainer>
              <Row gutter={[20, 20]}>
                {/* <CompanyLogoContainer> */}
                <Col span={4}>
                  <LogoContainer
                    onMouseOver={() => {
                      setIsHovering(true);
                    }}
                    onMouseOut={() => {
                      setIsHovering(false);
                    }}
                    backGroundImageUrl={creatorDetails?.image}>
                    {isHovering ? (
                      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                        <EditOutlined
                          style={{ background: "#F6F6FF", padding: "8px", borderRadius: "50%", cursor: "pointer" }}
                          onClick={() => {
                            setUpdateLogoModalVisible(true);
                          }}
                        />
                      </div>
                    ) : null}
                  </LogoContainer>
                </Col>
                {/* </CompanyLogoContainer> */}
                <Col span={20}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span style={{ display: "flex", fontSize: "32px", fontWeight: 600, color: "#010043", lineHeight: "39px", marginRight: "15px" }}>{creatorDetails?.name}</span>
                    <EditOutlined
                      style={{ background: "#F6F6FF", padding: "8px", borderRadius: "50%", cursor: "pointer" }}
                      onClick={() => {
                        setUpdateBasicInfoModalVisible(true);
                      }}
                    />
                  </div>
                  {creatorDetails?.categories && (
                    <Row style={{ display: "flex", alignItems: "center" }}>
                      {creatorDetails?.categories?.map((category) => {
                        return <CustomButton>{category}</CustomButton>;
                      })}
                    </Row>
                  )}
                </Col>
              </Row>
              <Divider />
              <Col span={24}>
                <span style={{ display: "flex", fontWeight: "600", fontSize: "14px", color: "#010043", marginBottom: "30px" }}>Basic Details</span>
              </Col>
              <FormContainer>
                <Row gutter={[20, 30]} style={{ display: "flex", justifyContent: "space-between" }}>
                  <Col span={6}>
                    <span style={{ display: "flex", fontSize: "14px", color: "#010043", marginTop: "5px" }}>Gender</span>
                  </Col>
                  <Col span={18}>
                    {!isEdit.includes("gender") && (
                      <Input
                        disabled={!isEdit.includes("gender") && true}
                        addonAfter={
                          !isEdit.includes("gender") ? (
                            <a
                              style={{ fontSize: "14px", color: "#1551EA" }}
                              onClick={() => {
                                setGender(userGender);
                                setIsEdit((prevValue) => [...prevValue, "gender"]);
                              }}>
                              Edit
                            </a>
                          ) : (
                            <></>
                          )
                        }
                        value={userGender}
                      />
                    )}
                    {isEdit.includes("gender") && (
                      <>
                        <LabelSpan>Select your Gender</LabelSpan>
                        <Radio.Group onChange={(e) => setGender(e.target.value)} value={gender}>
                          <Radio value="male">Male</Radio>
                          <Radio value="female">Female</Radio>
                          <Radio value="non_binary">Non Binary</Radio>
                        </Radio.Group>
                        <Divider />
                        <Col span={24} style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
                          <CancelButton
                            onClick={() => {
                              setUserGender(creatorDetails?.gender);
                              removeIsEdit("gender");
                            }}>
                            Cancel
                          </CancelButton>
                          <SaveButton
                            onClick={() => {
                              handleGenderChange();
                            }}>
                            Save
                          </SaveButton>
                        </Col>
                      </>
                    )}
                  </Col>
                  <Col span={6}>
                    <span style={{ display: "flex", fontSize: "14px", color: "#010043", marginTop: "5px" }}>DOB</span>
                  </Col>
                  <Col span={18}>
                    {!isEdit.includes("dob") && (
                      <Input
                        disabled={!isEdit.includes("dob") && true}
                        addonAfter={
                          !isEdit.includes("dob") ? (
                            <a
                              style={{ fontSize: "14px", color: "#1551EA" }}
                              onClick={() => {
                                setIsEdit((prevValue) => [...prevValue, "dob"]);
                              }}>
                              Edit
                            </a>
                          ) : (
                            <></>
                          )
                        }
                        value={userDob}
                      />
                    )}
                    {isEdit.includes("dob") && (
                      <>
                        <LabelSpan>Select your DOB</LabelSpan>
                        <DatePicker
                          onChange={(date, dateString) => {
                            setDate(dateString);
                          }}
                          disabledDate={disabledDate}
                        />
                        <Divider />
                        <Col span={24} style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
                          <CancelButton
                            onClick={() => {
                              setUserDob(creatorDetails?.date_of_birth);
                              removeIsEdit("dob");
                            }}>
                            Cancel
                          </CancelButton>
                          <SaveButton
                            onClick={() => {
                              handleChangeDob();
                            }}>
                            Save
                          </SaveButton>
                        </Col>
                      </>
                    )}
                  </Col>
                  <Col span={6}>
                    <span style={{ display: "flex", fontSize: "14px", color: "#010043", marginTop: "5px" }}>Country</span>
                  </Col>
                  <Col span={18}>
                    {!isEdit.includes("country") && (
                      <Input
                        disabled={!isEdit.includes("country") && true}
                        addonAfter={
                          !isEdit.includes("country") ? (
                            <a
                              style={{ fontSize: "14px", color: "#1551EA" }}
                              onClick={() => {
                                setIsEdit((prevValue) => [...prevValue, "country"]);
                                setLocation(creatorDetails?.location);
                              }}>
                              Edit
                            </a>
                          ) : (
                            <></>
                          )
                        }
                        value={userCountry}
                      />
                    )}
                    {isEdit.includes("country") && (
                      <>
                        <LabelSpan>Select your Country</LabelSpan>
                        <Select placeholder="Select Country" onChange={(value) => setLocation(value)} value={location}>
                          <Option value="India">India</Option>
                          <Option value="Dubai">Dubai</Option>
                          <Option value="USA">USA</Option>
                          <Option value="Canada">Canada</Option>
                          <Option value="Australia">Australia</Option>
                          <Option value="Japan">Japan</Option>
                        </Select>
                        <Divider />
                        <Col span={24} style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
                          <CancelButton
                            onClick={() => {
                              removeIsEdit("country");
                            }}>
                            Cancel
                          </CancelButton>
                          <SaveButton
                            onClick={() => {
                              handleChangeCountry();
                            }}>
                            Save
                          </SaveButton>
                        </Col>
                      </>
                    )}
                  </Col>
                </Row>
              </FormContainer>
              <Divider />
              <Col span={24} style={{ display: "flex", justifyContent: "space-between" }}>
                <span style={{ display: "flex", fontWeight: "600", fontSize: "14px", color: "#010043", marginBottom: "30px" }}>Rate card</span>
              </Col>
              <Col
                span={24}
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  gap: "6px",
                  fontSize: "16px",
                  fontWeight: 500,
                  marginBottom: "16px",
                  lineHeight: "normal"
                }}>
                <img src="https://static.admirian.com/64d8bcd2-a452-4586-85c8-fcd10261d3c2_Instagram_Glyph_Gradient_1.svg" style={{ height: "20px", width: "20px" }} />
                Instagram
              </Col>
              <FormContainer>
                <Row gutter={[20, 30]} style={{ display: "flex", justifyContent: "space-between" }}>
                  <Col span={6}>
                    <span style={{ display: "flex", fontSize: "14px", color: "#010043", marginTop: "5px" }}>Image</span>
                  </Col>
                  <Col span={18}>
                    <InputNumber
                      disabled={!isEdit.includes("imageInsta") && true}
                      addonAfter={
                        !isEdit.includes("imageInsta") ? (
                          <a
                            style={{ fontSize: "14px", color: "#1551EA" }}
                            onClick={() => {
                              setIsEdit((prevValue) => [...prevValue, "imageInsta"]);
                            }}>
                            Edit
                          </a>
                        ) : (
                          <></>
                        )
                      }
                      value={instaRate?.image}
                      onChange={(value) => {
                        setInstaRate((prev) => {
                          return {
                            ...prev,
                            image: value
                          };
                        });
                      }}
                    />
                    {isEdit.includes("imageInsta") && (
                      <Col span={24} style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
                        <CancelButton
                          onClick={() => {
                            setInstaRate((prev) => {
                              return {
                                ...prev,
                                image: insagramRateCard?.filter((instaRate) => instaRate?.media_type === "image")?.[0]?.rate ?? ""
                              };
                            });
                            removeIsEdit("imageInsta");
                          }}>
                          Cancel
                        </CancelButton>
                        <SaveButton
                          onClick={() => {
                            handleUpdateRate("instagram", "image", instaRate.image, "imageInsta");
                          }}>
                          Save
                        </SaveButton>
                      </Col>
                    )}
                  </Col>
                  <Col span={6}>
                    <span style={{ display: "flex", fontSize: "14px", color: "#010043", marginTop: "5px" }}>Carousel album</span>
                  </Col>
                  <Col span={18}>
                    <InputNumber
                      disabled={!isEdit.includes("instaCarousel") && true}
                      addonAfter={
                        !isEdit.includes("instaCarousel") ? (
                          <a
                            style={{ fontSize: "14px", color: "#1551EA" }}
                            onClick={() => {
                              setIsEdit((prevValue) => [...prevValue, "instaCarousel"]);
                            }}>
                            Edit
                          </a>
                        ) : (
                          <></>
                        )
                      }
                      value={instaRate?.carousel_album}
                      onChange={(value) => {
                        setInstaRate((prev) => {
                          return {
                            ...prev,
                            carousel_album: value
                          };
                        });
                      }}
                    />
                    {isEdit.includes("instaCarousel") && (
                      <Col span={24} style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
                        <CancelButton
                          onClick={() => {
                            setInstaRate((prev) => {
                              return {
                                ...prev,
                                carousel_album: insagramRateCard?.filter((instaRate) => instaRate?.media_type === "carousel_album")?.[0]?.rate ?? ""
                              };
                            });
                            removeIsEdit("instaCarousel");
                          }}>
                          Cancel
                        </CancelButton>
                        <SaveButton
                          onClick={() => {
                            handleUpdateRate("instagram", "carousel_album", instaRate.carousel_album, "instaCarousel");
                          }}>
                          Save
                        </SaveButton>
                      </Col>
                    )}
                  </Col>
                  <Col span={6}>
                    <span style={{ display: "flex", fontSize: "14px", color: "#010043", marginTop: "5px" }}>Story</span>
                  </Col>
                  <Col span={18}>
                    <InputNumber
                      disabled={!isEdit.includes("instaStory") && true}
                      addonAfter={
                        !isEdit.includes("instaStory") ? (
                          <a
                            style={{ fontSize: "14px", color: "#1551EA" }}
                            onClick={() => {
                              setIsEdit((prevValue) => [...prevValue, "instaStory"]);
                            }}>
                            Edit
                          </a>
                        ) : (
                          <></>
                        )
                      }
                      value={instaRate?.story}
                      onChange={(value) => {
                        setInstaRate((prev) => {
                          return {
                            ...prev,
                            story: value
                          };
                        });
                      }}
                    />
                    {isEdit.includes("instaStory") && (
                      <Col span={24} style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
                        <CancelButton
                          onClick={() => {
                            setInstaRate((prev) => {
                              return {
                                ...prev,
                                image: insagramRateCard?.filter((instaRate) => instaRate?.media_type === "story")?.[0]?.rate ?? ""
                              };
                            });
                            removeIsEdit("instaStory");
                          }}>
                          Cancel
                        </CancelButton>
                        <SaveButton
                          onClick={() => {
                            handleUpdateRate("instagram", "story", instaRate.story, "instaStory");
                          }}>
                          Save
                        </SaveButton>
                      </Col>
                    )}
                  </Col>
                  <Col span={6}>
                    <span style={{ display: "flex", fontSize: "14px", color: "#010043", marginTop: "5px" }}>Reel</span>
                  </Col>
                  <Col span={18}>
                    <InputNumber
                      disabled={!isEdit.includes("instaReel") && true}
                      addonAfter={
                        !isEdit.includes("instaReel") ? (
                          <a
                            style={{ fontSize: "14px", color: "#1551EA" }}
                            onClick={() => {
                              setIsEdit((prevValue) => [...prevValue, "instaReel"]);
                            }}>
                            Edit
                          </a>
                        ) : (
                          <></>
                        )
                      }
                      value={instaRate?.reel}
                      onChange={(value) => {
                        setInstaRate((prev) => {
                          return {
                            ...prev,
                            reel: value
                          };
                        });
                      }}
                    />
                    {isEdit.includes("instaReel") && (
                      <Col span={24} style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
                        <CancelButton
                          onClick={() => {
                            setInstaRate((prev) => {
                              return {
                                ...prev,
                                reel: insagramRateCard?.filter((instaRate) => instaRate?.media_type === "reel")?.[0]?.rate ?? ""
                              };
                            });
                            removeIsEdit("instaReel");
                          }}>
                          Cancel
                        </CancelButton>
                        <SaveButton
                          onClick={() => {
                            handleUpdateRate("instagram", "reel", instaRate.reel, "instaReel");
                          }}>
                          Save
                        </SaveButton>
                      </Col>
                    )}
                  </Col>
                </Row>
              </FormContainer>
              <Col
                span={24}
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  gap: "6px",
                  fontSize: "16px",
                  fontWeight: 500,
                  margin: "16px 0px",
                  lineHeight: "normal"
                }}>
                <img src="https://static.admirian.com/fa542b51-2c5a-4a0e-9b64-ba11ac49dde4_youtube.png" style={{ height: "20px", width: "20px" }} />
                Youtube
              </Col>
              <FormContainer>
                <Row gutter={[20, 30]} style={{ display: "flex", justifyContent: "space-between" }}>
                  <Col span={6}>
                    <span style={{ display: "flex", fontSize: "14px", color: "#010043", marginTop: "5px" }}>Shorts</span>
                  </Col>
                  <Col span={18}>
                    <InputNumber
                      disabled={!isEdit.includes("youtubeShort") && true}
                      addonAfter={
                        !isEdit.includes("youtubeShort") ? (
                          <a
                            style={{ fontSize: "14px", color: "#1551EA" }}
                            onClick={() => {
                              setIsEdit((prevValue) => [...prevValue, "youtubeShort"]);
                            }}>
                            Edit
                          </a>
                        ) : (
                          <></>
                        )
                      }
                      value={youtubeRate?.shorts}
                      onChange={(value) => {
                        setYoutubeRate((prev) => {
                          return {
                            ...prev,
                            shorts: value
                          };
                        });
                      }}
                    />
                    {isEdit.includes("youtubeShort") && (
                      <Col span={24} style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
                        <CancelButton
                          onClick={() => {
                            setYoutubeRate((prev) => {
                              return {
                                ...prev,
                                shorts: youtubeRateCard?.filter((instaRate) => instaRate?.media_type === "shorts")?.[0]?.rate ?? ""
                              };
                            });
                            removeIsEdit("youtubeShort");
                          }}>
                          Cancel
                        </CancelButton>
                        <SaveButton
                          onClick={() => {
                            handleUpdateRate("youtube", "shorts", youtubeRate.shorts, "youtubeShort");
                          }}>
                          Save
                        </SaveButton>
                      </Col>
                    )}
                  </Col>
                  <Col span={6}>
                    <span style={{ display: "flex", fontSize: "14px", color: "#010043", marginTop: "5px" }}>Video</span>
                  </Col>
                  <Col span={18}>
                    <InputNumber
                      disabled={!isEdit.includes("youtubeVideo") && true}
                      addonAfter={
                        !isEdit.includes("youtubeVideo") ? (
                          <a
                            style={{ fontSize: "14px", color: "#1551EA" }}
                            onClick={() => {
                              setIsEdit((prevValue) => [...prevValue, "youtubeVideo"]);
                            }}>
                            Edit
                          </a>
                        ) : (
                          <></>
                        )
                      }
                      value={youtubeRate?.video}
                      onChange={(value) => {
                        setYoutubeRate((prev) => {
                          return {
                            ...prev,
                            video: value
                          };
                        });
                      }}
                    />
                    {isEdit.includes("youtubeVideo") && (
                      <Col span={24} style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
                        <CancelButton
                          onClick={() => {
                            setInstaRate((prev) => {
                              return {
                                ...prev,
                                image: youtubeRateCard?.filter((instaRate) => instaRate?.media_type === "video")?.[0]?.rate ?? ""
                              };
                            });
                            removeIsEdit("youtubeVideo");
                          }}>
                          Cancel
                        </CancelButton>
                        <SaveButton
                          onClick={() => {
                            handleUpdateRate("youtube", "video", youtubeRate.video, "youtubeVideo");
                          }}>
                          Save
                        </SaveButton>
                      </Col>
                    )}
                  </Col>
                </Row>
              </FormContainer>
              <Divider />
              <Col span={24}>
                <span style={{ display: "flex", fontWeight: "600", fontSize: "14px", color: "#010043", marginBottom: "30px" }}>Account Details</span>
              </Col>
              <FormContainer>
                <Row gutter={[20, 30]} style={{ display: "flex", justifyContent: "space-between" }}>
                  <Col span={6}>
                    <span style={{ display: "flex", fontSize: "14px", color: "#010043", marginTop: "5px" }}>Email Address</span>
                  </Col>
                  <Col span={18}>
                    <Input
                      disabled={!isEdit.includes("email") && true}
                      addonAfter={
                        !isEdit.includes("email") ? (
                          <a
                            style={{ fontSize: "14px", color: "#1551EA" }}
                            onClick={() => {
                              setIsEdit((prevValue) => [...prevValue, "email"]);
                            }}>
                            Edit
                          </a>
                        ) : (
                          <></>
                        )
                      }
                      value={userEmail}
                      onChange={(e) => {
                        setUserEmail(e?.target?.value);
                      }}
                    />
                    {isEdit.includes("email") && (
                      <Col span={24} style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
                        <CancelButton
                          onClick={() => {
                            setUserEmail(creatorDetails?.email);
                            removeIsEdit("email");
                          }}>
                          Cancel
                        </CancelButton>
                        <SaveButton
                          onClick={() => {
                            handleChangeEmail();
                          }}>
                          Save
                        </SaveButton>
                      </Col>
                    )}
                  </Col>
                  <Col span={6}>
                    <span style={{ display: "flex", fontSize: "14px", color: "#010043", marginTop: "5px" }}>Mobile Number</span>
                  </Col>
                  <Col span={18}>
                    <Input
                      disabled={!isEdit.includes("phone") && true}
                      addonAfter={
                        !isEdit.includes("phone") ? (
                          <a
                            style={{ fontSize: "14px", color: "#1551EA" }}
                            onClick={() => {
                              setIsEdit((prevValue) => [...prevValue, "phone"]);
                            }}>
                            Edit
                          </a>
                        ) : (
                          <></>
                        )
                      }
                      value={userPhone}
                      onChange={(e) => {
                        setUserPhone(e?.target?.value);
                      }}
                      onInput={isNumber}
                    />
                    {isEdit.includes("phone") && (
                      <Col span={24} style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
                        <CancelButton
                          onClick={() => {
                            setUserPhone(creatorDetails?.phone_number);
                            removeIsEdit("phone");
                          }}>
                          Cancel
                        </CancelButton>
                        <SaveButton
                          onClick={() => {
                            handleChangePhone();
                          }}>
                          Save
                        </SaveButton>
                      </Col>
                    )}
                  </Col>
                  <Col span={6}>
                    <span style={{ display: "flex", fontSize: "14px", color: "#010043", marginTop: "5px" }}>Password</span>
                  </Col>
                  <Col span={18}>
                    <Input
                      disabled={true}
                      type="password"
                      addonAfter={
                        !isEdit.includes("password") ? (
                          <a
                            style={{ fontSize: "14px", color: "#1551EA" }}
                            onClick={() => {
                              setIsEdit((prevValue) => [...prevValue, "password"]);
                              setUpdatePasswordModalVisible(true);
                            }}>
                            Change password
                          </a>
                        ) : (
                          <></>
                        )
                      }
                    />
                  </Col>
                </Row>
              </FormContainer>
            </TabContainer>
          </TabPane>
        </Tabs>

        <UpdateLogoModal visible={updateLogoModalVisible} setVisible={setUpdateLogoModalVisible} creatorDetails={creatorDetails} getCreatorDetails={getCreatorDetails} />
        <UpdatePasswordModal
          visible={updatePasswordModalVisible}
          setVisible={setUpdatePasswordModalVisible}
          creatorDetails={creatorDetails}
          getCreatorDetails={getCreatorDetails}
        />
        <UpdateBasicInfoModal
          visible={updateBasicInfoModalVisible}
          setVisible={setUpdateBasicInfoModalVisible}
          brandDetails={creatorDetails}
          getBrandDetail={getCreatorDetails}
          categoryList={categoryList}
        />
        <RateCardModalCreator
          visible={rateCardModalVisible}
          setVisible={setRateCardModalVisible}
          getRateCard={getRateCard}
          insagramRateCard={insagramRateCard}
          youtubeRateCard={youtubeRateCard}
          rateCardData={rateCardData}
        />
      </Container>
    </>
  );
};

export default ProfileCreator;
