import { Button } from 'antd';
import styled from 'styled-components';

export const SidebarContainer = styled.div`
    height: 100%;
    display : flex;
    flex-direction : column;
    padding : 24px;
    width : 250px;
    overflow-y: scroll;
    background: #FFFFFF;
    border-radius: 5px;
    isolation: isolate;
    align-items: flex-start;

    .ant-menu-vertical{
        width : 100%;
        border-inline-end : none !important;
    }
    .ant-menu-item{
        display : flex;
        align-items : center ;
        justify-content : flex-start;
        height : 50px !important;
    }
    .ant-menu-title-content{
        display : flex;
    }
    .ant-divider{
        border-block-start: 2px solid rgba(5, 5, 5, 0.06);
    }
    .ant-select .ant-select-arrow{
        inset-inline-start : 11px !important;
    }

    .ant-menu-item-selected{
        display : flex;
        background: rgba(248, 111, 50, 0.1) !important;
        font-weight: 600;
        font-size: 14px;
        color : #F86624;
        align-items : center ;
        justify-content : flex-start;
    }

    .ant-select-selector {
        padding: 0px 30px !important;
        gap: 10px;
        height: 32px;
        background: #FFFFFF;
        border: 1px solid rgba(1, 0, 67, 0.2);
        border-radius: 20px;
    }
    .ant-select-selection-placeholder {
        padding-left : 20px;
    }
    .ant-slider .ant-slider-track{
        background-color : #F86624;
    }
    .ant-slider-handle::after{
        box-shadow :0 0 0 2px #F86624 !important;
    }
    // .ant-tooltip{
    //     width : 200px !important;
    // }

    .platform-icon-selected{
        opacity: 1;
    }
`

export const FansRadioContainer = styled.div`
    display : flex;

    .ant-radio-button-wrapper{
        display : flex;
        align-items : center;
        margin-bottom : 10px;
        border-radius : 20px;
        border-inline-start-width : 1px;
        position : inherit;
        border: 0px;
    }
    .ant-radio-button-wrapper-checked{
        background : #F6FAFF !important;
    }
    .ant-radio-button-wrapper:first-child{
        border-start-start-radius: 20px !important;
        border-end-start-radius: 20px !important;
    }
    .ant-radio-button-wrapper:last-child{
        border-start-end-radius: 20px !important;
        border-end-end-radius: 20px !important;
    }
    .ant-radio-group{
        display : flex;
        flex-direction : column;
        width : 100%;
    }
    .ant-radio-button{
        width : 0px;
    }
`

export const GenderRadioContainer = styled.div`
    display : flex;
    flex-direction : column;
    .ant-click-animating-node {
        display: none;
      }
`
export const GenderButton = styled(Button)`
    display : flex;
    justify-content : flex-start;
    align-items : center;
    border : 0px !important;
    box-shadow : none !important;
    color : ${(props) => {
        switch (props.isActive) {
          case true:
            return '#F86624 !important'
          default:
            return 'rgba(0, 0, 0, 0.88) !important';
        }
    }};    
    font-weight : ${(props) => {
        switch (props.isActive) {
          case true:
            return '600'
          default:
            return '400';
        }
    }}; 
`

export const PlatformImageIcon = styled.img`
    opacity: 0.2;
    cursor: pointer;
    width: 32px;
    height: 32px;

    .platform-icon-selected{
        opacity: 1;
    }
`;