import React from 'react';
import { isMobile } from '../../utils/constants/inedx';
import { Image} from "antd";

import { Heading6, Container, NewFormHeadingText, NewPasswordFormContainer, SubmitButton} from './style';
import { JustifyCenterContainer } from '../../utils/common';
import Footer from '../../components/Footer';
import { Link } from 'react-router-dom';

const SuccessfulPasswordChange = () => {

    return (
        <>
         <JustifyCenterContainer className="w-100" style={{ minHeight: 50, alignItems: 'center', padding: '6px 0px' }}>
                <Image height={50} style={{ margin: "4px 0px" }} preview={false} src="https://static.admirian.com/9a312601-984e-4237-bfad-1454204ce38f_BrandLogo.svg" alt="brand-logo" />
            </JustifyCenterContainer>
        <Container>  
        <Heading6>
            <NewPasswordFormContainer
                style={{ minWidth: !isMobile ? '400px' : '300px'}}
            >
                <img src="https://static.admirian.com/Group%20128.png" width={120} height={120} alt="lock" />
                <NewFormHeadingText>Your new password is <br/> created successfully</NewFormHeadingText>
                <Link to="/Sign-in" className='w-100'>
                    <SubmitButton>Sign in</SubmitButton>
                </Link>
            </NewPasswordFormContainer>
        </Heading6>
     </ Container>
     <Footer/>
     </>
    )
}

export default SuccessfulPasswordChange;