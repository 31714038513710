import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    data: '',
    isLoading: true,
}; 

export const ManageCampaignReducer = createSlice({
    name: 'ManageCampaign',
    initialState,
    reducers: {
        storeManageCampaign: (state, action) => {
            state.data = action?.payload;
            state.isLoading = false;
        }
    },
})

// Action creators are generated for each case reducer function
export const { storeManageCampaign} = ManageCampaignReducer.actions

export default ManageCampaignReducer.reducer