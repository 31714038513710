import React, { Fragment, useEffect, useState } from "react";
import {
    AcceptInfleuencerText,
    AcceptedOfferContainer,
    ActivityCard,
    AmountInformationContainer, BannerNameHeadingText, BannerNameText, CampaignContentContainer, CampaignInfoDescription, CampaignInfoHeading, CampaignObjectiveBox, CampaignObjectiveDescription, CampaignObjectiveHeading,
    CommentDescriptionText,
    Container, CreatedDateText, ListBox, ListContainer, ListContentBox, ListItemDescription, ListItemHeading, NoActivityContainer, NoActivityText, NoDataText, SecureText, UserNameText
} from "./style";
import { FlexContainer, Heading, SpaceBetweenContainer, SubHeading } from "../../../utils/common";
import { Avatar, Col, Divider, Row, Typography, Button, notification, Popconfirm, Tabs, Radio, Tooltip, Tag } from "antd";
import { LeftOutlined, DeleteOutlined, PlusOutlined, EyeOutlined, UserOutlined } from '@ant-design/icons';
import { useGetCampaignActivityQuery, useGetInfluencerSummaryQuery, useGetParticularCampaignQuery, useReadyToLiveContentMutation, useSelectedInfluencerDeleteInitiatedMutation } from "../../../services/manageCampaignsApi";
import { Link, useNavigate, useParams } from "react-router-dom";
import TransactionDetails from "../TransactionDetails";
import { BASE_URL } from "../../../utils/HelperFunctions/constants";
import axios from "axios";
import { parseJwtToken } from "../../../utils";

const { TabPane } = Tabs;

const ManageCampaignView = () => {
    //const campaignId = useSelector((state) => state?.ManageCampaign?.data);
    const refresh_token = parseJwtToken(localStorage.getItem('refresh_token'));
    const navigate = useNavigate();
    const { id } = useParams();
    const [influencerStatus, setInfluencerStatus] = useState("PENDING,ACCEPTED");
    const [brandDetails, setBrandDetails] = useState({});
    const { data: { campaign_creators = [] } = {}, refetch } = useGetInfluencerSummaryQuery({ campaignId: id, status: [influencerStatus] });
    const { data: { campaign_creators: campaignAcceptedData = [] } = {}, refetch : refetchAccptedData } = useGetInfluencerSummaryQuery({ campaignId: id, status: ["ACCEPTED"] });
    const { data: campaignData = {} } = useGetParticularCampaignQuery({ campaignId: id });
    const { data: activityData } = useGetCampaignActivityQuery({ campaignId: id });
    const [goLiveLoading, setGoLiveLoading] = useState(false);
    const [selectedInfluencerDeleteInitiated] = useSelectedInfluencerDeleteInitiatedMutation();
    const [readyToLiveContentInitiatedMutation] = useReadyToLiveContentMutation();

    const campaignAcceptedContent = campaignAcceptedData.filter((campData) => campData.payment_initiated === false);
    const creatorUuidArray = campaignAcceptedContent.map(obj => obj.creator_uuid);

    const getBrandDetail = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/profiles/brands/${refresh_token?.user_uuid}/`)

            if (!response?.data?.error) {
                setBrandDetails(response?.data)
            }
        } catch (error) {
            notification.error({ message: error?.response?.data?.message })
        }
    }

    useEffect(() => {
        if (refresh_token?.user_type === "Brand") {
            getBrandDetail()
        }
        refetch();
        refetchAccptedData();
    }, [])

    const handleConfirm = async (campaignCreatorId) => {
        const response = await selectedInfluencerDeleteInitiated({ campaignId: id, campaignCreatorId });
        try {
            if (response && !response.error) {
                notification.success({
                    message: 'Influencer removed from the campaign'
                })
                refetch();
            } else if (response.error) {
                notification.error({
                    message: response?.error?.data?.error
                })
            }
        }
        catch (error) {
            notification.error({
                message: response?.error?.data?.error
            })
        }
    };
    const cancel = (e) => {
        console.log(e);
    }


    const handleOnChange = (value) => {
        setInfluencerStatus(value);
    };

    const handleGoLiveConfirm = async (creatorId) => {
        const response = await readyToLiveContentInitiatedMutation({ campaignId: id, creatorId });
        setGoLiveLoading(true);
        try {
            if (response && !response.error) {
                notification.success({
                    message: response?.data?.message
                })
                setGoLiveLoading(false);
            } else {
                notification.error({
                    message: response?.error?.data?.error
                })
                setGoLiveLoading(false);
            }
        } catch (error) {
            notification.error({
                message: error?.response?.error?.data?.message
            })
            setGoLiveLoading(false);
        }
    }

    return (
        <Container>
            <Typography style={{ color: '#494785', fontSize: 14 }} onClick={() => navigate(-1)}>
                <LeftOutlined style={{ marginRight: 4 }} /> Back
            </Typography>
            <Divider />
            <Tabs
                defaultActiveKey="overview"
            >
                <TabPane
                    tab="Overview"
                    key="overview"
                >
                    {
                        !!campaignAcceptedContent?.length && (
                            <AcceptedOfferContainer>
                                <SpaceBetweenContainer style={{ alignItems: 'center' }}>
                                    <FlexContainer>
                                        <Avatar.Group
                                            maxCount={2}
                                            maxPopoverTrigger="click"
                                            maxStyle={{
                                                color: '#f56a00',
                                                backgroundColor: '#fde3cf',
                                                cursor: 'pointer',
                                            }}
                                            size={"small"}
                                        > {campaignAcceptedContent.map((campaignData, index) => {
                                            return (
                                                <Fragment key={index}>
                                                    {
                                                        campaignData?.thumbnail?.length ? <Avatar src={campaignData.thumbnail} size={"small"} key={index} /> : <Avatar icon={<UserOutlined />} size={"small"} key={index} />
                                                    }
                                                </Fragment>
                                            )
                                        })}</Avatar.Group>
                                        <AcceptInfleuencerText>
                                            {campaignAcceptedContent.length} {campaignAcceptedContent.length === 1 ? 'influencer' : 'influencers'} accepted your offer
                                            <Link to={`/proposed-influencers/${id}/${creatorUuidArray}`}>
                                                <span className="cursor-pointer" style={{ marginLeft: 12, textDecoration: 'underline', color: "#fff" }}>View all</span>
                                            </Link>
                                        </AcceptInfleuencerText>
                                    </FlexContainer>
                                    <div>
                                        {
                                            (brandDetails?.payment_style?.toLowerCase() === "prepaid") 
                                            ? <Link to={`/proposed-influencers/${id}/${creatorUuidArray}`}>
                                                <Button type="primary" shape="round">Make Payment</Button>
                                            </Link>
                                            : <Link to={`/proposed-influencers/${id}/${creatorUuidArray}`}>
                                                <Button type="primary" shape="round">Continue</Button>
                                            </Link>
                                        }
                                    </div>
                                </SpaceBetweenContainer>
                            </AcceptedOfferContainer>
                        )
                    }
                    <Row gutter={[16, 20]}>
                        <Col span={16}>
                            <Heading>Campaign Summary</Heading>
                            <SubHeading>View your campaign details, manage influencers as they progress through campaign and track campaign activity</SubHeading>
                            <div style={{ position: 'relative' }}>
                                <img src={campaignData?.banner_file?.length ? campaignData.banner_file : 'https://static.admirian.com/0d565fa0-d51b-4cd9-9459-9c8521fc5d69_Banner_placeholder.svg'} width={"100%"} height={290} style={{ objectFit: 'contain' }} />
                                <Avatar shape="square" size={120} src={campaignData?.brand_logo} style={{ position: 'absolute', left: 12, bottom: '-46px' }} />
                            </div>
                            <Row style={{ paddingTop: 8 }} gutter={[16, 24]}>
                                <Col span={5} />
                                <Col span={6}>
                                    <BannerNameHeadingText>Brand Name</BannerNameHeadingText>
                                    <BannerNameText>{campaignData?.brand_name}</BannerNameText>
                                </Col>
                                <Col span={6}>
                                    <BannerNameHeadingText>Campaign Status</BannerNameHeadingText>
                                    <BannerNameText>{campaignData?.status}</BannerNameText>
                                </Col>
                            </Row>
                            <CampaignContentContainer style={{ marginTop: 20 }}>
                                <div>
                                    <SubHeading style={{ marginTop: 0 }}>Campaign Name</SubHeading>
                                    <CampaignInfoHeading>{campaignData?.name}</CampaignInfoHeading>
                                </div>
                                <CampaignInfoDescription>{campaignData?.brief}</CampaignInfoDescription>
                            </CampaignContentContainer>
                            <CampaignContentContainer>
                                <SpaceBetweenContainer style={{ flexWrap: 'wrap' }}>
                                    <CampaignObjectiveBox>
                                        <CampaignObjectiveHeading>Campaign Objectives</CampaignObjectiveHeading>
                                        <CampaignObjectiveDescription>{campaignData?.objective}</CampaignObjectiveDescription>
                                    </CampaignObjectiveBox>
                                    <CampaignObjectiveBox>
                                        <CampaignObjectiveHeading>Category</CampaignObjectiveHeading>
                                        <CampaignObjectiveDescription>{campaignData?.category}</CampaignObjectiveDescription>
                                    </CampaignObjectiveBox>
                                    <CampaignObjectiveBox>
                                        <CampaignObjectiveHeading>Budget (₹)</CampaignObjectiveHeading>
                                        <CampaignObjectiveDescription>{campaignData?.min_budget} - {campaignData?.max_budget}</CampaignObjectiveDescription>
                                    </CampaignObjectiveBox>
                                    <CampaignObjectiveBox style={{ textAlign: 'right' }}>
                                        {/* <Typography style={{ color: '#1551EA', fontSize: 12 }}>Edit</Typography> */}
                                    </CampaignObjectiveBox>
                                </SpaceBetweenContainer>
                            </CampaignContentContainer>
                            <Divider />
                            <SpaceBetweenContainer>
                                <div>
                                    <Heading>All Influencers</Heading>
                                    <SubHeading>Manage and track your influencers as they move through the different stages of their campaign journey</SubHeading>
                                </div>
                                <div>
                                    {
                                        campaignData?.campaign_type?.toLowerCase() === "influencer" && (campaignData?.status?.toLowerCase() === "draft" || campaignData?.status?.toLowerCase() === "in progress" || campaignData?.status?.toLowerCase() === "published") && (
                                            <Link to={`/initial-influencer-details/${id}`}>
                                                <Button type="link" icon={<PlusOutlined />}>Add Influencers</Button>
                                            </Link>
                                        )
                                    }
                                </div>
                            </SpaceBetweenContainer>
                            <div style={{ marginTop: 20, marginBottom: 20 }}>
                                <Radio.Group defaultValue="PENDING,ACCEPTED" buttonStyle="solid" onChange={(e) => handleOnChange(e.target.value)}>
                                    <Radio.Button value="PENDING,ACCEPTED">All Requests</Radio.Button>
                                    <Radio.Button value="IN_PROGRESS">In-Progress</Radio.Button>
                                    <Radio.Button value="DELIVERED">Delivered</Radio.Button>
                                    <Radio.Button value="LIVE_REQUESTED">Live Requested</Radio.Button>
                                    <Radio.Button value="LIVE">Live</Radio.Button>
                                    {/* <Radio.Button value="REJECTED">Rejected</Radio.Button> */}
                                </Radio.Group>
                            </div>
                            <ListContainer>
                                {
                                    campaign_creators.length ? <>
                                        {
                                            campaign_creators.map((campaignDataValue, index) => {
                                                return (
                                                    <Link style={influencerStatus === 'PENDING,ACCEPTED' ? { color: 'unset', cursor: 'unset' } : { color: 'unset' }} to={influencerStatus === 'PENDING,ACCEPTED' ? '#' : `/influencer-campaign-information/${id}/${campaignDataValue.campaign_creator_id}`}>
                                                        <ListBox style={index === campaign_creators.length - 1 ? { borderBottom: 'unset' } : { borderBottom: '1px solid #D8D7EA' }} key={campaignDataValue.campaign_creator_id}>
                                                            <ListContentBox style={{ width: "30%" }}>
                                                                <FlexContainer>
                                                                    {
                                                                        campaignDataValue?.thumbnail?.length ? <Avatar size={'large'} src={campaignDataValue.thumbnail} style={{ marginRight: 8 }} /> : <Avatar size={'large'} icon={<UserOutlined />} style={{ marginRight: 8 }} />
                                                                    }
                                                                    <div>
                                                                        <ListItemHeading>
                                                                            Name
                                                                        </ListItemHeading>
                                                                        <ListItemDescription style={{ color: '#1551EA' }}>{campaignDataValue.name}</ListItemDescription>
                                                                    </div>
                                                                </FlexContainer>
                                                            </ListContentBox>
                                                            <ListContentBox>
                                                                <ListItemHeading>
                                                                    Media Type
                                                                </ListItemHeading>
                                                                <ListItemDescription>{campaignDataValue.media_type.length === 1 ? campaignDataValue.media_type[0] : `${campaignDataValue.media_type[0]} + ${campaignDataValue.media_type.length - 1}`}</ListItemDescription>
                                                            </ListContentBox>
                                                            <ListContentBox>
                                                                <ListItemHeading>
                                                                    Influencer Rate
                                                                </ListItemHeading>
                                                                <ListItemDescription>{campaignDataValue.creator_rate !== campaignDataValue.approved_rate && (
                                                                    <span style={{ color: "#8988BF", marginRight: 4, textDecoration: 'line-through' }}>₹{campaignDataValue.creator_rate}</span>
                                                                )} ₹{campaignDataValue.approved_rate}</ListItemDescription>
                                                            </ListContentBox>
                                                            <ListContentBox style={{ width: '15%' }}>
                                                                {
                                                                    influencerStatus === "IN_PROGRESS" || influencerStatus === "DELIVERED" || influencerStatus === "LIVE_REQUESTED" || influencerStatus === "LIVE"? <>
                                                                        <ListItemHeading>
                                                                            Content Delivered
                                                                        </ListItemHeading>
                                                                        <ListItemDescription>{campaignDataValue?.content_delivered}</ListItemDescription>
                                                                    </> : <>
                                                                        <ListItemHeading>
                                                                            Status
                                                                        </ListItemHeading>
                                                                        <ListItemDescription>{campaignDataValue?.status}</ListItemDescription>
                                                                    </>
                                                                }
                                                            </ListContentBox>
                                                            {
                                                                (brandDetails?.payment_style?.toLowerCase() === "prepaid" 
                                                                    && campaignDataValue?.status?.toLowerCase() === "accepted" 
                                                                    && campaignDataValue?.payment_initiated === false
                                                                ) 
                                                                ? <ListContentBox style={{ textAlign: 'center', width: "15%", margin: 'auto' }}> 
                                                                    <Link to={`/proposed-influencers/${id}/${campaignDataValue.creator_uuid}`}>
                                                                        <Button shape="round">Pay Now</Button>
                                                                    </Link>  
                                                                    {/* {campaignData?.campaign_type?.toLowerCase() === "influencer" && (
                                                                        <Popconfirm
                                                                            title={`Are you sure want to remove ${campaignDataValue.name} from this campaign?`}
                                                                            onConfirm={() => handleConfirm(campaignDataValue.campaign_creator_id)}
                                                                            onCancel={cancel}
                                                                            okText="Yes"
                                                                            cancelText="No"
                                                                        >
                                                                            <DeleteOutlined className="cursor-pointer" style={{ fontSize: 18, color: 'red' }} />
                                                                        </Popconfirm>
                                                                    )} */}
                                                                </ListContentBox>
                                                                : (campaignDataValue?.status?.toLowerCase() === "delivered") 
                                                                    ? <ListContentBox style={{ textAlign: 'center', width: "15%", margin: 'auto' }}>
                                                                        <Popconfirm placement="bottomLeft"
                                                                            onConfirm={() => handleGoLiveConfirm(campaignDataValue.campaign_creator_id)}
                                                                            title={`Are you sure want to request ${campaignDataValue?.name} to go live?`}
                                                                            okText="Yes"
                                                                            cancelText="No"
                                                                        >
                                                                            <Button shape="round" loading={goLiveLoading}>Go Live</Button>
                                                                        </Popconfirm>
                                                                    </ListContentBox> 
                                                                    : (campaignDataValue?.status?.toLowerCase() === "pending") 
                                                                        ? <Popconfirm
                                                                            title={`Are you sure want to remove ${campaignDataValue?.name} from this campaign?`}
                                                                            onConfirm={() => handleConfirm(campaignDataValue.campaign_creator_id)}
                                                                            onCancel={cancel}
                                                                            okText="Yes"
                                                                            cancelText="No"
                                                                        >
                                                                            <DeleteOutlined className="cursor-pointer" style={{ fontSize: 18, color: 'red', marginLeft: '60px'  }} />
                                                                        </Popconfirm> 
                                                                        : (brandDetails?.payment_style?.toLowerCase() === "postpaid" 
                                                                            && campaignDataValue?.status?.toLowerCase() === "accepted"
                                                                            && campaignDataValue?.payment_initiated === false
                                                                            ) 
                                                                        ? <ListContentBox style={{ textAlign: 'center', width: "15%", margin: 'auto' }}> 
                                                                            <Link to={`/proposed-influencers/${id}/${campaignDataValue.creator_uuid}`}>
                                                                                <Button shape="round">Continue</Button>
                                                                            </Link> 
                                                                            </ListContentBox> : null
                                                            }
                                                        </ListBox>
                                                    </Link>
                                                )
                                            })
                                        }
                                    </> :
                                        <div style={{ alignSelf: 'center', textAlign: 'center' }}>
                                            <img src='https://static.admirian.com/c224b1b7-bd85-476a-bff2-18a2a510b321_no-file.svg' width={64} height={64} />
                                            <NoDataText>No data available yet</NoDataText>
                                        </div>
                                }

                            </ListContainer>
                        </Col>
                        <Col span={8}>
                            <AmountInformationContainer>
                                <div>
                                    <Heading style={{ fontWeight: 'unset', fontSize: 16 }}>Activity</Heading>
                                    <SubHeading>Find all the influencer and campaign activity updates here</SubHeading>
                                </div>
                                {
                                    activityData?.activity.length ?
                                        <>
                                            {
                                                activityData?.activity.map((actData, index) => (
                                                    <ActivityCard key={index}>
                                                        <div style={{ paddingRight: 6 }}>
                                                            {
                                                                actData?.author_thumbnail ? <Avatar src={actData?.author_thumbnail} /> : <Avatar icon={<UserOutlined />} />
                                                            }
                                                        </div>
                                                        <div className="w-100">
                                                            <SpaceBetweenContainer>
                                                                <UserNameText>{actData?.author_name}</UserNameText>
                                                                <CreatedDateText>{actData?.timestamp}</CreatedDateText>
                                                            </SpaceBetweenContainer>
                                                            <CommentDescriptionText>{actData?.message}</CommentDescriptionText>
                                                        </div>
                                                    </ActivityCard>
                                                ))
                                            }
                                        </>
                                        :
                                        <NoActivityContainer>
                                            <NoActivityText>No Activity</NoActivityText>
                                            <SubHeading style={{ color: '#8988BF' }}>Find all the influencer and campaign activity updates here</SubHeading>
                                        </NoActivityContainer>
                                }
                            </AmountInformationContainer>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane
                    tab={"Transactions"}
                    key="transaction"
                >
                    <TransactionDetails />
                </TabPane>
                <TabPane
                    tab={<span>Performance <Tag color="blue">Coming soon</Tag></span>}
                    key="performance"
                    disabled
                >
                    <div>Coming soon</div>
                </TabPane>
            </Tabs>
        </Container>
    )
}

export default ManageCampaignView;