import styled from "styled-components";

export const NavigationCard = styled.div`
    position : sticky;
    display: flex;
    padding: var(--space-list-high, 24px) 18px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    border-radius: 10px;
    background: var(--typo-white, #FFF);
`
export const NavigationTitle = styled.div`
    display: flex;
    height: 32px;
    padding: 8px 16px;
    align-items: center;
    gap: 34px;
    align-self: stretch;
    border-radius: 8px;
    color : #494785 ;
    &:hover {
        background : var(--surface-medium, #EBF4FF);
        cursor : pointer;
        color : #0F0E1B ;
    }
`
export const NoDataAvailableContainer = styled.div`
    display: flex; 
    justify-content: center;
    color: red; 
    font-size: 20px; 
    font-weight: 600;
`;