import styled from 'styled-components';

export const Container = styled.div`
    padding: 20px;
    background : #FFFFFF;
    border-radius: 4px;
    margin : 24px 32px;
    width: 100%;

    .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #F86624;
    };
    .ant-tabs .ant-tabs-ink-bar {
        background: #F86624;
    }

`;
