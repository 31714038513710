import { Col, Image, Row } from "antd";
import React from "react";
import SignUpForm from "../../components/Home/SignUpForm";
import { FooterContainer, Heading8 } from "../../components/Home/style";
import { Container, MotoContainer, SignInFormContainer, SignUpFormText } from "./style";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { isMobile } from "../../utils/constants/inedx";
import { JustifyCenterContainer } from "../../utils/common";
import Footer from "../../components/Footer";

const SignUp = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location;

    return (
        <div className="overflow-hidden">
                <JustifyCenterContainer className="w-100" style={{minHeight: '50px', alignItems: 'center' }}>
                    <Image style={{ height: '50px', margin: "5px 0px" }} preview={false} src="https://static.admirian.com/9a312601-984e-4237-bfad-1454204ce38f_BrandLogo.svg" />
                </JustifyCenterContainer>
            <Container>
                <Row className="w-100" style={{ display: 'flex', justifyContent: 'center' }}>
                    <Col xl={11} lg={11} md={12} sm={24} xs={24} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <SignInFormContainer>
                            <SignUpFormText className="w-100"> {pathname.toLowerCase() === "/sign-up" ? "Sign Up as an Influencer" : "Sign Up as a Brand"}</SignUpFormText>
                            <SignUpForm />
                            {
                                pathname.toLowerCase() === "/sign-up" && (
                                    <div>
                                        If you are a Brand,{" "}
                                    <Link to="/request-demo">
                                        Request a Demo
                                    </Link> to get started
                                    </div>
                                )
                            }
                        </SignInFormContainer>
                    </Col>
                        <Col xl={13} lg={13} md={12} sm={0} xs={0} style={{ padding: '20px' }}>
                            <MotoContainer>
                                    Join.
                                    collaborate.
                                    Reach.
                            </MotoContainer>

                        </Col>
                </Row>
            </Container>
            <Footer />
        </div>
    )
}

export default SignUp