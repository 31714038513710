import { Button, Input } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
    display : flex;
    width : 720px;
    padding: 20px;
    background : #FFFFFF;
    border-radius: 10px;
    margin : 16px;
    .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
        color : #010043 !important;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        width : 100% ;
    }
    .ant-tabs{
        width : 100% !important;
    }
    .ant-tabs-ink-bar{
        background : #ff756c !important;
    }
    .ant-input-number-group-wrapper {
        width : 100%;
    }
`
export const CompanyDetails = styled.div`
    display : flex;
    align-items : center;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #010043;
    margin-right: 10px;
`
export const CompanyLogoContainer = styled.div`
  width: 32px;
  height: 32px;
  background: #FFFFFF;
  border: 1px solid #4E4BEE;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TabContainer = styled.div`
    display : flex;
    flex-direction : column;
    padding : 24px;
`
export const CustomButton = styled(Button)`
    display : flex;
    height : 40px;
    margin: 10px;
    margin-left : 0px;
    border-radius : 60px;
    justify-content: center;
    align-items: center;
    background: #F6F6FF !important;
    border: 0px !important;
    color: #010043 !important;
    
`

export const FormContainer = styled.div`
    .ant-input-number-input-wrap{
        height: 40px !important;
        display : flex;
        align-items : center;
    }
    .ant-input,.ant-input-number {
        height: 40px;
        border: 0px !important;
        background: #F6F6FF !important;
        border-radius : 60px;
        border-start-end-radius: 0 !important;
        border-end-end-radius : 0 !important;
        box-shadow: none !important;
    }
    .ant-input-group-addon, .ant-input-number-group-addon{
        background: #F6F6FF !important;
        border-start-start-radius: 0 !important;
        border-end-start-radius: 0 !important;
        border-radius: 60px;
        border: 0px !important;
    }
    .ant-radio-wrapper{
        background: #F6F6FF;
        padding: 15px 12px;
        border-radius: 30px;
    }
    .ant-picker{
        display : flex;
        height : 50px;
        border-radius: 60px;
        border: 1px solid #8988BF !important;
    }
    .ant-select{
        width : 100%;
    }
    .ant-select-selector{
        border: 1px solid #8988BF !important;
        border-radius: 60px;
        height : 50px !important;
    }
    .ant-select-selection-item, .ant-select-selection-placeholder, .ant-select-selection-search{
        display : flex !important;
        align-items : center !important;
    }
`

export const CancelButton = styled(Button)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 30px;
    gap: 10px;
    width: 107px;
    height: 40px;
    background: #FFFFFF !important;
    border: 1px solid #010043 !important;
    border-radius: 60px;
    margin-right : 16px;
    color: #010043 !important;
    font-weight : 500;
    font-size : 14px;
`

export const SaveButton = styled(Button)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 40px;
    gap: 10px;
    width: 113px;
    height: 40px;
    background: linear-gradient(0deg, #F86624, #F86624), #F9C80E !important;
    border-radius: 60px;
    border : 0px !important;
    color: #FFFFFF !important;
    font-weight : 500;
    font-size : 14px;
`

export const ModalTitle = styled.div`
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #010043;
`
export const ModalContainer = styled.div`
    display : flex;
`
export const UploadContainer = styled.div`
    display : flex;
    width : 100%;
    justify-content : center;
    .ant-upload-wrapper{
        display: flex !important;
        justify-content: center;
    }
    .ant-upload-list-item{
        padding : 0px !important;
    }
    .ant-upload-list-item::before{
        width: 100% !important;
        height: 100% !important;
        border-radius: 8px !important;
    }
    .ant-upload-list-item-name{
        width: 100% !important;
    }
`

export const LogoContainer = styled.div`
    display : flex;
    padding : 5px;
    height : 90px;
    width : 90px;
    border-radius : 50%;
    background : #F6F6FF;
    background-image: ${(props) => {
            return `url(${props.backGroundImageUrl})`;
        }
    }};
    background-size: 100%;
    background-position: center;
    background-repeat : no-repeat;
`

export const CustomInputPassword = styled(Input.Password)`
  border-radius : 40px !important;
  input:-internal-autofill-selected {
    background-color : #ffffff !important
  }
`;

export const LabelSpan = styled.div`
    display : flex;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 30px;
    color: #2C2B50;
`

export const AddRateButton = styled(Button)`
    display : flex;
    width: 100%;
    height: 40px !important;
    padding: 12px 24px 12px 24px !important;
    border-radius: 60px !important;
    justify-content: center;
    background: #F6F6FF !important;
    border : none !important;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    color : #1551EA !important;
`

export const RateModalContainer = styled.div`
    display : flex;
    flex-direction : column;
`

export const FormContainerRate = styled.div`
    display : flex;
    flex-direction : column;
    .ant-cascader-menu {
        height : 2000px !important;
    }
    .ant-input-group{
        border-radius: 60px;
        border: 1px solid #8988BF !important;
    }
    .ant-input{
        height: 48px;
        padding: 12px 16px 12px 16px;
        border-radius: 60px;
        border: none !important;
        border-left : 1px solid #8988BF !important;
        gap: 8px;
    }
    .ant-input-group .ant-input-group-addon .ant-select{
        height : 48px;
        display : flex;
        align-items : center;
        justify-content : center;
    }
    .ant-input-group-addon{
        border : none !important;
        background : inherit !important;
    }
    .ant-input-group .ant-input-group-addon:first-child .ant-select .ant-select-selector{
        display : flex;
        align-items : center;
        height: 48px;
        padding: 12px 16px 12px 16px;
        border: none !important;
        border-top-left-radius : 60px;
        border-bottom-left-radius : 60px;
        gap: 8px;
    }
    .ant-select-selector .ant-select-selection-search-input{
        height : 48px !important;
    }
    .ant-form-vertical .ant-form-item-label >label{
        font-family: Inter;
        font-size: 16px !important;
        font-weight: 400 !important;
        line-height: 24px !important;
        letter-spacing: 0em;
        text-align: left;
        color: #010043 !important;
        margin-bottom : 4px;
    }
`

export const NavigateButton = styled(Button)`
    display : flex;
    align-items : center;
    justify-content : flex-start;
    width: 100%;
    height: 44px;
    padding: 12px 16px 12px 16px;
    border-radius: 60px;
    gap : 10px;
    border : none !important;
    box-shadow : none !important;
    color : #010043 !important;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    background-color :  ${(props) => {
        switch (props.isActive) {
          case true:
            return '#EBF4FF !important'
          default:
            return '0px !important';
        }
    }};
`

export const AdditionalDeliverableContainer = styled.div`
    display : flex;
    flex-direction : column;
    width : 100%;
    .ant-input{
        height: 48px;
        padding: 12px 16px 12px 16px;
        border-radius: 60px;
        border: none !important;
        border : 1px solid #8988BF !important;
        gap: 8px;
    }
`
export const Label = styled.div`
    display : flex;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #010043;
    margin-bottom : 4px;
`

export const RateCardContainer = styled.div`
    display : flex;
    flex-direction : column;
    padding: 16px;
    border-radius: 8px;
    border: 1px;
    gap: 24px;
    border: 1px solid #D8D7EA;
`

export const RateSummary = styled.div`
    display : flex;
    width : 100%;
    justify-content : space-between;
    align-items : center;
    font-family: Inter;
    font-size: 12px;
    font-weight: 300;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #010043;
`