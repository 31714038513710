import React from 'react';
import {PaymentModeContainer, ScanContentHeading, ScannerContainer, UpiId, UpiIdContainer, UpiIdContentDescription, UpiIdCopy} from './style';
import { ScannerIcon } from '../../../assets/icons';
import { FlexContainer, SpaceBetweenContainer } from '../../../utils/common';
import { message } from 'antd';

const PaymentModeDetails =()=>{

    const handleUPIIdCopy=()=>{
        navigator.clipboard.writeText("admirian@kotak");
        message.success("copied!!")
    }
    const handleUPINumberCopy=()=>{
        navigator.clipboard.writeText("8286408471");
        message.success("copied!!")
    }

    return(
        <PaymentModeContainer>
           <ScanContentHeading>Scan to pay via UPI </ScanContentHeading>
           <ScannerContainer>
                {/* <ScannerIcon /> */}
                <div>
                    <img src="https://static.admirian.com/Admirian_UPI_QR.jpeg" width={"auto"} height={160} />
                </div>
                <FlexContainer style={{marginTop: 12, justifyContent: 'center'}}>
                    <div>
                        <img src="https://static.admirian.com/63a5bac5-3c63-4dc8-a3c1-43cfec53c707_paytm.svg" width={"auto"} height={32} />
                    </div>
                    <div style={{paddingLeft: 20, paddingRight: 20}}>
                        <img src="https://static.admirian.com/29585cfa-5a2c-4aa8-b6aa-89c3edad9c5d_google-pay.svg" width={"auto"} height={32} />
                    </div>
                    <div>
                        <img src="https://static.admirian.com/2abf1512-c4e2-4b3f-a858-949ea0d8f197_phonepe.svg" width={"auto"} height={32} />
                    </div>
                </FlexContainer>
           </ScannerContainer>
           <UpiIdContainer>
                <UpiIdContentDescription>UPI ID</UpiIdContentDescription>
                <SpaceBetweenContainer>
                    <UpiId>admirian@kotak</UpiId>
                    <UpiIdCopy onClick={handleUPIIdCopy}>Copy</UpiIdCopy>
                </SpaceBetweenContainer>
                <UpiIdContentDescription>UPI Number</UpiIdContentDescription>
                <SpaceBetweenContainer>
                    <UpiId>8286408471</UpiId>
                    <UpiIdCopy onClick={handleUPINumberCopy}>Copy</UpiIdCopy>
                </SpaceBetweenContainer>
           </UpiIdContainer>
        </PaymentModeContainer>
    )
}

export default PaymentModeDetails;