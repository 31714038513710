import { Col, Row, Spin, Carousel } from "antd";
import React, { useEffect, useState } from "react";
import { AnalyticsDetailCard, AnalyticsDetailCardRow, CardHeadingAnalytics, CardSubHeadingAnalytics, ChannelMatricsContainer, CompanyLogoContainer, CreatorAnalyticsContainer, CreatorMediaContainer, CustomColumn, CustomRow, FeaturedMediaCard, FeaturedPost, FollowerInfoHeading, FollowerInformation, GraphCardContainer, MediaCountText, MediaText, MostActiveMediaContainer, MostMetricCountText, MostMetricText, MostTextHeading, NoDataAvailableText, PastInfoText, PercentageInformation, PeriodText, ToggleButton } from "./style";
import { ResponsiveHeatMap } from "@nivo/heatmap";
import { ResponsiveBar } from '@nivo/bar'
import { RightOutlined, LeftOutlined, CheckOutlined } from '@ant-design/icons';
import { getTimeVariable } from "./utils";
import { FlexContainer } from "../../utils/common";
import { isMobile } from "../../utils/constants/inedx";

const CreatorAnalytics = ({ analyticsData, setAnalyticsData, isLoading }) => {
    const postCarouselRef = React.createRef();
    const reelCarouselRef = React.createRef();
    const [postCarouselActiveIndex, setPostCarouselActiveIndex] = useState(0);
    const [reelCarouselActiveIndex, setReelCarouselActiveIndex] = useState(0);
    const [data, setData] = useState([])
    const [BarData, setBarData] = useState([])
    useEffect(() => {
        if (analyticsData && analyticsData?.activity_heat_map) {
            setData(Object.entries(analyticsData?.activity_heat_map)?.map(([key, valuesArr]) => {
                return (
                    {
                        id: key,
                        data: Object.entries(valuesArr)?.map(([time, value]) => {
                            return (
                                {
                                    x: getTimeVariable[time],
                                    y: value
                                }
                            )
                        })
                    }
                )
            }))
        } else {
            setData([])
        }
        if (analyticsData && analyticsData?.post_density) {
            setBarData(Object.entries(analyticsData?.post_density)?.map(([key, value]) => {
                return (
                    {
                        day: key,
                        "Post Density": value,
                        "Post DensityColor": "#F86624",
                    }
                )
            }))
        } else {
            setBarData([])
        }
    }, [analyticsData])

    return (
        <CreatorAnalyticsContainer>
            <ChannelMatricsContainer id="channelMetrics">
                <CustomRow gutter={[16, 20]}>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24} style={{ display: "flex", flexDirection: "row", marginBottom: '10px' }}>
                        <CompanyLogoContainer>
                            <img
                                src={analyticsData?.profile_details?.profile_pic_url}
                                alt="logo"
                                width={28}
                                height={28}
                                style={{
                                    borderRadius: '50%',
                                }}
                            />
                            <img
                                src="https://static.admirian.com/5ad16d68-65de-4a4e-989c-19edbe81c1fa_Ellipse 16.svg"
                                alt="logo"
                                width={18}
                                height={18}
                                style={{
                                    borderRadius: '50%',
                                    marginLeft: '-8px'
                                }}
                            />
                        </CompanyLogoContainer>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <CardHeadingAnalytics>
                                Channel Metrics
                            </CardHeadingAnalytics>
                        </Col>
                    </Col>
                    <CustomColumn xl={6} lg={6} md={6} sm={12} xs={24}>
                        <AnalyticsDetailCard>
                            <img src="https://static.admirian.com/25f14eaf-c40d-49d6-9500-c672e2ed499b_followers.svg" width={48} height={48} style={{ border: 'unset', borderRadius: '50%' }} />
                            <FollowerInformation>
                                {analyticsData?.channel_metrics?.followers}
                            </FollowerInformation>
                            <FollowerInfoHeading>
                                New Followers
                            </FollowerInfoHeading>
                            <PastInfoText>(Past 30 days)</PastInfoText>
                            <PercentageInformation style={{ color: analyticsData?.channel_metrics?.followers_trend < 0 ? "#E9000B" : "#17A600" }}>
                                <span style={{ marginRight: 4 }}>{analyticsData?.channel_metrics?.followers_trend} %</span>
                                <img src={analyticsData?.channel_metrics?.followers_trend < 0 ? "https://static.admirian.com/86efc633-e04b-4206-af73-07333c9ba3e3_decrementLogo.svg" : "https://static.admirian.com/c66aab96-e919-4573-b46f-a534da62dfef_incrementLogo.svg"} height={24} width={24} />
                            </PercentageInformation>
                            <PeriodText>
                                {`${analyticsData?.channel_metrics?.followers_trend < 0 ? 'Lower' : "Higher"}`} vs. Previous Period
                            </PeriodText>
                        </AnalyticsDetailCard>
                    </CustomColumn>
                    <CustomColumn xl={6} lg={6} md={6} sm={12} xs={24}>
                        <AnalyticsDetailCard>
                            <img src="https://static.admirian.com/f29a4bd9-b951-4b53-bcd4-cc5560d8bef2_profileVisit.svg" width={48} height={48} style={{ border: 'unset', borderRadius: '50%' }} />
                            <FollowerInformation>
                                {analyticsData?.channel_metrics?.profile_views}
                            </FollowerInformation>
                            <FollowerInfoHeading>
                                Profile Views
                            </FollowerInfoHeading>
                            <PastInfoText>(Past 30 days)</PastInfoText>
                            <PercentageInformation style={{ color: analyticsData?.channel_metrics?.profile_views_trend < 0 ? "#E9000B" : "#17A600" }}>
                                <span style={{ marginRight: 4 }}>{analyticsData?.channel_metrics?.profile_views_trend} %</span>
                                <img src={analyticsData?.channel_metrics?.profile_views_trend < 0 ? "https://static.admirian.com/86efc633-e04b-4206-af73-07333c9ba3e3_decrementLogo.svg" : "https://static.admirian.com/c66aab96-e919-4573-b46f-a534da62dfef_incrementLogo.svg"} height={24} width={24} />
                            </PercentageInformation>
                            <PeriodText>
                                {`${analyticsData?.channel_metrics?.profile_views_trend < 0 ? 'Lower' : "Higher"}`} vs. Previous Period
                            </PeriodText>
                        </AnalyticsDetailCard>
                    </CustomColumn>
                    <CustomColumn xl={6} lg={6} md={6} sm={12} xs={24}>
                        <AnalyticsDetailCard>
                            <img src="https://static.admirian.com/56220734-8ead-41e9-ad7b-c2413e2c894a_reach.svg" width={48} height={48} style={{ border: 'unset', borderRadius: '50%' }} />
                            <FollowerInformation>
                                {analyticsData?.channel_metrics?.reach}
                            </FollowerInformation>
                            <FollowerInfoHeading>
                                Reach
                            </FollowerInfoHeading>
                            <PastInfoText>(Past 30 days)</PastInfoText>
                            <PercentageInformation style={{ color: analyticsData?.channel_metrics?.reach_trend < 0 ? "#E9000B" : "#17A600" }}>
                                <span style={{ marginRight: 4 }}>{analyticsData?.channel_metrics?.reach_trend} %</span>
                                <img src={analyticsData?.channel_metrics?.reach_trend < 0 ? "https://static.admirian.com/86efc633-e04b-4206-af73-07333c9ba3e3_decrementLogo.svg" : "https://static.admirian.com/c66aab96-e919-4573-b46f-a534da62dfef_incrementLogo.svg"} height={24} width={24} />
                            </PercentageInformation>
                            <PeriodText>
                                {`${analyticsData?.channel_metrics?.reach_trend < 0 ? 'Lower' : "Higher"}`} vs. Previous Period
                            </PeriodText>
                        </AnalyticsDetailCard>
                    </CustomColumn>
                    <CustomColumn xl={6} lg={6} md={6} sm={12} xs={24}>
                        <AnalyticsDetailCard>
                            <img src="https://static.admirian.com/f29a4bd9-b951-4b53-bcd4-cc5560d8bef2_profileVisit.svg" width={48} height={48} style={{ border: 'unset', borderRadius: '50%' }} />
                            <FollowerInformation>
                                {analyticsData?.channel_metrics?.impressions}
                            </FollowerInformation>
                            <FollowerInfoHeading>
                                Impressions
                            </FollowerInfoHeading>
                            <PastInfoText>(Past 30 days)</PastInfoText>
                            <PercentageInformation style={{ color: analyticsData?.channel_metrics?.impressions_trend < 0 ? "#E9000B" : "#17A600" }}>
                                <span style={{ marginRight: 4 }}>{analyticsData?.channel_metrics?.impressions_trend} %</span>
                                <img src={analyticsData?.channel_metrics?.impressions_trend < 0 ? "https://static.admirian.com/86efc633-e04b-4206-af73-07333c9ba3e3_decrementLogo.svg" : "https://static.admirian.com/c66aab96-e919-4573-b46f-a534da62dfef_incrementLogo.svg"} height={24} width={24} />
                            </PercentageInformation>
                            <PeriodText>
                                {`${analyticsData?.channel_metrics?.impressions_trend < 0 ? 'Lower' : "Higher"}`} vs. Previous Period
                            </PeriodText>
                        </AnalyticsDetailCard>
                    </CustomColumn>
                    <CustomColumn xl={6} lg={6} md={6} sm={12} xs={24}>
                        <AnalyticsDetailCard>
                            <img src="https://static.admirian.com/51e89da2-ac4b-45e4-b35e-7412084e23d5_likes.svg" width={48} height={48} style={{ border: 'unset', borderRadius: '50%' }} />
                            <FollowerInformation>
                                {analyticsData?.channel_metrics?.likes}
                            </FollowerInformation>
                            <FollowerInfoHeading>
                                Likes
                            </FollowerInfoHeading>
                            <PastInfoText>(Past 30 days)</PastInfoText>
                            <PercentageInformation style={{ color: analyticsData?.channel_metrics?.likes_trend < 0 ? "#E9000B" : "#17A600" }}>
                                <span style={{ marginRight: 4 }}>{analyticsData?.channel_metrics?.likes_trend} %</span>
                                <img src={analyticsData?.channel_metrics?.likes_trend < 0 ? "https://static.admirian.com/86efc633-e04b-4206-af73-07333c9ba3e3_decrementLogo.svg" : "https://static.admirian.com/c66aab96-e919-4573-b46f-a534da62dfef_incrementLogo.svg"} height={24} width={24} />
                            </PercentageInformation>
                            <PeriodText>
                                {`${analyticsData?.channel_metrics?.likes_trend < 0 ? 'Lower' : "Higher"}`} vs. Previous Period
                            </PeriodText>
                        </AnalyticsDetailCard>
                    </CustomColumn>
                    <CustomColumn xl={6} lg={6} md={6} sm={12} xs={24}>
                        <AnalyticsDetailCard>
                            <img src="https://static.admirian.com/b6fcca23-3830-493d-b36f-25cb6f8c95d8_totalMedia.svg" width={48} height={48} style={{ border: 'unset', borderRadius: '50%' }} />
                            <FollowerInformation>
                                {analyticsData?.channel_metrics?.saves}
                            </FollowerInformation>
                            <FollowerInfoHeading>
                                Saves
                            </FollowerInfoHeading>
                            <PastInfoText>(Past 30 days)</PastInfoText>
                            <PercentageInformation style={{ color: analyticsData?.channel_metrics?.saves_trend < 0 ? "#E9000B" : "#17A600" }}>
                                <span style={{ marginRight: 4 }}>{analyticsData?.channel_metrics?.saves_trend} %</span>
                                <img src={analyticsData?.channel_metrics?.saves_trend < 0 ? "https://static.admirian.com/86efc633-e04b-4206-af73-07333c9ba3e3_decrementLogo.svg" : "https://static.admirian.com/c66aab96-e919-4573-b46f-a534da62dfef_incrementLogo.svg"} height={24} width={24} />
                            </PercentageInformation>
                            <PeriodText>
                                {`${analyticsData?.channel_metrics?.saves_trend < 0 ? 'Lower' : "Higher"}`} vs. Previous Period
                            </PeriodText>
                        </AnalyticsDetailCard>
                    </CustomColumn>
                    <CustomColumn xl={6} lg={6} md={6} sm={12} xs={24}>
                        <AnalyticsDetailCard>
                            <img src="https://static.admirian.com/64780a6d-7227-487e-a078-662efb16ae1f_share.svg" width={48} height={48} style={{ border: 'unset', borderRadius: '50%' }} />
                            <FollowerInformation>
                                {analyticsData?.channel_metrics?.shares}
                            </FollowerInformation>
                            <FollowerInfoHeading>
                                Shares
                            </FollowerInfoHeading>
                            <PastInfoText>(Past 30 days)</PastInfoText>
                            <PercentageInformation style={{ color: analyticsData?.channel_metrics?.shares_trend < 0 ? "#E9000B" : "#17A600" }}>
                                <span style={{ marginRight: 4 }}>{analyticsData?.channel_metrics?.shares_trend} %</span>
                                <img src={analyticsData?.channel_metrics?.shares_trend < 0 ? "https://static.admirian.com/86efc633-e04b-4206-af73-07333c9ba3e3_decrementLogo.svg" : "https://static.admirian.com/c66aab96-e919-4573-b46f-a534da62dfef_incrementLogo.svg"} height={24} width={24} />
                            </PercentageInformation>
                            <PeriodText>
                                {`${analyticsData?.channel_metrics?.shares_trend < 0 ? 'Lower' : "Higher"}`} vs. Previous Period
                            </PeriodText>
                        </AnalyticsDetailCard>
                    </CustomColumn>
                    <CustomColumn xl={6} lg={6} md={6} sm={12} xs={24}>
                        <AnalyticsDetailCard>
                            <img src="https://static.admirian.com/ab007e7d-dcbe-418e-b76d-c00da298770e_engagement.svg" width={48} height={48} style={{ border: 'unset', borderRadius: '50%' }} />
                            <FollowerInformation>
                                {analyticsData?.channel_metrics?.engagement_current} %
                            </FollowerInformation>
                            <FollowerInfoHeading>
                                Engagement
                            </FollowerInfoHeading>
                            <PastInfoText>(Past 30 days)</PastInfoText>
                            <PercentageInformation style={{ color: analyticsData?.channel_metrics?.engagement_current < analyticsData?.channel_metrics?.engagement_prev ? "#E9000B" : "#17A600" }}>
                                <span style={{ marginRight: 4 }}>{Math.abs(analyticsData?.channel_metrics?.engagement_current - analyticsData?.channel_metrics?.engagement_prev).toFixed(2)} percent points</span>
                                <img src={analyticsData?.channel_metrics?.engagement_current < analyticsData?.channel_metrics?.engagement_prev ? "https://static.admirian.com/86efc633-e04b-4206-af73-07333c9ba3e3_decrementLogo.svg" : "https://static.admirian.com/c66aab96-e919-4573-b46f-a534da62dfef_incrementLogo.svg"} height={24} width={24} />
                            </PercentageInformation>
                            <PeriodText>
                                {`${analyticsData?.channel_metrics?.engagement_current < analyticsData?.channel_metrics?.engagement_prev ? 'Lower' : "Higher"}`} vs. Previous Period
                            </PeriodText>
                        </AnalyticsDetailCard>
                    </CustomColumn>
                </CustomRow>
                <CustomRow gutter={[16, 20]}>
                    <Col xl={8} lg={8} md={8} sm={12} xs={24}>
                        <AnalyticsDetailCard>
                            <img src="https://static.admirian.com/b59d6d49-4bb0-48d7-a6e3-e1fd21463c9b_viewDuration.svg" width={56} height={56} style={{ border: 'unset', borderRadius: '50%' }} />
                            <FollowerInformation>
                                {analyticsData?.channel_metrics?.avg_reel_play_count}
                            </FollowerInformation>
                            <FollowerInfoHeading>
                                Avg. No. of Times
                            </FollowerInfoHeading>
                            <FollowerInfoHeading>
                                a Reel is Played
                            </FollowerInfoHeading>
                        </AnalyticsDetailCard>
                    </Col>
                    <Col xl={8} lg={8} md={8} sm={12} xs={24}>
                        <AnalyticsDetailCard>
                            <img src="https://static.admirian.com/399bd3a1-a2ac-4299-bdcc-a46d2cfb3a73_min_watched.svg" width={56} height={56} style={{ border: 'unset', borderRadius: '50%' }} />
                            <FollowerInformation>
                                {analyticsData?.channel_metrics?.avg_reels_play_duration}
                            </FollowerInformation>
                            <FollowerInfoHeading>
                                Avg. Amount of Time
                            </FollowerInfoHeading>
                            <FollowerInfoHeading>
                                a Reel is Viewed
                            </FollowerInfoHeading>
                        </AnalyticsDetailCard>
                    </Col>
                    <Col xl={8} lg={8} md={8} sm={12} xs={24}>
                        <AnalyticsDetailCard>
                            <img src="https://static.admirian.com/399bd3a1-a2ac-4299-bdcc-a46d2cfb3a73_min_watched.svg" width={56} height={56} style={{ border: 'unset', borderRadius: '50%' }} />
                            <FollowerInformation>
                                {analyticsData?.channel_metrics?.avg_reels_view_time}
                            </FollowerInformation>
                            <FollowerInfoHeading>
                                Avg. Overall Watch Duration
                            </FollowerInfoHeading>
                            <FollowerInfoHeading>
                                of a Reel
                            </FollowerInfoHeading>
                        </AnalyticsDetailCard>
                    </Col>
                </CustomRow>
            </ChannelMatricsContainer>
            {analyticsData?.featured_media?.featured_posts.length > 0 &&
                <>
                    <FeaturedPost id="featurePost">
                        <FlexContainer style={{ alignItems: 'center' }}>
                            <CompanyLogoContainer>
                                <img
                                    src={analyticsData?.profile_details?.profile_pic_url}
                                    alt="logo"
                                    height={28}
                                    width={28}
                                    style={{
                                        borderRadius: '50%',
                                    }}
                                />
                                <img
                                    src="https://static.admirian.com/5ad16d68-65de-4a4e-989c-19edbe81c1fa_Ellipse 16.svg"
                                    alt="logo"
                                    height={18}
                                    width={18}
                                    style={{
                                        borderRadius: '50%',
                                        marginLeft: '-8px'
                                    }}
                                />
                            </CompanyLogoContainer>
                            <span>Featured Posts</span>
                        </FlexContainer>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <ToggleButton
                                style={{ marginRight: '15px' }}
                                onClick={() => {
                                    postCarouselRef.current.prev()
                                }}
                                disabled={postCarouselActiveIndex === 0}
                                icon={<LeftOutlined color="#000000" />}
                            >
                            </ToggleButton>
                            <ToggleButton
                                onClick={() => {
                                    postCarouselRef.current.next()
                                }}
                                disabled={postCarouselActiveIndex >= analyticsData?.featured_media?.featured_posts.length - 4}
                                icon={<RightOutlined color="#000000" />}
                            >
                            </ToggleButton>
                        </div>
                    </FeaturedPost>
                    <Row gutter={[20, 20]} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '24px' }}>
                        <Carousel
                            slidesToShow={isMobile ? 1 : 4}
                            infinite={false}
                            ref={postCarouselRef}
                            beforeChange={(current, next) => setPostCarouselActiveIndex(next)}
                        >
                            {analyticsData?.featured_media?.featured_posts?.map((featuredPost, index) => {
                                return (
                                    <Col span={24} key={index}>
                                        <FeaturedMediaCard>
                                            <img
                                                onClick={() => {
                                                    window.open(featuredPost?.permalink)
                                                }}
                                                src={featuredPost?.media_url}
                                                height={240}
                                                className="object-fit-contain m-auto cursor-pointer"
                                                style={{ display: 'flex', borderRadius: '10px' }}
                                            />
                                            <Row gutter={[20, 20]} className="w-100" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <Col xl={8} lg={8} md={8} sm={8} xs={8} className="text-center">
                                                    <img src="https://static.admirian.com/5439d055-3d88-4b7d-9766-cba1cb3f9cc8_heartOutlined.svg" height={20} width={20} className="m-auto" />
                                                    <MediaCountText>{featuredPost?.likes}</MediaCountText>
                                                    <MediaText>Likes</MediaText>
                                                </Col>
                                                <Col xl={8} lg={8} md={8} sm={8} xs={8} className="text-center">
                                                    <img src="https://static.admirian.com/a92d0f1a-88f5-4dc4-8107-cd7bb7642364_commentOutlined.svg" height={20} width={20} className="m-auto" />
                                                    <MediaCountText>{featuredPost?.comments}</MediaCountText>
                                                    <MediaText>Comments</MediaText>
                                                </Col>
                                                <Col xl={8} lg={8} md={8} sm={8} xs={8} className="text-center">
                                                    <img src="https://static.admirian.com/47039255-6d0a-49a1-a30f-cfd91877a28a_engagementOutlined.svg" height={20} width={20} className="m-auto" />
                                                    <MediaCountText>{featuredPost?.impressions}</MediaCountText>
                                                    <MediaText>Impressions</MediaText>
                                                </Col>
                                            </Row>
                                        </FeaturedMediaCard>
                                    </Col>
                                )
                            })}

                        </Carousel>
                    </Row>
                </>
            }

            {analyticsData?.featured_media?.featured_reels.length > 0 &&
                <>
                    <FeaturedPost id="featureReel">
                        <FlexContainer style={{ alignItems: 'center' }}>
                            <CompanyLogoContainer>
                                <img
                                    src={analyticsData?.profile_details?.profile_pic_url}
                                    alt="logo"
                                    style={{
                                        height: '28px',
                                        width: '28px',
                                        borderRadius: '50%',
                                    }}
                                />
                                <img
                                    src="https://static.admirian.com/5ad16d68-65de-4a4e-989c-19edbe81c1fa_Ellipse 16.svg"
                                    alt="logo"
                                    style={{
                                        height: '18px',
                                        width: '18px',
                                        borderRadius: '50%',
                                        marginLeft: '-8px'
                                    }}
                                />
                            </CompanyLogoContainer>
                            Featured Reels
                        </FlexContainer>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <ToggleButton
                                style={{ marginRight: '15px' }}
                                onClick={() => {
                                    reelCarouselRef.current.prev()
                                }}
                                disabled={reelCarouselActiveIndex === 0}
                                icon={<LeftOutlined color="#000000" />}
                            />
                            <ToggleButton
                                onClick={() => {
                                    reelCarouselRef.current.next()
                                }}
                                disabled={reelCarouselActiveIndex >= analyticsData?.featured_media?.featured_reels.length - 4}
                                icon={<RightOutlined color="#000000" />}
                            />
                        </div>
                    </FeaturedPost>
                    <Row gutter={[16, 20]} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '24px' }}>
                        <Carousel
                            slidesToShow={isMobile ? 1 : 4}
                            infinite={false}
                            ref={reelCarouselRef}
                            beforeChange={(current, next) => setReelCarouselActiveIndex(next)}
                        >
                            {analyticsData?.featured_media?.featured_reels?.map((featuredReel, index) => {
                                return (
                                    <Col xl={24} lg={24} md={24} sm={24} xs={24} key={index}>
                                        <FeaturedMediaCard>
                                            <img
                                                onClick={() => {
                                                    window.open(featuredReel?.permalink)
                                                }}
                                                src={featuredReel?.media_url}
                                                height={240}
                                                className="object-fit-contain m-auto cursor-pointer"
                                                style={{ display: 'flex', borderRadius: '10px' }}
                                            />
                                            <Row gutter={[20, 20]} className="w-100" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <Col xl={8} lg={8} md={8} sm={8} xs={8} className="text-center">
                                                    <img src="https://static.admirian.com/5439d055-3d88-4b7d-9766-cba1cb3f9cc8_heartOutlined.svg" height={20} width={20} className="m-auto" />
                                                    <MediaCountText>{featuredReel?.likes}</MediaCountText>
                                                    <MediaText>Likes</MediaText>
                                                </Col>
                                                <Col xl={8} lg={8} md={8} sm={8} xs={8} className="text-center">
                                                    <img src="https://static.admirian.com/a92d0f1a-88f5-4dc4-8107-cd7bb7642364_commentOutlined.svg" height={20} width={20} className="m-auto" />
                                                    <MediaCountText>{featuredReel?.comments}</MediaCountText>
                                                    <MediaText>Comments</MediaText>
                                                </Col>
                                                <Col xl={8} lg={8} md={8} sm={8} xs={8} className="text-center">
                                                    <img src="https://static.admirian.com/f90a67e1-9fb2-46fd-b224-19c74c1107f2_playOutlined.svg" height={20} width={20} className="m-auto" />
                                                    <MediaCountText>{featuredReel?.plays}</MediaCountText>
                                                    <MediaText>Plays</MediaText>
                                                </Col>
                                            </Row>
                                        </FeaturedMediaCard>
                                    </Col>
                                )
                            })}

                        </Carousel>
                    </Row>
                </>
            }

            <Row gutter={[20, 20]} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '24px' }}>
                <Col xl={8} lg={8} md={12} sm={24} xs={24} className="text-center">
                    <MostActiveMediaContainer>
                        <MostTextHeading>
                            Most Liked
                        </MostTextHeading>
                        <img
                            onClick={() => {
                                window.open(analyticsData?.media_metrics?.most_liked_media_permalink)
                            }}
                            src={analyticsData?.media_metrics?.most_liked_media_url} alt="Most liked photo" height={240} className="object-fit-contain cursor-pointer m-auto" style={{ borderRadius: '10px' }} />
                        <img src="https://static.admirian.com/51e89da2-ac4b-45e4-b35e-7412084e23d5_likes.svg" alt="likes" width={48} height={48} className="m-auto" style={{ borderRadius: '50%' }} />
                        <MostMetricCountText>{analyticsData?.media_metrics?.most_liked_media_likes}</MostMetricCountText>
                        <MostMetricText>Likes</MostMetricText>
                    </MostActiveMediaContainer>
                </Col>
                <Col xl={8} lg={8} md={12} sm={24} xs={24} className="text-center">
                    <MostActiveMediaContainer>
                        <MostTextHeading>
                            Most commented
                        </MostTextHeading>
                        <img
                            onClick={() => {
                                window.open(analyticsData?.media_metrics?.most_commented_media_permalink)
                            }}
                            src={analyticsData?.media_metrics?.most_commented_media_url} alt="Most liked photo" height={240} className="object-fit-contain cursor-pointer m-auto" style={{ borderRadius: '10px' }} />
                        <img src="https://static.admirian.com/71043585-f913-4a1d-a116-913ed67a4092_comments.svg" alt="likes" width={48} height={48} className="m-auto" style={{ borderRadius: '50%' }} />
                        <MostMetricCountText>{analyticsData?.media_metrics?.most_commented_media_comments}</MostMetricCountText>
                        <MostMetricText>Comments</MostMetricText>
                    </MostActiveMediaContainer>
                </Col>
                <Col xl={8} lg={8} md={12} sm={24} xs={24} className="text-center">
                    <MostActiveMediaContainer>
                        <MostTextHeading>
                            Most Engaging
                        </MostTextHeading>
                        <img
                            onClick={() => {
                                window.open(analyticsData?.media_metrics?.most_engaged_media_permalink)
                            }}
                            src={analyticsData?.media_metrics?.most_engaged_media_url} alt="Most liked photo" height={240} className="object-fit-contain cursor-pointer m-auto" style={{ borderRadius: '10px' }} />
                        <img src="https://static.admirian.com/ab007e7d-dcbe-418e-b76d-c00da298770e_engagement.svg" alt="likes" width={48} height={48} className="m-auto" style={{ borderRadius: '50%' }} />
                        <MostMetricCountText>{analyticsData?.media_metrics?.most_engaged_media_engagement}</MostMetricCountText>
                        <MostMetricText>Impressions or Plays</MostMetricText>
                    </MostActiveMediaContainer>
                </Col>
            </Row>
            <GraphCardContainer id="postDensity" style={{ height: data.length > 0 ? '330px' : '100px' }}>
                <FlexContainer style={{ alignItems: 'center' }}>
                    <CompanyLogoContainer>
                        <img
                            src={analyticsData?.profile_details?.profile_pic_url}
                            alt="logo"
                            width={28}
                            height={28}
                            style={{
                                borderRadius: '50%',
                            }}
                        />
                        <img
                            src="https://static.admirian.com/5ad16d68-65de-4a4e-989c-19edbe81c1fa_Ellipse 16.svg"
                            alt="logo"
                            height={18}
                            width={18}
                            style={{
                                borderRadius: '50%',
                                marginLeft: '-8px'
                            }}
                        />
                    </CompanyLogoContainer>
                    <div style={{ paddingLeft: 8 }}>
                        <CardHeadingAnalytics>
                            Post Density
                        </CardHeadingAnalytics>
                        <CardSubHeadingAnalytics>
                            No. of media posted across days of the week (for past 6 months)
                        </CardSubHeadingAnalytics>
                    </div>
                </FlexContainer>
                <div className="w-100" style={{ height: data.length > 0 ? "300px" : 'inherit' }}>
                    {BarData.length > 0 ?
                        <ResponsiveBar
                            data={BarData}
                            keys={[
                                'Post Density',
                            ]}
                            indexBy="day"
                            margin={{ top: 50, bottom: 50, left: 60 }}
                            padding={0.3}
                            valueScale={{ type: 'linear' }}
                            indexScale={{ type: 'band', round: true }}
                            colors={["#F86624"]}
                            defs={[
                                {
                                    id: 'dots',
                                    type: 'patternDots',
                                    background: 'inherit',
                                    color: '#38bcb2',
                                    size: 4,
                                    padding: 1,
                                    stagger: true
                                },
                                {
                                    id: 'lines',
                                    type: 'patternLines',
                                    background: 'inherit',
                                    color: '#eed312',
                                    rotation: -45,
                                    lineWidth: 6,
                                    spacing: 10
                                }
                            ]}
                            fill={[
                                {
                                    match: {
                                        id: 'fries'
                                    },
                                    id: 'dots'
                                },
                                {
                                    match: {
                                        id: 'sandwich'
                                    },
                                    id: 'lines'
                                }
                            ]}
                            borderColor={{
                                from: 'color',
                                modifiers: [
                                    [
                                        'darker',
                                        1.6
                                    ]
                                ]
                            }}
                            axisTop={null}
                            axisRight={null}
                            axisBottom={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legendPosition: 'middle',
                                legendOffset: 32
                            }}
                            axisLeft={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legendPosition: 'middle',
                                legendOffset: -40,
                                format: e => Math.floor(e) === e && e
                            }}
                            enableLabel={false}
                            enableGridY={false}
                            labelSkipWidth={12}
                            labelSkipHeight={12}
                            labelTextColor={{
                                from: 'color',
                                modifiers: [
                                    [
                                        'darker',
                                        1.6
                                    ]
                                ]
                            }}
                            legends={[]}
                            role="application"
                            ariaLabel="Nivo bar chart demo"
                            barAriaLabel={e => e.id + ": " + e.formattedValue + " in country: " + e.indexValue}
                        />
                        :
                        <NoDataAvailableText>
                            No Data Available
                        </NoDataAvailableText>
                    }
                </div>
            </GraphCardContainer>
            <GraphCardContainer id="activityHeatMap" style={{ height: data.length > 0 ? '410px' : '100px' }}>
                <FlexContainer style={{ alignItems: 'center' }}>
                    <CompanyLogoContainer>
                        <img
                            src={analyticsData?.profile_details?.profile_pic_url}
                            alt="logo"
                            width={28}
                            height={28}
                            style={{
                                borderRadius: '50%',
                            }}
                        />
                        <img
                            src="https://static.admirian.com/5ad16d68-65de-4a4e-989c-19edbe81c1fa_Ellipse 16.svg"
                            alt="logo"
                            width={18}
                            height={18}
                            style={{
                                borderRadius: '50%',
                                marginLeft: '-8px'
                            }}
                        />
                    </CompanyLogoContainer>
                    <div style={{ paddingLeft: 8 }}>
                        <CardHeadingAnalytics>
                            Activity Heat-map
                        </CardHeadingAnalytics>
                        <CardSubHeadingAnalytics>
                            Most active posting time of the week (for past 6 months)
                        </CardSubHeadingAnalytics>
                    </div>
                </FlexContainer>
                <div className="w-100" style={{ height: data.length > 0 ? "400px" : 'inherit' }}>
                    {data.length > 0 ?
                        <ResponsiveHeatMap
                            data={data}
                            margin={{ top: 60, bottom: 120, left: 90 }}
                            enableLabels={false}
                            axisTop={null}
                            axisBottom={{
                                tickSize: 5,
                                tickPadding: 5,
                                legend: '',
                                legendPosition: 'middle',
                                legendOffset: 36
                            }}
                            axisLeft={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: '',
                                legendPosition: 'middle',
                                legendOffset: -72
                            }}
                            colors={{
                                type: 'sequential',
                                scheme: 'blues',
                            }}
                            emptyColor="#ffffff"
                            inactiveOpacity={0.6}
                            borderColor="black"
                            hoverTarget="cell"
                            animate={false}
                        />
                        :
                        <NoDataAvailableText>
                            No Data Available
                        </NoDataAvailableText>
                    }
                </div>
            </GraphCardContainer>
        </CreatorAnalyticsContainer>
    )

}

export default CreatorAnalytics