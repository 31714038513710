import { Button, Checkbox, Divider, Input, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { FlexContainer, FlexEndContainer, SpaceBetweenContainer } from '../../../utils/common';
import { AmountInfoText, AmountText, DeliverableInfo, DeliverableInfoText, ProductInfoContainer, CustomModal } from './style';
import { useSelector } from 'react-redux';
import { useGetAllDeliverablesQuery, useGetInfluencerRatecardQuery, useGetParticularCampaignQuery } from '../../../services/manageCampaignsApi';
import { useParams } from 'react-router-dom';

const SelectDeliverablesModal = ({ selectDeliverableModal, setSelectDeliverableModal, selectedInfluencerId, handleAddToCampaign, rateWithIds, setRateWithIds, selectedCreatorId, particularInfluencerData }) => {
    const {campaignId} = useParams();
    const { data: allDeliverables = [] } = useGetAllDeliverablesQuery({ campaignId });
    const [estimatedAmount, setEstimatedAmount] = useState(0);
    const [brandValue, setBrandvalue] = useState('');
    const [allDeliverablesData, setAllDeliverablesData] = useState(allDeliverables);
    const [campaignDeliverablesId, setCampaignDeliverablesId] = useState([]);
    const {yt_handle = "", ig_handle = ""} = particularInfluencerData;


    const { data: campaignData = {}, isLoading, isError, isSuccess, error, refetch } =
        useGetParticularCampaignQuery({ campaignId })

    const { data: { ratecard = [] } = {} } =
        useGetInfluencerRatecardQuery({ platform: ["youtube", "instagram"], uuid: selectedInfluencerId });

        useEffect(()=>{
            if(ig_handle.length && yt_handle.length === 0) {
                const filteredData = allDeliverables.filter((item)=> item.platform.toLowerCase() === "instagram" && ratecard.some(rateCard => rateCard?.media_type?.toLowerCase() === item?.media_type?.toLowerCase())) 
                setAllDeliverablesData(filteredData)
            }
            if(yt_handle.length && ig_handle.length === 0) {
                const filteredData = allDeliverables.filter((item)=> item.platform.toLowerCase() === "youtube"  && ratecard.some(rateCard => rateCard?.media_type?.toLowerCase() === item?.media_type?.toLowerCase())) 
                setAllDeliverablesData(filteredData)
            }
            if(ig_handle.length && yt_handle.length){
                const filteredData = allDeliverables.filter((item)=> ratecard.some(rateCard => rateCard?.media_type?.toLowerCase() === item?.media_type?.toLowerCase())) 
                setAllDeliverablesData(filteredData)
            }
        },[particularInfluencerData, allDeliverables, ratecard])

    const showModal = () => {
        setSelectDeliverableModal(true);
    };
    const handleOk = () => {
        setSelectDeliverableModal(false);
    };
    const handleCancel = () => {
        setSelectDeliverableModal(false);
    };

    const handleAmountChange = (e) => {
        const { value: inputValue } = e.target;
        const reg = /^-?\d*(\.\d*)?$/;
        if (reg.test(inputValue) || inputValue === '' || inputValue === '-') {
            // onChange(inputValue);
            setBrandvalue(inputValue);
        }
    };

    const handleDeliverableSelected = (checkedValues) => {
        const deliverableId = checkedValues.map((value) => value.id);
        let estimateAmountValue = 0;
        checkedValues.forEach((selectedData) => {
            const myValue = ratecard.find((rateElement) => rateElement.media_type === selectedData.media_type && rateElement.platform === selectedData.platform)
            estimateAmountValue = estimateAmountValue + myValue.rate;
        });
        setEstimatedAmount(estimateAmountValue);
        setCampaignDeliverablesId(deliverableId);
    }

    const handleCampaignsData = () => {
        const campaign = {
            creator_id: selectedCreatorId,
            campaign_deliverable_ids: campaignDeliverablesId,
        }
        if (brandValue.length) {
            campaign.brand_offer = brandValue
            setRateWithIds({...rateWithIds, [selectedCreatorId]: Number(brandValue)  })
            handleAddToCampaign(campaign, {...rateWithIds, [selectedCreatorId]: Number(brandValue)  })
        }else {
            setRateWithIds({...rateWithIds, [selectedCreatorId]: estimatedAmount  })
            handleAddToCampaign(campaign, {...rateWithIds, [selectedCreatorId]: estimatedAmount  })   
        }
        // if (brandValue.length) {
        //     campaign.brand_offer = brandValue
        // }
        
        // setRateWithIds({...rateWithIds, [selectedCreatorId]: estimatedAmount  })
        // handleAddToCampaign(campaign, {...rateWithIds, [selectedCreatorId]: estimatedAmount  })
    }
    
    return (
        <CustomModal title="Select Deliverables" open={selectDeliverableModal} footer={null} onOk={handleOk} onCancel={handleCancel}>
            <ProductInfoContainer>
                <Checkbox.Group
                    style={{
                        display: 'block'
                    }}
                    onChange={handleDeliverableSelected}
                >
                    {
                        allDeliverablesData.map((info, index) => {

                            return (
                                <DeliverableInfo key={index}>
                                    <Checkbox
                                        value={info}
                                    >
                                        <FlexContainer>
                                            <img src={info.platform === "instagram" ? 'https://static.admirian.com/64d8bcd2-a452-4586-85c8-fcd10261d3c2_Instagram_Glyph_Gradient_1.svg' : "https://static.admirian.com/efd7f530-25f9-48e5-a327-afa92a654071_youtube-icon.svg"} alt="media" width={20} height={20} />
                                            <DeliverableInfoText>{info.name}</DeliverableInfoText>
                                        </FlexContainer>
                                    </Checkbox>
                                </DeliverableInfo>
                            )
                        })
                    }
                </Checkbox.Group>
            </ProductInfoContainer>
            <SpaceBetweenContainer style={{ paddingTop: 12 }}>
                <AmountInfoText>Campaign Budget</AmountInfoText>
                <AmountText>₹{campaignData?.min_budget} - ₹{campaignData?.max_budget}</AmountText>
            </SpaceBetweenContainer>
            <SpaceBetweenContainer style={{ paddingTop: 12 }}>
                <AmountInfoText>Estimated Rate</AmountInfoText>
                <AmountText style={{ color: '#010043' }}>₹{estimatedAmount}</AmountText>
            </SpaceBetweenContainer>
            <SpaceBetweenContainer style={{ paddingTop: 16 }}>
                <AmountInfoText>Your Offer <span style={{ color: '#8988BF' }}>(Optional)</span></AmountInfoText>
                <Input size="large" style={{
                    borderRadius: 20,
                    border: "1px solid #8988BF",
                    width: 120
                }} prefix={"₹"} onChange={handleAmountChange} />
            </SpaceBetweenContainer>
            <Divider />
            <FlexEndContainer>
                <Space>
                    {/* <Button style={{ color: '#010043' }} type='text'>Cancel</Button> */}
                    <Button size='large' shape='round' disabled={!estimatedAmount} 
                    onClick={handleCampaignsData}
                    >
                        Add to Campaign
                    </Button>
                </Space>
            </FlexEndContainer>
        </CustomModal>
    )
}

export default SelectDeliverablesModal;