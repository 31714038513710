import React, { useEffect, useState, useRef, useCallback } from "react";
import Sidebar from "./Sidebar";
import { MasterComponentStyle, MasterLayoutStyle } from "../Home/Header/style";
import FindProfile from "./FindProfile";
import LikedProfile from "./LikedProfile";
import axios from "axios";
import { notification } from "antd";
import { parseJwtToken } from "../../utils";
import { BASE_URL } from "../../utils/HelperFunctions/constants";

const DashboardBrand = () => {

    const [categoryList, setCategoryList] = useState([])
    const [categoryCount, setCategoryCount] = useState([])
    const [categorySelected, setCategorySelected] = useState([])
    const [sortBy,setSortBy] = useState('total_follower_count')
    const [location,setLocation] =useState([])
    const [minFollowers,setMinFollowers] = useState(null)
    const [maxFollowers,setMaxFollowers] = useState(null)
    const [minAge,setMinAge] = useState(13)
    const [maxAge,setMaxAge] = useState(100)
    const [gender, setGender] = useState(["All"])
    const [page,setPage]= useState(1)
    const [creatorsList,setCreatorsList] = useState([])
    const [hasMore,setHasMore] = useState(false)
    const [loadingCreators, setLoadingCreators]= useState(false)
    const [likedUserUuid, setLikedUserUuid] = useState([])
    const [platformType, setPlatformType]= useState("");
    const observer = useRef()
    const refresh_token = parseJwtToken(localStorage.getItem('refresh_token'))
    const lastCreatorCardRef = useCallback((node)=>{
        if(loadingCreators) {
            return
        }
        if(observer.current){
            observer.current.disconnect()
        }
        observer.current = new IntersectionObserver(entries => {
            if(entries[0].isIntersecting && hasMore){
                setPage(prevPage => prevPage + 1)
            }
        })
        if(node){
            observer.current.observe(node)
        }
    },[loadingCreators,hasMore])

    const getLikedUseIdList = async() => {
        const postData = {
            "filters": {},
            "return_params": "creator_uuid"
        }
        try{
            const response = await axios.post(`${BASE_URL}/profiles/brand/${refresh_token?.user_uuid}/likes/creators/`,
            postData)

            if(!response?.data?.error || !response?.data?.errors){
                setLikedUserUuid(response?.data?.creator_uuids)
            }
        }catch(error){
            // notification.error({message : error?.response?.data?.message})
        }
    }

    useEffect(()=>{
        getLikedUseIdList()
    },[])
    const getCategoryList = async() =>{
        const response = await axios.get(`${BASE_URL}/profiles/categories/`)
        if(!response?.data?.error){
            setCategoryList(response?.data)
        }
    }

    const getCategoryCount = async() => {
        const response = await axios.get(`${BASE_URL}/profiles/brand/${refresh_token?.user_uuid}/likes/creators/count/`)
        if(!response?.data?.error){
            setCategoryCount(response?.data?.count)
        }
    }

    useEffect(()=>{
        getCategoryList()
        getCategoryCount()
    },[])
    useEffect(()=>{
        if(gender.length === 0){
            setGender(["All"])
        }
    },[gender])

    const getCreatersList = async(minAge,maxAge,gender,minFollowers,maxFollowers,categorySelected,location,sortBy,page)=>{
        setLoadingCreators(true)
        let postData = {
            filters: {
            },
            sortby: sortBy,
            page: page
        }
        if(minFollowers){
            postData = {
                ...postData,
                filters : {
                    ...postData.filters,
                min_followers: minFollowers,
                }
            }
        }
        if(maxFollowers){
            postData = {
                ...postData,
                filters : {
                    ...postData.filters,
                max_followers: maxFollowers,
                }
            }
        }
        if(minAge && maxAge){
            postData = {
                ...postData,
                filters : {
                    ...postData.filters,
                    min_age: minAge,
                    max_age: maxAge,
                }
                
            }
        }
        if(gender){
            postData = {
                ...postData,
                filters : {
                    ...postData.filters,
                    gender: gender.includes("All") ? ["male","female","non_binary"] : gender,
                }
                
            }
        }
        if(categorySelected.length > 0){
            postData = {
                ...postData,
                filters : {
                    ...postData.filters,
                    categories: categorySelected,
                }
                
            }
        }
        if(location.length > 0){
            postData = {
                ...postData,
                filters : {
                    ...postData.filters,
                    location: location
                }
                
            }
        }
        if(platformType.length){
            postData ={
                ...postData,
                filters: {
                    ...postData.filters,
                    platform: platformType
                }
            }
        }
        try{
            const response = await axios.post(`${BASE_URL}/campaigns/${refresh_token?.user_uuid}/discover-creators/`,
            postData)
            
            if(!response?.data?.error){
                setCreatorsList((previosValue)=>[...previosValue,...response?.data?.creators])
                if(response?.data?.page ===response?.data?.total_pages){
                    setHasMore(false)
                }else{
                    setHasMore(true)
                }
            }
            else{
                notification.error({
                    message : response?.data?.error?.message
                })
            }
            setLoadingCreators(false)
        }catch(error){
            setLoadingCreators(false)
        }
    }

    // useEffect(()=>{
    //     setCreatorsList([])
    // },[minAge,maxAge,gender,minFollowers,maxFollowers,categorySelected,location,sortBy])

    useEffect(()=>{
        console.log('hit',minAge,maxAge,gender,minFollowers,maxFollowers,categorySelected,location,sortBy, platformType)
        setPage(1)
        setCreatorsList(()=>[])
        getCreatersList(minAge,maxAge,gender,minFollowers,maxFollowers,categorySelected,location,sortBy,1, platformType)
    },[minAge,maxAge,gender,minFollowers,maxFollowers,categorySelected,location,sortBy, platformType])

    useEffect(()=>{
        if(page !== 1){
        getCreatersList(minAge,maxAge,gender,minFollowers,maxFollowers,categorySelected,location,sortBy,page)
        }
    },[page])

    const [defaultSelectedKey, setDefaultSelectedKey] = useState('findProfile')

    return(
                <>
                    <Sidebar
                    defaultSelectedKey = {defaultSelectedKey}
                    setDefaultSelectedKey={setDefaultSelectedKey}
                    location = {location}
                    setLocation = {setLocation}
                    minFollowers = {minFollowers}
                    maxFollowers = {maxFollowers}
                    setMinFollowers = {setMinFollowers}
                    setMaxFollowers = {setMaxFollowers}
                    minAge = {minAge}
                    maxAge = {maxAge}
                    setMinAge = {setMinAge}
                    setMaxAge = {setMaxAge}
                    gender= {gender}
                    setGender = {setGender}
                    categoryCount ={categoryCount}
                    platformType={platformType}
                    setPlatformType={setPlatformType}
                    />
                    <MasterComponentStyle>
                        {defaultSelectedKey === 'findProfile'
                        ?   
                            <FindProfile
                            categoryList = {categoryList}
                            setCategoryList = {setCategoryList}
                            categorySelected = {categorySelected}
                            setCategorySelected = {setCategorySelected}
                            sortBy = {sortBy}
                            setSortBy = {setSortBy}
                            creatorsList = {creatorsList}
                            useRef = {useRef}
                            lastCreatorCardRef ={lastCreatorCardRef}
                            loadingCreators = {loadingCreators}
                            getCategoryCount ={getCategoryCount}
                            likedUserUuid = {likedUserUuid}
                            getLikedUseIdList = {getLikedUseIdList}
                            />
                        :
                            <LikedProfile
                            sortBy = {sortBy}
                            setSortBy = {setSortBy}
                            getCategoryCount = {getCategoryCount}
                            likedUserUuid = {likedUserUuid}
                            getLikedUseIdList = {getLikedUseIdList}
                            />
                        }        
                                    
                    </MasterComponentStyle>   
                </>  
    )
}

export default DashboardBrand