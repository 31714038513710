import styled from 'styled-components';
import { Button } from "antd";
import media from '../../utils/UIElements/media';

export const CarouselContainer = styled.div`
    display :flex;
    width : 100%;
    background-image: url(https://static.admirian.com/6041b757-e093-41fd-b55b-fad9065ba327_BgImgSignIn.svg),linear-gradient(180deg, #241046 0%, #130D58 100%);
    background-repeat : no-repeat;
    display : flex;
    justify-content : center;
    align-items : center;

    .slide{
        transform : scale(0.7);
        transition: transform 300ms;
        opacity : 0.4;
    }
    .active-slide{
        transform : scale(1.1);
        opacity : 1;
        
    }

    .slick-slide.slick-active > div {
        display:flex;
        justify-content: center;
    }


    .ant-input, .ant-select-selector{
        border: 1px solid #8988BF !important;
        border-radius: 60px;
        height : 38px !important;
    }
    .ant-select-selection-item, .ant-select-selection-placeholder, .ant-select-selection-search{
        display : flex !important;
        align-items : center !important;
    }
    .slick-dots{
        width : 0px;
    }
    .ant-upload-wrapper {
        display : flex;
        justify-content: center;
    }
    .ant-upload-list-item , .ant-upload-list{
        width: 192px !important;
        height: 192px !important;
        display: flex !important;
        border-radius: 200px !important;
        border : 0px;
    }
    .ant-upload-list-item-container, .ant-upload {
        width: 192px !important;
        height: 192px !important;
        display: inline-block;
        border-radius: 200px !important;
        border : 0px;
    }
    .ant-upload-list-item:hover::before{
        border-radius : 200px;
        border : 0px !important;
    }
    .ant-input-group .ant-input-group-addon:first-child{
        border: 1px solid #8988BF !important;
        border-right: 0px;
        border-start-start-radius: 60px;
        border-end-start-radius: 60px;
    }
    .ant-radio-wrapper{
        background: #F6F6FF;
        padding: 15px 12px;
        border-radius: 30px;
        ${media.mobile`
            padding: 12px 8px; 
        `}
    }
    .ant-form label {
        ${media.mobile`
            font-size: 12px;
        `}
    }
    .ant-picker{
        display : flex;
        height : 38px;
        border-radius: 60px;
        border: 1px solid #8988BF !important;
    }
`

export const CarouselBox = styled.div`
    display : flex !important;
    justify-content : center;
    align-items : center;
    flex-direction : column;
    width: 350px !important;
    background : #FFFFFF;
    border-radius : 20px;
    margin : 40px 20px;
    padding : 30px 16px;
    ${media.mobile`
        margin : 40px 12px;
        width: 100%;
        padding: 16px 12px;
    `}
`

export const CustomButton = styled(Button)`
    display : flex;
    height : 40px;
    margin: 5px;
    border-radius : 60px;
    justify-content: center;
    align-items: center;
    background: ${(props) => {
        switch (props.isActive) {
          case true:
            return '#FFFFFF !important';
          default:
            return '#F6F6FF !important';
        }
    }};
    border: ${(props) => {
        switch (props.isActive) {
          case true:
            return '1px solid #F86624 !important'
          default:
            return '0px !important';
        }
    }};
    color: ${(props) => {
        switch (props.isActive) {
          case true:
            return '#F86624 !important'
          default:
            return '#010043 !important';
        }
    }};  
`

export const ExploreInformationText = styled.div`
    color: #010043;
    opacity: 0.5;
    display: flex; 
    text-align: center;
    font-size: 18px;
    line-height: 22px;
    margin-top: 12px;
    ${media.mobile`
        font-size: 14px;
    `}
`;

export const BasicInformationHeading = styled.div`
    font-size: 18px;
    width: 100%;
    font-weight: 600;
    padding-bottom: 24px;
    color: #010043;
    display: flex;
`;

export const DoThisLater = styled.div`
    font-size : 16px;
    line-height: 20px;
    color : #010043;
    margin-top: 12px;
`;

export const GetStartedText = styled.div`
    display: flex;
    width: 100%;
    justify-content: center; 
    align-items : center;
    font-weight : 600;
    font-size : 24px;
    background : linear-gradient(96.74deg, #4E4BEE -1.75%, #F86624 112.67%);
    webkit-background-clip : text;
    webkit-text-fill-color : transparent;
    background-clip : text;
`;