import { Modal } from "antd";
import React from "react";
import { CustomButton, ModalContainer } from "./style";

const FetchingModal = ({visible, setVisible,modalError,setModalError,getCreatorAnalytics}) => {

    return(
        <ModalContainer>
            <Modal
            visible = {visible}
            closable = {modalError ? true : false}
            destroyOnClose = {true}
            onCancel={()=>{
                setModalError(false)
                setVisible(false)
            }}
            width={450}
            footer = {null}
            >
                 <div style={{color: "var(--typo-high, #0F0E1B)",textAlign: "center",fontFamily: "Inter",fontSize: "24px",fontWeight: "600",marginBottom : '40px'}}>
                 {!modalError ? `Relax! we are setting up your account` : 'Something went wrong, Please try again.'}
                 </div>
                 <div style={{display : 'flex',width : '100%',justifyContent : 'center'}}>
                 <img src="https://static.admirian.com/Admirian_Logo.png" style={{width : '200px', height : '200px',marginBottom : '20px'}}/>
                 </div>
                 {!modalError ? 
                    <div style={{color: "var(--typo-high, #494785)",textAlign: "center",fontFamily: "Inter",fontSize: "14px",lineHeight : '20px',marginBottom : '30px'}}>
                    Fetching insights.....
                    </div>
                    : 
                    <div style={{display : 'flex',width : '100%',justifyContent : 'center', marginBottom : '20px'}}>
                    <CustomButton
                    onClick={()=>{
                        setModalError(false)
                        getCreatorAnalytics()
                    }}
                    >
                        Try again
                    </CustomButton>
                    </div>
                 }
                 <div style={{color: "var(--typo-high, #494785)",textAlign: "center",fontFamily: "Inter",fontSize: "16px",lineHeight : '24px',marginBottom : '20px'}}>
                 Optimise your content and find what works best for you with our advanced insights
                 </div>
            </Modal>
        </ModalContainer>
    )
}

export default FetchingModal