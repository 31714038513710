import React from 'react';
import { isMobile } from '../../utils/constants/inedx';
import { Image} from "antd";
import { Heading6, Container, NewFormHeadingText, NewPasswordFormContainer, SignInText} from './style';
import { Link } from 'react-router-dom';
import { JustifyCenterContainer } from '../../utils/common';
import NewPasswordForm from '../../components/Home/NewPasswordForm';
import Footer from '../../components/Footer';

const CreateNewPassword = () => {
    return (
        <>
         <JustifyCenterContainer className="w-100" style={{ minHeight: 50, alignItems: 'center', padding: '6px 0px' }}>
                <Image height={50} style={{ margin: "4px 0px" }} preview={false} src="https://static.admirian.com/9a312601-984e-4237-bfad-1454204ce38f_BrandLogo.svg" alt="brand-logo" />
            </JustifyCenterContainer>
        <Container>  
        <Heading6>
            <NewPasswordFormContainer
                style={{ minWidth: !isMobile ? '400px' : '300px'}}
            >
                <img src="https://static.admirian.com/90181a30-954a-482d-88cf-fc900442f205_otp.svg" width={120} height={120} alt="lock" />
                <NewFormHeadingText>Create Your New Password</NewFormHeadingText>
                <NewPasswordForm />
                <Link to="/Sign-in" style={{textDecoration: 'unset'}}>
                    <SignInText>Sign In</SignInText>
                </Link>
            </NewPasswordFormContainer>
        </Heading6>
     </ Container>
     <Footer/>
     </>
    )
}

export default CreateNewPassword;