import React, { useState } from 'react';
import { Container, CardBox, Checkboxes, UploadImg, CampaignNameInput, BriefHolder, 
    CardDetails, BasicInfluencerHeading, InfluencerCardHeading, InfluencerCardDetails, 
    RadioContainer1,InfluencerSubDetails, NumericField, CategoryContainer } from './style';
//import { SearchOutlined } from '@ant-design/icons';
import { Card, Input, message, Form, notification, Upload } from 'antd';
import { FlexContainer, Heading, SubHeading } from '../../../../utils/common';
import { FileShadowIcon, PeopleShadowIcon } from '../../../../assets/icons';
import { Radio } from 'antd';

const { Meta } = Card;
const { TextArea } = Input;
const { Dragger } = UploadImg;

const RadioGroup1 = ({ setDeliveryMode }) => {
    return (

        <Radio.Group defaultValue="141" buttonStyle="solid" name="radiogroup1" onChange={(e) => setDeliveryMode(e.target.value)}>
            <FlexContainer>
                <RadioContainer1 style={{ marginRight: 16 }}>
                    <Radio value="141">Organic paid post</Radio>
                </RadioContainer1>
                <RadioContainer1>
                    <Radio value="142">Only content</Radio>
                </RadioContainer1>
            </FlexContainer>
        </Radio.Group>

    );
};

const BasicDetails = ({ setSelectedCard, setBannerFile, setCampaignName, setBriefDescription, setCampaignObjective, 
    setCampaignCategory, selectedCard, inFluencerFollowerCount, setInFluencerFollowerCount, genderSelected, setGenderSelected, setDeliveryMode, setMinValue, 
    setMaxValue, maxValue, minValue}) => {
    const [loading, setLoading] = useState(true);

    const uploadFileProps = {
        name: 'banner',
        onRemove: (file) => {
            setBannerFile([]);
        },
        beforeUpload: async (file) => {
            if (
                file.type === 'image/png' ||
                file.type === 'image/jpeg' ||
                file.type === 'image/jpg'
            ) {
                setBannerFile(file);
                return false;
            } else {
                notification.error({
                    message: 'You can only upload png/jpeg/jpg file!',
                });
                return Upload.LIST_IGNORE;
            }
        },
    };

    const minChange = (e) => {
        const numberInput = e.target.value.replace(/[^0-9]/g, '');
        setMinValue(numberInput);
    };

    const maxChange = (e) => {
        const maxNumberInput = e.target.value.replace(/[^0-9]/g, '');
        setMaxValue(maxNumberInput);
    };


    const handleCardClick = (cardNumber) => {
        setSelectedCard(cardNumber);
    };

    const renderCheckbox = (cardNumber) => {
        return selectedCard === cardNumber ? (
            <Checkboxes type="checkbox" checked readOnly />
        ) : null;
    };

    const onGenderChange = (e) => {
        setGenderSelected(e.target.value)
    };

    return (
        <div style={{ padding: '20px 48px' }}>
            <Container>
                <div>
                    <Heading>Lets start with basic details of your campaign</Heading>
                    <SubHeading>Fill basic campaign details and objectives to get started with the campaign creation</SubHeading>
                    <BasicInfluencerHeading>Select how you want to hire influencer</BasicInfluencerHeading>
                    <CardBox>
                        <Card className={`card-1 cursor-pointer ${selectedCard === 102 ? 'selected' : ''}`} onClick={() => handleCardClick(102)}>
                            {renderCheckbox(102)}
                            <CardDetails>
                                <PeopleShadowIcon />
                                <Meta title={<InfluencerCardHeading>Select Influencers for Campaign</InfluencerCardHeading>} />
                                <InfluencerCardDetails>You can select specific influencers and connect with them for your campaign.</InfluencerCardDetails>
                            </CardDetails>
                        </Card>
                        <Card className={`card-2 cursor-pointer ${selectedCard === 101 ? 'selected' : ''}`} onClick={() => handleCardClick(101)}>
                            {renderCheckbox(101)}
                            <CardDetails>
                                <FileShadowIcon />
                                <Meta title={<InfluencerCardHeading>Post Campaign on Admirian</InfluencerCardHeading>} />
                                <InfluencerCardDetails>Broadcast your campaign on the Admirian platform, where influencers can apply, then choose who to collaborate with</InfluencerCardDetails>
                            </CardDetails>
                        </Card>
                    </CardBox>
                    <div>
                        <BasicInfluencerHeading>Campaign banner</BasicInfluencerHeading>
                        <Dragger {...uploadFileProps} maxCount={1}>

                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            <p className="ant-upload-hint">
                                Upload Image
                            </p>
                        </Dragger>
                    </div>
                    <div>
                        <BasicInfluencerHeading>Give a name to your campaign</BasicInfluencerHeading>
                        <CampaignNameInput onChange={(e) => setCampaignName(e.target.value)} size='large' placeholder="e.g. Summer Beauty Fest" allowClear />
                    </div>
                    <BriefHolder>
                        <BasicInfluencerHeading>Write a brief about your campaign</BasicInfluencerHeading>
                        <TextArea
                            onChange={(e) => setBriefDescription(e.target.value)}
                            placeholder="e.g. Share your summer makeup looks and tips to inspire others. Include beach-friendly products..."
                            size='large'
                            allowClear
                        />
                    </BriefHolder>
                    <div>
                        <BasicInfluencerHeading>What this campaign is about</BasicInfluencerHeading>
                        <Radio.Group defaultValue="111" onChange={(e) => setCampaignObjective(e.target.value)}>
                            <Radio.Button value="111">Product Launch</Radio.Button>
                            <Radio.Button value="112">Promotion</Radio.Button>
                            <Radio.Button value="113">Review</Radio.Button>
                            <Radio.Button value="114">Create Awareness</Radio.Button>
                            <Radio.Button value="115">Other</Radio.Button>
                        </Radio.Group>
                    </div>
                    <div>
                        <BasicInfluencerHeading>Select category of this campaign</BasicInfluencerHeading>
                        <Radio.Group defaultValue="121" onChange={(e) => setCampaignCategory(e.target.value)}>
                            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '15px'}}>
                                <Radio.Button value="121">Fashion</Radio.Button>
                                <Radio.Button value="125">Lifestyle</Radio.Button>
                                <Radio.Button value="123">Fitness</Radio.Button>
                                <Radio.Button value="124">Automotive</Radio.Button>
                                <Radio.Button value="129">Art</Radio.Button>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <Radio.Button value="126">Travel</Radio.Button>
                                <Radio.Button value="127">Food</Radio.Button>
                                <Radio.Button value="128">Technology</Radio.Button>
                                <Radio.Button value="122">Education</Radio.Button>
                                <Radio.Button value="130">Other</Radio.Button>
                            </div>
                        </Radio.Group>
                    </div>
                    <div>
                        <BasicInfluencerHeading>Choose campaign type</BasicInfluencerHeading>
                        <RadioGroup1 setDeliveryMode={setDeliveryMode} />
                        <InfluencerSubDetails>Pay influencers a fee for them to create and share content that promotes your brand (sponsored posts).</InfluencerSubDetails>
                    </div>
                    <FlexContainer style={{ gap: 12 }}>
                        <div>
                            <BasicInfluencerHeading>Set Min budget</BasicInfluencerHeading>
                            <NumericField size='large' value={minValue} onChange={minChange} type='text' placeholder="Enter numeric value" allowClear />
                        </div>
                        <div>
                            <BasicInfluencerHeading>Set Max budget</BasicInfluencerHeading>
                            <NumericField size='large' value={maxValue} onChange={maxChange} type='text' placeholder="Enter numeric value" allowClear />
                        </div>
                    </FlexContainer>
                    {
                        selectedCard === 101 &&
                        <>
                            <CategoryContainer>
                                <BasicInfluencerHeading>Influencer follower count</BasicInfluencerHeading>
                                <Radio.Group defaultValue="50" onChange={(e)=> setInFluencerFollowerCount(e.target.value)} value={inFluencerFollowerCount}>
                                    <Radio.Button value="50">Nano(2K-10K)</Radio.Button>
                                    <Radio.Button value="51">Micro(10K-25K)</Radio.Button>
                                    <Radio.Button value="52">Mid Tier(25K-100K)</Radio.Button>
                                    <Radio.Button value="53">Macro(100K-250K)</Radio.Button>
                                    <Radio.Button value="54">Mega(250K+)</Radio.Button>
                                </Radio.Group>
                            </ CategoryContainer>
                            <CategoryContainer>
                                <BasicInfluencerHeading>Select influencer’s gender</BasicInfluencerHeading>
                                <Radio.Group onChange={onGenderChange} defaultValue={"male"} value={genderSelected}>
                                    <Radio value={"male"}>Male</Radio>
                                    <Radio value={"female"}>Female</Radio>
                                    <Radio value={"any"}>Any</Radio>
                                </Radio.Group>
                            </CategoryContainer>
                        </>
                    }
                </div>
            </Container>
        </div>
    );
}

export default BasicDetails;