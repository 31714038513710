import React, { useState } from 'react';
import { Collapse } from 'antd';
import { CategoryContainer, CollapseDetail, CollapseHeader, CollapseSubDetail, InputField, ProductDetailsExtraInformation, ProductLabel } from './style';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Radio } from 'antd';

const { Panel } = Collapse;

const AddProductDetails = ({associatedProductName, setAssociatedProductName, productGiftingType ,setProductGiftingType}) => {
    const onRadioChange = (e) => {
        setProductGiftingType(e.target.value);
    };

    return (<Collapse
        expandIconPosition='end'
        bordered={false}
        style={{ marginTop: 32 }}
        defaultActiveKey={"1"}
        expandIcon={({ isActive }) => isActive ? <UpOutlined /> : <DownOutlined />}
    >
        <Panel header={<>
            <CollapseHeader>Add Product Details</CollapseHeader>
            <CollapseSubDetail>Add product details if you need to ship your product or influencers to buy it</CollapseSubDetail>
        </>} key="1" >
            <CollapseDetail>
                <ProductLabel>Product Name</ProductLabel>
                <InputField placeholder="Enter product name" value={associatedProductName} allowClear onChange={(e) => setAssociatedProductName(e.target.value)} />
                <ProductLabel style={{ marginTop: 20 }}>How do you want to send this gift?</ProductLabel>
                <Radio.Group onChange={onRadioChange} value={productGiftingType}>
                    <Radio value={"401"}>Send as a gift</Radio>
                    <Radio value={"402"}>Send as a trial product</Radio>
                </Radio.Group>
                <ProductDetailsExtraInformation>Influencer doesn't have to pay for the product</ProductDetailsExtraInformation>
            </CollapseDetail>
        </Panel>
    </Collapse >
    )
}
export default AddProductDetails;